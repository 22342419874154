import React, {FC, useEffect} from 'react';
import Input from '@/app/modules/components/filterInputs/Input';
import Select from '@/app/modules/components/filterInputs/Select';
import Buttons from '@/app/modules/components/Buttons';
import {IMerchantListItem, transactionStatusLabels} from '@/app/interfaces';
import {IAdminTransactionFilters} from '@/app/interfaces/filters';
import {KTIcon} from '@/_metronic/helpers';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getAllMerchants} from '@/app/utils/requests/adminRequests';


interface Props {
    handleFilterSubmit: () => void;
    handleFilterReset: () => void;
    handleFilterChange: (name: string, value: string | object | null) => void;
    filters: IAdminTransactionFilters;
}

const TransactionFilters: FC<Props> = ({handleFilterSubmit, handleFilterReset, handleFilterChange, filters}) => {
    const _ = useTranslation();
    const merchantOptions = {};
    const [merchants, setMerchants] = React.useState<IMerchantListItem[]>([]);

    useEffect(() => {
        getAllMerchants().then((res) => {
            setMerchants(res.data);
        });
    }, []);
    merchants.forEach((merchant) => {
        merchantOptions[merchant.id] = merchant.merchantName;
    });
    const translatedStatusLabels = {};
    Object.keys(transactionStatusLabels).forEach((key) => {
        translatedStatusLabels[key] = _(transactionStatusLabels[key]);
    });
    return (
        <div className="row mb-10 gap-5">
            <div className="col-md ">
                <Input name="searchQuery" value={filters.searchQuery} label={_('Filter.SEARCH_QUERY')} onChange={handleFilterChange} />
            </div>
            <div className="col-md ">
                <Select isClearable options={translatedStatusLabels} name="status" label={_('Filter.STATUS')} onChange={handleFilterChange} value={filters.status} className={'form-control-solid'} />
            </div>
            <div className="col-md">
                <Select isClearable options={merchantOptions} name="merchant" label="Merchant" onChange={handleFilterChange} value={filters.merchant} className={'form-control-solid'} isSearchable={true} />
            </div>

            <div className={'col-md flex-grow-0 align-self-end'}>
                <Buttons>
                    <button onClick={handleFilterSubmit} className="btn  btn-primary  px-6">{_('Filter.FILTER')}</button>
                    <button onClick={handleFilterSubmit} className="btn btn-secondary">
                        <KTIcon iconName={'arrows-circle'} className={'fs-1 me-1'} />
                    </button>
                </Buttons>
            </div>
        </div>
    );
};

export default TransactionFilters;