import {type FC} from 'react';
import Report from '@/app/pages/common/Report/Report';

interface Props {
    id?: string;
}

const MerchantReport: FC<Props> = ({id}) => {
    if (id) {
        return <Report type={'merchantFromAdmin'} merchantId={id} />;
    }
    return <Report type={'merchant'} />;
};

export default MerchantReport;