import React, {FC, ReactNode} from 'react';
import CardHeader from "@/app/modules/components/card/CardHeader";
import clsx from "clsx";

interface Props {
    className?: string;
    title?: string;
    subTitle?: string | ReactNode;
    children: ReactNode;
    footer?: ReactNode;
    headerButton?: ReactNode;

}

const Card: FC<Props> = (props) => {
    return (
        <div className={clsx("card mb-5 mb-xl-8", {
            [props.className as string]: Boolean(props.className),
        })}>
            {props.title && (
                <CardHeader title={props.title} subTitle={props.subTitle} headerButton={props.headerButton}/>
            )}
            <div className={`card-body py-3 ${!props.title ? "pt-10" : ""}`}>
                {props.children}
            </div>
            {props.footer && (
                props.footer
            )}
        </div>
    );
};

export default Card;