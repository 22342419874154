import React, {FC, useMemo} from 'react';
import Select, {MultiValue, SingleValue} from 'react-select';
import {IOption} from '@/app/interfaces';
import Label from '@/app/modules/components/filterInputs/Label';
import {useTranslation} from '../../hooks/useTranslation';


export interface ICommonSelectProps {
    name: string;
    placeholder?: string;
    onChange: (name: string, value: string | string[] | null) => void;
    value: string | string[] | null;
    isClearable?: boolean;
    isMulti?: boolean;
    isDisabled?: boolean;
    isSearchable?: boolean;
    className?: string;
    label?: string;
}

interface Props extends ICommonSelectProps {
    options: Record<string, string>;
}

const Select2: FC<Props> = (props) => {
    const _ = useTranslation();
    // TODO: Performans optimizasyonu yapılabilir.
    const {
        placeholder,
        options,
        onChange,
        name,
        value,
        isClearable = false,
        isSearchable = false,
        isMulti = false,
        isDisabled = false,
        className,
        label,
    } = props;


    const formattedOptions: IOption[] = useMemo(() => {
        return Object.entries(options).map(([key, value]) => ({
            value: key,
            label: value,
        }));
    }, [options]);


    const getValue = () => {
        if (isMulti && Array.isArray(value)) {
            return formattedOptions.filter(option => value.includes(option.value));
        } else {
            return formattedOptions.find(option => option.value === value) || null;
        }
    };

    const singleChangeHandler = (selectedOption: SingleValue<any>) => {
        if (selectedOption && !Array.isArray(selectedOption)) {
            const selectedValue = typeof selectedOption.value !== 'undefined' ? selectedOption.value : selectedOption;
            onChange(name, selectedValue);
        } else {
            onChange(name, null);
        }
    };

    const multiChangeHandler = (selectedOption: MultiValue<any>) => {
        if (Array.isArray(selectedOption)) {
            const selectedValues = selectedOption.map(option => option.value);
            onChange(name, selectedValues);
        }
    };

    const commonOptions = {
        isDisabled,
        id: `id_${name}`,
        className,
        value: getValue(),
        options: formattedOptions,
        classNamePrefix: 'react-select',
        isClearable,
        placeholder: placeholder || _(`Select.PLACEHOLDER`),
        isSearchable,
    };


    return (
        <>
            {label && <Label label={label} id={`id_${name}`} />}
            {isMulti ?
                <Select
                    {...commonOptions}
                    onChange={multiChangeHandler}
                    isMulti
                />
                :
                <Select
                    {...commonOptions}
                    onChange={singleChangeHandler}
                />

            }
        </>
    );
};

export default Select2;