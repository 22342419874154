import React, {FC} from 'react';
import {KTIcon} from "@/_metronic/helpers";

interface Props {
    name: string;
    value: string;
    iconClassName: string;
    label: string;
    description: string;
    checked: boolean;
    onChange: (type: any) => void;
}

const HugeRadioInput: FC<Props> = (props) => {
    return (
        <>
            <input name={props.name} type="radio" className="btn-check" value={props.value}
                   checked={props.checked} onChange={() => props.onChange(props.value)}
                   id={`option_${props.value}`}/>
            <label
                className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-5"
                htmlFor={`option_${props.value}`}>
                <KTIcon iconName={props.iconClassName} className={"fs-4x me-4"}/>
                <span className="d-block fw-semibold text-start">
                    <span className="text-dark fw-bold d-block fs-3">{props.label}</span>
                    <span className="text-muted fw-semibold fs-6">
                        {props.description}
                    </span>
                </span>
            </label>
        </>
    );
};

export default HugeRadioInput;