import React, {FC, useEffect, useState} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {KTIcon} from '@/_metronic/helpers';
import {useDevice} from '@/app/modules/providers/DeviceProvider';
import {Link} from 'react-router-dom';
import {AdminRouteConstants} from '@/app/constants/routes.constants';
import clsx from 'clsx';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import {toast} from 'react-toastify';
import {IBalance} from '@/app/interfaces';
import {getAdminWallets} from '@/app/utils/requests/adminRequests';
import {WalletList} from '@/app/pages/common/WalletList/WalletList';
import {useAuth} from '@/app/modules/auth';
import {USER_TYPE} from '@/app/enums';
import {MenuComponent} from '@/_metronic/assets/ts/components';


const AdminWalletList: FC = () => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const {isMobile} = useDevice();
    const [balances, setBalances] = useState<IBalance[]>([]);
    const [totalBalance, setTotalBalance] = useState<string>('');

    const {currentUser} = useAuth();

    useEffect(() => {
        getAdminWallets().then((res) => {
            setBalances(res.data.wallets);
            setTotalBalance(res.data.totalUsdValue);

            setTimeout(() => {
                MenuComponent.reinitialization();
            }, 500);
        }).catch((e) => {
            toast.error(getErrorMessage(e));
        });
    }, [getErrorMessage]);

    return (
        <>
            <ToolbarWrapper title={_('WalletList.TITLE')}>
                <div className={'d-flex align-items-center gap-3 gap-lg-3 toolsButtons'}>
                    <Link to={AdminRouteConstants.WITHDRAWALS} className={clsx('btn btn-outline btn-outline-primary', {
                        'btn-sm': !isMobile,
                    })}>
                        <KTIcon iconName={'send'} className={'fs-2 me-1'} />
                        {_('WithdrawalList.TITLE')}
                    </Link>
                </div>
            </ToolbarWrapper>
            <WalletList title={_('WalletList.TITLE')} subTitle={_('WalletList.SUBTITLE')} balances={balances} isAdminWallets hideActions={currentUser?.userType !== USER_TYPE.SUPER_ADMIN} totalBalance={totalBalance} />

        </>
    );
};

export default AdminWalletList;
