import React, {FC, useMemo} from 'react';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import ThemeSwitcher from '@/app/modules/auth/components/tools/ThemeSwitcher';
import LanguageSwitcher from '@/app/modules/auth/components/tools/LanguageSwitcher';
import {useThemeMode} from '@/_metronic/partials';
import Timer from '@/app/pages/payment/pay/Timer';
import {TTransactionStatus} from '@/app/interfaces';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    endTime?: Date;
    onTimerEnd?: () => void;
    transactionStatus?: TTransactionStatus;
}

const Header: FC<Props> = ({endTime, onTimerEnd, transactionStatus}) => {

    const {mode} = useThemeMode();
    const _ = useTranslation();

    const countDown = useMemo(() => {
        if (transactionStatus === 'completed') {
            return <>
                <img src={toAbsoluteUrl('/custom/svg/checked.svg')} alt="Success" />
                {_('Success.PAYMENT_COMPLETED')}
            </>;
        }
        if (transactionStatus === 'failed') {
            return <>
                <img src={toAbsoluteUrl('/custom/svg/hourglass.svg')} alt="Failed" />
                {_('Timeout.PAYMENT_TIMEOUT')}
            </>;
        }

        return false;
    }, [transactionStatus, _]);

    return (
        <div className="top">
            <div className="logo">
                <img src={toAbsoluteUrl(mode === 'dark' ? '/media/logos/nullpay-default-dark.svg' : '/media/logos/nullpay-default.svg')} alt="" />
            </div>

            <div className="countdown">
                {countDown ? countDown : (
                    <>
                        <img src={toAbsoluteUrl('/custom/svg/hourglass-2.svg')} alt="" />
                        <Timer onEnd={onTimerEnd} endTime={endTime} />
                    </>
                )}
            </div>
            <div className="tools">
                <ThemeSwitcher />
                <LanguageSwitcher />
            </div>
        </div>
    );
};

export default Header;