import React, {FC, useCallback} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import Card from '@/app/modules/components/card/Card';
import {toast} from 'react-toastify';
import useDatatable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {IIpnHistory} from '@/app/interfaces';
import {initialMerchantIpnHistoryListSort} from '@/app/utils/initialSorts';
import {IpnHistoryTableHead} from '@/app/utils/tableHeads/merchant/IpnHistoryTableHead';
import StatusBadge from '@/app/pages/merchant/merchant/IpnHistory/columns/StatusBadge';
import dayjs from 'dayjs';
import IpnHistoryFilters from '@/app/pages/merchant/merchant/IpnHistory/IpnHistoryFilters';
import {initialIpnHistoryFilters} from '@/app/utils/initialFilters';
import {IIpnHistoryFilters} from '@/app/interfaces/filters';
import {Tooltip} from 'react-tooltip';
import {useJsonModal} from '@/app/modules/hooks/useJsonModal';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getIpnHistory, resendIpn} from '@/app/modules/auth/core/_requests';
import {getIpnHistoryFromAdmin, resendIpnFromAdmin} from '@/app/utils/requests/adminRequests';
import Badge from '@/app/modules/components/Badge';

interface Props {
    id?: string;
}

const IpnHistory: FC<Props> = ({id}) => {
    const _ = useTranslation();

    const getData: GetDataFunction<IIpnHistory, IIpnHistoryFilters> = useCallback(async ({pageIndex, filters, pageSize, sort}) => {
        const params = {
            sort,
            pageIndex,
            pageSize,
            orFilters: filters.searchQuery
                ? {
                    'transaction.id': filters.searchQuery,
                    'ipnContent': filters.searchQuery,
                    'response': filters.searchQuery,
                }
                : undefined,
            exactFilters: {
                ...(filters.status && {'httpStatusCode': filters.status}),
            },
        };
        let data: any;
        if (id) {
            const response = await getIpnHistoryFromAdmin(id, params);
            data = response.data;
        } else {
            const response = await getIpnHistory(params);
            data = response.data;
        }
        if (!data) return Promise.reject();
        return data;
    }, [id]);

    const {
        getDataCallback,
        DataTableComponent,
        handleFilterSubmit,
        handleFilterChange,
        filters,
    } = useDatatable<IIpnHistory, IIpnHistoryFilters>({
        initialSort: initialMerchantIpnHistoryListSort,
        initialFilters: initialIpnHistoryFilters,
        getData,
        id: 'ipnHistory',
    });

    const {showJSON, JSONModalComponent} = useJsonModal();
    const resendAction = useCallback(async (historyId: number) => {
        if (id) {
            await resendIpnFromAdmin(id, historyId);
        } else {
            await resendIpn(historyId);
        }
        toast.success(_('IpnHistory.RESENT'));
        await getDataCallback();
    }, [_, getDataCallback, id]);

    return (
        <>
            <ToolbarWrapper title={_('IpnHistory.TITLE')} onlyTitle={!!id} isMerchantSubMenu={!!id} />
            <JSONModalComponent />

            <Card title={_('IpnHistory.TITLE')} subTitle={_('IpnHistory.SUBTITLE')} className={'ipnHistory'}>
                <IpnHistoryFilters handleFilterSubmit={handleFilterSubmit} handleFilterChange={handleFilterChange} filters={filters} />
                {/*{isMobile ? <MobileTable history={ipnHistory} resendAction={resendAction}/> : <Table history={ipnHistory} resendAction={resendAction}/>}*/}
                <DataTableComponent head={IpnHistoryTableHead} renderRow={(history: IIpnHistory) => {
                    return (
                        <tr key={'ipnhistory' + history.id}>
                            <td>
                                <span className="text-dark fw-bold fs-6">
                                    {history.transactionId || history.withdrawId}
                                </span>
                            </td>
                            {/*<td>*/}
                            {/*    <span className="text-dark fw-bold d-block fs-7">*/}
                            {/*        {dayjs(history.createdAt).format('DD.MM.YYYY HH:mm:ss')}*/}
                            {/*    </span>*/}
                            {/*</td>*/}
                            <td className={'text-center'}>
                                <Badge width={'150px'} text={history.type.toUpperCase()}
                                       type={history.type == 'deposit' ? 'primary' : 'info'} isLight={true}/>
                            </td>
                            <td className={'text-center'}>
                                <StatusBadge statusCode={history.httpStatusCode}/>
                            </td>

                            <td>
                                <span className="text-dark fw-bold d-block fs-7">
                                    {dayjs(history.sentAt).format('DD.MM.YYYY HH:mm:ss')}
                                </span>
                            </td>
                            <td className={'text-end'}>
                                <div className={'d-flex justify-content-end gap-2'}>
                                    <Tooltip id="tooltip" place={'top'}/>
                                    <button onClick={() => showJSON('request', history.request)}
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content={_('IpnHistory.VIEW_REQUEST')}
                                            className={'btn btn-icon btn-bg-light btn-active-color-primary btn-sm'}>
                                        <i className="bi bi-eye-fill"></i>
                                    </button>
                                    <button onClick={() => showJSON('response', history.response)}
                                            data-tooltip-id="tooltip"
                                            data-tooltip-content={_('IpnHistory.VIEW_RESPONSE')}
                                            className={'btn btn-icon btn-bg-light btn-active-color-primary btn-sm'}>
                                        <i className="bi bi-eye-fill"></i>
                                    </button>
                                    <button onClick={() => resendAction(history.id)} data-tooltip-id="tooltip"
                                            data-tooltip-content={_('IpnHistory.RESEND')}
                                            className={'btn btn-icon btn-bg-light btn-active-color-primary btn-sm'}>
                                        <i className="bi bi-send-exclamation-fill"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    );
                }}/>
            </Card>
        </>
    );
};

export default IpnHistory;