import * as Yup from 'yup';
import 'yup-phone-lite';
import {Translator} from '@/app/interfaces';
import {codeField, emailField, nullableString, numberField, optionalString, passwordField, passwordRepeatField, phoneField, requiredString, urlField, userTypeField} from '@/app/utils/yupSchema/fields';

export const createUpdateUserSchema = (_: Translator) => Yup.object().shape({
    fullName: requiredString(_),
    email: emailField(_),
    phoneNumber: phoneField(_),
    userType: userTypeField(_),
});
export const createUpdateEmployeeSchema = (_: Translator) => Yup.object().shape({
    fullName: requiredString(_),
    email: emailField(_),
    phoneNumber: phoneField(_),
    password: passwordField(_),
});
export const accountSettingsEmailSchema = (_: Translator) => Yup.object().shape({
    newEmail: emailField(_),
    confirmPassword: passwordField(_),
});
export const accountSettingsPasswordSchema = (_: Translator) => Yup.object().shape({
    currentPassword: requiredString(_),
    newPassword: passwordField(_),
    passwordConfirmation: passwordRepeatField(_, 'newPassword'),
});
export const enterCodeSchema = (_: Translator) => Yup.object().shape({
    code: codeField(_),
});
export const registrationSchema = (_: Translator) => Yup.object().shape({
    fullName: requiredString(_),
    email: emailField(_),
    phoneNumber: phoneField(_),
    password: passwordField(_),
    passwordRepeat: passwordRepeatField(_, 'password'),
    acceptTerms: Yup.bool().required(_('Validation.TERMS_REQUIRED')),
});
export const forgotPasswordSchema = (_: Translator) => Yup.object().shape({
    password: passwordField(_),
    passwordRepeat: passwordRepeatField(_, 'password'),
});
export const loginSchema = (_: Translator) => Yup.object().shape({
    email: emailField(_),
    password: passwordField(_),
});
export const submitPhoneEnterCodeSchema = (_: Translator) => Yup.object().shape({
    code: codeField(_),
});
export const enterEmailSchema = (_: Translator) => Yup.object().shape({
    email: emailField(_),
});
export const enterPhoneSchema = (_: Translator) => Yup.object().shape({
    phone: phoneField(_),
});
export const apiKeyDetailsSchema = (_: Translator) => Yup.object().shape({
    keyName: nullableString(_),
    isActive: Yup.boolean(),
});
export const merchantSettingsSchema = (_: Translator) => Yup.object().shape({
    id: requiredString(_),
    merchantName: requiredString(_),
    merchantEmail: emailField(_),
    merchantUrl: urlField(_, false),
    ipnUrl: urlField(_, false),
    withdrawCallbackUrl: urlField(_, false),
    ipnSecret: optionalString(_),
});

export const updateCoinSchema = (_: Translator) => Yup.object().shape({
    minWithdraw: requiredString(_),
    maxWithdraw: requiredString(_),
    withdrawFixedFee: requiredString(_),
    feePercentage: requiredString(_),
    fixedFee: requiredString(_),
    status: Yup.boolean(),
    warningText: optionalString(_),
});

export const createOrderSchema = (_: Translator) => Yup.object().shape({
    productName: requiredString(_),
    productPrice: numberField(_),
    quantity: numberField(_),
});

export const withdrawalSchema = (_: Translator) => Yup.object().shape({
    amount: requiredString(_),
    address: requiredString(_),
});
