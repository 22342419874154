import {useIntl} from 'react-intl';
import {useCallback} from 'react';

// Custom hook for simplified translation usage
export const useTranslation = () => {
    const intl = useIntl();

    // _ function for translations
    // const _: Translator = (id, values?) => intl.formatMessage({id}, values);

    return useCallback((id: string, values?: any) => intl.formatMessage({id}, values), [intl]);
};
