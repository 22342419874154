import {type FC, useCallback, useMemo} from 'react';
import {isPositive} from '@/app/modules/helpers';

interface Props {
    title: string;
    usd: string;
    fiat: string;
    isColored?: boolean;
}

const TotalCard: FC<Props> = ({title, usd, fiat, isColored}) => {

    const getColor = useCallback((val: string, defaultColor?: string) => {
        if (isColored) {
            return isPositive(val) ? 'text-success' : 'text-danger';
        }
        return defaultColor || 'text-gray-600';
    }, [isColored]);

    return (
        <div className="card overflow-hidden  mb-5 mb-xl-10">
            <div className="card-body d-flex justify-content-between flex-column px-0 pb-0">
                <div className="mb-4 px-9">
                    <span className="fs-5 fw-bolder text-dark">{title} (USD)</span>
                    <div className="d-flex align-items-center mt-2">
                        {/*<span className={`fs-4 fw-bolder align-self-start ${getColor(usd, 'text-gray-500')}`}>$</span>*/}
                        <span className={`fs-2hx fw-bold ms-1 lh-1 ${getColor(usd)}`}>{usd} USD</span>
                    </div>
                </div>
                <div className="mb-4 px-9">
                    <span className="fs-5 fw-bolder text-dark">{title} (TRY)</span>
                    <div className="d-flex align-items-center mt-2">
                        <span className={`fs-2hx fw-bold me-1 lh-1 ${getColor(fiat)}`}>{fiat} TRY</span>
                        {/*<span className={`fs-4 fw-bolder align-self-start ${getColor(fiat, 'text-gray-500')}`}>₺</span>*/}
                    </div>
                </div>
            </div>
        </div>
    );
};


export default TotalCard;