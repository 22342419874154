import React, {FC, useMemo} from 'react';
import clsx from "clsx";

interface Props {
    text: string;
    // type?: "success" | "danger" | "warning" | "info" | "primary" | "secondary" | "dark";
    type?: string;
    isLight?: boolean;
    size?: "sm" | "lg";
    width?: string;
}

const Badge: FC<Props> = ({text, type, isLight, size = "lg", width}) => {
    let className = "";
    if (isLight) {
        className = "light-";
    }
    className += type || "primary";

    const widthClass = useMemo(() => {
        if (width) {
            return `w-${width}`;
        }
        if (size === "sm") {
            return "w-50px";
        }
        if (size === "lg") {
            return "w-100px";
        }
    }, [size, width]);

    return (
        <span className={clsx(`text-center d-inline-block badge fw-bold ${widthClass} ${"badge-" + className}`, {
            "px-4 py-3": size === "lg",
        })}>{text}</span>
    );
};

export default Badge;