import {createContext, ReactNode, useContext, useState} from "react";

interface LoadingContextProps {
    isLoading: boolean;
    setIsLoading: (value: boolean) => void;
}

const defaultValue: LoadingContextProps = {
    isLoading: false,
    setIsLoading: () => {
    }
}


const LoadingContext = createContext<LoadingContextProps>(defaultValue);

export const useLoading = (): LoadingContextProps => {
    const context = useContext(LoadingContext);
    return context;
}

interface LoadingProviderProps {
    children: ReactNode;
}

export const LoadingProvider = ({children}: LoadingProviderProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    return (
        <LoadingContext.Provider value={{isLoading, setIsLoading}}>
            {children}
        </LoadingContext.Provider>
    )
}