import React, {FC, useCallback} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import {Link, useSearchParams} from 'react-router-dom';
import Card from '@/app/modules/components/card/Card';
import useDatatable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {IWithdrawalRecord} from '@/app/interfaces';
import {initialMerchantWithdrawalHistoryListSort} from '@/app/utils/initialSorts';
import {WithdrawalHistoryTableHead} from '@/app/utils/tableHeads/merchant/WithdrawalHistoryTableHead';
import CoinName from '@/app/modules/components/CoinName';
import Balance from '@/app/pages/merchant/wallet/tools/Balance';
import dayjs from 'dayjs';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {cancelWithdrawRequest, getWithdrawalHistory} from '@/app/modules/auth/core/_requests';
import WithdrawStatus from '@/app/pages/merchant/wallet/tools/WithdrawStatus';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import {toast} from 'react-toastify';
import {getWithdrawalHistoryFromAdmin} from '@/app/utils/requests/adminRequests';
import clsx from 'clsx';
import {KTIcon} from '@/_metronic/helpers';
import {useDevice} from '@/app/modules/providers/DeviceProvider';
import UsdValue from '@/app/pages/merchant/wallet/tools/UsdValue';
import TxHash from '@/app/modules/components/TxHash';

interface Props {
    id?: string;
}


const WithdrawalHistory: FC<Props> = ({id}) => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const {isMobile} = useDevice();

    const [searchParams] = useSearchParams();

    const filteredCoinCode = searchParams.get('coin');

    const getData: GetDataFunction<IWithdrawalRecord, {}> = useCallback(async ({pageIndex, filters, pageSize, sort}) => {
        const params = {
            sort,
            pageIndex,
            pageSize,
            exactFilters: {
                ...(filteredCoinCode ? {'coin.id': filteredCoinCode} : {}),
            },
        };

        let data: any;
        if (id) {
            const response = await getWithdrawalHistoryFromAdmin(id, params);
            data = response.data;
        } else {
            const response = await getWithdrawalHistory(params);
            data = response.data;
        }
        if (!data) return Promise.reject();
        return data;
    }, [filteredCoinCode, id]);

    const {
        DataTableComponent,
        getDataCallback,
    } = useDatatable<IWithdrawalRecord, {}>({
        initialSort: initialMerchantWithdrawalHistoryListSort,
        initialFilters: {},
        getData,
        id: 'withdrawalHistory',
    });

    const cancelWithdrawal = useCallback(async (id: number) => {
        try {
            await cancelWithdrawRequest(id);
            await getDataCallback();
        } catch (error: any) {
            toast.error(getErrorMessage(error));
        }
    }, [getDataCallback, getErrorMessage]);

    return (
        <>
            <ToolbarWrapper onlyTitle={!!id} title={_('WithdrawalHistory.TITLE')} additionalBreadcrumb={[{
                title: _('MyWallet.TITLE'),
                path: MerchantRouteConstants.MY_WALLET,
                isActive: true,
            }]} isMerchantSubMenu={!!id}>
                <div className={'d-flex align-items-center gap-3 gap-lg-3 toolsButtons'}>
                    <Link to={MerchantRouteConstants.MY_WALLET} className={clsx('btn btn-outline btn-outline-primary', {
                        'btn-sm': !isMobile,
                    })}>
                        <KTIcon iconName={'send'} className={'fs-2 me-1'} />
                        {_('WithdrawalHistory.NEW_WITHDRAW')}
                    </Link>
                </div>
            </ToolbarWrapper>
            <Card title={_('WithdrawalHistory.WITHDRAWAL_HISTORY')} subTitle={_('WithdrawalHistory.VIEW_ALL_WITHDRAWAL_TRANSACTIONS')} className={'myWallet'}>
                <DataTableComponent tableStyle={{
                    dashed: false,
                    striped: false,
                }} head={WithdrawalHistoryTableHead} renderRow={(record: IWithdrawalRecord) => {
                    return (
                        <tr key={'withdrawalHistory' + record.id}>
                            <td>
                                <CoinName name={record.coinName} symbol={record.coinSymbol}
                                          subSymbol={record.coinSubSymbol} isTestNetwork={record.isTestNetwork} />
                            </td>
                            <td>
                                <Balance amount={record.fiatAmount} code={'TRY'} />

                            </td>
                            <td>
                                <UsdValue amount={record.usdAmount} />
                            </td>
                            <td>
                                <Balance amount={record.amount} code={record.coinSymbol}
                                         isTestNetwork={record.isTestNetwork} />
                            </td>
                            <td>
                                <span
                                    className={'text-dark fw-bold d-block fs-7'}>{dayjs(record.createdAt).format('DD.MM.YYYY HH:mm:ss')}</span>
                            </td>
                            <td>
                                {record.txHash && (
                                    <TxHash txHash={record.txHash} urlFormat={record.txDetailUrlFormat} />
                                )}
                            </td>
                            <td className={'text-center'}>
                                <WithdrawStatus status={record.status} />
                            </td>
                            <td>
                                {record.status === 'beingVerified' && (
                                    <button onClick={() => cancelWithdrawal(record.id)}
                                            className="btn btn-sm btn-light-danger px-6">
                                        {_('WithdrawalHistory.CANCEL')}
                                    </button>
                                )}
                            </td>
                        </tr>
                    );
                }} />

            </Card>
        </>
    );
};

export default WithdrawalHistory;