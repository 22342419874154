/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react';
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom';
import {MerchantPrivateRoutes, AdminPrivateRoutes} from './PrivateRoutes';
import {ErrorsPage} from '../modules/errors/ErrorsPage';
import {Logout, AuthPage, useAuth} from '../modules/auth';
import {App} from '../App';
import {PaymentsPage} from '@/app/modules/payments/PaymentsPage';
import {AdminRouteConstants, MerchantRouteConstants} from '@/app/constants/routes.constants';
import {USER_TYPE} from '@/app/enums';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = process.env;

const AppRoutes: FC = () => {
    const {currentUser} = useAuth();

    return (
        <BrowserRouter basename={PUBLIC_URL}>
            <Routes>
                <Route element={<App />}>

                    <Route path="payment/*" element={<PaymentsPage />} />
                    <Route path="error/*" element={<ErrorsPage />} />
                    <Route path="logout" element={<Logout />} />
                    {currentUser ? (
                        <>
                            {currentUser.userType === USER_TYPE.SUPER_ADMIN || currentUser.userType === USER_TYPE.ADMIN ? (
                                <>
                                    <Route path="/*" element={<AdminPrivateRoutes isSuperAdmin={currentUser.userType === USER_TYPE.SUPER_ADMIN} />} />
                                    <Route index element={<Navigate replace={true} to={AdminRouteConstants.DASHBOARD} />} />
                                </>
                            ) : (
                                <>
                                    <Route path="/*" element={<MerchantPrivateRoutes isMerchant={currentUser.userType === USER_TYPE.MERCHANT} />} />
                                    <Route index element={<Navigate replace={true} to={MerchantRouteConstants.DASHBOARD} />} />
                                </>
                            )}

                        </>
                    ) : (
                        <>
                            <Route path="auth/*" element={<AuthPage />} />
                            <Route path="*" element={<Navigate replace={true} to="/auth" />} />
                        </>
                    )}
                </Route>
            </Routes>
        </BrowserRouter>
    );
};

export {AppRoutes};
