import {Translator} from '@/app/interfaces';
import {useCallback} from 'react';


export const useErrorMessage = (_: Translator) => {
    const getErrorMessage = useCallback((error: any) => {
        const dataParams = ['message', 'error', 'messageCode'];
        for (const param of dataParams) {
            const message = error?.response?.data?.[param];
            if (message && _(`Errors.${message}`)) {
                return _(`Errors.${message}`);
            }
        }
        return _('Errors.UNKNOWN_ERROR');
    }, [_]);

    return {getErrorMessage};
};