import {useState, useEffect, useCallback} from 'react';

interface PaginationHook {
    page: number;
    totalPages: number;
    perPage: number; // perPage değerini dışarı aktar
    handlePageChange: (newPage: number) => void;
    handlePerPageChange: (newPerPage: string) => void; // perPage değerini güncellemek için fonksiyon
}

const usePagination = (totalItems: number, id: string): PaginationHook => {
    const initialPerPage = getInitialPerPage(id);
    const initialPage = getInitialPage(id);
    const [page, setPage] = useState<number>(initialPage);
    const [perPage, setPerPage] = useState<number>(initialPerPage); // perPage için state
    const [totalPages, setTotalPages] = useState<number>(Math.ceil(totalItems / initialPerPage));

    useEffect(() => {
        setTotalPages(Math.ceil(totalItems / perPage));
    }, [perPage, totalItems]);

    const handlePageChange = useCallback((newPage: number): void => {
        setPage(newPage);
        // sessionStorage.setItem(`${id}_page`, newPage.toString());
    }, []);

    const handlePerPageChange = useCallback((newPerPage: string): void => {
        handlePageChange(1);
        setPerPage(parseInt(newPerPage));
        localStorage.setItem(`${id}_perPage`, newPerPage);
    }, [handlePageChange, id]);

    return {page, totalPages, perPage, handlePageChange, handlePerPageChange};
};

export default usePagination;

const getInitialPerPage = (id: string): number => {
    const perPage = localStorage.getItem(`${id}_perPage`);
    return perPage ? parseInt(perPage) : 10;
};

const getInitialPage = (id: string): number => {
    const page = sessionStorage.getItem(`${id}_page`);
    return page ? parseInt(page) : 1;
};