import React, {FC} from 'react';
import clsx from 'clsx';
import {getCoinLogo, getCoinUnit} from '@/app/modules/helpers/getCoinSymbol';

interface Props {
    size?: 'lg';
    name: string;
    symbol: string;
    subSymbol: string | null;
    isTestNetwork?: boolean;
}

const CoinName: FC<Props> = ({size, name, symbol, subSymbol, isTestNetwork}) => {
    return (
        <div className={clsx('coinName', {
            [size as string]: Boolean(size),
        })}>
            <div className={'coinLogo bg-light'}>
                <img src={getCoinLogo(symbol)} alt="" />
            </div>
            <div className={'d-flex justify-content-start flex-column coinNameInfo'}>
                <span className={'text-dark fw-bold mb-1 fs-6 coinNameText'}>{name}</span>
                <span className="text-muted fw-semibold text-muted d-block fs-7 coinCode">{getCoinUnit({
                    symbol,
                    isTestNetwork: Boolean(isTestNetwork),
                })}</span>
            </div>
        </div>
    );
};

export default CoinName;