import React, {FC, useMemo} from 'react';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import clsx from 'clsx';
import {getCoinUnit} from '@/app/modules/helpers/getCoinSymbol';
import {IWithdrawalCoin} from '@/app/interfaces';

interface Props {
    value?: string;
    label: string;
    coin: IWithdrawalCoin | null;
    onClick?: () => void;
}

const Input: FC<Props> = ({label, value, coin, onClick}) => {

    const unitCode = useMemo(() => {
        if (!coin) return '';
        return getCoinUnit(coin);
    }, [coin]);

    const coinLogo = useMemo(() => {
        if (!coin) return '';
        return toAbsoluteUrl(`/custom/coins/${coin.symbol.toLowerCase()}.svg`);
    }, [coin]);

    return (
        <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6">{label}:</label>

            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                <div onClick={onClick} className={clsx('fakeInput', {
                    'code4': unitCode.length === 4,
                    'testNetworkCode': coin?.isTestNetwork,
                    'hoverable': Boolean(onClick),
                })}>
                    {value}
                    <span className={'prefix'}>
                        {coinLogo && (
                            <img src={coinLogo} alt={coin?.symbol} />
                        )}
                    </span>
                    <span className={'suffix text-muted fw-semibold text-muted fs-9 coinUnitCode'}>
                        {unitCode}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Input;