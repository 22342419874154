import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import {MerchantRouteConstants} from "@/app/constants/routes.constants";
import {getDummyApiKeys} from "@/app/mocks/apiKeys";

const apiKeys = getDummyApiKeys(5);

export const useApiKeyCheck = () => {
    const {id: apiID} = useParams();
    const navigate = useNavigate();


    const apiKey = apiKeys.find(apiKey => apiKey.id.toString() === apiID);

    useEffect(() => {
        if (!apiKey) {
            navigate(MerchantRouteConstants.API_KEYS);
        }
    }, [apiKey, navigate]);

    return apiKey;
}