import React, {FC, useCallback} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {ITransaction} from '@/app/interfaces';
import Card from '@/app/modules/components/card/Card';
import TransactionFilters from '@/app/pages/admin/transaction/transactionList/TransactionFilters';
import {TransactionsTableHead} from '@/app/utils/tableHeads/admin/TransactionsTableHead';
import dayjs from 'dayjs';
import TransactionStatus from '@/app/pages/merchant/wallet/tools/TransactionStatus';
import Balance from '@/app/pages/merchant/wallet/tools/Balance';
import useDataTable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {IAdminTransactionFilters} from '@/app/interfaces/filters';
import {initialAdminTransactionFilters} from '@/app/utils/initialFilters';
import {initialAdminTransactionSort} from '@/app/utils/initialSorts';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getTransactions} from '@/app/utils/requests/adminRequests';
import {Link} from 'react-router-dom';
import {AdminRouteConstants} from '@/app/constants/routes.constants';
import {generateRoute} from '@/app/modules/helpers';
import {amountWithCurrency} from '@/app/modules/helpers/getValuesFromId';

interface Props {

}


const TransactionList: FC<Props> = (props) => {
    const _ = useTranslation();
    const getData: GetDataFunction<ITransaction, IAdminTransactionFilters> = useCallback(async ({pageIndex, filters, pageSize, sort}) => {
        const params = {
            sort,
            pageIndex,
            pageSize,
            orFilters: filters.searchQuery
                ? {
                    'id': filters.searchQuery,
                    'buyerEmail': filters.searchQuery,
                    'productName': filters.searchQuery,
                    'merchant.merchantName': filters.searchQuery,
                    'txHash': filters.searchQuery,
                    'paymentAddress': filters.searchQuery,
                }
                : undefined,
            exactFilters: {
                ...(filters.merchant && {'merchant.id': filters.merchant}),
                ...(filters.status && {'status': filters.status}),
            },
        };
        const {data} = await getTransactions(params);
        if (!data) return Promise.reject();
        return data;

    }, []);
    const {
        handleFilterSubmit,
        handleFilterReset,
        handleFilterChange,
        filters,
        DataTableComponent,
    } = useDataTable<ITransaction, IAdminTransactionFilters>({
        initialSort: initialAdminTransactionSort,
        initialFilters: initialAdminTransactionFilters,
        getData,
        id: 'transactionList',
    });


    return (
        <>
            <ToolbarWrapper title={_('TransactionList.TITLE')} />

            <Card>
                <TransactionFilters handleFilterSubmit={handleFilterSubmit} handleFilterReset={handleFilterReset} handleFilterChange={handleFilterChange} filters={filters} />
                <DataTableComponent
                    head={TransactionsTableHead}
                    className={'gy-5'}
                    renderRow={(transaction: ITransaction) => {
                        return (
                            <tr key={transaction.id}>
                                <td><Link to={generateRoute(AdminRouteConstants.TRANSACTION_DETAIL, {id: transaction.id})} className="text-gray-800 fw-bold fs-6">{transaction.id}</Link></td>
                                <td>
                                    <div className="badge badge-light fw-bold">{dayjs(transaction.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
                                </td>
                                <td>{transaction.merchantName}</td>
                                {/*<td><span>{transaction.buyerEmail}</span></td>*/}
                                <td className="">
                                    {transaction.productTotalFiat && (
                                        <>
                                            <span className="text-dark fw-bold fs-6 amount">{amountWithCurrency(transaction.estimatedPaidAmountFiat || '0.00', transaction.productCurrency)}</span>
                                            <span className="px-1">/</span>
                                            <span className="text-dark fw-light fs-6 amount">{amountWithCurrency(transaction.productTotalFiat, transaction.productCurrency)}</span>
                                        </>
                                    )}
                                </td>
                                <td className="">
                                    {transaction.productTotal && (
                                        <>
                                            <span className="text-dark fw-bold fs-6 amount">{amountWithCurrency(transaction.estimatedPaidAmount || '0.00', 'USD')}</span>
                                            <span className="px-1">/</span>
                                            <span className="text-dark fw-light fs-6 amount">{amountWithCurrency(transaction.productTotal, 'USD')}</span>
                                        </>
                                    )}
                                </td>
                                <td><Balance amount={transaction.paidAmountAsCoin} code={transaction.coinSymbol} isTestNetwork={transaction.isTestNetwork} /></td>
                                <td className="text-center"><TransactionStatus status={transaction.status} /></td>
                            </tr>
                        );
                    }}
                />
            </Card>
        </>
    );
};

export default TransactionList;