import React, {FC} from 'react';
import Input from '@/app/modules/components/filterInputs/Input';
import Select from '@/app/modules/components/filterInputs/Select';
import Buttons from '@/app/modules/components/Buttons';
import {IAdminMerchantListFilters} from '@/app/interfaces/filters';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    handleFilterSubmit: () => void;
    handleFilterReset: () => void;
    handleFilterChange: (name: string, value: string | object | null) => void;
    filters: IAdminMerchantListFilters;

}

const MerchantListFilters: FC<Props> = ({handleFilterSubmit, handleFilterReset, handleFilterChange, filters}) => {
    const _ = useTranslation();
    return (
        <div className="row mb-10 gap-5">
            <div className="col-md ">
                <Input name="searchQuery" value={filters.searchQuery} label={_('Filter.SEARCH_QUERY')} onChange={handleFilterChange} submitOnEnterFunc={handleFilterSubmit} />
            </div>
            <div className="col-md ">
                <Select options={{
                    '1': _('UserStatus.INACTIVE'),
                    '2': _('UserStatus.ACTIVE'),
                    '3': _('UserStatus.BLOCKED'),
                }} name="status" label={_('MerchantListFilters.ACTIVE_STATUS')} onChange={handleFilterChange} value={filters.status} className={'form-control-solid'} isClearable />
            </div>

            <div className={'col-md flex-grow-0 align-self-end'}>
                <Buttons>
                    <button onClick={handleFilterSubmit} className="btn  btn-primary  px-6">{_('Filter.FILTER')}</button>
                </Buttons>
            </div>
        </div>
    );
};

export default MerchantListFilters;