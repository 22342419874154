import React, {FC} from 'react';
import {languages} from "@/_metronic/partials/layout/header-menus/Languages";
import {Dropdown} from "react-bootstrap";
import clsx from "clsx";
import {useLang} from "@/_metronic/i18n/Metronici18n";

interface Props {

}


const LanguageSwitcher: FC<Props> = (props) => {
    const {selectedLang: lang, setLang} = useLang()
    const currentLanguage = languages.find((x) => x.lang === lang)


    return (
        <Dropdown>
            <Dropdown.Toggle variant={"link"} as={"a"}
                             bsPrefix={"btn btn-icon btn-active-light-primary btn-custom"}>
                <img
                    className='w-20px h-20px rounded-1'
                    src={currentLanguage?.flag}
                    alt='metronic'
                />
            </Dropdown.Toggle>
            <Dropdown.Menu align={"end"} className={"menu-active-bg"}>
                {languages.map((l) => (
                    <Dropdown.Item bsPrefix={"menu-item px-3"} key={l.lang} as={"div"}>
                        <button
                            onClick={() => {
                                setLang(l.lang)
                            }}
                            className={clsx('menu-link d-flex px-5 w-100 border-0 bg-transparent', {active: l.lang === currentLanguage?.lang})}
                        >
                            <span className='symbol symbol-20px me-4'>
                                <img className='rounded-1' src={l.flag} alt='metronic'/>
                            </span>
                            {l.name}
                        </button>
                    </Dropdown.Item>
                ))}

            </Dropdown.Menu>
        </Dropdown>
    );
};

export default LanguageSwitcher;