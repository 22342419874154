import React, {FC} from 'react';
import {ipnHistoryStatusLabels, TIpnHistoryStatusCode} from '@/app/interfaces';
import Badge from '@/app/modules/components/Badge';

interface Props {
    statusCode: TIpnHistoryStatusCode;
}

const StatusBadge: FC<Props> = ({statusCode}) => {
    let color: string = 'danger';
    switch (statusCode) {
        case 200:
        case 201:
            color = 'success';
            break;
        case 400:
        case 401:
        case 402:
        case 403:
        case 404:
        case 405:
        case 429:
            color = 'warning';
            break;
        case 0:
        case 500:
        case 501:
        case 502:
        case 503:
        case 504:
            color = 'danger';
            break;
    }

    return <Badge width={'150px'} text={ipnHistoryStatusLabels[statusCode]} type={color} isLight={true} />;
    // return <span className={`badge badge-light-${color} fw-semibold fs-7`}>{ipnHistoryStatusLabels[statusCode]}</span>
};

export default StatusBadge;