import {IDataTableHead} from '@/app/interfaces';

export const WithdrawalHistoryTableHead: IDataTableHead[] = [

    {
        text: 'WithdrawalHistoryTH.COIN',
        name: 'coinCode',
        isSortable: false,
        align: 'left',
        width: '100px',
    },
    {
        text: 'WithdrawalHistoryTH.FIAT_AMOUNT',
        name: 'fiatAmount',
        isSortable: false,
        align: 'left',
        width: '100px',
    },
    {
        text: 'WithdrawalHistoryTH.USD_AMOUNT',
        name: 'usdAmount',
        isSortable: false,
        align: 'left',
        width: '75px',
    },

    {
        text: 'WithdrawalHistoryTH.AMOUNT',
        name: 'amount',
        isSortable: true,
        align: 'right',
        width: '150px',
    },
    {
        text: 'WithdrawalHistoryTH.CREATED_AT',
        name: 'createdAt',
        isSortable: true,
        align: 'left',
        width: '125px',
    },
    {
        text: 'WithdrawalHistoryTH.ORDER_ID',
        name: 'addressTxId',
        isSortable: false,
        align: 'left',
        width: '100px',
    },
    {
        text: 'WithdrawalHistoryTH.STATUS',
        name: 'status',
        isSortable: false,
        align: 'center',
        width: '100px',
    },
    {
        text: '',
        width: '100px',
    },
];