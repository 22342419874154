import React, {FC, useEffect} from 'react';
import {KTIcon} from '@/_metronic/helpers';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {AdminRouteConstants} from '@/app/constants/routes.constants';
import {generateRoute} from '@/app/modules/helpers';
import {MerchantDetailSubMenu} from '@/app/utils/MerchantDetailSubMenu';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getMerchant} from '@/app/utils/requests/adminRequests';
import {toast} from 'react-toastify';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';

interface Props {
    id: string;
}


const MerchantDetailHeader: FC<Props> = ({id}) => {

    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const location = useLocation();
    const navigate = useNavigate();
    const path = location.pathname.split('/').pop();
    const [merchant, setMerchant] = React.useState<any>(null);

    useEffect(() => {
        getMerchant(id).then((res) => {
            setMerchant(res.data);
        }).catch((err) => {
            navigate(AdminRouteConstants.MERCHANTS);
            toast.error(getErrorMessage(err));
        });
    }, [getErrorMessage, id, navigate]);

    return (
        <>
            {/*<ToolbarWrapper title={''} />*/}
            <div className="card mb-5 mb-xl-10">
                <div className="card-body pt-9 pb-0">
                    <div className="d-flex flex-wrap flex-sm-nowrap mb-0">
                        <div className="flex-grow-1">
                            <div className="d-flex justify-content-between align-items-start flex-wrap mb-0">
                                <div className="d-flex flex-column">
                                    <div className="d-flex align-items-center mb-2">
                                        <div className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                                            {merchant?.merchantName}
                                        </div>
                                        <span>
                                            <KTIcon iconName="verify" className="fs-1 text-primary" />
                                        </span>
                                    </div>

                                    <div className="d-flex flex-wrap fw-bold fs-6 mb-0 pe-2">
                                        <Link
                                            to={generateRoute(AdminRouteConstants.USER_DETAIL, {id: merchant?.ownerId})}
                                            className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2"
                                        >
                                            <KTIcon iconName="profile-circle" className="fs-4 me-1" />
                                            {(merchant && !merchant?.ownerId) && _('MerchantDetailHeader.NO_OWNER')}
                                            {merchant?.ownerName}
                                        </Link>

                                        <a
                                            href={`mailto:${merchant?.merchantEmail}`}
                                            className="d-flex align-items-center text-gray-500 text-hover-primary mb-2"
                                        >
                                            <KTIcon iconName="sms" className="fs-4 me-1" />
                                            {merchant?.merchantEmail}
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="d-flex overflow-auto h-55px">
                        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
                            {MerchantDetailSubMenu.map((item, index) => {
                                return (
                                    <li className="nav-item" key={index}>
                                        <Link
                                            className={
                                                `nav-link text-active-primary me-6 ` +
                                                (path === item.path && 'active')
                                            }
                                            to={`${generateRoute(AdminRouteConstants.MERCHANT_DETAIL, {id: id})}/${item.path}`}
                                        >
                                            {_(item.title)}
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MerchantDetailHeader;