import React, {FC} from 'react';
import clsx from 'clsx';
import CoinName from '@/app/modules/components/CoinName';
import Balance from '@/app/pages/merchant/wallet/tools/Balance';
import UsdValue from '@/app/pages/merchant/wallet/tools/UsdValue';
import Actions from '@/app/modules/components/Actions';
import {IBalance} from '@/app/interfaces';
import {generateActionItemsForCoin, generateActionItemsForCoinAdmin} from '@/app/modules/helpers/generateActionItems';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import TxHash from '@/app/modules/components/TxHash';


interface Props {
    balances: IBalance[];
    merchantID?: string;
    isAdminWallets?: boolean;
    hideActions?: boolean;
    totalBalance: string;
}

const Table: FC<Props> = ({balances, merchantID, isAdminWallets, hideActions = false, totalBalance}) => {
    const _ = useTranslation();
    return (
        <table className={clsx('table align-middle gs-0 gy-4 walletList')}>
            <thead>
            <tr className={'fw-bolder text-muted'}>
                <th>{_('Table.COIN_NAME')}</th>
                {isAdminWallets && (
                    <th>{_('Table.WALLET_ADDRESS')}</th>
                )}
                <th className={'text-end pe-5'}>{_('Table.BALANCE')}</th>
                <th className={'text-end'}>{_('Table.USD_VALUE')}</th>
                <th className={'text-end'}></th>
            </tr>
            </thead>
            <tbody>
            {balances.map((balance, index) => (
                <tr key={'desktopTable' + balance.coinId}>
                    <td>
                        <CoinName name={balance.coinName} symbol={balance.coinSymbol} isTestNetwork={balance.isTestNetwork} subSymbol={balance.coinSubSymbol} />
                    </td>
                    {isAdminWallets && (
                        <td>
                            <TxHash txHash={balance.mainPublicAddress} showFull />
                        </td>
                    )}
                    <td>
                        <Balance amount={balance.balance} code={balance.coinSymbol} isTestNetwork={balance.isTestNetwork} />
                    </td>
                    <td className={'text-end'}>
                        <UsdValue amount={balance.usdBalance} />
                    </td>
                    <td className={'text-end'}>
                        {!hideActions && (
                            isAdminWallets ? <Actions items={generateActionItemsForCoinAdmin(balance.coinId)} /> : <Actions items={generateActionItemsForCoin(balance.coinId, merchantID)} />
                        )}
                    </td>
                </tr>
            ))}
            <tr className="table-active">
                {/*Toplam*/}
                <td colSpan={isAdminWallets ? 3 : 2} className={'fw-bold text-right py-10 fs-4'}>{_('Table.TOTAL')}:</td>
                <td className={'fw-bold text-end py-10'}><UsdValue className="fs-4 fw-bolder" amount={totalBalance} /></td>
                <td></td>
            </tr>
            </tbody>
        </table>
    );
};

export default Table;