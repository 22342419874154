import React, {FC, useCallback, useEffect} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {ILoginLog, IUser} from '@/app/interfaces';
import {AdminRouteConstants, MerchantRouteConstants} from '@/app/constants/routes.constants';
import {useNavigate, useParams} from 'react-router-dom';
import CreateUpdateUserModal from '@/app/pages/admin/users/CreateUpdateUserModal';
import {toast} from 'react-toastify';
import {useLoading} from '@/app/providers/LoadingProvider';
import {LoginLogsTableHead} from '@/app/utils/tableHeads/admin/LoginLogsTableHead';
import Card from '@/app/modules/components/card/Card';
import ConfirmModal from '@/app/modules/components/modals/confirmModal/ConfirmModal';
import useDataTable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {initialAdminUserLoginLogSort} from '@/app/utils/initialSorts';
import {ICreateUpdateUserValues} from '@/app/interfaces/values';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {deleteUser, getLoginHistory, getUser, updateUser} from '@/app/utils/requests/adminRequests';
import UserInfoCard from '@/app/pages/common/userDetail/UserInfoCard';
import {userDetailTr} from '@/app/pages/common/userDetail/userDetailTr';
import {deleteEmployee, getEmployee, getEmployeeLoginHistory} from '@/app/modules/auth/core/_requests';

interface Props {
    isAdmin?: boolean;
}

const UserDetail: FC<Props> = ({isAdmin}) => {
    const _ = useTranslation();
    const navigate = useNavigate();
    const {setIsLoading} = useLoading();
    const {id} = useParams();
    const [user, setUser] = React.useState<IUser | null>(null);
    const [showUpdateUserModal, setShowUpdateUserModal] = React.useState<boolean>(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = React.useState<boolean>(false);
    const updateUserHandle = async (values: ICreateUpdateUserValues) => {
        if (!isAdmin) return;
        if (!id) return;
        await updateUser(parseInt(id), values);
        getUserReq(parseInt(id)).then();
        toast.success(_('UserDetail.USER_UPDATED'));
    };

    const deleteUserHandle = async () => {
        if (!id) return;
        if (isAdmin) {
            await deleteUser(parseInt(id));
        } else {
            await deleteEmployee(parseInt(id));
        }
        toast.success(_('UserDetail.USER_DELETED'));
        navigate(isAdmin ? AdminRouteConstants.USERS : MerchantRouteConstants.EMPLOYEES);
    };

    const getUserReq = useCallback(async (id: number) => {
        setIsLoading(true);
        const req = isAdmin ? await getUser(id) : await getEmployee(id);
        setUser({
            ...req.data,
        });
        // setUser(req.data);
        setIsLoading(false);
    }, [isAdmin, setIsLoading]);

    const getData: GetDataFunction<ILoginLog, {}> = useCallback(async ({pageIndex, pageSize, sort}) => {
        if (!id) return Promise.reject();
        const fetchLoginHistoryFunc = isAdmin ? getLoginHistory : getEmployeeLoginHistory;

        const {data} = await fetchLoginHistoryFunc(parseInt(id), {
            pageIndex,
            pageSize,
            sort,
        });
        if (!data) return Promise.reject();
        return data;
    }, [isAdmin, id]);

    const {
        DataTableComponent,
    } = useDataTable<ILoginLog, {}>({
        initialSort: initialAdminUserLoginLogSort,
        initialFilters: {},
        getData,
        id: 'userLoginLog',
    });

    useEffect(() => {
        if (id) getUserReq(parseInt(id)).then();
    }, [id, getUserReq]);

    return (
        <>
            <ToolbarWrapper title={_('UserDetail.TITLE')} />
            {isAdmin && (
                <CreateUpdateUserModal show={showUpdateUserModal} handleClose={() => setShowUpdateUserModal(false)} handleSubmit={updateUserHandle} currentValues={!user ? undefined : {
                    fullName: user.fullName,
                    email: user.email,
                    phoneNumber: user.phoneNumber,
                    userType: user.userType.toString(),
                }} />
            )}
            <ConfirmModal show={showDeleteUserModal} handleClose={() => setShowDeleteUserModal(false)} handleSubmit={deleteUserHandle} />

            <div className={'d-flex flex-column flex-lg-row'}>
                <div className="flex-column flex-lg-row-auto w-lg-250px w-xl-350px mb-10">
                    <UserInfoCard user={user} setShowUpdateUserModal={setShowUpdateUserModal} setShowDeleteUserModal={setShowDeleteUserModal} isAdmin={isAdmin} />
                </div>
                <div className="flex-lg-row-fluid ms-lg-15">
                    <Card title={_('UserDetail.LOGIN_LOG_TITLE')} className={'mb-5'}>
                        <DataTableComponent head={LoginLogsTableHead} renderRow={(data, index) => userDetailTr(data, index, _)} className={'gy-5'} />
                    </Card>
                </div>
            </div>

        </>
    );
};

export default UserDetail;