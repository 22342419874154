import {ILoginLog, RowRenderer, Translator} from '@/app/interfaces';
import dayjs from 'dayjs';
import React from 'react';
import {UAParser} from 'ua-parser-js';

const parser = new UAParser();

export const userDetailTr: RowRenderer<ILoginLog> = ((loginLog: ILoginLog, index, translator: Translator) => {
    const ua = parser.setUA(loginLog.userAgent).getResult();
    return (
        <tr key={loginLog.id}>
            <td>{ua.browser.name} {ua.browser.version} - {ua.os.name} {ua.os.version}</td>
            <td>{loginLog.ip}</td>
            <td className={'text-center'}>{
                loginLog.isSuccess ?
                    <span className={'badge badge-lg badge-light-success'}>{translator('General.SUCCESS')}</span> :
                    <span className={'badge badge-lg badge-light-danger'}>{translator('General.FAIL')}</span>
            }</td>
            <td>
                <div className="badge badge-light fw-bold">
                    {dayjs(loginLog.createdAt).format('DD.MM.YYYY HH:mm')}
                </div>
            </td>
        </tr>
    );
});
