import React, {FC} from 'react';
import clsx from 'clsx';
import {getCoinUnit} from '@/app/modules/helpers/getCoinSymbol';

interface Props {
    amount: string | number;
    code: string;
    size?: 'lg';
    isTestNetwork?: boolean;
}

const Balance: FC<Props> = ({amount, code, size, isTestNetwork}) => {
    return (
        <div className={clsx('balance', {
            [size as string]: Boolean(size),
        })}>
            <span className={'text-dark fw-bold fs-6 amount'}>
                {typeof amount === 'number' ? amount.toFixed(8) : amount}
            </span>
            <span className="text-muted fw-semibold text-muted fs-9 coinUnitCode">
                {getCoinUnit({
                    symbol: code,
                    isTestNetwork: Boolean(isTestNetwork),
                })}
            </span>

        </div>
    );
};

export default Balance;