import {PageTitleWrapper} from './page-title';
import React, {FC} from 'react';
import {PageTitle} from '../../core';


interface Props {
    children?: React.ReactNode,
    additionalBreadcrumb?: Array<{title: string, path: string, isActive: boolean}>;
    title: string;
    onlyTitle?: boolean;
    isMerchantSubMenu?: boolean;
}

const ToolbarWrapper: FC<Props> = ({onlyTitle, children, title, additionalBreadcrumb, isMerchantSubMenu}) => {

    if (isMerchantSubMenu) {
        title = `Merchant | ${title}`;
    }


    const breadcrumbs = [
        {
            title: 'Dashboard',
            path: '/dashboard',
            isActive: false,
        }, {
            isSeparator: true,
        },
    ];
    if (additionalBreadcrumb) {
        breadcrumbs.push(...additionalBreadcrumb);
        breadcrumbs.push({
            isSeparator: true,
        });
    }

    if (onlyTitle) return (
        <PageTitle breadcrumbs={additionalBreadcrumb}>
            {title}
        </PageTitle>
    );


    return (
        <>
            <PageTitle breadcrumbs={breadcrumbs}>
                {title}
            </PageTitle>

            <div
                id="kt_app_toolbar"
                className={'app-toolbar py-3 pb-lg-6'}
            >
                <div
                    id="kt_app_toolbar_container"
                    className={'d-flex flex-stack container-fluid flex-md-row flex-column gap-5'}
                    style={{padding: 0}}
                >
                    <PageTitleWrapper />

                    {children}
                </div>
            </div>
        </>
    );
};

export {ToolbarWrapper};
