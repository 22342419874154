import {FC} from 'react';
import Badge from '@/app/modules/components/Badge';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    isActive: boolean;
}

const Status: FC<Props> = (props) => {
    const _ = useTranslation();

    return (
        <span className={'text-dark fw-bold d-block fs-6'}>
            {props.isActive ? (
                <Badge text={_('General.ACTIVE')} type={'success'} isLight />
            ) : (
                <Badge text={_('General.INACTIVE')} type={'danger'} isLight />
            )}
        </span>
    );
};

export default Status;