import React, {FC} from 'react';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import {Link} from 'react-router-dom';
import {AdminRouteConstants, MerchantRouteConstants} from '@/app/constants/routes.constants';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    isAdminWithdraw?: boolean;
}

const Completed: FC<Props> = ({isAdminWithdraw}) => {
    const _ = useTranslation();

    return (
        <div className={'d-flex justify-content-center align-items-center flex-column'}>
            <div className="mb-10">
                <img src={toAbsoluteUrl('/custom/svg/check.svg')} className="w-100px" alt="" />
            </div>
            <h1 className="fw-bolder text-gray-900 mb-5">
                {_('Completed.WITHDRAW_COMPLETED')}
            </h1>
            <div className="fs-6 fw-semibold text-gray-500 mb-10 mw-500px text-center">
                {_('Completed.WITHDRAW_COMPLETED_DESCRIPTION')}
            </div>
            <div className="mb-11">
                <Link to={isAdminWithdraw ? AdminRouteConstants.WITHDRAWALS : MerchantRouteConstants.WITHDRAWAL_HISTORY} className="btn btn-sm btn-primary">{_('Completed.RETURN')}</Link>
            </div>
        </div>
    );
};

export default Completed;