import {AdminMerchantDetailSubRouteConstants} from '@/app/constants/routes.constants';
import WithdrawalHistory from '@/app/pages/merchant/wallet/History/WithdrawalHistory';
import TransactionHistory from '@/app/pages/merchant/wallet/TransactionHistory/TransactionHistory';
import CreateOrder from '@/app/pages/merchant/merchant/CreateOrder/CreateOrder';
import MerchantSettings from '@/app/pages/merchant/merchant/MerchantSettings/MerchantSettings';
import IpnHistory from '@/app/pages/merchant/merchant/IpnHistory/IpnHistory';
import CoinAcceptance from '@/app/pages/merchant/merchant/CoinAcceptance/CoinAcceptance';
import ApiKeys from '@/app/pages/merchant/apiKeys/ApiKeys/ApiKeys';
import Update from '@/app/pages/merchant/apiKeys/Update/Update';
import ViewLog from '@/app/pages/merchant/apiKeys/ViewLog/ViewLog';
import Users from '@/app/pages/admin/users/Users';
import MyWallet from '@/app/pages/merchant/wallet/MyWallet/MyWallet';
import TransactionDetail from '@/app/pages/merchant/wallet/TransactionHistory/TransactionDetail/TransactionDetail';
import Dashboard from '@/app/pages/merchant/dashboard/Dashboard';
import MerchantReport from '@/app/pages/merchant/report/Report';

export const MerchantDetailSubRoutes = [
    {
        path: AdminMerchantDetailSubRouteConstants.STATISTICS,
        element: Dashboard,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.WALLET,
        element: MyWallet,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.WITHDRAWALS,
        element: WithdrawalHistory,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.TRANSACTIONS,
        element: TransactionHistory,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.CREATE_MANUEL_ORDER,
        element: CreateOrder,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.MERCHANT_SETTINGS,
        element: MerchantSettings,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.IPN_HISTORY,
        element: IpnHistory,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.COIN_ACCEPTANCE_SETTINGS,
        element: CoinAcceptance,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.API_KEYS,
        element: ApiKeys,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.UPDATE_API_KEY,
        element: Update,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.VIEW_API_KEY_LOG,
        element: ViewLog,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.USERS,
        element: Users,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.TRANSACTION_DETAIL,
        element: TransactionDetail,
        common: true,
    },
    {
        path: AdminMerchantDetailSubRouteConstants.REPORTS,
        element: MerchantReport,
        common: true,
    },
];