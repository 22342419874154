import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import BasketCard from '@/app/pages/payment/common/BasketCard';
import Table from '@/app/pages/payment/common/Table';
import Qr from '@/app/pages/payment/pay/Qr';
import Success from '@/app/pages/payment/pay/Success';
import Timeout from '@/app/pages/payment/pay/Timeout';
import Header from '@/app/pages/payment/common/Header';
import {getTransaction, transactionPubTicker} from '@/app/modules/auth/core/_requests';
import {toast} from 'react-toastify';
import {TTransactionStatus} from '@/app/interfaces';
import {capitalizeFirstLetter, formatDateTime} from '@/app/modules/helpers';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import Partial from '@/app/pages/payment/pay/Partial';

interface Props {
    id: string;
}

interface ITransaction {
    id: string;
    email: string;
    merchantName: string;
    merchantEmail: string;
    merchantWebsite: string;
    productName: string;
    productPrice: string;
    productCount: number;
    productTotal: string;
    status: TTransactionStatus;
    createdAt: string;
    paidAmount: string;
    totalAmount: string;
    paymentAddress: string;
    symbol: string;
    redirectUrl: string;
    warningText: string;
}

const Pay: FC<Props> = ({id}) => {
    const _ = useTranslation();

    const [transaction, setTransaction] = useState<ITransaction>({
        id: '',
        email: '',
        merchantName: '',
        merchantEmail: '',
        merchantWebsite: '',
        productName: '',
        productPrice: '',
        productCount: 0,
        productTotal: '',
        status: 'pending',
        createdAt: '',
        totalAmount: '',
        paidAmount: '',
        paymentAddress: '',
        symbol: '',
        redirectUrl: '',
        warningText: '',
    });
    const [endTime, setEndTime] = useState<Date | undefined>(undefined);


    const checkTransaction = useCallback(() => {
        getTransaction(id).then((res) => {
            if (!res.data.id) {
                throw new Error('Transaction not found');
            }
            const info = res.data;

            const createdAtDate = new Date(info.createdAt);
            const timeoutInMillis = info.timeoutAsSecond * 1000;
            let endTime = new Date(createdAtDate.getTime() + timeoutInMillis);

            if (info.status === 'completed' || info.status === 'failed') {
                endTime = new Date();
            }

            setEndTime(endTime);

            setTransaction({
                id: info.id,
                email: info.email,
                merchantName: info.merchantName || '',
                merchantEmail: info.merchantEmail || '',
                merchantWebsite: info.merchantUrl || '',
                productName: info.productName,
                productPrice: info.productPrice,
                productCount: info.productCount,
                productTotal: info.productTotal,
                status: info.status,
                createdAt: formatDateTime(createdAtDate),
                totalAmount: info.totalAmountAsCoin,
                paidAmount: info.paidAmountAsCoin,
                paymentAddress: info.paymentAddress,
                symbol: info.selectedCoin.symbol,
                redirectUrl: info.redirectUrl,
                warningText: info.warningText,
            });

        }).catch((err) => {
            toast.error(_('Pay.ORDER_NOT_FOUND'));
        });

    }, [_]);

    const miniCheckTransaction = useCallback(() => {
        transactionPubTicker(id).then((res) => {
            if (!res.data.id) {
                throw new Error('Transaction not found');
            }
            const info = res.data;
            if (info.status === 'completed' || info.status === 'failed') {
                setEndTime(new Date());
            }

            setTransaction((prevState) => {
                return {
                    ...prevState,
                    status: info.status,
                    totalAmount: info.totalAmountAsCoin,
                    paidAmount: info.paidAmountAsCoin,
                };
            });
        }).catch((err) => {
            toast.error(_('Pay.ORDER_NOT_FOUND'));
        });
    }, [_, id]);

    useEffect(() => {
        checkTransaction();

        const transactionInterval = setInterval(() => {
            miniCheckTransaction();
        }, 3000);

        return () => {
            clearInterval(transactionInterval);
        };
    }, [checkTransaction, miniCheckTransaction]);


    const title = useMemo(() => {
        switch (transaction.status) {
            case 'completed':
                return _('Pay.PAYMENT_SUCCESSFUL');
            case 'failed':
                return _('Pay.PAYMENT_TIMEOUT');
            case 'partial':
                return _('Pay.PAYMENT_PARTIAL');
            default:
                return _('Pay.MAKE_PAYMENT');
        }
    }, [transaction.status, _]);

    const statusText = useMemo(() => {
        switch (transaction.status) {
            case 'pending':
                return _('Pay.PAYMENT_PENDING');
            case 'completed':
                return _('Pay.PAYMENT_SUCCESSFUL');
            case 'failed':
                return _('Pay.PAYMENT_TIMEOUT');
            case 'partial':
                return _('Pay.PAYMENT_PARTIAL');
            default:
                return _('Pay.ERROR_OCCURRED');
        }
    }, [transaction.status, _]);


    return (
        <>
            <Header endTime={endTime} transactionStatus={transaction.status} />
            <div className="basketCards payStep">
                <div className="first">
                    <BasketCard title={title} icon={'mobile-payment.svg'}>
                        {transaction.status === 'pending' && (
                            <Qr amount={transaction.totalAmount} coinCode={transaction.symbol}
                                address={transaction.paymentAddress} warningText={transaction.warningText}/>
                        )}
                        {transaction.status === 'partial' && (
                            <Partial redirectUrl={transaction.redirectUrl}/>
                        )}
                        {transaction.status === 'completed' && (
                            <Success redirectUrl={transaction.redirectUrl}/>
                        )}
                        {transaction.status === 'failed' && (
                            <Timeout/>
                        )}
                    </BasketCard>
                </div>
                <div className="last">
                    <BasketCard title={_('Pay.ORDER_INFO')} icon={'checklist.svg'}>
                        <Table fields={[
                            {
                                key: _('Pay.ORDER_ID'),
                                value: transaction.id,
                                iconName: 'basket',
                            },
                            {
                                key: _('Pay.STATUS'),
                                value: statusText,
                                iconName: 'notification-status',
                            },
                            {
                                key: _('Pay.CREATED_AT'),
                                value: transaction.createdAt,
                                iconName: 'calendar-2',
                            },
                            {
                                key: _('Pay.RECEIVED_AMOUNT'),
                                value: `${transaction.paidAmount} ${transaction.symbol}`,
                                iconName: 'questionnaire-tablet',
                            },
                            {
                                key: _('Pay.EMAIL'),
                                value: transaction.email,
                                iconName: 'send',
                            },
                        ]} />
                    </BasketCard>
                    <BasketCard title={_('Pay.MERCHANT_INFO')} icon={'store.svg'}>
                        <Table fields={[
                            {
                                key: _('Pay.MERCHANT'),
                                value: transaction.merchantName,
                                iconName: 'badge',
                            },
                            {
                                key: _('Pay.EMAIL'),
                                value: transaction.merchantEmail,
                                iconName: 'send',
                                url: `mailto:${transaction.merchantEmail}`,
                            },
                            {
                                key: _('Pay.WEBSITE'),
                                value: capitalizeFirstLetter(transaction.merchantWebsite.replace(/(^\w+:|^)\/\//, '')),
                                iconName: 'fasten',
                                url: transaction.merchantWebsite,
                            },
                        ]} />
                    </BasketCard>
                    <BasketCard title={_('Pay.PRODUCT_INFO')} icon={'brand-identity.svg'}>
                        <Table fields={[
                            {
                                key: _('Pay.PRODUCT_NAME'),
                                value: `${transaction.productName} x ${transaction.productCount}`,
                                iconName: 'text-align-justify-center',
                            },
                            {
                                key: _('Pay.UNIT_PRICE'),
                                value: `$${transaction.productPrice}`,
                                iconName: 'tag',
                            },
                            {
                                key: _('Pay.TOTAL'),
                                value: `$${transaction.productTotal}`,
                                iconName: 'to-right',
                            },
                        ]} />
                    </BasketCard>
                </div>
            </div>

        </>
    );
};

export default Pay;