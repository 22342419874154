import React, {FC, ReactNode} from 'react';

interface Props {
    title: string,
    subTitle: string | ReactNode,
    headerButton?: ReactNode
}

const CardHeader: FC<Props> = ({title, subTitle, headerButton}) => {
    return (
        <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">{title}</span>
                <span className="text-muted mt-1 fw-semibold fs-7">{subTitle}</span>
            </h3>
            {headerButton}
        </div>
    );
};

export default CardHeader;