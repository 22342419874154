import clsx from 'clsx';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import {HeaderUserMenu, ThemeModeSwitcher} from '@/_metronic/partials';

const itemClass = 'ms-md-4';
// const btnClass =
//     'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px';
const userAvatarClass = 'symbol-35px';
// const btnIconClass = 'fs-2';

const Navbar = () => {
    return (
        <div className="app-navbar flex-shrink-0 gap-2">


            {/*<div className={clsx('app-navbar-item', itemClass)}>*/}
            {/*    <div*/}
            {/*        data-kt-menu-trigger="{default: 'click'}"*/}
            {/*        data-kt-menu-attach="parent"*/}
            {/*        data-kt-menu-placement="bottom-end"*/}
            {/*        className={btnClass}*/}
            {/*    >*/}
            {/*        <KTIcon iconName="element-plus" className={btnIconClass} />*/}
            {/*    </div>*/}
            {/*<HeaderNotificationsMenu/>*/}
            {/*</div>*/}

            <div className={clsx('app-navbar-item', itemClass)}>
                <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} />
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
                <div
                    className={clsx('cursor-pointer symbol', userAvatarClass)}
                    data-kt-menu-trigger="{default: 'click'}"
                    data-kt-menu-attach="parent"
                    data-kt-menu-placement="bottom-end"
                >
                    <img src={toAbsoluteUrl('/media/svg/custom/man.svg')} alt="" />
                </div>
                <HeaderUserMenu />
            </div>

        </div>
    );
};

export {Navbar};
