import {IDataTableHead, RowRenderer} from '@/app/interfaces';
import clsx from 'clsx';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/tr';
import Select from '@/app/modules/components/filterInputs/Select';
import Pagination from '@/app/modules/components/Pagination';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import React from 'react';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

dayjs.extend(relativeTime);
dayjs.locale('tr');

export interface DataTableProps<TData> {
    data: TData[],
    head: IDataTableHead[],
    loading: boolean,
    sort: {field: string, direction: 'asc' | 'desc'},
    handleSort: (field: string | undefined) => void,
    renderRow: RowRenderer<TData>;
    className?: string;
    pagination: {
        page: number,
        totalPages: number,
        totalRecords: number,
        perPage: number,
        handlePageChange: (page: number) => void,
        handlePerPageChange: (perPage: string) => void,
    },
    tableStyle?: {
        striped?: boolean,
        dashed?: boolean,
    }
}

const DataTable = <TData extends {}>({data, head, loading, sort, handleSort, renderRow, pagination, className = '', tableStyle = {striped: true, dashed: true}}: DataTableProps<TData>) => {
    const _ = useTranslation();
    return (
        <>

            <div className={'table-responsive '}>
                <table className={clsx('table align-middle fs-6 dataTable no-footer ', [className], {
                    'table-striped': tableStyle.striped,
                    'table-row-dashed': tableStyle.dashed,
                })}>
                    <thead>
                    <tr className={'fw-bolder text-gray-700'}>
                        {head.map((item, index) => {
                            return (
                                <th onClick={item.isSortable && item.name ? () => handleSort(item.name) : undefined} key={index} className={clsx(`text-${item.align} `, {
                                    'cursor-pointer': item.isSortable,
                                    'sorting sorting_desc': item.isSortable && item.name === sort.field && sort.direction === 'desc',
                                    'sorting sorting_asc': item.isSortable && item.name === sort.field && sort.direction === 'asc',
                                })} style={{width: item.width ? item.width : 'auto'}}>
                                    {item.text ? _(item.text) : ''}
                                    {item.isSortable && item.name !== sort.field && <i className={'fas fa-sort ms-2 '} />}
                                </th>
                            );
                        })}
                    </tr>
                    </thead>
                    <tbody className={'text-gray-600 fw-semibold position-relative'}>
                    {loading &&
                        <>
                            {data.length === 0 &&
                                <tr>
                                    <td style={{height: '100px'}} colSpan={head.length}></td>
                                </tr>
                            }
                            <tr>
                                <td colSpan={head.length} style={{padding: 0}}>

                                    <div className={'tableLoading'}>
                                        <span className={'spinner-border spinner-border-sm me-2'} role="status" aria-hidden="true"></span>
                                        {_('DataTable.LOADING')}
                                    </div>

                                </td>
                            </tr>
                        </>

                    }
                    {data.length === 0 && !loading && <tr>
                        <td colSpan={head.length} className={'text-center'}>
                            <div className="d-flex mt-10 mb-10 justify-content-center flex-column w-100 align-items-center gap-5">
                                <img src={toAbsoluteUrl('/custom/svg/sad.svg')} className={'w-50px'} alt="Not Found" />
                                <h5>{_('DataTable.NO_RESULTS')}</h5>
                            </div>
                        </td>
                    </tr>}
                    {data.length > 0 && data.map((item: any, index: any) => renderRow(item, index))}
                    </tbody>
                </table>
            </div>

            <div className="row mt-10 mb-5 gap-5 gap-md-0">
                <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                    <span className={'me-2'}>{_('DataTable.SHOWING_RECORDS')}</span>
                    <Select options={{
                        '10': '10',
                        '20': '20',
                        '50': '50',
                        '100': '100',
                    }} name="perPage" onChange={(name, value) => {
                        if (!value) return;
                        if (typeof value === 'string') {
                            pagination.handlePerPageChange(value);
                        }
                    }} value={pagination.perPage.toString()} />
                    <span className={'ms-5'}>{_('DataTable.TOTAL_RECORDS')} <strong>{pagination.totalRecords}</strong></span>
                </div>
                <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <Pagination currentPage={pagination.page} totalPages={pagination.totalPages} onChange={pagination.handlePageChange} />
                </div>
            </div>
        </>
    );
};

export default DataTable;