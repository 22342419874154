import React, {FC} from 'react';

interface Props {
    title: string;
    children: any;
}

const Title: FC<Props> = (props) => {
    return (
        <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>{props.title}</h1>
            <div className='text-gray-500 fw-semibold fs-6'>
                {props.children}
            </div>
        </div>
    );
};

export default Title;