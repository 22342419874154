import React, {FC} from 'react';
import {blockchainTransactionStatusLabels, TBlockchainTransactionStatus, transactionStatusLabels, TTransactionStatus} from '@/app/interfaces';
import Badge from '@/app/modules/components/Badge';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    status: TBlockchainTransactionStatus;
}

const BlockchainTransactionStatus: FC<Props> = ({status}) => {
    const _ = useTranslation();

    let statusText = _(blockchainTransactionStatusLabels[status]), statusClass = 'dark', isLight = true;
    switch (status) {
        case 'pending':
            statusClass = 'dark';
            break;
        case 'confirmed':
            statusClass = 'success';
            break;
        case 'failed':
            statusClass = 'danger';
            isLight = false;
            break;
        case 'unknown':
            statusClass = 'dark';
            isLight = false;
            break;
    }

    return (
        <Badge size="lg" width="100px" text={statusText} type={statusClass} isLight={isLight} />
    );
};

export default BlockchainTransactionStatus;


