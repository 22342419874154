import {FC} from 'react';
import {Navigate, Outlet, Route, Routes, useParams} from 'react-router-dom';
import MerchantDetailHeader from '@/app/pages/admin/merchantDetail/MerchantDetailHeader';
import {MerchantDetailSubRoutes} from '@/app/routing/MerchantDetailSubRoutes';
import {AdminMerchantDetailSubRouteConstants} from '@/app/constants/routes.constants';

interface Props {

}

const MerchantDetail: FC<Props> = (props) => {
    const params = useParams();
    const id = params.id;

    if (!id) return (<Navigate to={'/admin/merchants'} />);


    return (
        <Routes>
            <Route element={
                <>
                    <MerchantDetailHeader id={id} />
                    <Outlet />
                </>
            }>
                {MerchantDetailSubRoutes.map((route, index) => (
                    (
                        route.common ?
                            <Route key={index} path={route.path} element={<route.element id={id} />} /> :
                            <Route key={index} path={route.path} element={<route.element />} />
                    )
                ))}
                <Route index element={<Navigate replace={true} to={window.location.pathname + '/' + AdminMerchantDetailSubRouteConstants.STATISTICS} />} />
            </Route>
        </Routes>
    );
};

export default MerchantDetail;