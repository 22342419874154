import React, {FC, ReactNode} from 'react';
import clsx from "clsx";

interface Props {
    className?: string;
    title: string;
    children: ReactNode;
}

const FormCard: FC<Props> = (props) => {
    return (
        <div className={clsx("card card-flush py-4", {
            [props.className as string]: Boolean(props.className),
        })}>
            <div className="card-header min-h-10px pb-4">
                <div className="card-title">
                    <h2>{props.title}</h2>
                </div>
            </div>
            <div className={"card-body border-top"}>
                {props.children}
            </div>
        </div>
    );
};

export default FormCard;