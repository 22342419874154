export enum USER_TYPE {
    SUPER_ADMIN = 1,
    ADMIN = 2,
    MERCHANT = 3,
    EMPLOYEE = 4,
}

export enum CURRENCY_SYMBOL {
    USD = '$',
    EUR = '€',
    GBP = '£',
    TRY = '₺',
}