import UserDetail from '@/app/pages/common/userDetail/UserDetail';
import {FC} from 'react';

interface Props {

}

const AdminUserDetail: FC<Props> = (props) => {
    return <UserDetail isAdmin={true} />;
};

export default AdminUserDetail;