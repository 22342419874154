import React, {useCallback, useState, PropsWithChildren} from 'react';

export const useAreaLoading = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const LoadingComponent: React.FC<PropsWithChildren<{}>> = useCallback(({children}) => {
        return (
            <div className={`${loading ? 'app-content--loading' : ''} `}>
                <div className="pageLoader">
                    <div className="cssload-loader">
                        <div className="cssload-inner cssload-one"></div>
                        <div className="cssload-inner cssload-two"></div>
                        <div className="cssload-inner cssload-three"></div>
                    </div>
                </div>
                {children}
            </div>
        );
    }, [loading]);

    return {setLoading, LoadingComponent};
};
