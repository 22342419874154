import React, {FC, useCallback, useState} from 'react';
import Card from '@/app/modules/components/card/Card';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {KTIcon} from '@/_metronic/helpers';
import {IApiKey} from '@/app/interfaces';
import {toast} from 'react-toastify';
import useDatatable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {initialMerchantApiKeyListSort} from '@/app/utils/initialSorts';
import {ApiKeysTableHead} from '@/app/utils/tableHeads/merchant/ApiKeysTableHead';
import {trApiKey} from '@/app/pages/merchant/apiKeys/ApiKeys/trApiKey';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import {generateApiKey, getApiKeys} from '@/app/modules/auth/core/_requests';
import {useLoading} from '@/app/providers/LoadingProvider';
import {generateApiKeyFromAdmin, getApiKeysFromAdmin} from '@/app/utils/requests/adminRequests';

interface Props {
    id?: string;
}

const ApiKeys: FC<Props> = ({id}) => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const {setIsLoading} = useLoading();

    const getData: GetDataFunction<IApiKey, {}> = useCallback(async ({pageIndex, filters, pageSize, sort}) => {
        const params = {
            sort,
            pageIndex,
            pageSize,
        };
        let data;
        if (id) {
            const response = await getApiKeysFromAdmin(id, params);
            data = response.data;
        } else {
            const response = await getApiKeys(params);
            data = response.data;
        }
        if (!data) return Promise.reject();
        return data;
    }, [id]);

    const {
        getDataCallback,
        DataTableComponent,
    } = useDatatable<IApiKey, {}>({
        initialSort: initialMerchantApiKeyListSort,
        initialFilters: {},
        getData,
        id: 'apiKeys',
    });
    const [showFullKey, setShowFullKey] = useState<number | null>(null);

    const toggleShowFullKey = useCallback((id: number) => {
        if (showFullKey === id) {
            setShowFullKey(null);
        } else {
            setShowFullKey(id);
        }
    }, [showFullKey]);

    const addNewApiKey = useCallback(async () => {
        try {
            setIsLoading(true);
            if (id) {
                await generateApiKeyFromAdmin(id);
            } else {
                await generateApiKey();
            }

            setIsLoading(false);
            toast.success(_('ApiKeys.ADDED_SUCCESS'));
            await getDataCallback();

        } catch (e) {
            toast.error(getErrorMessage(e));
        } finally {
            setIsLoading(false);
        }
    }, [_, getDataCallback, getErrorMessage, id, setIsLoading]);

    return (
        <>
            <ToolbarWrapper title={_('ApiKeys.TITLE')} onlyTitle={!!id} isMerchantSubMenu={!!id} />
            <Card headerButton={<button onClick={addNewApiKey} className={'btn btn-sm btn-outline-primary btn-outline'}>
                <KTIcon iconName={'plus'} className={'fs-2 me-1'} />
                {_('ApiKeys.ADD_NEW_KEY')}
            </button>} title={`${_('ApiKeys.API_KEY_LIST')}`} subTitle={_('ApiKeys.MANAGE_API_KEYS')} className={'apiKeys'}>
                {/*{isMobile ? <MobileTable apiKeys={apiKeys} merchantID={id}/> : <Table apiKeys={apiKeys} merchantID={id}/>}*/}
                <DataTableComponent head={ApiKeysTableHead} renderRow={(apiKey) => trApiKey({
                    apiKey,
                    merchantID: id,
                    showFullKey,
                    toggleShowFullKey,
                    translator: _,
                })} />
            </Card>
        </>
    );
};

export default ApiKeys;