import {FC, ReactNode} from 'react';

interface Props {
    children: ReactNode;
}

const Buttons: FC<Props> = ({children}) => {
    return (
        <div className="actionButtons">
            {children}
        </div>
    );
};

export default Buttons;