import React, {FC} from 'react';

interface Props {
    fiat: string;
}

const UsdValue: FC<Props> = ({fiat}) => {
    return (
        <span className={'text-dark fw-bold  d-block mb-1 fs-2'}>
            {fiat}
        </span>
    );
};

export default UsdValue;