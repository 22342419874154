import React, {FC, useCallback, useMemo, useRef} from 'react';
import {ICoinConfig} from '@/app/interfaces';
import clsx from 'clsx';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import CoinName from '@/app/modules/components/CoinName';
import {convertStringTo2precision} from '@/app/modules/helpers';
import {toast} from 'react-toastify';


interface Props {
    toggleCoin: (id: number) => void;
    toggleAllCoins: () => void;
    updateDiscountRate: (id: number, discountRate: string, isAdmin?: boolean) => void;
    coinAcceptance: ICoinConfig[];
    isAdmin?: boolean;
}

const Table: FC<Props> = ({toggleCoin, toggleAllCoins, coinAcceptance, updateDiscountRate, isAdmin}) => {
    const _ = useTranslation();

    const allEnabled = useMemo(() => coinAcceptance.every((coin) => coin.enabled), [coinAcceptance]);

    const inputRefs = useRef({});

    const focusInput = (id: string) => {
        const input = inputRefs.current[id];
        if (input) {
            input.focus();
            input.select();
        }
    };

    return (
        <table className={clsx('table align-middle gs-0 gy-4 walletList')}>
            <thead>
            <tr className={'fw-bolder text-muted'}>
                <th className="w-25px">
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                        <input className="form-check-input cursor-pointer" type="checkbox" value="1" onChange={() => toggleAllCoins()} checked={allEnabled} />
                    </div>
                </th>
                <th>{_('CoinAcceptance.COIN_NAME')}</th>
                <th className={'text-end pe-5 w-100px'}>{_('CoinAcceptance.DISCOUNT_RATE')}</th>
                {isAdmin && <th className={'text-end pe-5 w-100px'}>{_('CoinAcceptance.FEE_DISCOUNT_RATE')}</th>}
            </tr>
            </thead>
            <tbody>
            {coinAcceptance.map((coinConfig) => (
                <tr key={'coinConfig-' + coinConfig.id}>
                    <td>
                        <div className="form-check form-check-sm form-check-custom form-check-solid">
                            <input className="form-check-input cursor-pointer" type="checkbox" value="1"
                                   checked={coinConfig.enabled}
                                   onChange={() => toggleCoin(coinConfig.id)}
                            />
                        </div>
                    </td>
                    <td>
                        <div className={'d-flex align-items-center'} onClick={() => focusInput(coinConfig.id.toString())}>
                            <CoinName name={coinConfig.coinName} symbol={coinConfig.coinSymbol} subSymbol={coinConfig.coinSubSymbol} isTestNetwork={coinConfig.isTestNetwork} />
                            <div className={'seperator'} />
                        </div>
                    </td>
                    <td className={'text-end'}>
                        <div className={'discountRateInput'}>
                            <input
                                type="text"
                                value={coinConfig.discountRate}
                                // maxLength={2}
                                pattern="[0-9]*"
                                inputMode="numeric"
                                className="form-control "
                                onChange={(e) => {
                                    updateDiscountRate(coinConfig.id, e.target.value);
                                }}
                                onBlur={(e) => {
                                    const convertedValue = convertStringTo2precision(e.target.value);
                                    if (parseFloat(convertedValue) < 0 || parseFloat(convertedValue) > 100) {
                                        toast.error(_('CoinAcceptance.INVALID_DISCOUNT_RATE'));
                                        updateDiscountRate(coinConfig.id, '0.00');
                                        return;
                                    }
                                    updateDiscountRate(coinConfig.id, convertedValue);
                                }}
                                ref={(el) => inputRefs.current[coinConfig.id] = el}
                            />
                            <span className="suffix"><i className="fa fa-percent"></i></span>
                        </div>
                    </td>
                    {isAdmin && (
                        <td className={'text-end'}>
                            <div className={'discountRateInput'}>
                                <input
                                    type="text"
                                    value={coinConfig.feeDiscountRate}
                                    // maxLength={2}
                                    pattern="[0-9]*"
                                    inputMode="numeric"
                                    className="form-control "
                                    onChange={(e) => {
                                        updateDiscountRate(coinConfig.id, e.target.value, true);
                                    }}
                                    onBlur={(e) => {
                                        const convertedValue = convertStringTo2precision(e.target.value);
                                        if (parseFloat(convertedValue) < 0 || parseFloat(convertedValue) > 100) {
                                            toast.error(_('CoinAcceptance.INVALID_DISCOUNT_RATE'));
                                            updateDiscountRate(coinConfig.id, '0.00', true);
                                            return;
                                        }
                                        updateDiscountRate(coinConfig.id, convertedValue, true);
                                    }}
                                    ref={(el) => inputRefs.current[coinConfig.id] = el}
                                />
                                <span className="suffix"><i className="fa fa-percent"></i></span>
                            </div>
                        </td>
                    )}
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default Table;