import React, {FC} from 'react';
import Table from './Table';
import {useDevice} from '@/app/modules/providers/DeviceProvider';
import MobileTable from './MobileTable';
import Card from '@/app/modules/components/card/Card';
import {IBalance} from '@/app/interfaces';

interface Props {
    id?: string;
    title: string;
    subTitle: string;
    isAdminWallets?: boolean;
    balances: IBalance[];
    hideActions?: boolean;
    totalBalance: string;
}

const WalletList: FC<Props> = ({id, isAdminWallets, title, subTitle, balances, hideActions, totalBalance}) => {
    const {isMobile} = useDevice();

    return (
        <>
            <Card title={title} subTitle={subTitle} className={'myWallet'}>
                {isMobile ? <MobileTable hideActions={hideActions} merchantID={id} isAdminWallets={isAdminWallets} balances={balances} totalBalance={totalBalance} /> : <Table hideActions={hideActions} isAdminWallets={isAdminWallets} merchantID={id} balances={balances} totalBalance={totalBalance} />}
            </Card>
        </>
    );
};

export {WalletList};
