import React, {useEffect, useCallback} from 'react';
import {IDataTableHead, ISortState, RowRenderer} from '@/app/interfaces';
import usePagination from '@/app/modules/hooks/usePagination';
import useDataTableSort from '@/app/modules/hooks/useDataTableSort';
import DataTable from '@/app/modules/components/DataTable';
import {toast} from 'react-toastify';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';

interface IDataTableHook<TData, TFilters> {
    sort: ISortState;
    page: number;
    perPage: number;
    filters: TFilters;
    handleFilterSubmit: () => void;
    handleFilterReset: () => void;
    handleFilterChange: (name: string, value: string | object | null) => void;
    setDisplayedData: React.Dispatch<React.SetStateAction<TData[]>>;
    setDataLength: React.Dispatch<React.SetStateAction<number>>;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    DataTableComponent: React.FC<IDataTableProps<TData>>;
    getDataCallback: () => Promise<void>;
    loading: boolean;
}

interface IDataTableProps<TData> {
    head: IDataTableHead[],
    renderRow: RowRenderer<TData>;
    className?: string;
    tableStyle?: {
        striped?: boolean,
        dashed?: boolean,
    }
}


interface Props<TData, TFilters> {
    initialSort: ISortState;
    initialFilters: TFilters;
    getData: GetDataFunction<TData, TFilters>;
    id: string;
}

export interface GetDataFunction<TData, TFilters> {
    (params: {pageIndex: number, filters: TFilters, pageSize: number, sort: ISortState}): Promise<{data: TData[], length: number}>;
}

const useDataTable = <TData extends {}, TFilters>({initialSort, initialFilters, getData, id}: Props<TData, TFilters>): IDataTableHook<TData, TFilters> => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const [filters, setFilters] = React.useState<TFilters>(initialFilters);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [dataLength, setDataLength] = React.useState<number>(0);
    const [displayedData, setDisplayedData] = React.useState<TData[]>([]);

    const {page, totalPages, handlePageChange, perPage, handlePerPageChange} = usePagination(dataLength, id);
    const {sort, handleSorting} = useDataTableSort(initialSort);

    const handleFilterReset = useCallback(() => {
        setFilters(initialFilters);
    }, [initialFilters]);

    const handleFilterChange = useCallback((name: string, value: string | object | null) => {
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const handleSort = useCallback((field: string | undefined) => {
        if (!field) return;
        handlePageChange(1);
        handleSorting(field);
    }, [handleSorting, handlePageChange]);

    const getDataCallback = useCallback(async () => {
        setLoading(true);
        try {
            const data = await getData({pageIndex: page - 1, filters, pageSize: perPage, sort});
            setDisplayedData(data.data);
            setDataLength(data.length);
        } catch (e) {
            toast.error(getErrorMessage(e));
        } finally {
            setLoading(false);
        }

    }, [getData, page, filters, perPage, sort, getErrorMessage]);

    const handleFilterSubmit = useCallback(() => {
        handlePageChange(1);
        getDataCallback().then();
    }, [getDataCallback, handlePageChange]);

    useEffect(() => {
        getDataCallback().then(r => {
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, perPage, sort]);

    const DataTableComponent = (props: IDataTableProps<TData>) => (
        <DataTable<TData>
            data={displayedData}
            loading={loading}
            sort={sort}
            handleSort={handleSort}
            pagination={{
                page,
                totalPages,
                totalRecords: dataLength,
                handlePageChange,
                perPage,
                handlePerPageChange,
            }}
            {...props}

        />
    );

    return {
        handleFilterSubmit,
        handleFilterReset,
        handleFilterChange,
        setDisplayedData,
        setDataLength,
        setLoading,
        page,
        loading,
        perPage,
        sort,
        filters,
        DataTableComponent,
        getDataCallback,
    };
};

export default useDataTable;