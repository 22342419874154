import {IMerchant, RowRenderer, TMerchantStatus, Translator} from '@/app/interfaces';
import {getMerchantStatusBadgeInfo} from '@/app/modules/helpers/getValuesFromId';
import dayjs from 'dayjs';
import {Link} from 'react-router-dom';
import {KTIcon} from '@/_metronic/helpers';
import React from 'react';
import Badge from '@/app/modules/components/Badge';
import {generateRoute} from '@/app/modules/helpers';
import {AdminRouteConstants} from '@/app/constants/routes.constants';
import {Tooltip} from 'react-tooltip';


const status = (type: TMerchantStatus, translator: Translator) => {
    const info = getMerchantStatusBadgeInfo(type);
    return <Badge text={translator(info.text)} isLight={info.isLight} type={info.color} size={'lg'} />;
};
export const merchantTr: RowRenderer<IMerchant> = ((merchant: IMerchant, index: number, translator: Translator) => (
    <tr key={index}>
        <td className={'text-left'}>
            <div className="d-flex flex-column">
                <Link className="text-gray-800 text-hover-primary mb-1" to={generateRoute(AdminRouteConstants.MERCHANT_DETAIL, {id: merchant.id.toString()})}>
                    {merchant.merchantName}
                </Link>
            </div>
        </td>
        <td>
            {merchant.merchantEmail}
        </td>
        <td className={'text-center'}>
            {dayjs(merchant.createdAt).format('DD.MM.YYYY HH:mm')}
        </td>

        <td className="text-center">
            {status(merchant.status, translator)}
        </td>
        <td className={'text-end'}>
            <Tooltip id={'tooltip'} place={'top'} />
            <Link data-tooltip-id={'tooltip'} data-tooltip-content="Merchant Detayları" className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" to={generateRoute(AdminRouteConstants.MERCHANT_DETAIL, {id: merchant.id.toString()})}>
                <KTIcon iconName={'switch'} className={'fs-2'} />
            </Link>
        </td>
    </tr>
));