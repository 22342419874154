import {IDataTableHead} from '@/app/interfaces';

export const EmployeesTableHead: IDataTableHead[] = [
    {
        text: 'UsersTableHead.MEMBER',
        name: 'fullName',
        isSortable: true,
        align: 'left',
        width: '200px',
    },
    {
        text: 'UsersTableHead.MEMBERSHIP_TYPE',
        name: 'userType',
        isSortable: true,
        align: 'left',
        width: '150px',
    },
    {
        text: 'UsersTableHead.LAST_LOGIN',
        isSortable: false,
        align: 'center',
        width: '150px',
    },
    {
        text: 'UsersTableHead.STATUS',
        name: 'status',
        isSortable: true,
        align: 'center',
        width: '70px',
    },
    {
        text: 'UsersTableHead.JOIN_DATE',
        name: 'createdAt',
        isSortable: true,
        align: 'center',
        width: '150px',
    },
    {
        text: '',
        width: '50px',
    },
];