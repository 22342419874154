import {IDataTableHead} from '@/app/interfaces';

export const ApiKeysTableHead: IDataTableHead[] = [
    {
        text: '',
        width: '50px',
    },
    {
        text: 'ApiKeysTH.CREATED_AT',
        name: 'createdAt',
        isSortable: true,
        align: 'left',
        width: '100px',
    },
    {
        text: 'ApiKeysTH.NAME',
        name: 'name',
        isSortable: true,
        align: 'left',
        width: '150px',
    },
    {
        text: 'ApiKeysTH.KEY',
        name: 'key',
        isSortable: false,
        align: 'left',
        width: '200px',
    },
    {
        text: 'ApiKeysTH.STATUS',
        name: 'status',
        isSortable: true,
        align: 'center',
        width: '100px',
    },
    {
        text: 'ApiKeysTH.ACTIONS',
        width: '50px',
        align: 'end',
    },

];