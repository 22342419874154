import React, {FC, useEffect, useMemo} from 'react';
import Input from '@/app/modules/components/filterInputs/Input';
import Select from '@/app/modules/components/filterInputs/Select';
import Buttons from '@/app/modules/components/Buttons';
import {IMerchantListItem} from '@/app/interfaces';
import {IAdminUserListFilters} from '@/app/interfaces/filters';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getAllMerchants} from '@/app/utils/requests/adminRequests';

interface Props {
    handleFilterSubmit: () => void;
    handleFilterReset: () => void;
    handleFilterChange: (name: string, value: string | object | null) => void;
    filters: IAdminUserListFilters;
    merchantID?: string;
}

const UserFilters: FC<Props> = ({handleFilterSubmit, handleFilterReset, handleFilterChange, filters, merchantID}) => {
    const _ = useTranslation();

    const [merchants, setMerchants] = React.useState<IMerchantListItem[]>([]);

    useEffect(() => {
        getAllMerchants().then((res) => {
            setMerchants(res.data);
        });
    }, []);

    const merchantOptions = useMemo(() => {
        let options: {[key: number]: string} = {};
        merchants.forEach((merchant) => {
            options[merchant.id] = merchant.merchantName;
        });
        return options;
    }, [merchants]);

    // if (merchantID) {
    //     filters.merchantID = merchantID;
    // }

    return (
        <div className="row mb-10 gap-5">
            <div className="col-md ">
                <Input name="searchQuery" value={filters.searchQuery} label={_('Filter.SEARCH_QUERY')} onChange={handleFilterChange} submitOnEnterFunc={handleFilterSubmit} />
            </div>
            <div className="col-md ">
                <Select options={{
                    1: _('UserType.SUPER_ADMIN'),
                    2: _('UserType.ADMIN'),
                    3: _('UserType.MERCHANT'),
                    4: _('UserType.EMPLOYEE'),
                }} name="role" label={_('UserFilters.USER_ROLE')} onChange={handleFilterChange} value={filters.role} className={'form-control-solid'} isClearable />
            </div>
            <div className="col-md">
                <Select options={merchantOptions} name="merchantID" label={_('UserType.MERCHANT')} onChange={handleFilterChange} value={filters.merchantID} isDisabled={!!merchantID} className={'form-control-solid'} isSearchable isClearable />
            </div>

            <div className={'col-md flex-grow-0 align-self-end'}>
                <Buttons>
                    <button onClick={handleFilterSubmit} className="btn  btn-primary  px-6">{_('Filter.FILTER')}</button>
                </Buttons>
            </div>
        </div>
    );
};

export default UserFilters;