/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react';
import {Outlet, Link} from 'react-router-dom';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import ThemeSwitcher from './components/tools/ThemeSwitcher';
import LanguageSwitcher from './components/tools/LanguageSwitcher';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

const AuthLayout = () => {
    const _ = useTranslation();
    useEffect(() => {
        const root = document.getElementById('root');
        if (root) {
            root.style.height = '100%';
        }
        return () => {
            if (root) {
                root.style.height = 'auto';
            }
        };
    }, []);


    return (
        <div className="d-flex flex-column flex-lg-row flex-column-fluid h-100">
            {/* begin::Body */}

            <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1">
                <div className={'d-flex justify-content-end px-0 px-lg-10 gap-2'}>
                    <ThemeSwitcher />
                    <LanguageSwitcher />

                </div>
                {/* begin::Form */}
                <div className="d-flex flex-center flex-column flex-lg-row-fluid">
                    {/* begin::Wrapper */}
                    <div className="w-lg-500px p-0 p-lg-10">

                        <Outlet />
                    </div>
                    {/* end::Wrapper */}
                </div>
                {/* end::Form */}

                {/* begin::Footer */}
                <div className="d-flex flex-center flex-wrap px-5">
                    {/* begin::Links */}
                    <div className="d-flex fw-semibold text-primary fs-base">
                        <a href="#" className="px-5" target="_blank">
                            {_('AuthLayout.MENU_LINK_1')}
                        </a>

                        <a href="#" className="px-5" target="_blank">
                            {_('AuthLayout.MENU_LINK_2')}
                        </a>

                        <a href="#" className="px-5" target="_blank">
                            {_('AuthLayout.MENU_LINK_3')}
                        </a>
                    </div>
                    {/* end::Links */}
                </div>
                {/* end::Footer */}
            </div>
            {/* end::Body */}

            {/* begin::Aside */}
            <div
                className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2"
                style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`}}
            >
                {/* begin::Content */}
                <div className="d-flex flex-column flex-center py-15 px-5 px-md-15 w-100">
                    {/* begin::Logo */}
                    <Link to="/" className="mb-md-12">
                        <img alt="Logo" src={toAbsoluteUrl('/media/logos/nullpay-default-dark.svg')}
                             className="h-50px" />
                    </Link>
                    {/* end::Logo */}


                    {/* begin::Title */}
                    <h1 className="text-white fs-2qx fw-bolder text-center mb-7 d-none d-md-block">
                        {_('AuthLayout.AUTH_SLOGAN_TITLE')}
                    </h1>
                    {/* end::Title */}

                    {/* begin::Text */}
                    <div className="text-white fs-base text-center mw-500px d-none d-md-block">
                        {_('AuthLayout.AUTH_SLOGAN_TEXT')}
                    </div>
                    {/* end::Text */}
                </div>
                {/* end::Content */}
            </div>
            {/* end::Aside */}
        </div>
    );
};

export {AuthLayout};
