import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import FormCard from '@/app/modules/components/form-card/FormCard';
import {toast} from 'react-toastify';
import {AdminRouteConstants, MerchantRouteConstants} from '@/app/constants/routes.constants';
import Buttons from '@/app/modules/components/Buttons';
import {useLoading} from '@/app/providers/LoadingProvider';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import Input from '@/app/modules/auth/components/tools/inputs/Input';
import {useFormik} from 'formik';
import {IUpdateCoinValues} from '@/app/interfaces/values';
import {updateCoinSchema} from '@/app/utils/yupSchema';
import {updateCoinInitialValues} from '@/app/utils/initialValues';
import Submit from '@/app/modules/auth/components/tools/buttons/Submit';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import {getCoin, updateCoin} from '@/app/utils/requests/adminRequests';
import {useNavigate, useParams} from 'react-router-dom';
import {IListCoin} from '@/app/interfaces';
import {convertStringToNumber} from '@/app/modules/helpers';


const UpdateCoin: FC = () => {
    const params = useParams();
    const navigate = useNavigate();
    const id = params.id;
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const {isLoading, setIsLoading} = useLoading();
    const [coin, setCoin] = useState<IListCoin | null>(null);
    const formik = useFormik<IUpdateCoinValues>({
        initialValues: updateCoinInitialValues,
        validationSchema: updateCoinSchema(_),
        onSubmit: async (values, {setSubmitting}) => {
            if (!id) return;
            try {

                const payload = {
                    status: values.status,
                    minWithdraw: parseFloat(values.minWithdraw),
                    maxWithdraw: parseFloat(values.maxWithdraw),
                    withdrawFixedFee: parseFloat(values.withdrawFixedFee),
                    withdrawFeePercentage: parseFloat(values.withdrawFeePercentage),
                    feePercentage: parseFloat(values.feePercentage),
                    fixedFee: parseFloat(values.fixedFee),
                    warningText: values.warningText,
                };
                const {status} = await updateCoin(parseInt(id), payload);

                if (status === 200) {
                    toast.success(_('UpdateCoin.UPDATED_SUCCESS'));
                    navigate(AdminRouteConstants.COIN_SETTINGS);
                }
            } catch (error: any) {
                toast.error(getErrorMessage(error));
            } finally {
                setSubmitting(false);
            }
        },
    });

    const flag = useRef(true);
    useEffect(() => {
        if (flag.current) {
            if (!id) return;
            flag.current = false;
            setIsLoading(true);

            getCoin(parseInt(id)).then(({data}) => {
                setCoin(data);
                formik.setValues({
                    status: data.status,
                    minWithdraw: data.minWithdraw,
                    maxWithdraw: data.maxWithdraw,
                    withdrawFixedFee: data.withdrawFixedFee,
                    withdrawFeePercentage: data.withdrawFeePercentage,
                    feePercentage: data.feePercentage,
                    fixedFee: data.fixedFee,
                    warningText: data.warningText,
                });
            }).catch((err) => {
                getErrorMessage(err);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [flag, setIsLoading, formik, id, getErrorMessage]);

    const inputComponent = useCallback((props: {name: string, type: string, label: string, checkboxLabel?: string, description?: string, disabled?: boolean, precision?: number}) => {
        return <Input
            className="form-control-lg form-control-solid"
            name={props.name}
            type={props.type}
            formik={formik}
            labelSize={2}
            label={_(props.label)}
            placeholder=""
            isRow
            description={props.description ? _(props.description) : ''}
            disabled={props.disabled}
            onChange={props.precision ? (e) => formik.setFieldValue(props.name, e.target.value) : undefined}
            // @ts-ignore
            onBlur={props.precision ? (e) => formik.setFieldValue(props.name, convertStringToNumber(e.target.value, props.precision)) : undefined}
            checkboxLabel={props.checkboxLabel}
        />;
    }, [_, formik]);

    return (
        <>
            <ToolbarWrapper title={_('UpdateCoin.TITLE')} />

            <form onSubmit={formik.handleSubmit} className="form" noValidate>
                <div className={'d-flex flex-column gap-7 gap-lg-10 merchantSettings'}>
                    <FormCard title={coin ? coin.name : ''}>
                        {inputComponent({name: 'status', type: 'checkbox', label: 'UpdateCoin.STATUS', checkboxLabel: formik.values.status ? _('General.ACTIVE') : _('General.INACTIVE')})}
                        {inputComponent({name: 'minWithdraw', type: 'number', label: 'UpdateCoin.MIN_WITHDRAW', description: 'UpdateCoin.MIN_WITHDRAW_DESC', precision: 8})}
                        {inputComponent({name: 'maxWithdraw', type: 'number', label: 'UpdateCoin.MAX_WITHDRAW', description: 'UpdateCoin.MAX_WITHDRAW_DESC', precision: 8})}
                        {inputComponent({name: 'withdrawFixedFee', type: 'number', label: 'UpdateCoin.WITHDRAW_FEE', description: 'UpdateCoin.WITHDRAW_FEE_DESC', precision: 8})}
                        {inputComponent({name: 'withdrawFeePercentage', type: 'number', label: 'UpdateCoin.WITHDRAW_FEE_PERCENTAGE', description: 'UpdateCoin.WITHDRAW_FEE_PERCENTAGE_DESC', precision: 2})}
                        {inputComponent({name: 'fixedFee', type: 'number', label: 'UpdateCoin.FIXED_FEE', description: 'UpdateCoin.FIXED_FEE_DESC', precision: 8})}
                        {inputComponent({name: 'feePercentage', type: 'number', label: 'UpdateCoin.FEE_PERCENTAGE', description: 'UpdateCoin.FEE_PERCENTAGE_DESC', precision: 2})}
                        {inputComponent({name: 'warningText', type: 'text', label: 'UpdateCoin.WARNING_TEXT', description: 'UpdateCoin.WARNING_TEXT_DESC'})}
                    </FormCard>
                    <Buttons>
                        <a href={MerchantRouteConstants.DASHBOARD} className="btn btn-light">
                            {_('General.CANCEL')}
                        </a>

                        <Submit disabled={formik.isSubmitting || !formik.isValid} loading={isLoading} label={_('General.SAVE_CHANGES')} />

                    </Buttons>
                </div>
            </form>
        </>
    );
};

export default UpdateCoin;
