import {IUser, RowRenderer, Translator, TUserStatus} from '@/app/interfaces';
import {getUserStatusBadgeInfo, getUserType} from '@/app/modules/helpers/getValuesFromId';
import dayjs from 'dayjs';
import {Link} from 'react-router-dom';
import {KTIcon} from '@/_metronic/helpers';
import React from 'react';
import Badge from '@/app/modules/components/Badge';
import {generateRoute} from '@/app/modules/helpers';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import {Tooltip} from 'react-tooltip';
import {Lang} from '@/_metronic/i18n/Metronici18n';

export const statusBadge = (type: TUserStatus, translator: Translator) => {
    const info = getUserStatusBadgeInfo(type);
    return <Badge text={translator(info.text)} isLight={info.isLight} type={info.color} size={'lg'} />;
};

export const employeeTr: RowRenderer<IUser> = ((user: IUser, index: number, translator: Translator, selectedLang?: Lang) => (
    <tr key={index}>
        <td className={'text-left'}>
            <div className="d-flex flex-column">
                <Link className="text-gray-800 text-hover-primary mb-1" to={generateRoute(MerchantRouteConstants.EMPLOYEE_DETAIL, {id: user.id.toString()})}>{user.fullName}</Link>
                <span>{user.email}</span>
            </div>
        </td>
        <td>
            {translator(getUserType(user.userType))}
        </td>
        <td className={'text-center'}>
            <div className="badge badge-light fw-bold">
                {selectedLang ? dayjs(user.lastLoginAt).locale(selectedLang).fromNow() : dayjs(user.lastLoginAt).fromNow()}
            </div>
        </td>
        <td className="text-center">
            {statusBadge(user.status, translator)}
        </td>
        <td className={'text-center'}>
            {dayjs(user.createdAt).format('DD.MM.YYYY HH:mm')}
        </td>
        <td className={'text-end'}>
            <Tooltip id="tooltip" place={'top'} />
            <Link data-tooltip-id="tooltip" data-tooltip-content="Düzenle" to={generateRoute(MerchantRouteConstants.EMPLOYEE_DETAIL, {id: user.id.toString()})} className={'btn btn-icon btn-bg-light btn-active-color-primary btn-sm'}>
                <KTIcon iconName={'pencil'} className={'fs-2'} />
            </Link>
        </td>
    </tr>
));