import React, {FC, useCallback, useEffect, useState} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import Card from '@/app/modules/components/card/Card';
import Table from '@/app/pages/merchant/merchant/CoinAcceptance/Table';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import {toast} from 'react-toastify';
import Buttons from '@/app/modules/components/Buttons';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getCoinConfig, setCoinConfigBatch} from '@/app/modules/auth/core/_requests';
import {ICoinConfig} from '@/app/interfaces';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import {useLoading} from '@/app/providers/LoadingProvider';
import Submit from '@/app/modules/auth/components/tools/buttons/Submit';
import {getCoinConfigFromAdmin, setCoinConfigBatchFromAdmin} from '@/app/utils/requests/adminRequests';

interface Props {
    id?: string;
}

const CoinAcceptance: FC<Props> = ({id}) => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const {isLoading, setIsLoading} = useLoading();

    const [coinAcceptance, setCoinAcceptance] = useState<ICoinConfig[]>([]);


    const toggleCoin = (id: number) => {
        setCoinAcceptance((prevState) => {
            const newState = [...prevState];
            const coin = newState.find((coin) => coin.id === id);
            if (coin) {
                coin.enabled = !coin.enabled;
            }
            return newState;
        });
    };

    const toggleAllCoins = () => {
        setCoinAcceptance((prevState) => {
            const newState = [...prevState];
            const anyDisabled = newState.some((coin) => !coin.enabled);
            newState.forEach((coin) => {
                coin.enabled = anyDisabled;
            });
            return newState;
        });
    };

    const updateDiscountRate = (id: number, discountRate: string, isAdmin?: boolean) => {
        setCoinAcceptance((prevState) => {
            const newState = [...prevState];
            const coin = newState.find((coin) => coin.id === id);
            if (coin) {
                if (isAdmin) {
                    coin.feeDiscountRate = discountRate;
                } else {
                    coin.discountRate = discountRate;
                }
            }
            return newState;
        });
    };

    const refreshCoinConfigs = useCallback(() => {
        const fetchCoinConfig = id ? () => getCoinConfigFromAdmin(id) : getCoinConfig;
        fetchCoinConfig().then(({data}) => {
            setCoinAcceptance(data);
        });
    }, [id]);


    useEffect(() => {
        refreshCoinConfigs();
    }, [refreshCoinConfigs]);

    const handleSubmit = async () => {
        setIsLoading(true);
        try {
            const payload = coinAcceptance.map(({id, enabled, discountRate, feeDiscountRate}) => {
                const data = {
                    coinId: id,
                    enabled,
                    discountRate: parseFloat(discountRate),
                };
                if (feeDiscountRate) {
                    data['feeDiscountRate'] = parseFloat(feeDiscountRate);
                }
                return data;
            });
            if (id) {
                await setCoinConfigBatchFromAdmin(id, payload);
            } else {
                await setCoinConfigBatch(payload);
            }
            toast.success(_('CoinAcceptance.SETTINGS_UPDATED_SUCCESSFULLY'));
            refreshCoinConfigs();
        } catch (e) {
            toast.error(getErrorMessage(e));
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <ToolbarWrapper title={_('CoinAcceptance.TITLE')} onlyTitle={!!id} isMerchantSubMenu={!!id} />

            <Card title={_('CoinAcceptance.TITLE')} subTitle={_('CoinAcceptance.SUB_TITLE')} className={'coinAcceptance'}>
                <Table toggleCoin={toggleCoin} toggleAllCoins={toggleAllCoins} coinAcceptance={coinAcceptance} updateDiscountRate={updateDiscountRate} isAdmin={!!id} />
            </Card>
            <Buttons>
                <a href={MerchantRouteConstants.DASHBOARD} className="btn btn-light">
                    {_('General.CANCEL')}
                </a>
                <Submit loading={isLoading} label={_('CoinAcceptance.SAVE_CHANGES')} onClick={handleSubmit} type="button" />
            </Buttons>
        </>
    );
};

export default CoinAcceptance;