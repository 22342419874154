import React, {FC} from 'react';

interface Props {
    children: any;
    formik: any;
    name: string;
}

const Check: FC<Props> = (props) => {
    return (
        <div className='fv-row mb-8'>
            <label className='form-check form-check-inline' htmlFor={props.name}>
                <input
                    className='form-check-input'
                    type='checkbox'
                    id={props.name}
                    {...props.formik.getFieldProps(props.name)}
                />
                <span>
                    {props.children}
                </span>
            </label>
            {props.formik.touched[props.name] && props.formik.errors[props.name] && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert'>{props.formik.errors[props.name]}</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Check;