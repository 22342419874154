import {type FC, useEffect, useState} from 'react';
import StatCard from '@/app/pages/common/Dashboard/StatCards/StatCard/StatCard';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import {toast} from 'react-toastify';
import {IStatCard, TFetchGeneralStats} from '@/app/interfaces';

interface Props {
    cards: IStatCard[];
    fetchGeneralStats: TFetchGeneralStats;
}

const StatCards: FC<Props> = ({fetchGeneralStats, cards}) => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);

    const [cardValues, setCardValues] = useState<any>({});
    const [loading, setLoading] = useState<boolean>(true);


    useEffect(() => {
        setLoading(true);

        // const fetchGeneralStats = id ? () => generalStatsFromAdmin(id) : generalStats;
        fetchGeneralStats().then((response) => {
            setCardValues(response.data);
        }).catch((e) => {
            toast.error(getErrorMessage(e));
        }).finally(() => {
            setLoading(false);
        });
    }, [getErrorMessage]);
    return (
        <>
            {cards.map((card) => (
                <div className="col-sm-6 col-xl-4" key={card.key}>
                    <StatCard
                        title={card.title}
                        value={cardValues[card.key] || 0}
                        svg={card.svg}
                        isUsd={card.isUsd}
                        isPercentage={card.isPercentage}
                        loading={loading}
                    />
                </div>
            ))}
        </>
    );
};

export default StatCards;