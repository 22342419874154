import React, {FC} from 'react';
import {KTIcon} from "@/_metronic/helpers";

interface IField {
    key: string;
    value: string;
    iconName: string;
    url?: string;
}

interface Props {
    fields: IField[];
}

const Table: FC<Props> = ({fields}) => {
    return (
        <div className="table-responsive">
            <table className="table align-middle mb-0 fs-6 gy-2">
                <tbody>
                {fields.map((field, index) => {
                    if (!field.value) return null;
                    return (
                        <tr key={index}>
                            <td className="key">
                                <KTIcon className="fs-2 me-2" iconName={field.iconName}/> {field.key}
                            </td>
                            <td className="value">
                                {field.url ? (
                                    <a href={field.url} rel={"noreferrer"} target="_blank">
                                        {field.value}
                                    </a>
                                ) : (
                                    field.value
                                )}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    );
};

export default Table;