import {KTIcon} from '@/_metronic/helpers';
import {Tooltip} from 'react-tooltip';
import {Link} from 'react-router-dom';
import React from 'react';
import {IListCoin, Translator} from '@/app/interfaces';
import CoinName from '@/app/modules/components/CoinName';
import Badge from '@/app/modules/components/Badge';
import {generateRoute} from '@/app/modules/helpers';
import {AdminRouteConstants} from '@/app/constants/routes.constants';

export const trCoin = (coin: IListCoin, _: Translator) => {
    let updateCoinRoute = generateRoute(AdminRouteConstants.UPDATE_COIN_SETTING, {id: coin.id.toString()});

    return (
        <tr key={'coinListCoin' + coin.id}>
            <td>
                <CoinName name={coin.name} symbol={coin.symbol} subSymbol={coin.subSymbol} isTestNetwork={coin.isTestNetwork} />
            </td>
            <td className="text-center">
                {coin.status ? (
                    <Badge text={_('General.ACTIVE')} isLight={true} type={'success'} size={'lg'} />
                ) : (
                    <Badge text={_('General.INACTIVE')} isLight={true} type={'danger'} size={'lg'} />
                )}
            </td>
            <td className="text-center">
                <div className="badge badge-light fw-bold">{coin.minWithdraw}</div>
            </td>
            <td className="text-center">
                <div className="badge badge-light fw-bold">{coin.maxWithdraw}</div>
            </td>
            <td className="text-center">
                <div className="badge badge-light fw-bold">{coin.withdrawFixedFee}</div>
            </td>
            <td className="text-center">
                <div className="badge badge-light fw-bold">{coin.withdrawFeePercentage}%</div>
            </td>
            <td className="text-center">
                <div className="badge badge-light fw-bold">{coin.fixedFee}</div>
            </td>
            <td className="text-center">
                <div className="badge badge-light fw-bold">{coin.feePercentage}%</div>
            </td>
            <td className="text-center flex justify-content-center">
                {coin.isTestNetwork ? (
                    <Badge text={_('General.YES')} isLight={true} type={'success'} size={'sm'} />
                ) : (
                    <Badge text={_('General.NO')} isLight={true} type={'danger'} size={'sm'} />
                )}
            </td>
            <td className="text-center">
                <span>{coin.warningText}</span>
            </td>
            <td className={'text-end'}>
                <div className={'gap-2 d-flex justify-content-end'}>
                    <Tooltip id={'tooltip'} place={'top'} />
                    <Link data-tooltip-id="tooltip" data-tooltip-content={_('General.EDIT')} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" to={updateCoinRoute}>
                        <KTIcon iconName={'pencil'} className={'fs-2'} />
                    </Link>
                </div>
            </td>
        </tr>
    );
};