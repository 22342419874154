import React, {FC, ReactNode, useCallback, useEffect, useState} from 'react';
import clsx from 'clsx';
import {PasswordMeterComponent} from '@/_metronic/assets/ts/components';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {PhoneInput} from 'react-international-phone';
import {CountryIso2} from 'react-international-phone/dist/types';

interface Props {
    label?: string;
    placeholder?: string;
    name: string;
    formik: any;
    passwordMeter?: boolean;
    className?: string;
    isRow?: boolean;
    description?: string | ReactNode;
    checkboxLabel?: string | ReactNode;
    afterComponent?: ReactNode;
    numericKeyboard?: boolean;
    labelSize?: number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

const Input: FC<Props & React.InputHTMLAttributes<HTMLInputElement>> = (props) => {
    const _ = useTranslation();

    let inputClass = props.className ? props.className : 'bg-transparent';
    let {className, afterComponent, checkboxLabel, formik, isRow, labelSize = 4, ...restProps} = props;

    const getBrowserCountryCode = (): CountryIso2 => {
        const userLanguage = navigator.language || navigator.languages[0];
        return userLanguage.slice(-2).toLowerCase() as CountryIso2;
    };


    useEffect(() => {
        if (props.passwordMeter) {
            PasswordMeterComponent.bootstrap();
        }
    }, [props.passwordMeter]);
    const renderInput = () => {
        let input: any;
        let disabled = props.disabled;
        if (props.formik.isSubmitting) {
            disabled = true;
        }

        if (!restProps.onChange) {
            restProps.onChange = props.formik.getFieldProps(props.name).onChange;
        }
        if (!restProps.onBlur) {
            restProps.onBlur = props.formik.getFieldProps(props.name).onBlur;
        }

        switch (props.type) {
            case 'textarea':
                input = <textarea
                    placeholder={props.placeholder ? props.placeholder : props.label}
                    autoComplete="off"
                    {...props.formik.getFieldProps(props.name)}
                    {...restProps}
                    className={clsx(
                        'form-control',
                        {
                            'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name],
                        },
                        {
                            'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name],
                        },
                        inputClass,
                    )}
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    disabled={disabled}
                />;
                break;
            case 'checkbox':
                input = (
                    <div className="form-check form-check-solid form-switch form-check-custom fv-row me-2">
                        <input type="checkbox" checked={props.formik.getFieldProps(props.name).value} id={props.name} {...props.formik.getFieldProps(props.name)} {...restProps} className="form-check-input w-45px h-30px" />
                        <label className="form-check-label" htmlFor={props.name}>{props.checkboxLabel}</label>
                    </div>
                );
                break;
            case 'phoneNumber':
                input = (
                    <PhoneInput
                        defaultCountry={getBrowserCountryCode()}
                        value={formik.values[props.name]}
                        onChange={(phone) => formik.setFieldValue(props.name, phone)}
                        onBlur={formik.handleBlur}
                        inputProps={{
                            name: 'phoneNumber',
                            required: true,
                        }}
                        inputClassName={clsx(
                            'form-control',
                            {
                                'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name],
                            },
                            {
                                'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name],
                            },
                            inputClass,
                        )}
                    />
                );
                break;
            default:
                input = <input
                    placeholder={props.placeholder ? props.placeholder : props.label}
                    autoComplete="off"
                    {...props.formik.getFieldProps(props.name)}
                    {...restProps}
                    inputMode={props.type === 'number' || props.numericKeyboard ? 'decimal' : undefined}
                    pattern={props.type === 'number' || props.numericKeyboard ? '\\d*(\\.\\d+)?' : undefined}
                    className={clsx(
                        'form-control',
                        {
                            'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name],
                        },
                        {
                            'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name],
                        },
                        inputClass,
                    )}
                    value={props.formik.values[props.name] || ''}
                    disabled={disabled}
                />;
                break;
        }
        return (
            <>
                {input}
                {props.description && (
                    <div className="form-text">{props.description}</div>
                )}
                {props.formik.touched[props.name] && props.formik.errors[props.name] && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert">{props.formik.errors[props.name]}</span>
                        </div>
                    </div>
                )}
            </>
        );
    };

    const renderLabel = () => (
        props.label ? <label className="form-label fw-bolder text-dark fs-6">{props.label}</label> : null
    );
    return (
        <div className="fv-row mb-8" data-kt-password-meter={props.passwordMeter ? 'true' : null}>
            {props.passwordMeter ? (
                <>
                    <div className="mb-1">
                        <label className="form-label fw-bolder text-dark fs-6">{props.label}</label>
                        <div className="position-relative mb-3">
                            {renderInput()}
                        </div>
                        <div
                            className="d-flex align-items-center mb-3"
                            data-kt-password-meter-control="highlight"
                        >
                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                            <div className="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                        </div>
                    </div>
                    <div className="text-muted">
                        {_('Input.PASSWORD_ADVICE')}
                    </div>
                </>
            ) : (
                <>
                    {props.isRow ? (
                        <div className="row">
                            <div className={`col-lg-${labelSize} col-lg-${labelSize} col-form-label`}>{renderLabel()}</div>
                            <div className={clsx(`col-lg-${12 - labelSize}`, {
                                'd-flex align-items-center': props.type === 'checkbox' || props.afterComponent,
                            })}>
                                {renderInput()}
                                {props.afterComponent}
                            </div>
                        </div>
                    ) : (
                        <>
                            {renderLabel()}
                            {renderInput()}
                        </>
                    )}

                </>
            )}
        </div>
    );
};

export default Input;