import React, {FC, ReactElement} from 'react';
import Buttons from '@/app/modules/components/Buttons';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    children: ReactElement;
    onReset?: () => void;
    onFilter?: () => void;
}

const Filter: FC<Props> = (props) => {
    const _ = useTranslation();

    return (
        <>
            {props.children}
            {/*{rows}*/}
            <div className="card-footer ">
                <Buttons>
                    {props.onReset && (

                        <button onClick={props.onReset} className="btn btn-sm btn-light btn-active-light-primary">{_('Filter.RESET')}</button>

                    )}

                    <button onClick={props.onFilter} className="btn btn-sm btn-primary  px-6">{_('Filter.FILTER')}</button>
                </Buttons>

            </div>
        </>
    );
};

export default Filter;