import React, {FC} from 'react';
import Label from '@/app/modules/components/filterInputs/Label';


interface Props {
    name: string;
    type?: 'text' | 'number' | 'datetime-local';
    value: string;
    label?: string;
    placeholder?: string;
    onChange: (name: string, value: string) => void;
    submitOnEnterFunc?: () => void;
}

const Input: FC<Props> = ({placeholder, label, name, value, onChange, type = 'text', submitOnEnterFunc}) => {
    return (
        <>
            {label && <Label label={label} id={`id_${name}`} />}
            <input
                id={`id_${name}`}
                value={value}
                onChange={(e) => onChange(name, e.target.value)}
                type={type}
                pattern={type === 'number' ? '\\d*(\\.\\d+)?' : undefined}
                inputMode={type === 'number' ? 'decimal' : undefined}
                className="form-control form-control-solid"
                placeholder={placeholder}
                onKeyDown={(e) => {
                    if (e.key === 'Enter' && submitOnEnterFunc) {
                        submitOnEnterFunc();
                    }
                }}
            />
        </>
    );
};

export default Input;