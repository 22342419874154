import React, {FC} from 'react';
import {Modal} from 'react-bootstrap';
import {KTIcon, toAbsoluteUrl} from '@/_metronic/helpers';
import {useFormik} from 'formik';
import {submitPhoneEnterCodeSchema} from '@/app/utils/yupSchema';
import Input from '@/app/modules/auth/components/tools/inputs/Input';
import Submit from '@/app/modules/auth/components/tools/buttons/Submit';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import {convertStringToInteger, convertStringToNumber} from '@/app/modules/helpers';

interface Props {
    validationId: string;
    onEntered: (validationId: string, code: string) => Promise<void>;
    onSuccess: (response: any) => void;
    handleClose: () => void;
    show: boolean;
}


const SubmitPhone: FC<Props> = ({validationId, onSuccess, onEntered, show, handleClose}) => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);

    const [loading, setLoading] = React.useState<boolean>(false);

    const formik = useFormik({
        initialValues: {code: ''},
        validationSchema: submitPhoneEnterCodeSchema(_),
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true);
            setStatus(undefined);
            try {
                await onEntered(validationId, values.code.toString());
            } catch (error: any) {
                setStatus(getErrorMessage(error));
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    return (
        <Modal
            id="kt_modal_submit_phone"
            tabIndex={-1}
            aria-hidden="true"
            dialogClassName="modal-dialog modal-dialog-centered mw-500px"
            show={show}
            onHide={handleClose}
            backdrop={'static'}
        >
            <div className="modal-header">
                <h2>{_('SubmitPhone.VERIFICATION')}</h2>
                <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
                    <KTIcon className="fs-1" iconName="cross" />
                </div>
            </div>
            <div className="modal-body scroll-y pt-10 pb-15 px-lg-17">
                <div className="text-center">
                    <img className={'mw-75px mb-5'} src={toAbsoluteUrl('/media/svg/custom/conversation.svg')}
                         alt="" />
                    <h3 className="text-dark fw-bold fs-3 mb-5">
                        {_('SubmitPhone.VERIFY_PHONE')}
                    </h3>
                    <div className="text-muted fw-semibold mb-10">
                        {_('SubmitPhone.INSTRUCTION')}<br />
                        <small className={'text-primary'}>{_('SubmitPhone.SENT_CODE', {code: '123456'})}</small>
                    </div>
                    <form className="form fv-plugins-bootstrap5 fv-plugins-framework" onSubmit={formik.handleSubmit}
                          noValidate
                    >
                        {formik.status && (
                            <div className="text-danger mb-2 font-weight-bold">{formik.status}</div>
                        )}
                        <div className="fv-row fv-plugins-icon-container d-flex align-items-center flex-column">
                            <Input
                                name={'code'}
                                type="text"
                                formik={formik}
                                className={'form-control-lg form-control-solid mw-200px text-center fw-bold'}
                                placeholder={_('SubmitPhone.CODE_PLACEHOLDER')}
                                maxLength={6}
                                minLength={6}
                                onChange={(e) => formik.setFieldValue('code', convertStringToInteger(e.target.value))}
                            />
                        </div>
                        <div className="d-flex flex-center">
                            <Submit disabled={formik.isSubmitting || !formik.isValid} loading={loading} label={_('SubmitPhone.VERIFY')} />
                        </div>
                    </form>

                </div>

            </div>
        </Modal>
    );
};

export default SubmitPhone;