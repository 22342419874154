import {MerchantRouteConstants} from '@/app/constants/routes.constants';
// import DepositHistory from "@/app/pages/merchant/wallet/History/DepositHistory";
import WithdrawalHistory from '@/app/pages/merchant/wallet/History/WithdrawalHistory';
// import Deposit from "@/app/pages/merchant/wallet/Deposit/Deposit";
import TransactionHistory from '@/app/pages/merchant/wallet/TransactionHistory/TransactionHistory';
import ApiKeys from '@/app/pages/merchant/apiKeys/ApiKeys/ApiKeys';
import Update from '@/app/pages/merchant/apiKeys/Update/Update';
import ViewLog from '@/app/pages/merchant/apiKeys/ViewLog/ViewLog';
import MerchantSettings from '@/app/pages/merchant/merchant/MerchantSettings/MerchantSettings';
import CoinAcceptance from '@/app/pages/merchant/merchant/CoinAcceptance/CoinAcceptance';
import IpnHistory from '@/app/pages/merchant/merchant/IpnHistory/IpnHistory';
import AccountSettings from '@/app/pages/merchant/account/AccountSettings';
import CreateOrder from '@/app/pages/merchant/merchant/CreateOrder/CreateOrder';
import Withdraw from '@/app/pages/merchant/wallet/Withdraw/Withdraw';
import MyWallet from '@/app/pages/merchant/wallet/MyWallet/MyWallet';
import Dashboard from '@/app/pages/merchant/dashboard/Dashboard';
import TransactionDetail from '@/app/pages/merchant/wallet/TransactionHistory/TransactionDetail/TransactionDetail';
import Employees from '@/app/pages/merchant/employees/Employees';
import EmployeeDetail from '@/app/pages/merchant/employees/employeeDetail/EmployeeDetail';
import MerchantReport from '@/app/pages/merchant/report/Report';

export const MerchantRoutes = [
    {
        path: MerchantRouteConstants.DASHBOARD,
        element: <Dashboard />,
    },
    {
        path: MerchantRouteConstants.ACCOUNT_SETTINGS,
        element: <AccountSettings />,
    },
    {
        path: MerchantRouteConstants.MY_WALLET_OVERVIEW,
        element: <MyWallet />,
    },
    // {
    //     path: MerchantRouteConstants.DEPOSIT_HISTORY,
    //     element: <DepositHistory/>
    // },
    {
        path: MerchantRouteConstants.WITHDRAWAL_HISTORY,
        element: <WithdrawalHistory />,
    },
    // {
    //     path: MerchantRouteConstants.DEPOSIT,
    //     element: <Deposit/>
    // },
    {
        path: MerchantRouteConstants.WITHDRAW,
        element: <Withdraw />,
    },
    {
        path: MerchantRouteConstants.TRANSACTION_HISTORY,
        element: <TransactionHistory />,
    },
    {
        path: MerchantRouteConstants.API_KEYS,
        element: <ApiKeys />,
    },
    {
        path: MerchantRouteConstants.UPDATE_API_KEY,
        element: <Update />,
    },
    {
        path: MerchantRouteConstants.VIEW_API_KEY_LOG,
        element: <ViewLog />,
    },
    {
        path: MerchantRouteConstants.MERCHANT_SETTINGS,
        element: <MerchantSettings />,
    },
    {
        path: MerchantRouteConstants.MERCHANT_COIN_ACCEPTANCE_SETTINGS,
        element: <CoinAcceptance />,
    },
    {
        path: MerchantRouteConstants.MERCHANT_IPN_HISTORY,
        element: <IpnHistory />,
    },
    {
        path: MerchantRouteConstants.CREATE_MANUEL_ORDER,
        element: <CreateOrder />,
    },
    {
        path: MerchantRouteConstants.TRANSACTION_DETAIL,
        element: <TransactionDetail />,
    },
    {
        path: MerchantRouteConstants.EMPLOYEES,
        element: <Employees />,
    },
    {
        path: MerchantRouteConstants.EMPLOYEE_DETAIL,
        element: <EmployeeDetail />,
    },
    {
        path: MerchantRouteConstants.REPORTS,
        element: <MerchantReport />,
    },


];

