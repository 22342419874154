import React, {FC} from 'react';

interface Props {
    status: string;
    marginBottom?: number;
}

const FormikStatus: FC<Props> = (props) => {
    const mb = props.marginBottom ? props.marginBottom : 15;
    return (
        <>
            {props.status && (
                <div className={`mb-lg-${mb} alert alert-danger`}>
                    <div className='alert-text font-weight-bold'>{props.status}</div>
                </div>
            )}
        </>
    );
};

export default FormikStatus;