import React, {FC, useCallback} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import clsx from 'clsx';
import {KTIcon} from '@/_metronic/helpers';
import {useDevice} from '@/app/modules/providers/DeviceProvider';
import {IUser} from '@/app/interfaces';
import Card from '@/app/modules/components/card/Card';
import {toast} from 'react-toastify';
import useDatatable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {IEmployeeListFilters} from '@/app/interfaces/filters';
import {initialEmployeeListSort} from '@/app/utils/initialSorts';
import {initialEmployeeListFilters} from '@/app/utils/initialFilters';
import {ICreateUpdateEmployeeValues} from '@/app/interfaces/values';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useLang} from '@/_metronic/i18n/Metronici18n';
import {createEmployee, getEmployees} from '@/app/modules/auth/core/_requests';
import CreateUpdateEmployeeModal from '@/app/pages/merchant/employees/CreateUpdateEmployeeModal';
import EmployeeFilters from '@/app/pages/merchant/employees/EmployeeFilters';
import {EmployeesTableHead} from '@/app/utils/tableHeads/merchant/EmployeesTableHead';
import {employeeTr} from '@/app/pages/merchant/employees/employeeTr';

interface Props {
    id?: string;
}

const Employees: FC<Props> = () => {
    const _ = useTranslation();
    const [showCreateEmployeeModal, setShowCreateEmployeeModal] = React.useState<boolean>(false);
    const {selectedLang} = useLang();

    const getData: GetDataFunction<IUser, IEmployeeListFilters> = useCallback(async ({sort, pageIndex, pageSize, filters}) => {
        const params = {
            sort,
            pageIndex,
            pageSize,
            orFilters: filters.searchQuery
                ? {
                    'fullName': filters.searchQuery,
                    'email': filters.searchQuery,
                }
                : undefined,
        };
        const {data} = await getEmployees(params);
        if (!data) return Promise.reject();
        return data;
    }, []);
    const {
        handleFilterSubmit,
        handleFilterReset,
        handleFilterChange,
        filters,
        DataTableComponent,
        getDataCallback,
    } = useDatatable<IUser, IEmployeeListFilters>({
        initialSort: initialEmployeeListSort,
        initialFilters: initialEmployeeListFilters,
        getData,
        id: 'employees',
    });

    const handleCreateEmployee = async (user: ICreateUpdateEmployeeValues) => {
        const createReq = await createEmployee({
            email: user.email,
            fullName: user.fullName,
            phoneNumber: user.phoneNumber,
            password: user.password,
        });
        if (!createReq.data) return Promise.reject();
        setShowCreateEmployeeModal(false);
        toast.success(_('Users.USER_CREATED'));
        await getDataCallback();
    };

    const {isMobile} = useDevice();
    return (
        <>
            <CreateUpdateEmployeeModal
                show={showCreateEmployeeModal}
                handleClose={() => setShowCreateEmployeeModal(false)}
                handleSubmit={handleCreateEmployee}
            />
            <ToolbarWrapper title={_('Users.TITLE')}>
                <div className={'d-flex align-items-center gap-3 gap-lg-3 toolsButtons'}>
                    <button onClick={() => setShowCreateEmployeeModal(true)} className={clsx('btn btn-outline btn-outline-primary', {
                        'btn-sm': !isMobile,
                    })}>
                        <KTIcon iconName={'plus'} className={'fs-2 me-1'} />
                        {_('Users.ADD_USER')}
                    </button>
                </div>
            </ToolbarWrapper>

            <Card>
                <EmployeeFilters handleFilterSubmit={handleFilterSubmit} handleFilterReset={handleFilterReset} handleFilterChange={handleFilterChange} filters={filters} />
                <DataTableComponent head={EmployeesTableHead} renderRow={(data, index) => employeeTr(data, index, _, selectedLang)} />
            </Card>
        </>
    );
};

export default Employees;