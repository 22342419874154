import {AdminRouteConstants, MerchantRouteConstants} from '@/app/constants/routes.constants';
import Dashboard from '@/app/pages/admin/dashboard/Dashboard';
import AccountSettings from '@/app/pages/merchant/account/AccountSettings';
import MerchantList from '@/app/pages/admin/merchantList/MerchantList';
import MerchantDetail from '@/app/pages/admin/merchantDetail/MerchantDetail';
import Users from '@/app/pages/admin/users/Users';
import UserDetail from '@/app/pages/admin/users/userDetail/UserDetail';
import TransactionList from '@/app/pages/admin/transaction/transactionList/TransactionList';
import Settings from '@/app/pages/admin/settings/Settings';
import CoinList from '@/app/pages/admin/coinSettings/CoinList';
import UpdateCoin from '@/app/pages/admin/coinSettings/updateCoin/UpdateCoin';
import BlockchainTransactionList from '@/app/pages/admin/blockchain-transaction/blockchainTransactionList/BlockchainTransactionList';
import WithdrawalList from '@/app/pages/admin/withdrawal/withdrawalList/WithdrawalList';
import WalletList from '@/app/pages/admin/walletList/WalletList';
import Withdraw from '@/app/pages/admin/withdrawal/withdraw/Withdraw';
import TransactionDetail from '@/app/pages/admin/transaction/transactionDetail/TransactionDetail';
import AdminReport from '@/app/pages/admin/report/Report';


export const AdminRoutes = [
    {
        path: AdminRouteConstants.DASHBOARD,
        element: <Dashboard />,
    },
    {
        path: MerchantRouteConstants.ACCOUNT_SETTINGS,
        element: <AccountSettings />,
    },
    {
        path: AdminRouteConstants.MERCHANTS,
        element: <MerchantList />,
    },
    {
        path: AdminRouteConstants.MERCHANT_DETAIL + '/*',
        element: <MerchantDetail />,
    },
    {
        path: AdminRouteConstants.USERS,
        element: <Users />,
    },
    {
        path: AdminRouteConstants.USER_DETAIL,
        element: <UserDetail />,
    },
    {
        path: AdminRouteConstants.TRANSACTIONS,
        element: <TransactionList />,
    },
    {
        path: AdminRouteConstants.TRANSACTION_DETAIL,
        element: <TransactionDetail />,
    },
    {
        path: AdminRouteConstants.SETTINGS,
        element: <Settings />,
    },
    {
        path: AdminRouteConstants.COIN_SETTINGS,
        element: <CoinList />,
    },
    {
        path: AdminRouteConstants.UPDATE_COIN_SETTING,
        element: <UpdateCoin />,
    },
    {
        path: AdminRouteConstants.BLOCKCHAIN_TRANSACTIONS,
        element: <BlockchainTransactionList />,
    },
    {
        path: AdminRouteConstants.WITHDRAWALS,
        element: <WithdrawalList />,
    },
    {
        path: AdminRouteConstants.WALLETS,
        element: <WalletList />,
    },
    {
        path: AdminRouteConstants.WITHDRAW + '/*',
        element: <Withdraw />,
    },
    {
        path: AdminRouteConstants.REPORTS,
        element: <AdminReport />,
    },
];