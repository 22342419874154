import {type FC} from 'react';
import Withdraw from '@/app/pages/common/Withdraw/Withdraw';
import {useNavigate, useParams} from 'react-router-dom';
import {AdminRouteConstants} from '@/app/constants/routes.constants';
import {useAuth} from '@/app/modules/auth';
import {USER_TYPE} from '@/app/enums';

interface Props {

}

const AdminWithdraw: FC<Props> = (props) => {
    const {coin: coinId} = useParams();
    const navigate = useNavigate();
    const {currentUser} = useAuth();

    if (!coinId) {
        navigate(AdminRouteConstants.WALLETS);
        return null;
    }

    if (currentUser?.userType !== USER_TYPE.SUPER_ADMIN) {
        navigate(AdminRouteConstants.WALLETS);
        // return null;
    }

    return <Withdraw coinId={coinId} isAdminWithdraw />;
};

export default AdminWithdraw;