import React, {type FC, useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import {useLoading} from '@/app/providers/LoadingProvider';
import {useFormik} from 'formik';
import {getAdminConfig, updateAdminConfig} from '@/app/utils/requests/adminRequests';
import {toast} from 'react-toastify';
import Input from '@/app/modules/auth/components/tools/inputs/Input';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import FormCard from '@/app/modules/components/form-card/FormCard';
import Buttons from '@/app/modules/components/Buttons';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import Submit from '@/app/modules/auth/components/tools/buttons/Submit';
import * as Yup from 'yup';
import {requiredString} from '@/app/utils/yupSchema/fields';
import {IAdminConfigResponse} from '@/app/interfaces';

const Settings: FC = () => {
    const id = undefined;
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const {isLoading, setIsLoading} = useLoading();
    const [settings, setSettings] = useState<IAdminConfigResponse[]>([]);
    const [schema, setSchema] = useState(Yup.object());

    const formik = useFormik({
        initialValues: {},
        validationSchema: schema,
        onSubmit: async (values, {setSubmitting}) => {
            try {
                setSubmitting(true);
                setIsLoading(true);
                const payload = Object.keys(values).map((key) => {
                    return {configId: parseInt(key), value: values[key] as string};
                });
                const {status} = await updateAdminConfig(payload);
                if (status === 200) {
                    toast.success(_('MerchantSettings.SETTINGS_UPDATED_SUCCESSFULLY'));
                }
            } catch (error: any) {
                toast.error(getErrorMessage(error));
            } finally {
                setSubmitting(false);
                setIsLoading(false);
            }
        },
    });

    const flag = useRef(true);
    useEffect(() => {
        if (flag.current) {
            flag.current = false;
            setIsLoading(true);

            getAdminConfig().then(({data}) => {
                setSettings(data);
                let schemaObject = {};
                data.forEach((item: any) => {
                    schemaObject[item.id] = requiredString(_, {min: 1, max: 9999});
                });
                setSchema(Yup.object().shape(schemaObject));
                const values = {};
                data.forEach((item: any) => {
                    values[item.id] = item.value;
                });
                formik.setValues(values);
            }).catch((err) => {
                getErrorMessage(err);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [flag, setIsLoading, formik, id, getErrorMessage, _]);

    const inputComponent = useCallback((props: {name: string, label: string}) => {
        return <Input
            className="form-control-lg form-control-solid"
            name={props.name}
            type="text"
            formik={formik}
            labelSize={3}
            label={_(props.label)}
            placeholder=""
            isRow />;
    }, [_, formik]);

    return (
        <>
            <ToolbarWrapper title={_('AdminSettings.TITLE')} />

            <form onSubmit={formik.handleSubmit} className="form" noValidate>
                <div className={'d-flex flex-column gap-7 gap-lg-10 merchantSettings'}>
                    <FormCard title={_('AdminSettings.CARD_TITLE')}>
                        {settings.map((item) => {
                            return <React.Fragment key={item.key}>
                                {inputComponent({name: item.id.toString(), label: `AdminSettings.${item.key}`})}
                            </React.Fragment>;
                        })}
                    </FormCard>

                    <Buttons>
                        <a href={MerchantRouteConstants.DASHBOARD} className="btn btn-light">
                            {_('General.CANCEL')}
                        </a>
                        <Submit disabled={formik.isSubmitting || !formik.isValid} loading={isLoading} label={_('General.SAVE_CHANGES')} />
                    </Buttons>
                </div>
            </form>
        </>
    );
};

export default Settings;