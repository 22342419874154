import React, {FC, useEffect, useMemo, useState} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {AdminRouteConstants, MerchantRouteConstants} from '@/app/constants/routes.constants';
import Input from '@/app/pages/common/Withdraw/Input';
import FakeInput from '@/app/pages/common/Withdraw/FakeInput';
import SubmitPhone from '@/app/modules/auth/components/tools/modals/SubmitPhone';
import Completed from '@/app/pages/common/Withdraw/Completed';
import Card from '@/app/modules/components/card/Card';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {FormattedMessage} from 'react-intl';
import {useLoading} from '@/app/providers/LoadingProvider';
import {checkWithdrawable, confirmWithdrawRequest, startWithdrawRequest} from '@/app/modules/auth/core/_requests';
import {useNavigate} from 'react-router-dom';
import {useFormik} from 'formik';
import {IWithdrawValues} from '@/app/interfaces/values';
import {withdrawalInitialValues} from '@/app/utils/initialValues';
import {withdrawalSchema} from '@/app/utils/yupSchema';
import {toast} from 'react-toastify';
import {getCoinSymbol, getCoinUnit} from '@/app/modules/helpers/getCoinSymbol';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import {convertStringTo8precision} from '@/app/modules/helpers';
import Submit from '@/app/modules/auth/components/tools/buttons/Submit';
import ConfirmModal from '@/app/pages/common/Withdraw/ConfirmModal';
import {IWithdrawalCoin} from '@/app/interfaces';
import {checkWithdrawableFromAdmin, confirmWithdrawRequestFromAdmin, startWithdrawRequestFromAdmin} from '@/app/utils/requests/adminRequests';

interface Props {
    coinId: string;
    isAdminWithdraw?: boolean;
}

const Withdraw: FC<Props> = ({coinId, isAdminWithdraw = false}) => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const {isLoading, setIsLoading} = useLoading();
    const navigate = useNavigate();

    const [totalWithdrawAmount, setTotalWithdrawAmount] = useState(0);
    const [totalFee, setTotalFee] = useState(0);
    const [showConfirmModal, setShowConfirmModal] = useState<boolean>(false);
    const [show2faModal, setShow2faModal] = useState<boolean>(false);
    const [completed, setCompleted] = useState<boolean>(false);
    const [coin, setCoin] = useState<IWithdrawalCoin | null>(null);
    const [walletBalance, setWalletBalance] = useState<string>('');
    const [validationId, setValidationId] = useState<string>('');

    useEffect(() => {
        setIsLoading(true);
        const checkFunc = isAdminWithdraw ? () => checkWithdrawableFromAdmin(coinId) : () => checkWithdrawable(coinId);

        checkFunc().then(({data: info}) => {
            setCoin({
                name: info.coinName,
                symbol: info.coinSymbol,
                subSymbol: info.coinSubSymbol,
                isTestNetwork: info.isTestNetwork,
                minWithdrawalAmount: info.minWithdrawalAmount,
                withdrawFixedFee: info.withdrawFixedFee,
                withdrawFeePercentage: info.withdrawFeePercentage,
            });
            setWalletBalance(info.balance);
        }).catch((e) => {
            toast.error(getErrorMessage(e));
            navigate(isAdminWithdraw ? AdminRouteConstants.WALLETS : MerchantRouteConstants.MY_WALLET);
            return;
        }).finally(() => {
            setIsLoading(false);
        });
    }, [coinId, getErrorMessage, isAdminWithdraw, navigate, setIsLoading]);

    const formik = useFormik<IWithdrawValues>({
        initialValues: withdrawalInitialValues,
        validationSchema: withdrawalSchema(_),
        onSubmit: async (values, {setSubmitting}) => {
            try {
                setShowConfirmModal(true);
            } catch (error: any) {
                toast.error(getErrorMessage(error));
            }
        },
    });

    useEffect(() => {
        if (!coin) return;
        const amount = parseFloat(formik.values.amount) || 0;
        const fee = parseFloat(coin.withdrawFixedFee) + (amount * parseFloat(coin.withdrawFeePercentage) / 100);
        setTotalFee(fee);
        setTotalWithdrawAmount(fee + amount);
    }, [coin, formik.values.amount]);

    const onConfirm = async () => {
        try {
            const params = {
                coinId: parseInt(coinId),
                amount: parseFloat(formik.values.amount),
                address: formik.values.address,
            };
            const startWithdrawFunc = isAdminWithdraw ? () => startWithdrawRequestFromAdmin(params) : () => startWithdrawRequest(params);

            const {data} = await startWithdrawFunc();
            setValidationId(data.validationId);
            setShowConfirmModal(false);
            setShow2faModal(true);
        } catch (e) {
            formik.setSubmitting(false);
            toast.error(getErrorMessage(e));
            setShowConfirmModal(false);
        }
    };

    const on2FAConfirm = async (validationId: string, code: string) => {
        try {
            const confirmWithdrawFunc = isAdminWithdraw ? () => confirmWithdrawRequestFromAdmin({validationId, code}) : () => confirmWithdrawRequest({validationId, code});
            await confirmWithdrawFunc();
            toast.success(_('Withdraw.WITHDRAW_SUCCESS'));
            setCompleted(true);
            setShow2faModal(false);
        } catch (e) {
            toast.error(getErrorMessage(e));
            throw e;
        }
    };

    const setAmountToBalance = () => {
        if (!coin) return;


        const balance = parseFloat(walletBalance);
        const fixedFee = parseFloat(coin.withdrawFixedFee);
        const feePercentage = parseFloat(coin.withdrawFeePercentage) / 100;

        const amount = (balance - fixedFee) / (1 + feePercentage);
        const fee = fixedFee + (amount * feePercentage);

        setTotalFee(fee);
        formik.setFieldValue('amount', convertStringTo8precision(amount.toString()));
    };

    const complete = async () => {
        setShow2faModal(false);
        setCompleted(true);
    };

    let footer = <></>;
    if (!completed) {
        footer = (
            <div className="card-footer d-flex justify-content-center py-6 px-9">
                <div className={'w-700px mw-100 text-end'}>
                    <Submit disabled={formik.isSubmitting || !formik.isValid} loading={isLoading} label={_('Withdraw.CONFIRM_SENDING')} />
                </div>
            </div>
        );
    }

    const title = useMemo(() => {
        if (!coin) return '';
        return _('Withdraw.TITLE', {code: getCoinSymbol(coin)});
    }, [_, coin]);


    return (
        <>
            <SubmitPhone validationId={validationId} onEntered={on2FAConfirm} handleClose={() => setShow2faModal(false)} show={show2faModal} onSuccess={complete} />
            <ConfirmModal show={showConfirmModal} handleClose={() => setShowConfirmModal(false)} onConfirm={onConfirm} withdrawDetails={{
                address: formik.values.address,
                amount: formik.values.amount,
                fee: coin?.withdrawFixedFee,
                total: totalWithdrawAmount,
            }} />
            <ToolbarWrapper title={title} additionalBreadcrumb={[
                {
                    title: _(isAdminWithdraw ? 'WalletList.TITLE' : 'MyWallet.TITLE'),
                    path: isAdminWithdraw ? AdminRouteConstants.WALLETS : MerchantRouteConstants.MY_WALLET,
                    isActive: false,
                },
            ]} />
            <form onSubmit={formik.handleSubmit} className="form" noValidate>
                <Card className={'myWallet'} footer={footer} title={_('Withdraw.WITHDRAW')} subTitle={
                    <FormattedMessage
                        id={'Withdraw.SUBTITLE'}
                        values={{
                            unitCode: coin ? getCoinUnit(coin) : '',
                            strong: chunks => <strong>{chunks}</strong>,
                        }}
                    />
                }>
                    <div className="withdraw">
                        {completed ? <Completed isAdminWithdraw={isAdminWithdraw} /> :
                            <>
                                <FakeInput
                                    coin={coin}
                                    value={coin?.minWithdrawalAmount}
                                    label={_('Withdraw.MINIMUM_WITHDRAWAL_LIMIT')}
                                />
                                <FakeInput
                                    onClick={setAmountToBalance}
                                    coin={coin}
                                    value={walletBalance}
                                    label={_('Withdraw.CURRENT_BALANCE')}
                                />
                                <Input
                                    name={'amount'}
                                    formik={formik}
                                    coin={coin}
                                    type={'text'}
                                    label={_('Withdraw.WITHDRAWAL_AMOUNT')}
                                    onChange={(e: any) => formik.setFieldValue('amount', e.target.value)}
                                    onBlur={(e: any) => formik.setFieldValue('amount', convertStringTo8precision(e.target.value))}
                                />
                                <FakeInput
                                    coin={coin}
                                    value={'+' + totalFee}
                                    label={_('Withdraw.TRANSACTION_FEE')}
                                />
                                <FakeInput
                                    coin={coin}
                                    value={totalWithdrawAmount.toFixed(8)}
                                    label={_('Withdraw.TOTAL_FEE')}
                                />
                                <Input
                                    name={'address'}
                                    formik={formik}
                                    onChange={(e) => formik.setFieldValue('address', e.target.value)}
                                    label={_('Withdraw.RECIPIENT_ADDRESS')}
                                />

                                <div>
                                    <span className={'text-muted fw-semibold text-center d-block fs-6'}>
                                        {_('Withdraw.NOTE')}
                                    </span>
                                </div>
                            </>
                        }
                    </div>
                </Card>
            </form>
        </>
    );
};

export default Withdraw;