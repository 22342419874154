import React, {type FC, useEffect, useState} from 'react';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import {useDevice} from '@/app/modules/providers/DeviceProvider';
import {IBalance} from '@/app/interfaces';
import {getBalancesFromAdmin} from '@/app/utils/requests/adminRequests';
import {getBalances} from '@/app/modules/auth/core/_requests';
import {toast} from 'react-toastify';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {Link} from 'react-router-dom';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import clsx from 'clsx';
import {KTIcon} from '@/_metronic/helpers';
import {WalletList} from '@/app/pages/common/WalletList/WalletList';

interface Props {
    id?: string;
}

const MyWallet: FC<Props> = ({id}) => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const {isMobile} = useDevice();
    const [balances, setBalances] = useState<IBalance[]>([]);
    const [totalBalance, setTotalBalance] = useState<string>('');

    useEffect(() => {
        const fetchBalances = id ? () => getBalancesFromAdmin(id) : getBalances;
        fetchBalances().then((res) => {
            const balances = res.data['wallets'];
            setBalances(balances);
            setTotalBalance(res.data['totalUsdValue']);

        }).catch((e) => {
            toast.error(getErrorMessage(e));
        });
    }, [getErrorMessage, id]);

    return (
        <>
            <ToolbarWrapper title={_('MyWallet.TITLE')} onlyTitle={!!id} isMerchantSubMenu={!!id}>
                <div className={'d-flex align-items-center gap-3 gap-lg-3 toolsButtons'}>
                    <Link to={MerchantRouteConstants.WITHDRAWAL_HISTORY} className={clsx('btn btn-outline btn-outline-primary', {
                        'btn-sm': !isMobile,
                    })}>
                        <KTIcon iconName={'send'} className={'fs-2 me-1'} />
                        {_('MyWallet.WITHDRAWAL_HISTORY')}
                    </Link>
                </div>
            </ToolbarWrapper>
            <WalletList title={_('MyWallet.TITLE')} subTitle={_('MyWallet.SUBTITLE')} balances={balances} id={id} totalBalance={totalBalance} />

        </>
    );
};

export default MyWallet;