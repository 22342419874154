import {toAbsoluteUrl} from '@/_metronic/helpers';

export const getCoinSvg = (symbol: string) => {
    if (symbol.toLowerCase().indexOf('-test') > -1) {
        symbol = symbol.toLowerCase().replace('-test', '');
    }
    return symbol.toLowerCase();
};

export const getCoinSvgUrl = (symbol: string) => {
    return toAbsoluteUrl(`/custom/coins/${getCoinSvg(symbol)}.svg`);
};