/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom';
import PaymentsLayout from '@/app/pages/payment/PaymentsLayout';
// import Basket from "@/app/pages/payment/basket/Basket";
// import Pay from "@/app/pages/payment/pay/Pay";
import Payment from '@/app/pages/payment/Payment';

const PaymentsPage = () => (
    <Routes>
        <Route element={<PaymentsLayout />}>
            {/*<Route path='basket' element={<Basket/>}/>*/}
            {/*<Route path='pay' element={<Pay/>}/>*/}
            <Route path=":id" element={<Payment />} />
        </Route>
    </Routes>
);

export {PaymentsPage};
