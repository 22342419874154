import {IDataTableHead} from '@/app/interfaces';

export const LoginLogsTableHead: IDataTableHead[] = [
    {
        text: 'LoginLogsTH.DEVICE',
        name: 'device',
        isSortable: false,
        align: 'left',
        width: '200px',
    },
    {
        text: 'LoginLogsTH.IP_ADDRESS',
        name: 'ip',
        isSortable: false,
        align: 'left',
        width: '200px',
    },
    {
        text: 'LoginLogsTH.LOGIN_STATUS',
        name: 'isSuccess',
        isSortable: false,
        align: 'center',
        width: '70px',
    },
    {
        text: 'LoginLogsTH.LOGIN_DATE',
        name: 'createdAt',
        isSortable: true,
        align: 'left',
        width: '150px',
    },
];