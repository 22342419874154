// helpers.ts veya uygun bir dosya
import {IActionItem} from '@/app/interfaces';
import {AdminMerchantDetailSubRouteConstants, AdminRouteConstants, MerchantRouteConstants} from '@/app/constants/routes.constants';
import {generateRoute} from '@/app/modules/helpers';


export const generateActionItemsForCoin = (id: number, merchantID?: string): IActionItem[] => {
    let idString = id.toString();
    if (merchantID) return [
        {
            type: 'link',
            label: 'CoinActionList.WITHDRAWAL_HISTORY',
            route: `${generateRoute(AdminRouteConstants.MERCHANT_DETAIL, {id: merchantID})}/${AdminMerchantDetailSubRouteConstants.WITHDRAWALS}?coin=${idString}`,
            ktIconName: 'send',
        },
    ];
    return [
        // {
        //     type: 'link',
        //     label: 'CoinActionList.DEPOSIT',
        //     route: generateRoute(MerchantRouteConstants.DEPOSIT, {coin: idString}),
        //     icon: 'bi bi-clipboard-plus',
        // },
        {
            type: 'link',
            label: 'CoinActionList.SEND',
            route: generateRoute(MerchantRouteConstants.WITHDRAW, {coin: idString}),
            icon: 'bi bi-send-plus',
        },
        // {
        //     type: 'separator',
        // },
        // {
        //     type: 'link',
        //     label: 'CoinActionList.DEPOSIT_HISTORY',
        //     route: `${MerchantRouteConstants.DEPOSIT_HISTORY}?coin=${idString}`,
        //     ktIconName: 'save-deposit',
        // },
        {
            type: 'link',
            label: 'CoinActionList.WITHDRAWAL_HISTORY',
            route: `${MerchantRouteConstants.WITHDRAWAL_HISTORY}?coin=${idString}`,
            ktIconName: 'send',
        },
    ];
};

export const generateActionItemsForCoinAdmin = (id: number): IActionItem[] => {
    let idString = id.toString();
    return [
        {
            type: 'link',
            label: 'CoinActionList.SEND',
            route: generateRoute(AdminRouteConstants.WITHDRAW, {coin: idString}),
            ktIconName: 'send',
        },
    ];

};
