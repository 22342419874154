import React, {FC, useMemo} from 'react';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import clsx from 'clsx';
import {getCoinUnit} from '@/app/modules/helpers/getCoinSymbol';
import {IWithdrawalCoin} from '@/app/interfaces';

interface Props {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    label: string;
    coin?: IWithdrawalCoin | null;
    onBlur?: any;
    formik?: any;
    type?: string;
    name: string;
}

const Input: FC<Props> = ({name, type = 'text', label, onChange, coin, onBlur, formik}) => {
    const unitCode = useMemo(() => {
        if (!coin) return '';
        return getCoinUnit(coin);
    }, [coin]);

    const coinLogo = useMemo(() => {
        if (!coin) return '';
        return toAbsoluteUrl(`/custom/coins/${coin.symbol.toLowerCase()}.svg`);
    }, [coin]);

    let disabled = false;
    if (formik.isSubmitting) {
        disabled = true;
    }

    return (
        <div className="row mb-6">
            <label className="col-lg-4 col-form-label fw-semibold fs-6 required">{label}:</label>

            <div className="col-lg-8 fv-row fv-plugins-icon-container">
                <div className={clsx('customInput', {
                    'coinInput': Boolean(coin),
                    'testNetworkCode': coin?.isTestNetwork,
                    'code4': unitCode.length === 4,
                })}>
                    <input
                        autoComplete="off"
                        placeholder={label}

                        {...formik.getFieldProps(name)}
                        inputMode={type === 'number' ? 'decimal' : undefined}
                        pattern={type === 'number' ? '\\d*(\\.\\d+)?' : undefined}
                        // onBlur={onBlur}
                        type={type}
                        name={name}
                        className={clsx(
                            'form-control form-control-solid text-end customInput',
                            {
                                'is-invalid': formik.touched[name] && formik.errors[name],
                            },
                            {
                                'is-valid': formik.touched[name] && !formik.errors[name],
                            },
                        )}
                        value={formik.values[name] || ''}
                        disabled={disabled}
                        onChange={onChange}
                        onBlur={onBlur}
                    />
                    {coin &&
                        <>
                            <span className={'prefix'}>
                                {coinLogo &&
                                    <img src={coinLogo} alt="" />
                                }
                            </span>
                            <span className={'suffix text-muted fw-semibold text-muted fs-9 coinUnitCode'}>
                                {unitCode}
                            </span>
                        </>
                    }
                </div>
                {formik.touched[name] && formik.errors[name] && (
                    <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                            <span role="alert">{formik.errors[name]}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Input;