import React, {FC} from 'react';
import CoinName from '@/app/modules/components/CoinName';
import Balance from '@/app/pages/merchant/wallet/tools/Balance';
import UsdValue from '@/app/pages/merchant/wallet/tools/UsdValue';
import Actions from '@/app/modules/components/Actions';
import {IBalance} from '@/app/interfaces';
import {generateActionItemsForCoin, generateActionItemsForCoinAdmin} from '@/app/modules/helpers/generateActionItems';
import TxHash from '@/app/modules/components/TxHash';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    balances: IBalance[],
    merchantID?: string;
    isAdminWallets?: boolean;
    hideActions?: boolean;
    totalBalance: string;
}

const MobileTable: FC<Props> = ({balances, merchantID, isAdminWallets = false, hideActions = false, totalBalance}) => {
    const _ = useTranslation();

    return (
        <div className={'mobileTable'}>
            {balances.map((balance, index) => (
                <div className={'single'} key={'MobileTable' + balance.coinId}>
                    <div className={'info'}>
                        <div className="first">
                            <CoinName name={balance.coinName} symbol={balance.coinSymbol} isTestNetwork={balance.isTestNetwork} subSymbol={balance.coinSubSymbol} />
                        </div>
                        <div className="last">
                            <Balance amount={balance.balance} code={balance.coinSymbol} />
                            <UsdValue amount={balance.usdBalance} />
                        </div>
                    </div>
                    {isAdminWallets && (
                        <div className="address my-4 text-center">
                            <TxHash txHash={balance.mainPublicAddress} showFull />
                        </div>
                    )}

                    {!hideActions && (
                        <div className={'actions'}>
                            {isAdminWallets ? <Actions items={generateActionItemsForCoinAdmin(balance.coinId)} /> : <Actions items={generateActionItemsForCoin(balance.coinId, merchantID)} />}
                        </div>
                    )}
                </div>
            ))}

            <div className={'total text-center py-10'}>
                <div className="first fs-4 fw-bolder">{_('Table.TOTAL')}</div>
                <div className="last"><UsdValue className="fs-2 fw-bolder mt-2" amount={totalBalance} /></div>
            </div>
        </div>
    );
};

export default MobileTable;