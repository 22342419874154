import {AdminRouteConstants} from '@/app/constants/routes.constants';
import {toAbsoluteUrl} from '@/_metronic/helpers';

export {getCoinSvg} from '@/app/modules/helpers/getCoinSvg';

export const generateRoute = (route: string, params: Record<string, string>) => {
    let generatedRoute = route;
    console.log('generatedRoute', generatedRoute);

    for (const [key, value] of Object.entries(params)) {
        generatedRoute = generatedRoute.replace(`:${key}`, value);
    }
    return generatedRoute;
};

function padNumber(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
}

export const calculateTimeLeft = (endTime: Date) => {
    const now = new Date();
    const difference = endTime.getTime() - now.getTime();


    if (difference < 0) return 0;

    return difference / 1000;
};

export const secondsToCountdownFormat = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secondsLeft = Math.floor(seconds % 60);

    return `${padNumber(hours)}:${padNumber(minutes)}:${padNumber(secondsLeft)}`;
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatDateTime = (date: Date) => {
    return `${padNumber(date.getDate())}.${padNumber(date.getMonth() + 1)}.${date.getFullYear()} ${padNumber(date.getHours())}:${padNumber(date.getMinutes())}:${padNumber(date.getSeconds())}`;
};

export const generateTxDetailUrl = (txUrlFormat: string, txId: string) => {
    return txUrlFormat.replace('{txHash}', txId);
};

export const shortenTxHash = (txHash: string) => {
    return `${txHash.slice(0, 8)}...${txHash.slice(-8)}`;
};

export const convertStringToNumber = (value: string, precision?: number) => {
    //convert , to .
    value = value.replace(',', '.');
    //remove all non numeric characters
    value = value.replace(/[^0-9.]/g, '');
    //remove all but first .
    value = value.replace(/\.(?=.*\.)/, '');
    //remove all but first digit
    value = value.replace(/^0+(?=\d)/, '');
    //remove all but 8 digits after .
    value = value.replace(/(\.\d{8})\d+/, '$1');
    if (value === '') return value;

    if (precision === undefined) return value;
    return parseFloat(value).toFixed(precision);
};

export const convertStringToInteger = (value: string) => {
    value = convertStringToNumber(value);
    // remove .
    value = value.replace('.', '');
    return value;
};

export const convertStringTo8precision = (value: string) => {
    return convertStringToNumber(value, 8);
};

export const convertStringTo2precision = (value: string) => {
    return convertStringToNumber(value, 2);
};

export const getAdditionalBreadcrumb = () => {
    return [
        {
            title: 'Merchant',
            path: AdminRouteConstants.MERCHANTS,
            isActive: false,
        },
    ];
};

export const numberFormat = (value: number) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const getCustomSvgUrl = (svg: string) => {
    return toAbsoluteUrl(`/custom/svg/${svg}`);
};


export const isPositive = (value: string | number) => {
    if (typeof value === 'number') {
        return value >= 0;
    }
    return value.indexOf('-') === -1;
};