import {AdminRouteConstants, MerchantRouteConstants} from '@/app/constants/routes.constants';

export const superAdminExcludedPaths: string[] = [
    AdminRouteConstants.COIN_SETTINGS,
    AdminRouteConstants.SETTINGS,
];

export const superAdminExcludedSections = [
    'AdminMenu.SETTINGS_SECTION',
];

export const merchantExcludedPaths: string[] = [
    MerchantRouteConstants.MERCHANT_SETTINGS,
    MerchantRouteConstants.MERCHANT_COIN_ACCEPTANCE_SETTINGS,
    MerchantRouteConstants.API_KEYS,
    MerchantRouteConstants.UPDATE_API_KEY,
    MerchantRouteConstants.WITHDRAW,
    MerchantRouteConstants.VIEW_API_KEY_LOG,
    MerchantRouteConstants.EMPLOYEES,
    MerchantRouteConstants.EMPLOYEE_DETAIL,
];

export const merchantExcludedSections = [
    'MerchantMenu.API_SECTION',
];