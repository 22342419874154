// PermissionCheckbox.tsx
import {FC} from "react";

interface PermissionCheckboxProps {
    name: string;
    title: string;
    description: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
}

const PermissionCheckbox: FC<PermissionCheckboxProps> = ({name, title, description, checked, onChange}) => {
    return (
        <div className="d-flex">
            <div className="form-check form-check-custom form-check-solid">
                <input
                    className="form-check-input me-3  cursor-pointer"
                    type="checkbox"
                    checked={checked}
                    name={name}
                    id={`permission_${name}`}
                    onChange={(e) => onChange(e.target.checked)}
                />
                <label className="form-check-label  cursor-pointer" htmlFor={`permission_${name}`}>
                    <div className="fw-bold text-dark">{title}</div>
                    <div className="text-gray-600">{description}</div>
                </label>
            </div>
        </div>
    );
};

export default PermissionCheckbox;