import {type FC, useMemo} from 'react';

interface Props {
    percent: number;
}

const PercentBadge: FC<Props> = ({percent}) => {
    const isPositive = useMemo(() => percent >= 0, [percent]);
    return (
        <span className={`badge fs-base ${isPositive ? 'badge-light-success' : 'badge-light-danger'}`}>
            <i className={`ki-duotone  fs-5 ms-n1 ${isPositive ? 'ki-arrow-up text-success' : 'ki-arrow-down text-danger'}`}><span className="path1"></span><span className="path2"></span></i>
            {percent}%
        </span>
    );
};

export default PercentBadge;