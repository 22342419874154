import React, {FC, useState} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import Card from '@/app/modules/components/card/Card';
import {useFormik} from 'formik';
import {accountSettingsEmailSchema, accountSettingsPasswordSchema} from '@/app/utils/yupSchema';
import {IUpdateEmailValues, IUpdatePasswordValues} from '@/app/interfaces/values';
import {passwordUpdateInitialValues} from '@/app/utils/initialValues';
import {useAuth} from '@/app/modules/auth';
import Input from '@/app/modules/auth/components/tools/inputs/Input';
import AccountSettingsForm from '@/app/pages/merchant/account/AccountSettingsForm';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {updatePassword} from '@/app/modules/auth/core/_requests';
import {toast} from 'react-toastify';

const AccountSettings: FC = () => {
    const _ = useTranslation();
    const {currentUser} = useAuth();

    const [showEmailForm, setShowEmailForm] = useState<boolean>(false);
    const [showPasswordForm, setShowPasswordForm] = useState<boolean>(false);
    const [updateEmailLoading, setUpdateEmailLoading] = useState(false);
    const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);

    const updateEmailFormik = useFormik<IUpdateEmailValues>({
        initialValues: {
            newEmail: currentUser?.email || '',
            confirmPassword: '',
        },
        validationSchema: accountSettingsEmailSchema(_),
        onSubmit: (values, {setSubmitting}) => {
            setUpdateEmailLoading(true);
            setTimeout((values) => {
                setSubmitting(false);
                setUpdateEmailLoading(false);
                setShowEmailForm(false);
            }, 1000);
        },
    });

    const updatePasswordFormik = useFormik<IUpdatePasswordValues>({
        initialValues: passwordUpdateInitialValues,
        validationSchema: accountSettingsPasswordSchema(_),
        onSubmit: async (values) => {
            setUpdatePasswordLoading(true);
            try {
                const {status} = await updatePassword({
                    oldPassword: values.currentPassword,
                    newPassword: values.newPassword,
                });
                if (status === 201) {
                    setUpdatePasswordLoading(false);
                    setShowPasswordForm(false);
                    toast.success(_('AccountSettings.UPDATE_PASSWORD_SUCCESS'));
                }
            } catch (error: any) {
                setUpdatePasswordLoading(false);
                toast.error(error.response.data.message);
            }

        },
    });
    return (
        <>
            <ToolbarWrapper title={_('AccountSettings.TITLE')} />
            <Card title={_('AccountSettings.TITLE')} subTitle={_('AccountSettings.SUB_TITLE')} className={'accountSettings'}>
                <div className="separator separator-dashed my-6"></div>
                <AccountSettingsForm showForm={showEmailForm} formik={updateEmailFormik} loading={updateEmailLoading} setShowForm={setShowEmailForm} buttonText={_('AccountSettings.CHANGE_EMAIL')} label={_('AccountSettings.EMAIL_ADDRESS')} value={currentUser?.email || ''}>
                    <div className="row mb-6">
                        <div className="col-lg-6 mb-4 mb-lg-0">
                            <Input className="form-control-lg form-control-solid" name={'newEmail'} type={'email'} formik={updateEmailFormik} label={_('AccountSettings.NEW_EMAIL_ADDRESS')} placeholder="" />
                        </div>
                        <div className="col-lg-6">
                            <Input className="form-control-lg form-control-solid" name={'confirmPassword'} type={'password'} formik={updateEmailFormik} label={_('AccountSettings.CONFIRM_PASSWORD')} placeholder=" " />
                        </div>
                    </div>
                </AccountSettingsForm>
                <div className="separator separator-dashed my-6"></div>
                <AccountSettingsForm showForm={showPasswordForm} formik={updatePasswordFormik} loading={updatePasswordLoading} setShowForm={setShowPasswordForm} buttonText={_('AccountSettings.CHANGE_PASSWORD')} label={_('AccountSettings.PASSWORD')} value={'************'} className={'mb-5'}>
                    <div className="row mb-1">
                        <div className="col-lg-4">
                            <Input className="form-control-lg form-control-solid" name={'currentPassword'} type={'password'} formik={updatePasswordFormik} label={_('AccountSettings.CURRENT_PASSWORD')} placeholder=" " />
                        </div>

                        <div className="col-lg-4">
                            <Input className="form-control-lg form-control-solid" name={'newPassword'} type={'password'} formik={updatePasswordFormik} label={_('AccountSettings.NEW_PASSWORD')} placeholder=" " />
                        </div>

                        <div className="col-lg-4">
                            <Input className="form-control-lg form-control-solid" name={'passwordConfirmation'} type={'password'} formik={updatePasswordFormik} label={_('AccountSettings.PASSWORD_CONFIRMATION')} placeholder=" " />
                        </div>
                    </div>
                </AccountSettingsForm>

            </Card>

        </>
    );
};

export default AccountSettings;