import React, {type FC, ReactNode} from 'react';
import {KTIcon} from '@/_metronic/helpers';

interface Props {
    title: string;
    items: Array<{icon: string, title: string, value?: string | ReactNode, isWhiteLabel?: boolean}>;
}

const TransactionDetailCard: FC<Props> = ({title, items}) => {
    return (
        <div className="card card-flush py-4 flex-row-fluid">
            <div className="card-header">
                <div className="card-title">
                    <h2>{title}</h2>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="table-responsive">
                    <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                        <tbody className="fw-semibold text-gray-600">
                        {items.map((item, index) => (
                            <tr key={index}>
                                <td className={`${item.isWhiteLabel ? 'text-dark fs-4' : 'text-muted'}`}>
                                    <div className="d-flex align-items-center">
                                        <KTIcon iconName={item.icon} className="fs-2 me-2" /> {item.title}
                                    </div>
                                </td>
                                <td className="fw-bold text-end text-dark">{item.value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TransactionDetailCard;