import React, {FC, useCallback} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import Card from '@/app/modules/components/card/Card';
import MerchantListFilters from '@/app/pages/admin/merchantList/MerchantListFilters';
import {IMerchant} from '@/app/interfaces';
import {MerchantListTableHead} from '@/app/utils/tableHeads/admin/MerchantListTableHead';
import {merchantTr} from '@/app/pages/admin/merchantList/merchantTr';
import {IAdminMerchantListFilters} from '@/app/interfaces/filters';
import useDatatable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {initialAdminMerchantListSort} from '@/app/utils/initialSorts';
import {initialAdminTransactionFilters} from '@/app/utils/initialFilters';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getMerchants} from '@/app/utils/requests/adminRequests';

interface Props {

}



const MerchantList: FC<Props> = (props) => {
    const _ = useTranslation();

    const getData: GetDataFunction<IMerchant, IAdminMerchantListFilters> = useCallback(async ({pageIndex, filters, pageSize, sort}) => {
        const params = {
            sort,
            pageIndex,
            pageSize,
            orFilters: filters.searchQuery
                ? {
                    'merchantName': filters.searchQuery,
                    'merchantEmail': filters.searchQuery,
                }
                : undefined,
            exactFilters: {
                ...(filters.status && {'status': filters.status}),
            },
        };
        const {data} = await getMerchants(params);
        if (!data) return Promise.reject();
        return data;
    }, []);
    const {
        handleFilterSubmit,
        handleFilterReset,
        handleFilterChange,
        filters,
        DataTableComponent,
    } = useDatatable<IMerchant, IAdminMerchantListFilters>({
        initialSort: initialAdminMerchantListSort,
        initialFilters: initialAdminTransactionFilters,
        getData,
        id: 'merchantList',
    });

    return (
        <>
            <ToolbarWrapper title={'Merchant Listesi'} />
            <Card>
                <MerchantListFilters handleFilterSubmit={handleFilterSubmit} handleFilterReset={handleFilterReset} handleFilterChange={handleFilterChange} filters={filters} />
                <DataTableComponent head={MerchantListTableHead} renderRow={(data, index) => {
                    return merchantTr(data, index, _);
                }} />
            </Card>
        </>
    );
};

export default MerchantList;