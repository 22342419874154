import {AdminRouteConstants} from '@/app/constants/routes.constants';

export const adminMenuItems = [
    {
        type: 'item',
        to: AdminRouteConstants.DASHBOARD,
        icon: 'element-11',
        title: 'AdminMenu.DASHBOARD',
    },
    {
        type: 'section',
        title: 'AdminMenu.RECORDS',
    },
    {
        type: 'item',
        to: AdminRouteConstants.MERCHANTS,
        icon: 'office-bag',
        title: 'AdminMenu.MERCHANT_LIST',
    },
    {
        type: 'item',
        to: AdminRouteConstants.USERS,
        icon: 'profile-user',
        title: 'AdminMenu.MEMBERS',
    },
    {
        type: 'item',
        to: AdminRouteConstants.WALLETS,
        icon: 'wallet',
        title: 'AdminMenu.WALLETS',
    },
    {
        type: 'item',
        to: AdminRouteConstants.REPORTS,
        icon: 'directbox-default',
        title: 'AdminMenu.REPORTS',
    },

    {
        type: 'section',
        title: 'AdminMenu.PROCESSES',
    },

    {
        type: 'item',
        to: AdminRouteConstants.TRANSACTIONS,
        icon: 'notepad',
        title: 'AdminMenu.TRANSACTION_LIST',
    },
    {
        type: 'item',
        to: AdminRouteConstants.WITHDRAWALS,
        icon: 'send',
        title: 'AdminMenu.WITHDRAW_HISTORY',
    },
    {
        type: 'item',
        to: AdminRouteConstants.BLOCKCHAIN_TRANSACTIONS,
        icon: 'disconnect',
        title: 'AdminMenu.BLOCKCHAIN_TRANSACTIONS',
    },
    {
        type: 'section',
        title: 'AdminMenu.SETTINGS_SECTION',
    },
    {
        type: 'item',
        to: AdminRouteConstants.COIN_SETTINGS,
        icon: 'bitcoin',
        title: 'AdminMenu.COIN_SETTINGS',
    },
    {
        type: 'item',
        to: AdminRouteConstants.SETTINGS,
        icon: 'setting-2',
        title: 'AdminMenu.SETTINGS',
    },
];