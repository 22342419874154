import axios from 'axios';
import {
    AuthForgotPasswordConfirmResponseModel,
    AuthForgotPasswordResponseModel,
    AuthForgotPasswordValidateResponseModel,
    AuthLoginConfirmResponseModel,
    AuthLoginResponseModel,
    AuthRegisterConfirmResponseModel,
    AuthRegisterResponseModel, CreatedTransactionModel,
    MerchantSettingsModel, MiniTransactionModel,
    TransactionModel, TransactionPostModel,
    UserModel,
} from './_models';
import {IDataTableParams} from '@/app/utils/requests/adminRequests';
import {ICoinConfig, IBalance, ICheckWithdrawableResult, IConfirmWithdrawResponse, IStartWithdrawResponse, IApiKeyDetail, IUpdateApiPayload, IGeneralStatsResponse, IGraphStatsResponse, TDashboardGraphStatType, TDashboardGraphStatRange, IUser, IDateRange} from '@/app/interfaces';
import {IReport} from '@/app/interfaces/report';

const API_URL = process.env.REACT_APP_API_URL;
const AUTH_URL = `${API_URL}/user/auth`;

export function login(email: string, password: string) {
    return axios.post<AuthLoginResponseModel>(`${AUTH_URL}/login`, {
        email,
        password,
    });
}

export function verifyCode(validationId: string, code: string) {
    return axios.post<AuthLoginConfirmResponseModel>(`${AUTH_URL}/login/confirm`, {
        validationId,
        code,
    });
}

export function registerVerifyCode(validationId: string, code: string) {
    return axios.post<AuthRegisterConfirmResponseModel>(`${AUTH_URL}/register/confirm`, {
        validationId,
        code,
    });
}

export function sendPasswordResetWithEmail(email: string) {
    return axios.post<AuthForgotPasswordResponseModel>(`${AUTH_URL}/forgot-password`, {
        email,
    });
}

export function sendPasswordResetWithPhone(phone: string) {
    return axios.post<AuthForgotPasswordResponseModel>(`${AUTH_URL}/forgot-password`, {
        phoneNumber: phone,
    });
}

export function verifyPasswordResetCode({id, source, code}: {id: string, source: number, code: string}) {
    return axios.post<AuthForgotPasswordValidateResponseModel>(`${AUTH_URL}/forgot-password/validate`, {
        validationId: id,
        validationSource: source,
        code,
    });
}


export function resetPassword(
    {
        validationId,
        code,
        password,
        validationSource,
    }: {
        validationId: string,
        code: string,
        password: string,
        validationSource: number,
    }) {
    return axios.post<AuthForgotPasswordConfirmResponseModel>(`${AUTH_URL}/forgot-password/confirm`, {
        validationId,
        code,
        password,
        validationSource,
    });
}

export function register(
    email: string,
    fullName: string,
    phoneNumber: string,
    password: string,
) {
    return axios.post<AuthRegisterResponseModel>(`${AUTH_URL}/register`, {
        email,
        fullName,
        phoneNumber,
        password,
    });
}

export function getUserByToken() {
    return axios.get<UserModel>(`${API_URL}/user`);
}

export function getMerchant() {
    return axios.get<MerchantSettingsModel>(`${API_URL}/merchant`);
}

export function createTransaction({name, count, price}: {
    name: string,
    count: number,
    price: number
}) {
    return axios.post<CreatedTransactionModel>(`${API_URL}/transaction`, {
        productName: name,
        productCount: count,
        productPrice: price.toString(),
    });
}

export function getTransaction(id: string) {
    return axios.get<TransactionModel>(`${API_URL}/transaction/pub/${id}`);
}

export function transactionPubTicker(id: string) {
    return axios.get<MiniTransactionModel>(`${API_URL}/transaction/pub/ticker/${id}`);
}

export function transactionPost({id, email, coinId}: {id: string, email: string, coinId: number}) {
    return axios.post<TransactionPostModel>(`${API_URL}/transaction/pub/${id}`, {
        email,
        coinId,
    });
}

export function updatePassword({oldPassword, newPassword}: {oldPassword: string, newPassword: string}) {
    return axios.post(`${API_URL}/user/change-password`, {
        oldPassword,
        newPassword,
    });
}

export function updateMerchantSettings({merchantName, merchantUrl, merchantEmail, IPNUrl, IPNSecret, withdrawCallbackUrl}: {
    merchantName: string,
    merchantUrl: string,
    merchantEmail: string,
    IPNUrl: string,
    IPNSecret: string,
    withdrawCallbackUrl: string
}) {
    return axios.patch(`${API_URL}/merchant`, {
        merchantName,
        merchantUrl,
        merchantEmail,
        IPNUrl,
        withdrawCallbackUrl,
        IPNSecret,
    });
}

export function getMerchantTransactions(params: IDataTableParams) {
    return axios.get(`${API_URL}/transaction`, {
        params,
    });
}

export function getBalances() {
    return axios.get<IBalance[]>(`${API_URL}/user/wallet`);
}

export function getWithdrawalHistory(params: IDataTableParams) {
    return axios.get(`${API_URL}/withdrawal`, {
        params,
    });
}

export function checkWithdrawable(coinId: string) {
    return axios.get<ICheckWithdrawableResult>(`${API_URL}/withdrawal/checkWithdrawable/${coinId}`);
}

export function startWithdrawRequest({coinId, amount, address}: {coinId: number, amount: number, address: string}) {
    return axios.post<IStartWithdrawResponse>(`${API_URL}/withdrawal`, {
        coinId,
        amount,
        address,
    });
}

export function confirmWithdrawRequest({validationId, code}: {validationId: string, code: string}) {
    return axios.post<IConfirmWithdrawResponse>(`${API_URL}/withdrawal/confirm`, {
        validationId,
        code,
    });
}

export function cancelWithdrawRequest(withdrawId: number) {
    return axios.post(`${API_URL}/withdrawal/cancel`, {
        withdrawId,
    });
}

export function getCoinConfig() {
    return axios.get<ICoinConfig[]>(`${API_URL}/merchant/coin-config`);
}

export function setCoinConfigBatch(configs: {coinId: number, enabled: boolean, discountRate: number}[]) {
    return axios.patch(`${API_URL}/merchant/coin-config`, {
        coinConfig: configs,
    });
}

export function getIpnHistory(params: IDataTableParams) {
    return axios.get(`${API_URL}/ipn`, {
        params,
    });
}

export function resendIpn(id: number) {
    return axios.post(`${API_URL}/ipn/resend/${id}`);
}

export function generateApiKey() {
    return axios.post(`${API_URL}/apiKey/generateKey`);
}

export function getApiKeys(params: IDataTableParams) {
    return axios.get(`${API_URL}/apiKey`, {
        params,
    });
}

export function getApiKey(id: number) {
    return axios.get<IApiKeyDetail>(`${API_URL}/apiKey/${id}`);
}

export function updateApiKey(id: number, payload: IUpdateApiPayload) {
    return axios.patch(`${API_URL}/apiKey/${id}`, payload);
}

export function generalStats() {
    return axios.get<IGeneralStatsResponse>(`${API_URL}/statistics`);
}

export function graphStats(type: TDashboardGraphStatType, range: TDashboardGraphStatRange) {
    return axios.get<IGraphStatsResponse>(`${API_URL}/statistics/graph`, {
        params: {
            type,
            range,
        },
    });
}

export function getMerchantTransactionDetail({transactionId}: {transactionId: string}) {
    return axios.get(`${API_URL}/transaction/${transactionId}`);
}

export function getEmployees(params: IDataTableParams) {
    return axios.get(`${API_URL}/user/employee`, {
        params,
    });
}


export async function createEmployee({email, fullName, phoneNumber, password}: {email: string, fullName: string, phoneNumber: string, password: string}) {
    return axios.post(`${API_URL}/user/create`, {
        fullName,
        email,
        phoneNumber,
        password,
    });
}


export async function deleteEmployee(employeeId: number) {
    return axios.delete(`${API_URL}/user/employee/${employeeId}`);
}


export async function getEmployee(employeeId: number) {
    return axios.get<IUser>(`${API_URL}/user/employee/${employeeId}`);
}


export async function getEmployeeLoginHistory(employeeId: number, params: IDataTableParams) {
    return axios.get(`${API_URL}/user/employee/${employeeId}/login-history`, {
        params,
    });
}


export function getReport(dateRange: IDateRange) {
    return axios.get<IReport>(`${API_URL}/statistics/report`, {
        params: dateRange,
    });
}
