import {MerchantRouteConstants} from '@/app/constants/routes.constants';

export const merchantMenuItems = [
    {
        type: 'item',
        to: MerchantRouteConstants.DASHBOARD,
        icon: 'element-11',
        title: 'MerchantMenu.DASHBOARD',
    },
    {
        type: 'section',
        title: 'MerchantMenu.WALLET_SECTION',
    },
    {
        type: 'item',
        to: MerchantRouteConstants.MY_WALLET_OVERVIEW,
        icon: 'wallet',
        title: 'MerchantMenu.MY_WALLET',
    },
    {
        type: 'item',
        to: MerchantRouteConstants.CREATE_MANUEL_ORDER,
        icon: 'abstract-10',
        title: 'MerchantMenu.CREATE_ORDER',
    },
    {
        type: 'section',
        title: 'MerchantMenu.HISTORY_SECTION',
    },
    {
        type: 'item',
        to: MerchantRouteConstants.TRANSACTION_HISTORY,
        icon: 'notepad',
        title: 'MerchantMenu.TRANSACTION_HISTORY',
    },
    {
        type: 'item',
        to: MerchantRouteConstants.WITHDRAWAL_HISTORY,
        icon: 'send',
        title: 'MerchantMenu.WITHDRAWAL_HISTORY',
    },

    {
        type: 'section',
        title: 'MerchantMenu.MERCHANT_SECTION',
    },
    {
        type: 'item',
        to: MerchantRouteConstants.EMPLOYEES,
        icon: 'profile-user',
        title: 'MerchantMenu.EMPLOYEES',
    },
    {
        type: 'item',
        to: MerchantRouteConstants.REPORTS,
        icon: 'directbox-default',
        title: 'MerchantMenu.REPORTS',
    },
    {
        type: 'item',
        to: MerchantRouteConstants.MERCHANT_SETTINGS,
        icon: 'setting-2',
        title: 'MerchantMenu.MERCHANT_SETTINGS',
    },
    {
        type: 'item',
        to: MerchantRouteConstants.MERCHANT_COIN_ACCEPTANCE_SETTINGS,
        icon: 'bitcoin',
        title: 'MerchantMenu.COIN_SETTINGS',
    },
    {
        type: 'section',
        title: 'MerchantMenu.API_SECTION',
    },
    {
        type: 'item',
        to: MerchantRouteConstants.API_KEYS,
        icon: 'code',
        title: 'MerchantMenu.API_KEYS',
    },
    {
        type: 'item',
        to: MerchantRouteConstants.MERCHANT_IPN_HISTORY,
        icon: 'abstract-14',
        title: 'MerchantMenu.IPN_HISTORY',
    },
];
