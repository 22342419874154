import {type FC, useCallback, useEffect, useMemo, useState} from 'react';
import SimpleCardChart from '@/app/pages/common/Dashboard/charts/SimpleCardChart';
import PercentBadge from '@/app/pages/common/Dashboard/StatsWithChartCards/StatsWithChartCard/PercentBadge';
import {numberFormat} from '@/app/modules/helpers';
import {TDashboardGraphStatRange, TDashboardGraphStatType, TFetchGraphStats} from '@/app/interfaces';
import RangePicker from '@/app/pages/common/Dashboard/StatsWithChartCards/StatsWithChartCard/RangePicker';
import {useAreaLoading} from '@/app/modules/hooks/useAreaLoading';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    title: string;
    subtitle: string;
    isUsd?: boolean;
    type: TDashboardGraphStatType;
    fetchGraphStats: TFetchGraphStats;
}

const StatsWithChartCard: FC<Props> = ({title, subtitle, isUsd, type, fetchGraphStats}) => {
    const _ = useTranslation();
    const [total, setTotal] = useState(0);
    const [percent, setPercent] = useState(0);
    const [range, setRange] = useState<TDashboardGraphStatRange>('weekly');
    const [values, setValues] = useState<Record<string, number>>({});
    const {setLoading, LoadingComponent} = useAreaLoading();

    const onRangeChange = useCallback((range: TDashboardGraphStatRange) => {
        setLoading(true);
        setRange(range);
        setValues({});

        // const fetchGraphStats = merchantId ? () => graphStatsFromAdmin(merchantId, type, range) : () => graphStats(type, range);
        fetchGraphStats(type, range).then((response) => {
            const {data} = response;
            setPercent(data.changeRate);
            setTotal(data.total);
            setValues(data.values);

        }).finally(() => {
            setLoading(false);
        });
    }, [fetchGraphStats, setLoading, type]);

    useEffect(() => {
        onRangeChange(range);
    }, []);


    const rangeText = useMemo(() => {
        return {
            monthly: _('Dashboard.STATS.RANGE.MONTH_TEXT'),
            weekly: _('Dashboard.STATS.RANGE.WEEK_TEXT'),
            daily: _('Dashboard.STATS.RANGE.DAY_TEXT'),
        };
    }, []);

    return (
        <LoadingComponent>
            <div className="card card-flush">
                <div className="card-header pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-900">{title}</span>
                        <span className="text-gray-500 mt-1 fw-semibold fs-6">{subtitle}</span>
                    </h3>
                    <RangePicker range={range} type={type} onChange={onRangeChange} />
                </div>
                <div className="card-body d-flex justify-content-between flex-column px-0 pb-0">
                    <div className="mb-4 px-9">
                        <div className="d-flex align-items-center">
                            {isUsd && <span className="fs-4 fw-semibold text-gray-500 me-1 align-self-start">$</span>}
                            <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{numberFormat(total)}</span>
                            <PercentBadge percent={percent} />
                        </div>

                        <span className="fs-6 fw-semibold text-gray-500">{rangeText[range]}</span>
                    </div>

                    <SimpleCardChart title={title} values={values} />
                </div>
            </div>
        </LoadingComponent>
    );
};

export default StatsWithChartCard;