import React, {type FC} from 'react';
import {amountWithCurrency} from '@/app/modules/helpers/getValuesFromId';
import Balance from '@/app/pages/merchant/wallet/tools/Balance';
import {ITransaction} from '@/app/interfaces';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    products: Array<{name: string, quantity: number, price: string}>;
    transaction: ITransaction;

}

const TransactionDetailProducts: FC<Props> = ({products, transaction}) => {
    const _ = useTranslation();
    return (
        <div className="card card-flush py-4 flex-row-fluid overflow-hidden mb-10">
            <div className="card-header">
                <div className="card-title">
                    <h2>{_('TransactionDetail.PRODUCTS_TITLE')}</h2>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="table-responsive">
                    <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                        <thead>
                        <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                            <th className="min-w-175px">{_('TransactionDetail.PRODUCT')}</th>
                            <th className="min-w-70px text-end">{_('TransactionDetail.QUANTITY')}</th>
                            <th className="min-w-100px text-end">{_('TransactionDetail.SINGLE_PRICE')}</th>
                            <th className="min-w-100px text-end">{_('TransactionDetail.TOTAL_PRICE')}</th>
                        </tr>
                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                        {products.map((product, index) => (
                            <tr key={`transaction-product-${index}`}>
                                <td>
                                    <div className="d-flex align-items-center">
                                        <div className="ms-5">
                                            <span className="fw-bold text-gray-600">{product.name}</span>
                                        </div>
                                    </div>
                                </td>
                                <td className="text-end">
                                    {product.quantity}
                                </td>
                                <td className="text-end">
                                    {amountWithCurrency(product.price, 'USD')}
                                </td>
                                <td className="text-end">
                                    {amountWithCurrency((parseFloat(product.price) * product.quantity).toFixed(2), 'USD')}
                                </td>
                            </tr>
                        ))}

                        <tr>
                            <td colSpan={3} className="fs-3 text-gray-900 text-end">
                                {_('TransactionDetail.TOTAL')}
                            </td>
                            <td className="text-gray-900 fs-3 fw-bolder text-end">
                                {amountWithCurrency(transaction.productTotal, 'USD')}
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={3} className="fs-3 text-gray-900 text-end">
                                {_('TransactionDetail.TOTAL_AS_COIN')}
                            </td>
                            <td className="text-gray-900 fs-3 fw-bolder text-end">
                                <Balance amount={transaction?.totalAmountAsCoin} code={transaction.coinSymbol} isTestNetwork={transaction.isTestNetwork} />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TransactionDetailProducts;