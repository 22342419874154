import React, {FC} from 'react';
import {KTIcon, toAbsoluteUrl} from '@/_metronic/helpers';
import useCopyToClipboard from '@/app/modules/hooks/useCopyToClipboard';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import QRCode from 'react-qr-code';


interface Props {
    amount: string;
    coinCode: string;
    address: string;
    warningText?: string;
}

const Qr: FC<Props> = ({amount, coinCode, address, warningText}) => {
    const _ = useTranslation();
    const [copyAmount, isCopiedAmount] = useCopyToClipboard();
    const [copyAddress, isCopiedAddress] = useCopyToClipboard();
    return (
        <section className={'paySection'}>
            <div className="amount">
                <span className="label">{_('Qr.AMOUNT_TO_BE_SENT')}</span>
                <span className="copyArea" onClick={() => copyAmount(amount)}>
                    <img style={{width: '20px'}} src={toAbsoluteUrl(`/custom/coins/${coinCode.toLowerCase()}.svg`)}
                         alt="" />
                    <span className="amountWithCode">{amount}<small>{coinCode.toUpperCase()}</small></span>
                    <span className={`copy ${isCopiedAmount ? 'show' : ''}`}>{_('General.COPIED')}</span>
                </span>
            </div>
            <div className="qrCode">
                {/*TODO: QR kodunu propstan al*/}
                {/*<img src={toAbsoluteUrl('/custom/svg/qrcode.svg')} alt="QR Code" />*/}
                <div style={{background: 'white', padding: '16px'}}>

                    <QRCode
                        size={268}
                        style={{height: 'auto', maxWidth: '100%', width: '100%'}}
                        value={address}
                        viewBox={`0 0 268 268`}

                    />
                </div>
            </div>
            <div className="walletAddress w-100">
                <span className="label">{_('Qr.WALLET_ADDRESS')}</span>
                <span className="copyArea w-100" onClick={() => copyAddress(address)}>
                    {address}
                    <span className={`copy ${isCopiedAddress ? 'show' : ''}`}>{_('General.COPIED')}</span>
                </span>

            </div>
            <div className="addressWarning alert alert-dismissible bg-light-info d-flex flex-column flex-sm-row w-100 p-5 mt-5 align-items-center">
                <i className="ki-duotone ki-notification-bing fs-2hx text-info me-4 mb-5 mb-sm-0"><span className="path1"></span><span className="path2"></span><span className="path3"></span></i>

                <div className="d-flex flex-column pe-0">
                    <span className="warningText">{_('Qr.ADDRESS_WARNING')}</span>
                </div>
            </div>

            {warningText && (
                <div
                    className="alert bg-light-warning  border-warning border border-dashed d-flex flex-row w-100 p-2 mt-5 align-items-center">
                    <KTIcon iconName="information" className="fs-2hx text-dark me-4" />
                    <h6 className="mb-0">{warningText}</h6>
                </div>
            )}
            {/*//TODO: write a modal for button cancel confirm or deny and make request*/}

            {/*<div className="mt-5">*/}
            {/*    <button className="btn btn-lg btn-block btn-danger w-100">{_('Qr.CANCEL')}</button>*/}
            {/*</div>*/}
        </section>
    );
};

export default Qr;