/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useMemo} from 'react';
import {Link} from 'react-router-dom';
import {useAuth} from '@/app/modules/auth';
import {Languages} from './Languages';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {USER_TYPE} from '@/app/enums';

const HeaderUserMenu: FC = () => {
    const {logout, currentUser} = useAuth();
    const _ = useTranslation();


    const rank = useMemo(() => {
        switch (currentUser?.userType) {
            case USER_TYPE.SUPER_ADMIN:
                return 'Super Admin';
            case USER_TYPE.ADMIN:
                return 'Admin';
            case USER_TYPE.MERCHANT:
                return 'Merchant';
            default:
                return 'Employee';
        }
    }, [currentUser?.userType]);

    return (
        <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-auto"
            data-kt-menu="true"
        >
            <div className="menu-item px-3">
                <div className="menu-content d-flex align-items-center px-3">
                    <div className="symbol symbol-50px me-5">
                        <img alt="Logo" src={toAbsoluteUrl('/media/svg/custom/man.svg')} />
                    </div>

                    <div className="d-flex flex-column">
                        <div className="fw-bolder d-flex align-items-center fs-5">
                            {currentUser?.fullName}
                            <span className="badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2">{rank}</span>
                        </div>
                        <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
                            {currentUser?.email}
                        </a>
                    </div>
                </div>
            </div>

            <div className="separator my-2"></div>

            <Languages />

            <div className="menu-item px-5 my-1">
                <Link to={MerchantRouteConstants.ACCOUNT_SETTINGS} className="menu-link px-5">
                    {_('AccountSettings.TITLE')}
                </Link>
            </div>

            <div className="menu-item px-5">
                <a onClick={logout} className="menu-link px-5">
                    {_('General.LOGOUT')}
                </a>
            </div>
        </div>
    );
};

export {HeaderUserMenu};
