import React, {FC, useMemo} from 'react';
import {Modal} from "react-bootstrap";
import {KTIcon} from "@/_metronic/helpers";
import ResetTypeSelection from "./forgotPasswordSteps/ResetTypeSelection";
import EnterSms from "./forgotPasswordSteps/EnterSms";
import EnterEmail from "./forgotPasswordSteps/EnterEmail";
import EnterCode from "./forgotPasswordSteps/EnterCode";
import Success from "./forgotPasswordSteps/Success";
import NewPassword from "./forgotPasswordSteps/NewPassword";
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    show: boolean;
    handleClose: () => void;
}

export interface IStepProps {
    infoChangeHandler: TInfoFunc;
    stepChangeHandler: TStepFunc;
    info: Info;
    current: boolean;
}

type TInfoFunc = (key: keyof Info, value: any) => void;
type TStepFunc = (step: StepName) => void;

interface Info {
    resetType: 'email' | 'sms' | null;
    email: string | null;
    phone: string | null;
    validationId: string | null;
    validationSource: number | null;
    code: string | null;
    newPassword?: string | null;
}

type StepName =
    'resetTypeSelection'
    | 'enterEmail'
    | 'enterSms'
    | 'enterCode'
    | 'newPassword'
    | 'success';

const ForgotPassword: FC<Props> = (props) => {
    const _ = useTranslation();

    const [stepName, setStepName] = React.useState<StepName>('resetTypeSelection')
    const [info, setInfo] = React.useState<Info>({
        resetType: null,
        email: null,
        phone: null,
        validationId: null,
        validationSource: null,
        code: null,
    })

    const infoChangeHandler: TInfoFunc = (key, value) => {
        setInfo(prevState => ({
            ...prevState,
            [key]: value
        }))
    }

    const stepChangeHandler: TStepFunc = (stepName) => {
        setStepName(stepName)
    }
    const steps = useMemo(() => {
        return [
            {name: "resetTypeSelection", component: ResetTypeSelection},
            {name: "enterEmail", component: EnterEmail},
            {name: "enterSms", component: EnterSms},
            {name: "enterCode", component: EnterCode, props: {type: info.resetType, validationId: info.validationId, validationSource: info.validationSource}},
            {name: "newPassword", component: NewPassword},
            {name: "success", component: Success, props: {closeModal: props.handleClose}},

        ];
    }, [props.handleClose, info.validationId, info.validationSource, info.resetType]);


    return (
        <Modal
            id='kt_modal_create_app'
            tabIndex={-1}
            aria-hidden='true'
            dialogClassName='modal-dialog modal-dialog-centered mw-650px'
            show={props.show}
            onHide={props.handleClose}
            backdrop={"static"}
        >
            <div className='modal-header'>
                <h2>{_('ForgotPassword.TITLE')}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.handleClose}>
                    <KTIcon className='fs-1' iconName='cross'/>
                </div>
            </div>

            <div className='modal-body'>
                <div
                    className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
                    id='kt_modal_create_app_stepper'
                >
                    <div className='flex-row-fluid px-lg-15'>
                        {steps.map((step, index) => {
                            const StepComponent = step.component;
                            return (
                                // @ts-ignore
                                <StepComponent
                                    key={index}
                                    infoChangeHandler={infoChangeHandler}
                                    stepChangeHandler={stepChangeHandler}
                                    info={info}
                                    current={stepName === step.name}
                                    {...step.props}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default ForgotPassword;