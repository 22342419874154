import {type FC, useMemo} from 'react';
import Dashboard from '@/app/pages/common/Dashboard/Dashboard';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {TDashboardGraphStatType, TDashboardGraphStatRange} from '@/app/interfaces';
import {generalStatsFromAdmin, graphStatsFromAdmin} from '@/app/utils/requests/adminRequests';
import {generalStats, graphStats} from '@/app/modules/auth/core/_requests';

interface Props {
    id?: string;
}

const MerchantDashboard: FC<Props> = ({id}) => {
    const _ = useTranslation();

    const chartCards = useMemo(() => [
        {key: 'transactionCount', title: _('Dashboard.ORDER_COUNT_TITLE'), subtitle: _('Dashboard.ORDER_COUNT_SUBTITLE')},
        {key: 'transactionAmount', title: _('Dashboard.TOTAL_ORDER_AMOUNT_TITLE'), subtitle: _('Dashboard.TOTAL_ORDER_AMOUNT_SUBTITLE'), isUsd: true},
        {key: 'withdrawAmount', title: _('Dashboard.TOTAL_WITHDRAWAL_AMOUNT_TITLE'), subtitle: _('Dashboard.TOTAL_WITHDRAWAL_AMOUNT_SUBTITLE'), isUsd: true},
    ], [_]);


    const statCards = useMemo(() => [
        {key: 'TOTAL_ORDER_COUNT', title: _('Dashboard.STAT_CARD_TITLE.TOTAL_ORDER_COUNT'), svg: 'online-shopping.svg'},
        {key: 'TOTAL_ORDER_AMOUNT', title: _('Dashboard.STAT_CARD_TITLE.TOTAL_ORDER_AMOUNT'), svg: 'transaction.svg', isUsd: true},
        {key: 'TOTAL_WITHDRAWAL_AMOUNT', title: _('Dashboard.STAT_CARD_TITLE.TOTAL_WITHDRAWAL_AMOUNT'), svg: 'transfer.svg', isUsd: true},
        {key: 'TOTAL_BALANCE', title: _('Dashboard.STAT_CARD_TITLE.TOTAL_BALANCE'), svg: 'money.svg', isUsd: true},
        {key: 'CUSTOMER_COUNT', title: _('Dashboard.STAT_CARD_TITLE.CUSTOMER_COUNT'), svg: 'rating.svg'},
        {key: 'SUCCESSFUL_ORDER_RATE', title: _('Dashboard.STAT_CARD_TITLE.SUCCESSFUL_ORDER_RATE'), svg: 'shopping-bag.svg', isPercentage: true},
        {key: 'EMPLOYEE_COUNT', title: _('Dashboard.STAT_CARD_TITLE.EMPLOYEE_COUNT'), svg: 'employee.svg'},
        {key: 'IPN_COUNT', title: _('Dashboard.STAT_CARD_TITLE.IPN_COUNT'), svg: 'alarm-bell.svg'},
        {key: 'API_KEY_COUNT', title: _('Dashboard.STAT_CARD_TITLE.API_KEY_COUNT'), svg: 'api-key.svg'},
    ], [_]);

    const fetchGraphStats = async (type: TDashboardGraphStatType, range: TDashboardGraphStatRange) => {
        const fn = id ? () => graphStatsFromAdmin(id, type, range) : () => graphStats(type, range);
        return await fn();
    };

    const fetchGeneralStats = async () => {
        const fn = id ? () => generalStatsFromAdmin(id) : () => generalStats();
        return await fn();
    };

    return <Dashboard chartCards={chartCards} fetchGraphStats={fetchGraphStats} statCards={statCards} fetchGeneralStats={fetchGeneralStats} />;

};

export default MerchantDashboard;