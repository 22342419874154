import {useCallback, useState} from "react";


const useCopyToClipboard = (): [(text: string) => void, boolean] => {
    const [isCopied, setIsCopied] = useState(false);

    const copy = useCallback((text: string) => {
        if (!navigator.clipboard) return console.error('Tarayıcı desteklenmiyor.');
        if (!text) return console.error('Kopyalanacak metin bulunamadı.');
        if (isCopied) return console.error('Zaten kopyalandı.');
        try {
            const el = document.createElement('textarea');
            el.value = text;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 3000);
        } catch (error) {
            setIsCopied(false);
            console.error('Kopyalama hatası:', error);
        }
    }, [isCopied]);

    return [copy, isCopied];
};

export default useCopyToClipboard;