import {IDataTableHead} from "@/app/interfaces";

export const ApiLogsTableHead: IDataTableHead[] = [
    {
        text: 'ApiLogsTH.CREATED_AT',
        name: "createdAt",
        isSortable: true,
        align: "left",
        width: "100px"
    },
    {
        text: 'ApiLogsTH.IP_ADDRESS',
        name: "ip",
        isSortable: false,
        align: "left",
        width: "150px",
    },
    {
        text: 'ApiLogsTH.RESULT',
        name: "isSuccess",
        isSortable: false,
        align: "center",
        width: "100px",
    },
    {
        text: 'ApiLogsTH.VIEW',
        width: "100px",
        align: "end"
    },

];