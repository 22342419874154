import React, {FC} from 'react';
import {IStepProps} from "../ForgotPassword";
import {toAbsoluteUrl} from "@/_metronic/helpers";
import Submit from "../../buttons/Submit";
import {useTranslation} from '@/app/modules/hooks/useTranslation';


interface Props extends IStepProps {
    closeModal: () => void;
}

const Success: FC<Props> = (props) => {
    const _ = useTranslation();

    return (
        <div className={props.current ? "current" : ""} data-kt-stepper-element='content'>
            <div className='w-100 text-center'>
                <img src={toAbsoluteUrl("/media/svg/custom/check.svg")} className={"h-75px mb-5"} alt=""/>
                <h3 className="text-dark fw-bold fs-3 mb-5">
                    {_('Success.PASSWORD_CHANGED')}
                </h3>
                <div className="text-muted fw-semibold mb-10">
                    {_('Success.INSTRUCTION')}
                </div>



                <div className="d-flex flex-center">
                    <Submit loading={false} label={_('Success.LOGIN')} disabled={false} onClick={props.closeModal}/>

                </div>
            </div>
        </div>
    );
};

export default Success;