import {IApiKey} from '@/app/interfaces';
import {faker} from '@faker-js/faker/locale/tr';

export const getDummyApiKeys = (count: number): IApiKey[] => {
    faker.seed(100);
    return Array.from({length: count}).map((_, index) => {
        return {
            id: index + 1,
            createdAt: faker.date.past(),
            name: faker.lorem.sentence({min: 2, max: 5}),
            apiKey: faker.string.alphanumeric(128),
            status: faker.datatype.boolean(),
        };
    });
};