import React, {FC, useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import {useThemeMode} from '@/_metronic/partials';
import {toAbsoluteUrl} from '@/_metronic/helpers';

interface Props {

}

const BODY_CLASSES = ['bgi-size-cover', 'bgi-position-center', 'bgi-no-repeat'];
const PaymentsLayout: FC<Props> = (props) => {
    const {mode} = useThemeMode();
    useEffect(() => {
        BODY_CLASSES.forEach((c) => document.body.classList.add(c));
        document.body.style.backgroundImage =
            mode === 'dark'
                ? `url(${toAbsoluteUrl('/media/auth/bg10-dark.jpeg')})`
                : `url(${toAbsoluteUrl('/media/auth/bg10.jpeg')})`;

        return () => {
            BODY_CLASSES.forEach((c) => document.body.classList.remove(c));
            document.body.style.backgroundImage = 'none';
        };
    }, [mode]);
    return (

        <div id="Payment">
            <Outlet />
        </div>
    );
};

export default PaymentsLayout;