import React, {FC, useCallback, useMemo} from 'react';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import TransactionFilters from '@/app/pages/merchant/wallet/TransactionHistory/TransactionFilters';
import Card from '@/app/modules/components/card/Card';
import {useDevice} from '@/app/modules/providers/DeviceProvider';
import Actions from '@/app/modules/components/Actions';
import clsx from 'clsx';
import {IMerchantTransactionFilters} from '@/app/interfaces/filters';
import useDatatable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {ITransaction} from '@/app/interfaces';
import {initialMerchantTransactionSort} from '@/app/utils/initialSorts';
import {initialMerchantTransactionFilters} from '@/app/utils/initialFilters';
import {TransactionsTableHead} from '@/app/utils/tableHeads/merchant/TransactionHistoryTableHead';
import dayjs from 'dayjs';
import Balance from '@/app/pages/merchant/wallet/tools/Balance';
import TransactionStatus from '@/app/pages/merchant/wallet/tools/TransactionStatus';
import {KTIcon} from '@/_metronic/helpers';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getMerchantTransactions} from '@/app/modules/auth/core/_requests';
import {getMerchantTransactionsFromAdmin} from '@/app/utils/requests/adminRequests';
import {generateRoute} from '@/app/modules/helpers';
import {Link} from 'react-router-dom';
import {amountWithCurrency} from '@/app/modules/helpers/getValuesFromId';
import {DateRangePicker} from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';

interface Props {
    id?: string;
}

interface IRangeFilter {
    [key: string]: {
        min?: string | number | null;
        max?: string | number | null;
    };
}

const TransactionHistory: FC<Props> = ({id}) => {
    const _ = useTranslation();

    const exportRecords = (type: 'json' | 'csv' | 'xml') => {
        alert('exporting ' + type);
    };

    const {isMobile} = useDevice();

    const getData: GetDataFunction<ITransaction, IMerchantTransactionFilters> = useCallback(async ({pageIndex, filters, pageSize, sort}) => {

        const rangeFilters: IRangeFilter = {
            createdAt: {
                min: filters.startDate ?? null,
                max: filters.endDate ?? null,
            },
            productTotal: {
                min: filters.minAmount ?? null,
                max: filters.maxAmount ?? null,
            },
        };

        const params = {
            sort,
            pageIndex,
            pageSize,
            orFilters: filters.searchQuery
                ? {
                    'id': filters.searchQuery,
                    'buyerEmail': filters.searchQuery,
                    'productName': filters.searchQuery,
                    'txHash': filters.searchQuery,
                    'paymentAddress': filters.searchQuery,
                }
                : undefined,
            rangeFilters,
            exactFilters: {
                ...(filters.type && {'status': filters.type}),
            },
        };
        let data;
        if (id) {

            const response = await getMerchantTransactionsFromAdmin(id, params);
            data = response.data;
        } else {
            const response = await getMerchantTransactions(params);
            data = response.data;
        }
        if (!data) return Promise.reject();
        return data;
    }, [id]);

    const {
        handleFilterSubmit,
        handleFilterReset,
        handleFilterChange,
        filters,
        DataTableComponent,
    } = useDatatable<ITransaction, IMerchantTransactionFilters>({
        initialSort: initialMerchantTransactionSort,
        initialFilters: initialMerchantTransactionFilters,
        getData,
        id: 'transactionHistory',
    });

    const refreshButton = useMemo(() => (
        <button onClick={handleFilterSubmit} className="btn btn-secondary btn-icon">
            <KTIcon iconName={'arrows-circle'} className={'fs-1 me-1'} />
        </button>
    ), [handleFilterSubmit]);

    return (
        <>
            <ToolbarWrapper onlyTitle={!!id} isMerchantSubMenu={!!id} title={_('TransactionHistory.TITLE')} additionalBreadcrumb={[{
                title: _('MyWallet.TITLE'),
                path: MerchantRouteConstants.MY_WALLET,
                isActive: true,
            }]}>
                <Actions title={<><KTIcon iconName={'download'} className={'fs-5 me-1'} /> {_('TransactionHistory.EXPORT')}</>} btnClass={clsx('btn-outline btn-outline-primary', {
                    'w-100 flex-center mb-3': isMobile,
                })} items={[
                    {
                        type: 'link',
                        label: 'JSON',
                        icon: 'bi bi-filetype-json',
                        onClick: () => exportRecords('json'),
                    },
                    {
                        type: 'link',
                        label: 'CSV',
                        icon: 'bi bi-filetype-csv',
                        onClick: () => exportRecords('csv'),
                    },
                    {
                        type: 'link',
                        label: 'XML',
                        icon: 'bi bi-filetype-xml',
                        onClick: () => exportRecords('xml'),
                    },
                ]} mobilePlacement={'bottom'} />
            </ToolbarWrapper>
            <TransactionFilters handleFilterSubmit={handleFilterSubmit} handleFilterReset={handleFilterReset} handleFilterChange={handleFilterChange} filters={filters} />
            <Card title={_('TransactionHistory.TITLE')} subTitle={_('TransactionHistory.SUBTITLE')} className={'mt-10'} headerButton={refreshButton}>
                <DataTableComponent head={TransactionsTableHead} renderRow={(transaction: ITransaction) => {
                    return (
                        <tr key={transaction.id}>
                            <td><Link to={id ? transaction.id : generateRoute(MerchantRouteConstants.TRANSACTION_DETAIL, {id: transaction.id})} className="text-gray-800 fw-bold fs-6">{transaction.id}</Link></td>
                            <td>
                                <div className="badge badge-light fw-bold">{dayjs(transaction.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
                            </td>
                            {/*<td><span>{transaction.buyerEmail}</span></td>*/}
                            <td className="">
                                {transaction.productTotalFiat && (
                                    <>
                                        <span className="text-dark fw-bold fs-6 amount">{amountWithCurrency(transaction.estimatedPaidAmountFiat || '0.00', transaction.productCurrency)}</span>
                                        <span className="px-1">/</span>
                                        <span className="text-dark fw-light fs-6 amount">{amountWithCurrency(transaction.productTotalFiat, transaction.productCurrency)}</span>
                                    </>
                                )}
                            </td>
                            <td className="">
                                {transaction.productTotal && (
                                    <>
                                        <span className="text-dark fw-bold fs-6 amount">{amountWithCurrency(transaction.estimatedPaidAmount || '0.00', 'USD')}</span>
                                        <span className="px-1">/</span>
                                        <span className="text-dark fw-light fs-6 amount">{amountWithCurrency(transaction.productTotal, 'USD')}</span>
                                    </>
                                )}
                            </td>
                            <td><Balance amount={transaction.paidAmountAsCoin} code={transaction.coinSymbol} isTestNetwork={transaction.isTestNetwork} /></td>


                            <td className="text-center"><TransactionStatus status={transaction.status} /></td>
                        </tr>
                    );
                }} />
            </Card>
        </>
    );
};

export default TransactionHistory;