import axios from 'axios';
import {
    IAdminConfigResponse,
    IApiKeyDetail,
    IBalance, ICheckWithdrawableResult,
    ICoinConfig,
    ICoinListItem, IConfirmWithdrawResponse, IDateRange,
    IGeneralStatsResponse,
    IGetMerchantResult,
    IGraphStatsResponse,
    IListCoin,
    IMerchantListItem,
    ISortState, IStartWithdrawResponse,
    IUpdateApiPayload,
    IUpdateCoinPayload,
    IUser, TDashboardGraphStatRange, TDashboardGraphStatType,
} from '@/app/interfaces';
import {CreatedTransactionModel, MerchantSettingsModel} from '@/app/modules/auth';
import {IReport} from '@/app/interfaces/report';

const API_URL = `${process.env.REACT_APP_API_URL}/admin`;

export interface IDataTableParams {
    pageIndex: number;
    pageSize: number;
    sort: ISortState;
    filters?: any;
    exactFilters?: any;
    orFilters?: any;
}

export async function getUsers(params: IDataTableParams) {
    return axios.get(`${API_URL}/user`, {
        params,
    });
}

export async function getAllMerchants() {
    return axios.get<IMerchantListItem[]>(`${API_URL}/merchant/all`);
}

export async function updateUser(userId: number, {email, fullName, phoneNumber, userType}: {email: string, fullName: string, phoneNumber: string, userType: string}) {
    return axios.patch(`${API_URL}/user/${userId}`, {
        fullName,
        email,
        phoneNumber,
        userType,
    });
}

export async function createUser({email, fullName, phoneNumber, userType}: {email: string, fullName: string, phoneNumber: string, userType: string}) {
    return axios.post(`${API_URL}/user/create`, {
        fullName,
        email,
        phoneNumber,
        userType,
    });
}

export async function deleteUser(userId: number) {
    return axios.delete(`${API_URL}/user/${userId}`);
}

export async function getUser(userId: number) {
    return axios.get<IUser>(`${API_URL}/user/${userId}`);
}

export async function getLoginHistory(userId: number, params: IDataTableParams) {
    return axios.get(`${API_URL}/user/${userId}/login-history`, {
        params,
    });
}

export async function getMerchants(params: IDataTableParams) {
    return axios.get(`${API_URL}/merchant`, {
        params,
    });
}

export async function getMerchant(merchantId: string) {
    return axios.get<IGetMerchantResult>(`${API_URL}/merchant/${merchantId}`);
}

export async function getTransactions(params: IDataTableParams) {
    return axios.get(`${API_URL}/transaction`, {
        params,
    });
}

export function getBalancesFromAdmin(merchantId: string) {
    return axios.get<IBalance[]>(`${API_URL}/wallet/${merchantId}`);
}

export function getWithdrawalHistoryFromAdmin(merchantId: string, params: IDataTableParams) {
    return axios.get(`${API_URL}/withdrawal`, {
        params: {
            ...params,
            merchantId,
        },
    });
}


export function getMerchantTransactionsFromAdmin(merchantId: string, params: IDataTableParams) {
    return axios.get(`${API_URL}/transaction/merchant`, {
        params: {
            ...params,
            merchantId,
        },
    });
}

export function getMerchantTransactionDetailFromAdmin({transactionId}: {transactionId: string}) {
    return axios.get(`${API_URL}/transaction/${transactionId}`);
}

export function createTransactionFromAdmin(merchantId: string, {name, count, price}: {
    name: string,
    count: number,
    price: number
}) {
    return axios.post<CreatedTransactionModel>(`${API_URL}/transaction`, {
        productName: name,
        productCount: count,
        productPrice: price.toString(),
    }, {
        params: {
            merchantId,
        },
    });
}

export function getMerchantFromAdmin(merchantId: string) {
    return axios.get<MerchantSettingsModel>(`${API_URL}/merchant/settings`, {
        params: {
            merchantId,
        },
    });
}


export function updateMerchantSettingsFromAdmin(merchantId: string, {merchantName, merchantUrl, merchantEmail, IPNUrl, IPNSecret, withdrawCallbackUrl}: {
    merchantName: string,
    merchantUrl: string,
    merchantEmail: string,
    IPNUrl: string,
    IPNSecret: string,
    withdrawCallbackUrl: string;
}) {
    return axios.patch(`${API_URL}/merchant`, {
        merchantName,
        merchantUrl,
        merchantEmail,
        IPNUrl,
        IPNSecret,
        withdrawCallbackUrl,
    }, {
        params: {
            merchantId,
        },
    });
}


export function getIpnHistoryFromAdmin(merchantId: string, params: IDataTableParams) {
    return axios.get(`${API_URL}/ipn`, {
        params: {
            ...params,
            merchantId,
        },
    });
}

export function resendIpnFromAdmin(merchantId: string, id: number) {
    return axios.post(`${API_URL}/ipn/resend/${id}`, {
        merchantId,
    });
}


export function getCoinConfigFromAdmin(merchantId: string) {
    return axios.get<ICoinConfig[]>(`${API_URL}/merchant/coin-config`, {
        params: {
            merchantId,
        },
    });
}

export function setCoinConfigBatchFromAdmin(merchantId: string, configs: {coinId: number, enabled: boolean, discountRate: number}[]) {
    return axios.patch(`${API_URL}/merchant/coin-config`, {
        coinConfig: configs,
    }, {
        params: {
            merchantId,
        },
    });
}


export function generateApiKeyFromAdmin(merchantId: string) {
    return axios.post(`${API_URL}/apiKey/generateKey`, null, {
        params: {
            merchantId,
        },
    });
}

export function getApiKeysFromAdmin(merchantId: string, params: IDataTableParams) {
    return axios.get(`${API_URL}/apiKey`, {
        params: {
            ...params,
            merchantId,
        },
    });
}

export function getApiKeyFromAdmin(merchantId: string, id: number) {
    return axios.get<IApiKeyDetail>(`${API_URL}/apiKey/${id}`, {
        params: {
            merchantId,
        },
    });
}

export function updateApiKeyFromAdmin(merchantId: string, id: number, payload: IUpdateApiPayload) {
    return axios.patch(`${API_URL}/apiKey/${id}`, payload, {
        params: {
            merchantId,
        },
    });
}


export function generalStatsFromAdmin(merchantId: string) {
    return axios.get<IGeneralStatsResponse>(`${API_URL}/statistics/${merchantId}`);
}

export function graphStatsFromAdmin(merchantId: string, type: TDashboardGraphStatType, range: TDashboardGraphStatRange) {
    return axios.get<IGraphStatsResponse>(`${API_URL}/statistics/graph/${merchantId}`, {
        params: {
            type,
            range,
        },
    });
}

export function getAdminStats() {
    return axios.get<IGeneralStatsResponse>(`${API_URL}/statistics`);
}

export function getAdminGraphStats(type: TDashboardGraphStatType, range: TDashboardGraphStatRange) {
    return axios.get<IGraphStatsResponse>(`${API_URL}/statistics/graph`, {
        params: {
            type,
            range,
        },
    });
}


export function getAdminConfig() {
    return axios.get<IAdminConfigResponse[]>(`${API_URL}/config`);
}

export function updateAdminConfig(configs: {configId: number, value: string}[]) {
    return axios.patch(`${API_URL}/config/update-all`, {
        configs,
    });
}

export function getCoinList(params: IDataTableParams) {
    return axios.get(`${API_URL}/coin-config`, {
        params: {
            ...params,
        },
    });
}

export function getCoin(coinId: number) {
    return axios.get<IListCoin>(`${API_URL}/coin-config/${coinId}`);
}

export function updateCoin(coinId: number, payload: IUpdateCoinPayload) {
    return axios.patch(`${API_URL}/coin-config/${coinId}`, payload);
}


export async function getBlockchainTransactions(params: IDataTableParams) {
    return axios.get(`${API_URL}/transaction/blockchain`, {
        params,
    });
}


export async function getAllCoins() {
    return axios.get<ICoinListItem[]>(`${API_URL}/coin-config/all`);
}

export async function getAllWithdrawals(params: IDataTableParams) {
    return axios.get(`${API_URL}/withdrawal/all`, {
        params,
    });
}

export async function getAdminWallets() {
    return axios.get(`${API_URL}/wallet/`);
}


export function checkWithdrawableFromAdmin(coinId: string) {
    return axios.get<ICheckWithdrawableResult>(`${API_URL}/withdrawal/checkWithdrawable/${coinId}`);
}


export function startWithdrawRequestFromAdmin({coinId, amount, address}: {coinId: number, amount: number, address: string}) {
    return axios.post<IStartWithdrawResponse>(`${API_URL}/withdrawal`, {
        coinId,
        amount,
        address,
    });
}


export function confirmWithdrawRequestFromAdmin({validationId, code}: {validationId: string, code: string}) {
    return axios.post<IConfirmWithdrawResponse>(`${API_URL}/withdrawal/confirm`, {
        validationId,
        code,
    });
}


export function cancelWithdrawRequestFromAdmin(withdrawId: number) {
    return axios.post(`${API_URL}/withdrawal/cancel`, {
        withdrawId,
    });
}

export function getTransactionDetail({transactionId}: {transactionId: string}) {
    return axios.get(`${API_URL}/transaction/${transactionId}`);
}

export function getAdminReport(dateRange: IDateRange) {
    return axios.get<IReport>(`${API_URL}/statistics/report`, {
        params: dateRange,
    });
}

export function getMerchantReport(dateRange: IDateRange, merchantId: string) {
    return axios.get<IReport>(`${API_URL}/statistics/report/${merchantId}`, {
        params: dateRange,
    });
}