/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItem} from './SidebarMenuItem';
import {merchantMenuItems} from '@/app/utils/merchantMenuItems';
import {useAuth} from '@/app/modules/auth';
import {adminMenuItems} from '@/app/utils/adminMenuItems';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {USER_TYPE} from '@/app/enums';
import {merchantExcludedPaths, superAdminExcludedPaths, superAdminExcludedSections, merchantExcludedSections} from '@/app/utils/excludedPaths';

interface MenuItems {
    type: string;
    title: string;
    to?: string;
    icon?: string;
}

const SidebarMenuMain = () => {
    const {currentUser} = useAuth();
    const _ = useTranslation();


    let menuItems: MenuItems[] = [];
    if (currentUser?.userType === USER_TYPE.SUPER_ADMIN || currentUser?.userType === USER_TYPE.ADMIN) {
        menuItems = adminMenuItems;
        if (currentUser?.userType === USER_TYPE.ADMIN) {
            menuItems = adminMenuItems.filter((item) => !item.to || !superAdminExcludedPaths.includes(item.to));
            menuItems = menuItems.filter((item) => item.title && !superAdminExcludedSections.includes(item.title));
        }
    } else {
        menuItems = merchantMenuItems;
        if (currentUser?.userType === USER_TYPE.EMPLOYEE) {
            menuItems = merchantMenuItems.filter((item) => !item.to || !merchantExcludedPaths.includes(item.to));
            menuItems = menuItems.filter((item) => item.title && !merchantExcludedSections.includes(item.title));
        }
    }

    return (
        <>
            {menuItems.map((menuItem, index) => {
                if (menuItem.type === 'section') {
                    return (
                        <div key={index} className="menu-item">
                            <div className="menu-content pt-8 pb-2">
                                <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                                    {_(menuItem.title)}
                                </span>
                            </div>
                        </div>
                    );
                }

                if (menuItem.type === 'item') {
                    if (!menuItem.to) return null;
                    return (
                        <SidebarMenuItem
                            key={index}
                            to={menuItem.to}
                            icon={menuItem.icon}
                            title={_(menuItem.title)}
                        />
                    );
                }

                return null;
            })}
        </>
    );
};

export {SidebarMenuMain};
