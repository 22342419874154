import {FC} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import StatCards from '@/app/pages/common/Dashboard/StatCards/StatCards';
import StatsWithChartCards from '@/app/pages/common/Dashboard/StatsWithChartCards/StatsWithChartCards';
import {IChartCard, IStatCard, TFetchGeneralStats, TFetchGraphStats} from '@/app/interfaces';


interface Props {
    chartCards: IChartCard[];
    fetchGraphStats: TFetchGraphStats;
    statCards: IStatCard[];
    fetchGeneralStats: TFetchGeneralStats;
}

const Dashboard: FC<Props> = ({chartCards, fetchGraphStats, statCards, fetchGeneralStats}) => {
    return (
        <>
            <ToolbarWrapper title={'Dashboard'} />

            <div className="row gy-5 gx-xl-10">

                <div className="col-xl-12 mb-5 mb-xl-10">
                    <div className="row g-5 g-xl-10">
                        <StatsWithChartCards fetchGraphStats={fetchGraphStats} cards={chartCards} />
                        <StatCards cards={statCards} fetchGeneralStats={fetchGeneralStats} />
                    </div>
                </div>


            </div>
        </>
    );
};

export default Dashboard;
