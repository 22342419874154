import React, {FC} from 'react';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import HugeRadioInput from '../../inputs/HugeRadioInput';
import Submit from '../../buttons/Submit';
import {IStepProps} from '../ForgotPassword';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props extends IStepProps {
}

const ResetTypeSelection: FC<Props> = (props) => {
    const _ = useTranslation();

    const inputs = [
        {
            value: 'email',
            iconClassName: 'send',
            label: _('ResetTypeSelection.EMAIL_VERIFICATION'),
            description: _('ResetTypeSelection.EMAIL_DESCRIPTION'),
        },
        {
            value: 'sms',
            iconClassName: 'message-text-2',
            label: _('ResetTypeSelection.SMS_VERIFICATION'),
            description: _('ResetTypeSelection.SMS_DESCRIPTION'),
        },
    ];

    const nextStep = () => {
        if (props.info.resetType === 'email') {
            props.stepChangeHandler('enterEmail');
        } else if (props.info.resetType === 'sms') {
            props.stepChangeHandler('enterSms');
        }
    };

    return (
        <div className={props.current ? 'current' : ''} data-kt-stepper-element="content">
            <div className="w-100">
                <div data-kt-element="options" className="text-center">
                    <img src={toAbsoluteUrl('/media/svg/custom/lock.svg')} alt="" className="mw-75px mb-5" />
                    <p className="text-muted fs-5 fw-semibold mb-10">
                        {_('ResetTypeSelection.INSTRUCTION')}
                    </p>

                    <div className="pb-10">
                        {inputs.map((input, index) => (
                            <HugeRadioInput
                                key={index}
                                checked={props.info.resetType === input.value}
                                name={'type'}
                                value={input.value}
                                iconClassName={input.iconClassName}
                                label={input.label}
                                description={input.description}
                                onChange={() => props.infoChangeHandler('resetType', input.value)}
                            />
                        ))}
                    </div>

                    <Submit loading={false} label={_('ResetTypeSelection.CONTINUE')} disabled={props.info.resetType === null} onClick={nextStep} />
                </div>
            </div>
        </div>
    );
};

export default ResetTypeSelection;