import React, {FC} from 'react';
import {IActionItem, TActionsMobilePlacement} from '@/app/interfaces';
import {KTIcon} from '@/_metronic/helpers';
import {Link} from 'react-router-dom';
import {useDevice} from '@/app/modules/providers/DeviceProvider';
import {useTranslation} from '@/app/modules/hooks/useTranslation';


interface Props {
    title?: string | JSX.Element;
    items: IActionItem[];
    mobilePlacement?: TActionsMobilePlacement;
    btnClass?: string;
}

const Actions: FC<Props> = ({btnClass = 'btn-light btn-active-light-primary', title, items, mobilePlacement}) => {
    const _ = useTranslation();

    const {isMobile} = useDevice();
    if (!title) title = _('Actions.TITLE');

    return (
        <>
            <button
                type="button"
                className={`btn btn-sm btn-flex btn-center ${btnClass}`}
                data-kt-menu-trigger="click"
                data-kt-menu-placement={isMobile ? mobilePlacement || 'bottom' : 'bottom-end'}
                data-kt-menu-flip="top-end"
            >
                {title}
                <KTIcon iconName={'down'} className={'fs-5 ms-1'} />
            </button>
            <div
                className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold w-200px pt-3 pb-3"
                data-kt-menu="true"
            >
                {items.map((item, index) => {
                    if (item.type === 'separator') {
                        return <div key={index} className="separator mt-3 mb-3 opacity-75"></div>;
                    }
                    if (item.onClick) {
                        return (
                            <div key={index} className="menu-item px-3">
                                <button className="menu-link px-3 border-0 w-100" onClick={(e) => {
                                    e.preventDefault();
                                    if (item.onClick) item.onClick();
                                }}>
                                    {item.icon && <i className={item.icon}></i>}
                                    {item.ktIconName && <KTIcon iconName={item.ktIconName} />}
                                    {item.label && _(item.label)}
                                </button>
                            </div>
                        );
                    }
                    return (
                        <div key={index} className="menu-item px-3">
                            <Link to={item.route || '#'} className="menu-link px-3">
                                {item.icon && <i className={item.icon}></i>}
                                {item.ktIconName && <KTIcon iconName={item.ktIconName} />}
                                {item.label && _(item.label)}
                            </Link>
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default Actions;