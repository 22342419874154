import React, {FC} from 'react';
import {IStepProps} from '../ForgotPassword';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import Submit from '../../buttons/Submit';
import {useFormik} from 'formik';
import Input from '../../inputs/Input';
import {sendPasswordResetWithEmail} from '@/app/modules/auth/core/_requests';
import FormikStatus from '../../FormikStatus';
import {enterEmailSchema} from '@/app/utils/yupSchema';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';


interface Props extends IStepProps {
}


const EnterEmail: FC<Props> = (props) => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);

    const [loading, setLoading] = React.useState<boolean>(false);

    const formik = useFormik({
        initialValues: {email: ''},
        validationSchema: enterEmailSchema(_),
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true);
            setStatus(undefined);
            try {
                const {data: response} = await sendPasswordResetWithEmail(values.email);

                if (!response.validationId) {
                    throw new Error(_('EnterEmail.EMAIL_NOT_REGISTERED'));
                }
                props.infoChangeHandler('validationId', response.validationId);
                props.infoChangeHandler('validationSource', response.validationSource);
                props.infoChangeHandler('email', values.email);
                props.stepChangeHandler('enterCode');
            } catch (error) {
                setStatus(getErrorMessage(error));
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    return (
        <form className={props.current ? 'current' : ''} data-kt-stepper-element="content"
              onSubmit={formik.handleSubmit}>
            <div className="w-100 text-center">
                <img src={toAbsoluteUrl('/media/svg/custom/email.svg')} className={'h-75px mb-5'} alt="" />
                <h3 className="text-dark fw-bold fs-3 mb-5">
                    {_('EnterEmail.RESET_WITH_EMAIL')}
                </h3>
                <div className="text-muted fw-semibold mb-10">
                    {_('EnterEmail.INSTRUCTION')}
                </div>
                <FormikStatus status={formik.status} marginBottom={5} />

                <Input placeholder={_('EnterEmail.EMAIL_PLACEHOLDER')} name={'email'} type={'email'} formik={formik}
                       className={'form-control-lg form-control-solid text-center'} />

                <div className="d-flex flex-center">
                    <button type="button" className="btn btn-light me-3"
                            onClick={() => props.stepChangeHandler('resetTypeSelection')}>
                        {_('EnterEmail.BACK')}
                    </button>
                    <Submit loading={loading} label={_('EnterEmail.SUBMIT_CODE')} disabled={false} />

                </div>

            </div>
        </form>
    );
};

export default EnterEmail;