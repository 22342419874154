import React, {FC} from 'react';
import {IStepProps} from '../ForgotPassword';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import Submit from '../../buttons/Submit';
import {useFormik} from 'formik';
import Input from '../../inputs/Input';
import {verifyPasswordResetCode} from '@/app/modules/auth/core/_requests';
import FormikStatus from '../../FormikStatus';
import {enterCodeSchema} from '@/app/utils/yupSchema';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';


interface Props extends IStepProps {
    type: 'email' | 'sms',
    validationId: string | null,
    validationSource: number | null,
}

const EnterCode: FC<Props> = (props) => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);

    const [loading, setLoading] = React.useState<boolean>(false);

    const formik = useFormik({
        initialValues: {code: ''},
        validationSchema: enterCodeSchema(_),
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true);
            setStatus(undefined);
            try {
                if (!props.validationId || !props.validationSource) throw new Error(_('EnterCode.ERROR_MESSAGE'));

                const {data: response} = await verifyPasswordResetCode({
                    id: props.validationId,
                    source: props.validationSource,
                    code: values.code,
                });

                if (response?.message !== 'VALIDATION_CODE_IS_VALID') throw new Error(_('EnterCode.INVALID_CODE'));

                props.infoChangeHandler('code', values.code);
                props.stepChangeHandler('newPassword');
            } catch (error) {
                setStatus(getErrorMessage(error));
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    const prevStep = props.type === 'email' ? 'enterEmail' : 'enterSms';

    return (
        <form className={props.current ? 'current' : ''} data-kt-stepper-element="content"
              onSubmit={formik.handleSubmit}>
            <div className="w-100 text-center">
                <img src={toAbsoluteUrl('/media/svg/custom/one-time-password.svg')} className={'h-75px mb-5'} alt="" />
                <h3 className="text-dark fw-bold fs-3 mb-5">
                    {_('EnterCode.CODE_SENT')}
                </h3>
                <div className="text-muted fw-semibold mb-10">
                    {_('EnterCode.INSTRUCTION', {type: props.type === 'email' ? _('EnterCode.EMAIL') : _('EnterCode.PHONE')})}
                    <br />
                    <small className={'text-primary'}>{_('EnterCode.SENT_CODE', {code: '123456'})}</small>
                </div>
                <FormikStatus status={formik.status} marginBottom={5} />

                <Input placeholder={_('EnterCode.RESET_CODE_PLACEHOLDER')} name={'code'} type={'number'} formik={formik}
                       className={'form-control-lg form-control-solid text-center'} />

                <div className="d-flex flex-center">
                    <button type="button" className="btn btn-light me-3"
                            onClick={() => props.stepChangeHandler(prevStep)}>
                        {_('EnterCode.BACK')}
                    </button>
                    <Submit loading={loading} label={_('EnterCode.SUBMIT_CODE')} disabled={false} />

                </div>

            </div>
        </form>
    );
};

export default EnterCode;