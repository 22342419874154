import React, {FC} from 'react';
import {IStepProps} from '../ForgotPassword';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import Submit from '../../buttons/Submit';
import {useFormik} from 'formik';
import Input from '../../inputs/Input';
import {resetPassword} from '@/app/modules/auth/core/_requests';
import FormikStatus from '../../FormikStatus';
import {forgotPasswordSchema} from '@/app/utils/yupSchema';
import {forgotPasswordInitialValues} from '@/app/utils/initialValues';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';


interface Props extends IStepProps {
}


const NewPassword: FC<Props> = (props) => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);


    const [loading, setLoading] = React.useState<boolean>(false);

    const formik = useFormik({
        initialValues: forgotPasswordInitialValues,
        validationSchema: forgotPasswordSchema(_),
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true);
            setStatus(undefined);
            try {
                if (!props.info.validationId || !props.info.validationSource || !props.info.code) throw new Error(_('NewPassword.ERROR_MESSAGE'));

                const {data: response} = await resetPassword({
                    code: props.info.code,
                    password: values.password,
                    validationId: props.info.validationId,
                    validationSource: props.info.validationSource,
                });

                if (response?.message !== 'PASSWORD_CHANGED_SUCCESSFULLY') throw new Error(_('NewPassword.ERROR_MESSAGE'));


                props.infoChangeHandler('newPassword', values.password);
                props.stepChangeHandler('success');
            } catch (error) {
                setStatus(getErrorMessage(error));
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    return (
        <form className={props.current ? 'current' : ''} data-kt-stepper-element="content"
              onSubmit={formik.handleSubmit}>
            <div className="w-100 text-center">
                <img src={toAbsoluteUrl('/media/svg/custom/password.svg')} className={'h-75px mb-5'} alt="" />
                <h3 className="text-dark fw-bold fs-3 mb-5">
                    {_('NewPassword.SET_NEW_PASSWORD')}
                </h3>
                <div className="text-muted fw-semibold mb-10">
                    {_('NewPassword.INSTRUCTION')}
                </div>
                <FormikStatus status={formik.status} marginBottom={5} />

                <Input label={_('NewPassword.PASSWORD')} name={'password'} type={'password'} formik={formik} passwordMeter={true} />
                <Input label={_('NewPassword.PASSWORD_REPEAT')} name={'passwordRepeat'} type={'password'} formik={formik} />
                <div className="d-flex flex-center">
                    <button type="button" className="btn btn-light me-3"
                            onClick={() => props.stepChangeHandler('enterCode')}>
                        {_('NewPassword.BACK')}
                    </button>
                    <Submit loading={loading} label={_('NewPassword.CHANGE_PASSWORD')} disabled={false} />

                </div>

            </div>
        </form>
    );
};

export default NewPassword;