import {IDataTableHead} from "@/app/interfaces";

export const MerchantListTableHead: IDataTableHead[] = [

    {
        text: 'MerchantListTH.MERCHANT',
        name: 'merchant',
        isSortable: true,
        align: 'left',
        width: '200px',
    },
    {
        text: 'MerchantListTH.EMAIL',
        name: 'email',
        isSortable: true,
        align: 'left',
        width: '200px',
    },
    {
        text: 'MerchantListTH.JOIN_DATE',
        name: 'createdAt',
        isSortable: true,
        align: 'center',
        width: '150px',
    },
    {
        text: 'MerchantListTH.STATUS',
        name: 'status',
        isSortable: true,
        align: 'center',
        width: '70px',
    },
    {
        text: "",
        width: "50px",
    },
]