import {ISortState} from '@/app/interfaces';

export const initialAdminTransactionSort: ISortState = {
    field: 'createdAt',
    direction: 'desc',
};

export const initialAdminMerchantListSort: ISortState = {
    field: 'createdAt',
    direction: 'desc',
};

export const initialAdminUserListSort: ISortState = {
    field: 'createdAt',
    direction: 'desc',
};
export const initialEmployeeListSort: ISortState = {
    field: 'createdAt',
    direction: 'desc',
};

export const initialAdminUserLoginLogSort: ISortState = {
    field: 'createdAt',
    direction: 'desc',
};

export const initialMerchantTransactionSort: ISortState = {
    field: 'createdAt',
    direction: 'desc',
};
export const initialMerchantApiKeyListSort: ISortState = {
    field: 'createdAt',
    direction: 'desc',
};
export const initialMerchantIpnHistoryListSort: ISortState = {
    field: 'sentAt',
    direction: 'desc',
};
export const initialMerchantApiLogListSort: ISortState = {
    field: 'createdAt',
    direction: 'desc',
};


export const initialMerchantWithdrawalHistoryListSort: ISortState = {
    field: 'createdAt',
    direction: 'desc',
};


export const initialCoinListSort: ISortState = {
    field: 'createdAt',
    direction: 'asc',
};


export const initialBlockchainTransactionsSort: ISortState = {
    field: 'createdAt',
    direction: 'desc',
};


export const initialAllWithdrawalSort: ISortState = {
    field: 'createdAt',
    direction: 'desc',
};