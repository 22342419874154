import React, {FC, useEffect} from 'react';
import {KTIcon} from '@/_metronic/helpers';
import {Modal} from 'react-bootstrap';
import {useFormik} from 'formik';
import FormikStatus from '@/app/modules/auth/components/tools/FormikStatus';
import Input from '@/app/modules/auth/components/tools/inputs/Input';
import Submit from '@/app/modules/auth/components/tools/buttons/Submit';
import {createUpdateEmployeeInitialValues} from '@/app/utils/initialValues';
import {ICreateUpdateEmployeeValues} from '@/app/interfaces/values';
import {createUpdateEmployeeSchema} from '@/app/utils/yupSchema';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';

interface Props {
    show: boolean;
    handleClose: () => void;
    handleSubmit: (values: ICreateUpdateEmployeeValues) => Promise<void>;
    currentValues?: null | ICreateUpdateEmployeeValues;
}

const CreateUpdateEmployeeModal: FC<Props> = ({show, handleClose, handleSubmit, currentValues}) => {

    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const [loading, setLoading] = React.useState<boolean>(false);

    const formik = useFormik({
        initialValues: createUpdateEmployeeInitialValues,
        validationSchema: createUpdateEmployeeSchema(_),
        onSubmit: async (values: ICreateUpdateEmployeeValues, {setStatus, setSubmitting}) => {
            setLoading(true);
            try {
                await handleSubmit(values);
                handleClose();
                if (!currentValues) {
                    formik.resetForm();
                }

            } catch (error) {
                setStatus(getErrorMessage(error));
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        if (currentValues) {
            formik.setValues(currentValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentValues, formik.setValues]);

    return (
        // <div>test</div>
        <Modal
            id="kt_modal_create_user"
            tabIndex={-1}
            aria-hidden="true"
            dialogClassName="modal-dialog modal-dialog-centered mw-650px"
            show={show}
            onHide={handleClose}
        >
            <div className="modal-header">
                <h2>{currentValues ? _('CreateUpdateUserModal.UPDATE_TITLE') : _('CreateUpdateUserModal.TITLE')}</h2>
                <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
                    <KTIcon className="fs-1" iconName="cross" />
                </div>
            </div>
            <div className="modal-body scroll-y pt-10 pb-15 px-lg-17">
                <form
                    className="form w-100"
                    noValidate
                    onSubmit={formik.handleSubmit}
                >
                    <FormikStatus status={formik.status} />

                    <Input label={_('CreateUpdateUserModal.FULL_NAME')} name={'fullName'} type={'text'} formik={formik} />
                    <Input label={_('CreateUpdateUserModal.EMAIL')} name={'email'} type={'email'} formik={formik} />
                    <Input label={_('CreateUpdateUserModal.PHONE_NUMBER')} name={'phoneNumber'} type={'phoneNumber'} formik={formik} />
                    <Input label={_('CreateUpdateUserModal.PASSWORD')} name={'password'} type={'password'} formik={formik} />

                    <div className="d-flex justify-content-center gap-5">
                        <Submit disabled={formik.isSubmitting || !formik.isValid} loading={loading} label={currentValues ? _('CreateUpdateUserModal.UPDATE') : _('CreateUpdateUserModal.CREATE_USER')} className="mb-5 flex-grow-1 " />
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default CreateUpdateEmployeeModal;