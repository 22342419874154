import React, {FC} from 'react';
import {Dropdown} from "react-bootstrap";
import {KTIcon} from "@/_metronic/helpers";
import clsx from "clsx";
import {ThemeModeType, useThemeMode} from "@/_metronic/partials";
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {

}

const ThemeSwitcher: FC<Props> = (props) => {
    const _ = useTranslation();

    const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode();

    const switchMode = (mode: ThemeModeType) => {
        updateMenuMode(mode)
        updateMode(mode)
    }

    const modes: {
        name: string,
        icon: string,
        mode: ThemeModeType
    }[] = [
        {
            name: _('ThemeSwitcher.LIGHT'),
            icon: 'night-day',
            mode: 'light'
        },
        {
            name: _('ThemeSwitcher.DARK'),
            icon: 'moon',
            mode: 'dark'
        },
        {
            name: _('ThemeSwitcher.SYSTEM'),
            icon: 'screen',
            mode: 'system'
        }
    ];

    return (
        <Dropdown>
            <Dropdown.Toggle variant={"link"} as={"a"}
                             bsPrefix={"btn btn-icon btn-active-light-primary btn-custom"}>
                {mode === 'light' ? (
                    <KTIcon iconName='moon' className='fs-2x'/>
                ) : (
                    <KTIcon iconName='night-day' className='fs-2x'/>
                )}
            </Dropdown.Toggle>
            <Dropdown.Menu align={"end"} className={"menu-active-bg"}>
                {modes.map((mode, index) => (
                    <Dropdown.Item bsPrefix={"menu-item px-3 my-0"} key={index} as={"div"}>
                        <button
                            className={clsx('menu-link px-3 py-2 w-100 border-0 bg-transparent', {active: menuMode === mode.mode})}
                            onClick={() => switchMode(mode.mode)}
                        >
                            <span className='menu-icon' data-kt-element='icon'>
                                <KTIcon iconName={mode.icon} className='fs-1'/>
                            </span>
                            <span className='menu-title'>{mode.name}</span>
                        </button>
                    </Dropdown.Item>
                ))}

            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ThemeSwitcher;