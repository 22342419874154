import React, {FC} from 'react';

interface Props {
    amount: string;
    className?: string;
}

const UsdValue: FC<Props> = ({amount, className}) => {
    if (!amount) amount = '0.00';

    return (
        <span className={`text-dark d-block ${className ? className : 'fw-bold mb-1 fs-6'}`}>
            ${parseFloat(amount).toFixed(2)}
        </span>
    );
};

export default UsdValue;