import {toAbsoluteUrl} from '@/_metronic/helpers';

interface CoinUnitProps {
    symbol: string,
    isTestNetwork: boolean
}

interface CoinSymbolProps extends CoinUnitProps {
    subSymbol: string | null,
}

export const getCoinSymbol = ({symbol, subSymbol, isTestNetwork}: CoinSymbolProps) => {
    let formattedSymbol = symbol;
    if (symbol === 'USDT' && subSymbol) {
        formattedSymbol += ` (${subSymbol})`;
    }

    if (isTestNetwork) {
        return `${formattedSymbol} T`;
    }
    return formattedSymbol;
};

export const getCoinUnit = ({symbol, isTestNetwork}: CoinUnitProps) => {
    if (isTestNetwork) {
        return `${symbol}-T`;
    }
    return symbol;
};

export const getCoinLogo = (symbol: string) => {
    return toAbsoluteUrl(`/custom/coins/${symbol.toLowerCase()}.svg`);
};