import React, {FC, useCallback} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {IAdminWithdrawal} from '@/app/interfaces';
import Card from '@/app/modules/components/card/Card';
import dayjs from 'dayjs';
import Balance from '@/app/pages/merchant/wallet/tools/Balance';
import useDataTable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {IAdminWithdrawalFilters} from '@/app/interfaces/filters';
import {initialAllWithdrawalFilters} from '@/app/utils/initialFilters';
import {initialAllWithdrawalSort} from '@/app/utils/initialSorts';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {cancelWithdrawRequestFromAdmin, getAllWithdrawals} from '@/app/utils/requests/adminRequests';
import {getCoinSymbol} from '@/app/modules/helpers/getCoinSymbol';
import {AllWithdrawalsTableHead} from '@/app/utils/tableHeads/admin/AllWithdrawalsTableHead';
import WithdrawStatus from '@/app/pages/merchant/wallet/tools/WithdrawStatus';
import Badge from '@/app/modules/components/Badge';
import WithdrawalListFilters from '@/app/pages/admin/withdrawal/withdrawalList/WithdrawalListFilters';
import {toast} from 'react-toastify';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import TxHash from '@/app/modules/components/TxHash';


const WithdrawalList: FC = () => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const getData: GetDataFunction<IAdminWithdrawal, IAdminWithdrawalFilters> = useCallback(async ({pageIndex, filters, pageSize, sort}) => {
        const params = {
            sort,
            pageIndex,
            pageSize,
            orFilters: filters.searchQuery
                ? {
                    'blockchainTransaction.txHash': filters.searchQuery,
                }
                : undefined,
            exactFilters: {
                ...(filters.coin && {'coin.id': filters.coin}),
                ...(filters.status && {'status': filters.status}),
                ...(filters.merchant && {'merchant.id': filters.merchant}),
                ...(filters.isAdminWithdraw && {'isAdminWithdraw': filters.isAdminWithdraw}),
            },
        };
        const {data} = await getAllWithdrawals(params);
        if (!data) return Promise.reject();
        return data;

    }, []);
    const {
        handleFilterSubmit,
        handleFilterReset,
        handleFilterChange,
        filters,
        DataTableComponent,
        getDataCallback,
    } = useDataTable<IAdminWithdrawal, IAdminWithdrawalFilters>({
        initialSort: initialAllWithdrawalSort,
        initialFilters: initialAllWithdrawalFilters,
        getData,
        id: 'allWithdrawalList',
    });


    const cancelWithdrawal = useCallback(async (id: number) => {
        try {
            await cancelWithdrawRequestFromAdmin(id);
            await getDataCallback();
        } catch (error: any) {
            toast.error(getErrorMessage(error));
        }
    }, [getDataCallback, getErrorMessage]);

    return (
        <>
            <ToolbarWrapper title={_('WithdrawalList.TITLE')} />

            <Card>
                <WithdrawalListFilters handleFilterSubmit={handleFilterSubmit} handleFilterReset={handleFilterReset} handleFilterChange={handleFilterChange} filters={filters} />
                <DataTableComponent
                    head={AllWithdrawalsTableHead}
                    className={'gy-5'}
                    renderRow={(withdrawal: IAdminWithdrawal) => {
                        return (
                            <tr key={`allWithdrawal_${withdrawal.id}`}>
                                <td>
                                    <div className="badge badge-light fw-bold">{dayjs(withdrawal.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
                                </td>
                                <td>
                                    {withdrawal.txHash ? (
                                        <TxHash txHash={withdrawal.txHash} urlFormat={withdrawal.txDetailUrlFormat} />
                                    ) : (
                                        <span className="text-gray-800 fw-bold fs-6">-</span>
                                    )}
                                </td>
                                <td className="text-center"><WithdrawStatus status={withdrawal.status} /></td>
                                <td className="text-center">{withdrawal.merchantName || '-'}</td>
                                <td className="text-center">{withdrawal.userFullName || '-'}</td>
                                <td className="text-center">
                                    {withdrawal.isAdminWithdraw ? (
                                        <Badge text={_('General.YES')} isLight={true} type={'success'} size={'sm'} />
                                    ) : (
                                        <Badge text={_('General.NO')} isLight={true} type={'danger'} size={'sm'} />
                                    )}
                                </td>
                                <td><Balance amount={withdrawal.amount} code={withdrawal.coinSymbol} isTestNetwork={withdrawal.isTestNetwork} /></td>
                                <td className="text-center">
                                    {getCoinSymbol({
                                        symbol: withdrawal.coinSymbol,
                                        subSymbol: withdrawal.coinSubSymbol,
                                        isTestNetwork: withdrawal.isTestNetwork,
                                    })}
                                </td>
                                <td>
                                    {withdrawal.status === 'beingVerified' && (
                                        <button onClick={() => cancelWithdrawal(withdrawal.id)} className="btn btn-sm btn-light-danger px-6">
                                            {_('WithdrawalHistory.CANCEL')}
                                        </button>
                                    )}
                                </td>
                            </tr>
                        );
                    }}
                />
            </Card>
        </>
    );
};

export default WithdrawalList;