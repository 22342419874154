/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import {FC, useMemo, useRef} from 'react';
import {toAbsoluteUrl} from '../../../helpers';
import {useLang, Lang} from '../../../i18n/Metronici18n';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface ILanguage {
    lang: Lang,
    name: string,
    flag: string,
}

export const languages: ILanguage[] = [
    {
        lang: Lang.tr,
        name: 'Türkçe',
        flag: toAbsoluteUrl('/media/flags/turkey.svg'),
    }, {
        lang: Lang.en,
        name: 'English',
        flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
];

const Languages: FC = () => {
    const _ = useTranslation();
    const {selectedLang: lang, setLang} = useLang();
    const currentLanguage = languages.find((x) => x.lang === lang);
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    const menuPlacement = useMemo(() => {
        const [width] = windowSize.current;
        if (width < 1024) {
            return 'bottom';
        }
        return 'left-start';
    }, []);

    return (
        <div
            className="menu-item px-5"
            data-kt-menu-trigger="hover"
            data-kt-menu-placement={menuPlacement}
            data-kt-menu-flip="bottom"
        >
            <a href="#" className="menu-link px-5">
                <span className="menu-title position-relative">
                    {_('General.LANGUAGE')}
                    <span className="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">
                        {currentLanguage?.name}{' '}
                        <img
                            className="w-15px h-15px rounded-1 ms-2"
                            src={currentLanguage?.flag}
                            alt="metronic"
                        />
                    </span>
                </span>
            </a>

            <div className="menu-sub menu-sub-dropdown w-175px py-4">
                {languages.map((l) => (
                    <div
                        className="menu-item px-3"
                        key={l.lang}
                        onClick={() => {
                            setLang(l.lang);
                        }}
                    >
                        <a
                            href="#"
                            className={clsx('menu-link d-flex px-5', {active: l.lang === currentLanguage?.lang})}
                        >
                            <span className="symbol symbol-20px me-4">
                                <img className="rounded-1" src={l.flag} alt="metronic" />
                            </span>
                            {l.name}
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

export {Languages};
