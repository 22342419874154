import React, {FC, useCallback, useEffect, useRef} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import FormCard from '@/app/modules/components/form-card/FormCard';
import {toast} from 'react-toastify';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import Buttons from '@/app/modules/components/Buttons';
import {useLoading} from '@/app/providers/LoadingProvider';
import {getMerchant, updateMerchantSettings} from '@/app/modules/auth/core/_requests';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import Input from '@/app/modules/auth/components/tools/inputs/Input';
import {useFormik} from 'formik';
import {IMerchantSettingsValues} from '@/app/interfaces/values';
import {merchantSettingsSchema} from '@/app/utils/yupSchema';
import {merchantSettingsInitialValues} from '@/app/utils/initialValues';
import Submit from '@/app/modules/auth/components/tools/buttons/Submit';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import {getMerchantFromAdmin, updateMerchantSettingsFromAdmin} from '@/app/utils/requests/adminRequests';

interface Props {
    id?: string;
}

const MerchantSettings: FC<Props> = ({id}) => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const {isLoading, setIsLoading} = useLoading();
    const formik = useFormik<IMerchantSettingsValues>({
        initialValues: merchantSettingsInitialValues,
        validationSchema: merchantSettingsSchema(_),
        onSubmit: async (values, {setSubmitting}) => {
            try {
                const payload = {
                    merchantName: values.merchantName,
                    merchantEmail: values.merchantEmail,
                    merchantUrl: values.merchantUrl,
                    IPNUrl: values.ipnUrl,
                    withdrawCallbackUrl: values.withdrawCallbackUrl,
                    IPNSecret: values.ipnSecret,
                };
                let status = 0;
                if (id) {
                    status = (await updateMerchantSettingsFromAdmin(id, payload)).status;
                } else {
                    status = (await updateMerchantSettings(payload)).status;
                }
                if (status === 200) {
                    toast.success(_('MerchantSettings.SETTINGS_UPDATED_SUCCESSFULLY'));
                }
            } catch (error: any) {
                toast.error(getErrorMessage(error));
            } finally {
                setSubmitting(false);
            }
        },
    });

    const flag = useRef(true);
    useEffect(() => {
        if (flag.current) {
            flag.current = false;
            setIsLoading(true);

            const fetchMerchant = id ? () => getMerchantFromAdmin(id) : getMerchant;
            fetchMerchant().then(({data: merchant}) => {
                formik.setValues({
                    id: merchant.id,
                    merchantName: merchant.merchantName,
                    merchantEmail: merchant.merchantEmail,
                    merchantUrl: merchant.merchantUrl,
                    ipnUrl: merchant.ipnUrl ? merchant.ipnUrl : '',
                    ipnSecret: merchant.ipnSecret ? merchant.ipnSecret : '',
                    withdrawCallbackUrl: merchant.withdrawCallbackUrl ? merchant.withdrawCallbackUrl : '',
                });
            }).catch((err) => {
                getErrorMessage(err);
            }).finally(() => {
                setIsLoading(false);
            });
        }
    }, [flag, setIsLoading, formik, id, getErrorMessage]);

    const inputComponent = useCallback((props: {name: string, type: string, label: string, description: string, disabled?: boolean}) => {
        return <Input
            className="form-control-lg form-control-solid"
            name={props.name}
            type={props.type}
            formik={formik}
            labelSize={3}
            label={_(props.label)}
            placeholder=""
            isRow
            description={_(props.description)}
            disabled={props.disabled} />;
    }, [_, formik]);

    return (
        <>
            <ToolbarWrapper title={_('MerchantSettings.TITLE')} onlyTitle={!!id} isMerchantSubMenu={!!id} />

            <form onSubmit={formik.handleSubmit} className="form" noValidate>
                <div className={'d-flex flex-column gap-7 gap-lg-10 merchantSettings'}>
                    <FormCard title={_('MerchantSettings.MERCHANT_INFO')}>
                        {inputComponent({name: 'id', type: 'text', label: 'MerchantSettings.MERCHANT_ID', description: 'MerchantSettings.MERCHANT_ID_DESC', disabled: true})}
                    </FormCard>
                    <FormCard title={_('MerchantSettings.SALES_PAGE_INFO')}>
                        {inputComponent({name: 'merchantName', type: 'text', label: 'MerchantSettings.COMPANY_NAME', description: 'MerchantSettings.COMPANY_NAME_DESC'})}
                        {inputComponent({name: 'merchantEmail', type: 'email', label: 'MerchantSettings.COMPANY_EMAIL', description: 'MerchantSettings.COMPANY_EMAIL_DESC'})}
                        {inputComponent({name: 'merchantUrl', type: 'url', label: 'MerchantSettings.COMPANY_URL', description: 'MerchantSettings.COMPANY_URL_DESC'})}
                    </FormCard>
                    <FormCard title={'IPN'}>
                        {inputComponent({name: 'ipnSecret', type: 'text', label: 'MerchantSettings.IPN_SECRET_KEY', description: 'MerchantSettings.IPN_SECRET_KEY_DESC'})}
                        {inputComponent({name: 'ipnUrl', type: 'url', label: 'MerchantSettings.IPN_URL', description: 'MerchantSettings.IPN_URL_DESC'})}
                        {inputComponent({name: 'withdrawCallbackUrl', type: 'url', label: 'MerchantSettings.IPN_URL_WITHDRAW', description: 'MerchantSettings.IPN_URL_DESC'})}
                    </FormCard>
                    <Buttons>
                        <a href={MerchantRouteConstants.DASHBOARD} className="btn btn-light">
                            {_('General.CANCEL')}
                        </a>

                        <Submit disabled={formik.isSubmitting || !formik.isValid} loading={isLoading} label={_('General.SAVE_CHANGES')} />

                    </Buttons>
                </div>
            </form>
        </>
    );
};

export default MerchantSettings;