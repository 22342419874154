import React, {FC, FormEvent, useEffect, useRef, useState} from 'react';
import {KTIcon} from '@/_metronic/helpers';
import CoinSelection from '@/app/pages/payment/basket/CoinSelection';
import Header from '@/app/pages/payment/common/Header';
import BasketCard from '@/app/pages/payment/common/BasketCard';
import Table from '@/app/pages/payment/common/Table';
import {getTransaction, transactionPost} from '@/app/modules/auth/core/_requests';
import {AvailableCoin} from '@/app/modules/auth';
import {capitalizeFirstLetter, getCoinSvg} from '@/app/modules/helpers';
import clsx from 'clsx';
import Timeout from '@/app/pages/payment/pay/Timeout';
import {toast} from 'react-toastify';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    id: string;
    redirectToPay: () => void;
}

interface ITransaction {
    merchantName: string;
    merchantEmail: string;
    merchantWebsite: string;
    productName: string;
    productPrice: string;
    productCount: number;
    productTotal: string;
    email: string;
}

const Basket: FC<Props> = ({id, redirectToPay}) => {
    const _ = useTranslation();

    const [selectedCoinId, setSelectedCoinId] = useState<null | number>(null);
    const [selectedCoin, setSelectedCoin] = useState<null | string>(null);
    const [availableCoins, setAvailableCoins] = useState<AvailableCoin[]>([]);
    const [endTime, setEndTime] = useState<Date | undefined>(undefined);
    const emailRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isActive, setIsActive] = useState<boolean>(true);

    const [transaction, setTransaction] = useState<ITransaction>({
        merchantName: '',
        merchantEmail: '',
        merchantWebsite: '',
        productName: '',
        productPrice: '',
        productCount: 0,
        productTotal: '',
        email: '',
    });

    const onTimeout = () => {
        setIsActive(false);
    };
    useEffect(() => {
        if (!id) return;

        getTransaction(id).then((res) => {
            if (!res.data) return;
            const info = res.data;

            if (info.paymentAddress) {
                redirectToPay();
                return;
            }

            setTransaction({
                merchantName: info.merchantName || '',
                merchantEmail: info.merchantEmail || '',
                merchantWebsite: info.merchantUrl || '',
                productName: info.productName,
                productPrice: info.productPrice,
                productCount: info.productCount,
                productTotal: info.productTotal,
                email: info.email,
            });
            setAvailableCoins(info.availableCoins);
            const createdAtDate = new Date(info.createdAt);
            const timeoutInMillis = info.timeoutAsSecond * 1000;
            const endTime = new Date(createdAtDate.getTime() + timeoutInMillis);

            setEndTime(endTime);
        });

    }, [id, redirectToPay]);


    const handleSelectCoin = (coinId: number) => {
        setSelectedCoinId(coinId);
        const coin = availableCoins.find((coin) => coin.id === coinId);
        setSelectedCoin(coin?.symbol || null);

        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        });
    };

    const submit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!selectedCoin || !selectedCoinId) return;

        const email = emailRef.current?.value;
        if (!email) return;

        if (!id) return;

        setLoading(true);


        transactionPost({
            id,
            email,
            coinId: selectedCoinId,
        }).then((res) => {
            if (!res.data.id) {
                throw new Error('Transaction failed');
            }
            redirectToPay();

        }).catch((err) => {
            toast.error('Transaction failed');
        }).finally(() => {
            setLoading(false);
        });

    };

    if (transaction.productCount === 0) return <h1 className={'text-center'}>Loading...</h1>;

    return (
        <form onSubmit={submit}>
            <Header endTime={endTime} onTimerEnd={onTimeout} />
            <div className="basketCards">
                <div className="first">
                    <BasketCard title={_('Basket.MERCHANT_INFO')} icon={'store.svg'}>
                        <Table fields={[
                            {
                                key: _('Basket.MERCHANT'),
                                value: transaction.merchantName,
                                iconName: 'badge',
                            },
                            {
                                key: _('Basket.EMAIL'),
                                value: transaction.merchantEmail,
                                iconName: 'send',
                                url: `mailto:${transaction.merchantEmail}`,
                            },
                            {
                                key: _('Basket.WEBSITE'),
                                value: capitalizeFirstLetter(transaction.merchantWebsite.replace(/(^\w+:|^)\/\//, '')),
                                iconName: 'fasten',
                                url: transaction.merchantWebsite,
                            },
                        ]} />
                    </BasketCard>
                    <BasketCard title={_('Basket.PRODUCT_INFO')} icon={'brand-identity.svg'}>
                        <Table fields={[
                            {
                                key: _('Basket.PRODUCT_NAME'),
                                value: `${transaction.productName} x ${transaction.productCount}`,
                                iconName: 'text-align-justify-center',
                            },
                            {
                                key: _('Basket.UNIT_PRICE'),
                                // value: "$500,00",
                                value: `$${transaction.productPrice}`,
                                iconName: 'tag',
                            },
                            {
                                key: _('Basket.TOTAL'),
                                value: `$${transaction.productTotal}`,
                                iconName: 'to-right',
                            },
                        ]} />
                    </BasketCard>
                    <BasketCard title={_('Basket.CONTACT_INFO')} icon={'at.svg'}>
                        <div className="form">
                            <label htmlFor="email" className="form-label required">{_('Basket.EMAIL_ADDRESS')}</label>
                            <input ref={emailRef} disabled={!isActive} required id="email" className="form-control" type="email" value={transaction.email} readOnly={!!transaction.email} />
                        </div>
                    </BasketCard>
                </div>
                <div className="last">
                    <BasketCard title={_('Basket.PAYMENT_SELECTION')} icon={'coins.svg'}>
                        {isActive ? (
                            <div className="currencyList">
                                {/*<div className={`currencies ${selectedCoin ? "leastOneSelected" : ""} `}>*/}
                                <div className={clsx('currencies', {
                                    leastOneSelected: selectedCoin,
                                    onlyOne: availableCoins.length === 1,
                                })}>
                                    {availableCoins.map((coin, index) => (
                                            <CoinSelection coinId={coin.id} key={index} code={coin.symbol} name={coin.name} amount={coin.precision} svg={getCoinSvg(coin.symbol)} onSelect={handleSelectCoin} selected={selectedCoinId === coin.id} />
                                        ),
                                    )}

                                </div>

                            </div>
                        ) : (
                            <Timeout />
                        )}

                    </BasketCard>
                </div>

            </div>
            {isActive && (
                <div className="bottom">
                    <button type="submit" disabled={!selectedCoin || loading} className="btn btn-primary fs-4">
                        {_('Basket.CONTINUE')} {selectedCoin ? <small>({selectedCoin})</small> : ''}
                        {loading ?
                            <span className="loading"><span className="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            :
                            <KTIcon iconName="double-right" />
                        }
                    </button>
                </div>
            )}
        </form>
    );
};

export default Basket;