import React, {FC, useEffect} from 'react';
import Input from '@/app/modules/components/filterInputs/Input';
import Select from '@/app/modules/components/filterInputs/Select';
import Buttons from '@/app/modules/components/Buttons';
import {blockchainTransactionStatusLabels, ICoinListItem} from '@/app/interfaces';
import {IBlockchainTransactionFilters} from '@/app/interfaces/filters';
import {KTIcon} from '@/_metronic/helpers';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getAllCoins} from '@/app/utils/requests/adminRequests';


interface Props {
    handleFilterSubmit: () => void;
    handleFilterReset: () => void;
    handleFilterChange: (name: string, value: string | object | null) => void;
    filters: IBlockchainTransactionFilters;
}

const BlockChainTransactionFilters: FC<Props> = ({handleFilterSubmit, handleFilterReset, handleFilterChange, filters}) => {
    const _ = useTranslation();
    const options = {};
    const [coins, setCoins] = React.useState<ICoinListItem[]>([]);

    useEffect(() => {
        getAllCoins().then((res) => {
            setCoins(res.data);
        });
    }, []);
    coins.forEach((coin) => {
        options[coin.id] = coin.name;
    });
    const translatedStatusLabels = {};
    Object.keys(blockchainTransactionStatusLabels).forEach((key) => {
        translatedStatusLabels[key] = _(blockchainTransactionStatusLabels[key]);
    });
    return (
        <div className="row mb-10 gap-5">
            <div className="col-md ">
                <Input name="searchQuery" value={filters.searchQuery} label={_('Filter.SEARCH_QUERY')} onChange={handleFilterChange} />
            </div>
            <div className="col-md ">
                <Select isClearable options={translatedStatusLabels} name="status" label={_('Filter.STATUS')} onChange={handleFilterChange} value={filters.status} className={'form-control-solid'} />
            </div>
            <div className="col-md">
                <Select isClearable options={options} name="coin" label="Coin" onChange={handleFilterChange} value={filters.coin} className={'form-control-solid'} isSearchable={true} />
            </div>

            <div className={'col-md flex-grow-0 align-self-end'}>
                <Buttons>
                    <button onClick={handleFilterSubmit} className="btn  btn-primary  px-6">{_('Filter.FILTER')}</button>
                    <button onClick={handleFilterSubmit} className="btn btn-secondary">
                        <KTIcon iconName={'arrows-circle'} className={'fs-1 me-1'} />
                    </button>
                </Buttons>
            </div>
        </div>
    );
};

export default BlockChainTransactionFilters;