import {FC} from 'react';
import FormCard from '@/app/modules/components/form-card/FormCard';
import Buttons from '@/app/modules/components/Buttons';
import {Link} from 'react-router-dom';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import useCopyToClipboard from '@/app/modules/hooks/useCopyToClipboard';
import {toast} from 'react-toastify';

interface Props {
    orderUrl: string;
    resetForm: any;
}

const Created: FC<Props> = ({orderUrl, resetForm}) => {
    const _ = useTranslation();
    const [copy, isCopied] = useCopyToClipboard();


    return (
        <div className={'d-flex flex-column gap-7 gap-lg-10 merchantSettings'}>
            <FormCard title={_('Order.CREATED')}>
                <div className="row mb-6">
                    <label className="col-lg-3 col-form-label fw-semibold fs-6">{_('Order.LINK')}</label>
                    <div className="col-lg-9">
                        <input onClick={() => {
                            if (isCopied) return;
                            copy(orderUrl);
                            toast.success(_('General.COPIED'));
                        }} type={'text'} name={'orderUrl'} className="form-control form-control-lg form-control-solid" value={orderUrl} readOnly />
                    </div>
                </div>
            </FormCard>
            <Buttons>
                <Link to={MerchantRouteConstants.DASHBOARD} className="btn btn-light">
                    {_('General.CANCEL')}
                </Link>

                <button className="btn btn-primary" onClick={resetForm}>
                    {_('Order.CREATE_NEW')}
                </button>
            </Buttons>
        </div>
    );
};

export default Created;