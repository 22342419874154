import {FC} from 'react';
import {Modal} from 'react-bootstrap';
import clsx from 'clsx';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    showModal: boolean;
    setShowModal: (showModal: boolean) => void;
    json: string;
    jsonType: 'request' | 'response' | null;
}

const JsonModal: FC<Props> = ({showModal, setShowModal, json, jsonType}) => {
    const _ = useTranslation();

    const beautifyJSON = (json: string) => {
        try {
            return JSON.stringify(JSON.parse(json), null, 2);
        } catch (e) {
            return json;
        }
    };
    return (
        <Modal size={'lg'} show={showModal} centered onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {jsonType === 'request' ? _('JsonModal.REQUEST_LOG') : _('JsonModal.RESPONSE_LOG')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <pre className={'fs-5'}>{beautifyJSON(json)}</pre>
            </Modal.Body>
            <Modal.Footer>
                <button className={clsx('btn btn-light btn-active-light-primary me-2 btn-sm')} type="button" onClick={() => setShowModal(false)} data-bs-dismiss="modal">{_('JsonModal.CLOSE')}</button>
            </Modal.Footer>
        </Modal>
    );
};

export default JsonModal;