import React, {useCallback} from 'react';
import {ISortState} from '@/app/interfaces';

interface DataTableSortHook {
    sort: ISortState;
    handleSorting: (field: string) => void;
}

const useDataTableSort = (initialSort: ISortState): DataTableSortHook => {
    const [sort, setSort] = React.useState<ISortState>(initialSort);

    const handleSorting = useCallback((field: string) => {
        if (sort.field === field) {
            setSort({
                field,
                direction: sort.direction === 'asc' ? 'desc' : 'asc',
            });
        } else {
            setSort({
                field,
                direction: 'asc',
            });
        }
    }, [sort.field, sort.direction]);

    return {sort, handleSorting};
};

export default useDataTableSort;