import React, {FC} from 'react';
import {Modal} from 'react-bootstrap';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import {toast} from 'react-toastify';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    show: boolean;
    handleClose: () => void;
    handleSubmit: () => Promise<void>;
    title?: string;
    description?: string;
}

const ConfirmModal: FC<Props> = ({show, handleClose, handleSubmit, title, description}) => {
    const _ = useTranslation();

    if (!title) title = _('ConfirmModal.TITLE');
    if (!description) description = _('ConfirmModal.DESCRIPTION');

    const [loading, setLoading] = React.useState<boolean>(false);
    const submit = async () => {
        setLoading(true);
        try {
            await handleSubmit();
        } catch (e) {
            toast.error(_('General.ERROR'));
        } finally {
            setLoading(false);
            handleClose();
        }
    };

    return (
        <Modal
            id="kt_modal_confirm_modal"
            tabIndex={-1}
            aria-hidden="true"
            dialogClassName="modal-dialog modal-dialog-centered mw-650px"
            show={show}
            onHide={handleClose}
        >
            <div className="modal-body scroll-y pt-10 pb-15 px-lg-17 text-center">
                <img className="mw-75px mb-10" src={toAbsoluteUrl('/custom/svg/alert.svg')} alt="Alert" />

                <div className="fw-semibold">
                    <h4 className="text-gray-900 fw-bold fs-1 mb-5">{title}</h4>

                    <div className="fs-6 text-gray-700 mb-10">{description}</div>
                </div>
                <div className="d-flex justify-content-center gap-5">

                    {/*<ContentLoader loading={loading}/>*/}

                    <button disabled={loading} onClick={submit} className="btn btn-sm btn-light-primary  px-6">
                        {loading ? (
                            <span className="indicator-progress" style={{display: 'block'}}>
                                {_('ConfirmModal.PROGRESS')}{' '}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        ) : (
                            <span className="indicator-label">{_('ConfirmModal.CONFIRM')}</span>
                        )}
                    </button>
                    <button disabled={loading} onClick={handleClose} className="btn btn-sm btn-light-danger btn-active-light-primary ">{_('ConfirmModal.CANCEL')}</button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmModal;