import React, {FC} from 'react';
import clsx from 'clsx';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    disabled?: boolean;
    loading: boolean;
    label: string;
    className?: string;
    id?: string;
    type?: 'submit' | 'button' | 'reset';
    onClick?: () => void;
}

const Submit: FC<Props> = (props) => {
    const _ = useTranslation();
    return (
        <button
            type={props.type ? props.type : 'submit'}
            onClick={props.onClick}
            id={props.id}
            className={clsx('btn btn-primary', props.className)}
            disabled={props.disabled}
        >
            {!props.loading && <span className="indicator-label">{props.label}</span>}
            {props.loading && (
                <span className="indicator-progress" style={{display: 'block'}}>
                    {_('Submit.LOADING_TEXT')}{' '}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
            )}
        </button>
    );
};

export default Submit;