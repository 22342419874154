import {FC} from 'react';
import {KTIcon} from "@/_metronic/helpers";

interface Props {
    id: number;
    fullKey: string;
    isFullKeyShown: boolean;
    toggleShowFullKey: (id: number) => void;


}

const ApiKey: FC<Props> = (props) => {
    const partialKey = `${props.fullKey.substring(0, 12)}...${props.fullKey.substring(props.fullKey.length - 12)}`;
    return (
        <span className={"text-dark fw-bold d-block fs-6 longKey d-flex align-items-center mw-400px"}>
            {props.isFullKeyShown ? props.fullKey : partialKey}
            <button onClick={() => props.toggleShowFullKey(props.id)} className={"btn btn-icon btn-sm "}>
                <KTIcon iconName={props.isFullKeyShown ? "eye-slash" : "eye"} className={"fs-2 me-1"}/>
            </button>
        </span>
    );
};

export default ApiKey;