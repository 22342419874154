import React, {FC, createContext, useContext, useState, useCallback} from 'react'
import {WithChildren} from '../helpers'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

export enum Lang {
    tr = 'tr',
    en = 'en',
}

type Props = {
    selectedLang: Lang
    setLang: (lang: Lang) => void
}
const initialState: Props = {
    selectedLang: Lang.en,
    setLang: () => {
    }
}

function getConfig(): Lang {

    const ls = localStorage.getItem(I18N_CONFIG_KEY)
    if (ls) {
        try {
            const parse = JSON.parse(ls);
            if (Object.values(Lang).includes(parse.selectedLang)) {
                return parse.selectedLang
            }
        } catch (er) {
            console.error(er)
        }
    }
    if (Object.values(Lang).includes(navigator.language.slice(0, 2) as Lang)) {
        console.log(`detected lang: ${navigator.language.slice(0, 2) as Lang}`);
        return navigator.language.slice(0, 2) as Lang
    }
    return initialState.selectedLang
}

// Side effect
export function setLanguage(lang: string) {
    localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
    // window.location.reload()
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
    return useContext(I18nContext)
}

const MetronicI18nProvider: FC<WithChildren> = ({children}) => {
    // const lang = getConfig()
    const [lang, setLang] = useState(getConfig())
    const setLangWithSideEffect = useCallback((lang: Lang) => {
        setLanguage(lang)
        setLang(lang)

    }, [])


    return <I18nContext.Provider
        value={{selectedLang: lang, setLang: setLangWithSideEffect}}>{children}</I18nContext.Provider>
}

export {MetronicI18nProvider, useLang}
