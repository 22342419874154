import {IDataTableHead} from '@/app/interfaces';

export const AllWithdrawalsTableHead: IDataTableHead[] = [

    {
        text: 'AllWithdrawalsTH.DATE',
        name: 'createdAt',
        isSortable: true,
        align: 'left',
        width: '70px',
    },
    {
        text: 'AllWithdrawalsTH.TX_HASH',
        isSortable: false,
        align: 'left',
        width: '50px',
    },
    {
        text: 'AllWithdrawalsTH.STATUS',
        name: 'status',
        align: 'center',
        isSortable: true,
        width: '100px',
    },
    {
        text: 'AllWithdrawalsTH.MERCHANT',
        name: 'merchant.merchantName',
        align: 'center',
        isSortable: true,
        width: '100px',
    },
    {
        text: 'AllWithdrawalsTH.USER',
        name: 'user.fullName',
        align: 'center',
        isSortable: true,
        width: '100px',
    },
    {
        text: 'AllWithdrawalsTH.IS_ADMIN_WITHDRAW',
        name: 'isAdminWithdraw',
        align: 'center',
        isSortable: true,
        width: '50px',
    },
    {
        text: 'AllWithdrawalsTH.AMOUNT',
        name: 'amount',
        isSortable: true,
        align: 'right',
        width: '100px',
    },
    {
        text: 'AllWithdrawalsTH.COIN',
        name: 'coin.symbol',
        isSortable: true,
        align: 'center',
        width: '100px',
    },

    {
        text: '',
        width: '100px',
    },
];