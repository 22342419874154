import React, {FC, useCallback} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import Card from '@/app/modules/components/card/Card';
import {useApiKeyCheck} from '@/app/modules/hooks/useApiKeyCheck';
import {getDummyApiLogs} from '@/app/mocks/apiLogs';
import useDatatable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {IApiLog} from '@/app/interfaces';
import {initialMerchantApiLogListSort} from '@/app/utils/initialSorts';
import {ApiLogsTableHead} from '@/app/utils/tableHeads/merchant/ApiLogsTableHead';
import Badge from '@/app/modules/components/Badge';
import dayjs from 'dayjs';
import {Tooltip} from 'react-tooltip';
import {useJsonModal} from '@/app/modules/hooks/useJsonModal';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {

}

const apiLogs = getDummyApiLogs(50);

const ViewLog: FC<Props> = (props) => {
    const apiKey = useApiKeyCheck();
    const _ = useTranslation();

    const getData: GetDataFunction<IApiLog, {}> = useCallback(async ({pageIndex, filters, pageSize, sort}) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let sortedData = apiLogs.sort((a, b) => {
                    if (sort.direction === 'asc') {
                        return a[sort.field] > b[sort.field] ? 1 : -1;
                    } else {
                        return a[sort.field] < b[sort.field] ? 1 : -1;
                    }
                });
                resolve({
                    data: sortedData.slice((pageIndex) * pageSize, (pageIndex + 1) * pageSize),
                    length: apiLogs.length,
                });
            }, 1000);
        });
    }, []);

    const {
        DataTableComponent,
    } = useDatatable<IApiLog, {}>({
        initialSort: initialMerchantApiLogListSort,
        initialFilters: {},
        getData,
        id: 'apiLogs',
    });

    const {showJSON, JSONModalComponent} = useJsonModal();

    return (
        <>
            <ToolbarWrapper title={_('ViewLog.TITLE')} additionalBreadcrumb={[
                {
                    title: _('ViewLog.MY_API_KEYS'),
                    path: MerchantRouteConstants.API_KEYS,
                    isActive: false,
                },
            ]} />

            <JSONModalComponent />

            <Card title={_('ViewLog.API_LOGS_TITLE')} subTitle={<><strong className={'text-gray-700'}>{apiKey?.apiKey.slice(0, 10)}...</strong> {_('ViewLog.API_LOGS_SUBTITLE')}</>} className={'apiKeys viewLog'}>
                {/*{isMobile ? <MobileTable apiLogs={apiLogs} showJson={showJSON}/> : <Table apiLogs={apiLogs} showJson={showJSON}/>}*/}
                <DataTableComponent head={ApiLogsTableHead} renderRow={(log: IApiLog) => {
                    return (
                        <tr key={log.id}>
                            <td>
                                <span className={'text-gray-800 fw-bold d-block fs-6'}>{dayjs(log.createdAt).format('DD.MM.YYYY HH:mm:ss')}</span>
                            </td>
                            <td>
                                <span className={'text-gray-800 fw-bold d-block fs-6'}>{log.ip}</span>
                            </td>
                            <td className={'text-center'}>
                                {log.isSuccess ? <Badge text={_('General.SUCCESS')} type={'success'} isLight={true} /> : <Badge text={_('General.FAIL')} type={'danger'} isLight={true} />}
                            </td>
                            <td className={'text-end'}>
                                <div className={'d-flex justify-content-end gap-2'}>
                                    <Tooltip id="tooltip" place={'top'} />
                                    <button onClick={() => showJSON('request', log.request)} data-tooltip-id="tooltip" data-tooltip-content={_('ViewLog.VIEW_REQUEST')} className={'btn btn-icon btn-bg-light btn-active-color-primary btn-sm'}>
                                        <i className="bi bi-arrow-bar-right"></i>
                                    </button>
                                    <button onClick={() => showJSON('response', log.response)} data-tooltip-id="tooltip" data-tooltip-content={_('ViewLog.VIEW_RESPONSE')} className={'btn btn-icon btn-bg-light btn-active-color-primary btn-sm'}>
                                        <i className="bi bi-arrow-bar-left"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    );
                }} />
            </Card>
        </>
    );
};

export default ViewLog;