import React, {useState} from 'react';
import JsonModal from '@/app/modules/components/modals/jsonModal/JsonModal';

export const useJsonModal = () => {
    const [json, setJson] = useState<string>('');
    const [jsonType, setJsonType] = useState<'request' | 'response' | null>(null);
    const [showModal, setShowModal] = useState<boolean>(false);

    const showJSON = (type: 'request' | 'response', json: string) => {
        setJsonType(type);
        setJson(json);
        setShowModal(true);
    };

    const JSONModalComponent = () => {
        return <JsonModal showModal={showModal} setShowModal={setShowModal} json={json} jsonType={jsonType} />;
    };

    return {
        showJSON,
        JSONModalComponent,
    };
};