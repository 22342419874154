import React, {FC} from 'react';
import Input from '@/app/modules/components/filterInputs/Input';
import Select from '@/app/modules/components/filterInputs/Select';
import Buttons from '@/app/modules/components/Buttons';
import {ipnHistoryStatusLabels} from '@/app/interfaces';
import {IIpnHistoryFilters} from '@/app/interfaces/filters';
import {KTIcon} from '@/_metronic/helpers';
import {useTranslation} from '@/app/modules/hooks/useTranslation';


interface Props {
    handleFilterSubmit: () => void;
    handleFilterChange: (name: string, value: string | object | null) => void;
    filters: IIpnHistoryFilters;
}

const IpnHistoryFilters: FC<Props> = ({handleFilterSubmit, handleFilterChange, filters}) => {
    const _ = useTranslation();

    return (
        <div className="row mb-10 gap-5">
            <div className="col-md ">
                <Input name="searchQuery" value={filters.searchQuery} label={_('Filter.SEARCH_QUERY')} onChange={handleFilterChange} />
            </div>
            <div className="col-md ">
                <Select isClearable options={ipnHistoryStatusLabels} name="status" label={_('IpnHistory.STATUS_CODE')} onChange={handleFilterChange} value={filters.status} className={'form-control-solid'} />
            </div>

            <div className={'col-md flex-grow-0 align-self-end'}>
                <Buttons>
                    <button onClick={handleFilterSubmit} className="btn  btn-primary  px-6">{_('Filter.FILTER')}</button>
                    <button onClick={handleFilterSubmit} className="btn btn-secondary">
                        <KTIcon iconName={'arrows-circle'} className={'fs-1 me-1'} />
                    </button>
                </Buttons>
            </div>
        </div>
    );
};

export default IpnHistoryFilters;