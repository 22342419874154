import React, {type FC, useEffect} from 'react';
import {generateTxDetailUrl, shortenTxHash} from '@/app/modules/helpers';
import useCopyToClipboard from '@/app/modules/hooks/useCopyToClipboard';
import {toast} from 'react-toastify';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    txHash: string;
    urlFormat?: string;
    showFull?: boolean;
}

const TxHash: FC<Props> = ({txHash, urlFormat, showFull}) => {
    const [copyHash, isCopiedHash] = useCopyToClipboard();
    const _ = useTranslation();

    useEffect(() => {
        if (isCopiedHash) {
            toast.success(_('General.COPIED'), {
                position: 'top-right',
                autoClose: 1000,
                hideProgressBar: true,
            });
        }
    }, [_, isCopiedHash]);

    return (
        <span className="text-gray-800 fw-bold fs-6 font-monospace">
            {urlFormat ? <a href={generateTxDetailUrl(urlFormat, txHash)} target="_blank" rel="noreferrer">{showFull ? txHash : shortenTxHash(txHash)}</a> : showFull ? txHash : shortenTxHash(txHash)}
            <i className="bi bi-clipboard ms-2 cursor-pointer p-2" onClick={() => copyHash(txHash)}></i>
        </span>
    );
};

export default TxHash;