import {IDataTableHead} from '@/app/interfaces';

export const TransactionsTableHead: IDataTableHead[] = [
    {
        text: 'TransactionHistoryTH.ID',
        name: 'id',
        isSortable: false,
        align: 'left',
        width: '300px',
    },
    {
        text: 'TransactionHistoryTH.DATE',
        name: 'createdAt',
        isSortable: true,
        align: 'left',
        width: '70px',
    },
    // {
    //     text: 'TransactionHistoryTH.EMAIL',
    //     name: 'from',
    //     isSortable: false,
    //     align: 'left',
    //     width: '150px',
    // },
    {
        text: 'TransactionHistoryTH.PAID_AND_AMOUNT_FIAT',
        name: 'estimatedPaidAmountFiat',
        isSortable: true,
        align: 'left',
        width: '200px',
    },
    {
        text: 'TransactionHistoryTH.PAID_AND_AMOUNT',
        name: 'estimatedPaidAmount',
        isSortable: true,
        align: 'left',
        width: '200px',
    },
    {
        text: 'TransactionHistoryTH.PAID_AMOUNT',
        name: 'paidAmountAsCoin',
        isSortable: true,
        align: 'right',
        width: '200px',
    },
    {
        text: 'TransactionHistoryTH.STATUS',
        name: 'status',
        align: 'center',
        isSortable: true,
        width: '100px',
    },
];
