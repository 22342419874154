import {IApiKey, Translator} from '@/app/interfaces';
import {KTIcon, toAbsoluteUrl} from '@/_metronic/helpers';
import dayjs from 'dayjs';
import KeyName from '@/app/pages/merchant/apiKeys/ApiKeys/columns/KeyName';
import ApiKey from '@/app/pages/merchant/apiKeys/ApiKeys/columns/ApiKey';
import Status from '@/app/pages/merchant/apiKeys/ApiKeys/columns/Status';
import {Link} from 'react-router-dom';
import {generateRoute} from '@/app/modules/helpers';
import {AdminMerchantDetailSubRouteConstants, AdminRouteConstants, MerchantRouteConstants} from '@/app/constants/routes.constants';
import React from 'react';
import {Tooltip} from 'react-tooltip';

interface Props {
    apiKey: IApiKey,
    merchantID?: string,
    showFullKey: number | null,
    toggleShowFullKey: (id: number) => void,
    translator: Translator
}

export const trApiKey = ({apiKey, merchantID, showFullKey, toggleShowFullKey, translator}: Props) => {
    const id = apiKey.id;
    let updateApiKeyRoute = generateRoute(MerchantRouteConstants.UPDATE_API_KEY, {id: id.toString()});
    // let viewApiKeyLogRoute = generateRoute(MerchantRouteConstants.VIEW_API_KEY_LOG, {id: id.toString()});
    if (merchantID) updateApiKeyRoute = `${generateRoute(AdminRouteConstants.MERCHANT_DETAIL, {id: merchantID})}/${generateRoute(AdminMerchantDetailSubRouteConstants.UPDATE_API_KEY, {id: id.toString()})}`;
    // if (merchantID) viewApiKeyLogRoute = `${generateRoute(AdminRouteConstants.MERCHANT_DETAIL, {id: merchantID})}/${generateRoute(AdminMerchantDetailSubRouteConstants.VIEW_API_KEY_LOG, {id: id.toString()})}`;
    const isFullKeyShown = showFullKey === apiKey.id;
    return (
        <tr key={apiKey.id}>
            <td>
                <img className={'w-25px'} src={toAbsoluteUrl('/custom/svg/key.svg')} alt="" />
            </td>
            <td>
                <span className={'text-dark fw-bold d-block fs-7'}>{dayjs(apiKey.createdAt).format('DD.MM.YYYY HH:mm:ss')}</span>
            </td>
            <td>
                <KeyName name={apiKey.name} />
            </td>
            <td>
                <ApiKey id={apiKey.id} fullKey={apiKey.apiKey} isFullKeyShown={isFullKeyShown} toggleShowFullKey={toggleShowFullKey} />
            </td>
            <td className={'text-center'}>
                <Status isActive={apiKey.status} />
            </td>
            <td className={'text-end'}>
                <div className={'gap-2 d-flex justify-content-end'}>
                    <Tooltip id={'tooltip'} place={'top'} />
                    {/*<Link data-tooltip-id="tooltip" data-tooltip-content={translator('ApiKeys.VIEW_LOGS')} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" to={viewApiKeyLogRoute}>*/}
                    {/*    <KTIcon iconName={'eye'} className={'fs-2'} />*/}
                    {/*</Link>*/}
                    <Link data-tooltip-id="tooltip" data-tooltip-content={translator('ApiKeys.EDIT')} className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" to={updateApiKeyRoute}>
                        <KTIcon iconName={'pencil'} className={'fs-2'} />
                    </Link>
                </div>
            </td>
        </tr>
    );
};