import React, {type FC} from 'react';
import Balance from '@/app/pages/merchant/wallet/tools/Balance';
import {IBlockchainTransaction} from '@/app/interfaces';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import dayjs from 'dayjs';
import BlockchainTransactionStatus from '@/app/pages/admin/blockchain-transaction/blockchainTransactionList/BlockchainTransactionStatus';
import {getCoinSymbol} from '@/app/modules/helpers/getCoinSymbol';
import TxHash from '@/app/modules/components/TxHash';

interface Props {
    blockchainTransactions: IBlockchainTransaction[];
}

const TransactionBlockchainTransactionTable: FC<Props> = ({blockchainTransactions}) => {
    const _ = useTranslation();
    return (
        <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
            <div className="card-header">
                <div className="card-title">
                    <h2>{_('TransactionDetail.BT_TABLE_TITLE')}</h2>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="table-responsive">
                    <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                        <thead>
                        <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                            {/*<th className="min-w-175px">{_('TransactionDetail.PRODUCT')}</th>*/}
                            {/*<th className="min-w-70px text-end">{_('TransactionDetail.QUANTITY')}</th>*/}
                            {/*<th className="min-w-100px text-end">{_('TransactionDetail.SINGLE_PRICE')}</th>*/}
                            {/*<th className="min-w-100px text-end">{_('TransactionDetail.TOTAL_PRICE')}</th>*/}

                            <th style={{width: '70px'}}>{_('BlockchainTransactionsTH.DATE')}</th>
                            <th style={{width: '100px'}}>{_('BlockchainTransactionsTH.TX_HASH')}</th>
                            <th className="text-center" style={{width: '100px'}}>{_('BlockchainTransactionsTH.STATUS')}</th>
                            <th className="text-right" style={{width: '100px'}}>{_('BlockchainTransactionsTH.AMOUNT')}</th>
                            <th className="text-center" style={{width: '100px'}}>{_('BlockchainTransactionsTH.COIN')}</th>
                        </tr>


                        </thead>
                        <tbody className="fw-semibold text-gray-600">
                        {blockchainTransactions.map((transaction, index) => (
                            <tr key={`tb_blockchainTransaction_${transaction.id}`}>
                                <td>
                                    <div className="badge badge-light fw-bold">{dayjs(transaction.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
                                </td>
                                <td>
                                    <TxHash txHash={transaction.txHash} urlFormat={transaction.coinTxDetailUrlFormat} />
                                </td>
                                <td className="text-center"><BlockchainTransactionStatus status={transaction.status} /></td>
                                <td><Balance amount={transaction.amount} code={transaction.coinSymbol} isTestNetwork={transaction.isTestNetwork} /></td>
                                <td className="text-center">
                                    {getCoinSymbol({
                                        symbol: transaction.coinSymbol,
                                        subSymbol: transaction.coinSubSymbol,
                                        isTestNetwork: transaction.isTestNetwork,
                                    })}
                                </td>
                            </tr>
                        ))}

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TransactionBlockchainTransactionTable;