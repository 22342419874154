import React, {FC} from 'react';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import {Link} from 'react-router-dom';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {

}

const Timeout: FC<Props> = (props) => {
    const _ = useTranslation();

    return (
        <section className="timeout">
            <img src={toAbsoluteUrl('/custom/svg/hourglass.svg')} alt="Timeout" />
            <h1 className="text-dark fs-2qx mb-3">
                {_('Timeout.PAYMENT_TIMEOUT')}
            </h1>
            <div className="text-dark fs-base text-center mb-10">
                {_('Timeout.PAYMENT_TIMEOUT_DESCRIPTION')}
            </div>

            {/*<Link to={MerchantRouteConstants.BASKET} className="btn btn-primary me-4">*/}
            {/*    {_('Timeout.START_NEW_PAYMENT')}*/}
            {/*</Link>*/}
        </section>
    );
};

export default Timeout;