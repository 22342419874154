import {type FC, useCallback, useEffect, useRef} from 'react';
import {useThemeMode} from '@/_metronic/partials';
import ApexCharts, {ApexOptions} from 'apexcharts';
import {getCSS, getCSSVariableValue} from '@/_metronic/assets/ts/_utils';
import {numberFormat} from '@/app/modules/helpers';


interface Props {
    title: string;
    values: Record<string, number>;
}

const SimpleCardChart: FC<Props> = ({title, values}) => {
    const chartRef = useRef<HTMLDivElement | null>(null);
    const {mode} = useThemeMode();

    const refreshChart = useCallback(() => {
        if (!chartRef.current) {
            return;
        }

        const height = parseInt(getCSS(chartRef.current, 'height'));

        const chart = new ApexCharts(chartRef.current, getChartOptions({height, title, values}));
        if (chart) {
            chart.render();
        }

        return chart;
    }, [title, values]);
    useEffect(() => {
        const chart = refreshChart();

        return () => {
            if (chart) {
                chart.destroy();
            }
        };
    }, [mode, refreshChart, values]);

    return (
        <div ref={chartRef} id="kt_card_widget_12_chart" className="min-h-auto" style={{height: '125px'}}></div>
    );
};

export default SimpleCardChart;

interface ChartOptions extends Props {
    height: number;
}

function getChartOptions({height, title, values}: ChartOptions): ApexOptions {
    const successColor = getCSSVariableValue('--bs-success');
    const borderDashedColor = getCSSVariableValue('--bs-border-dashed-color');
    const markerStrokeColor = getCSSVariableValue('--bs-gray-800');


    return {
        series: [{name: title, data: Object.values(values)}],
        chart: {fontFamily: 'inherit', type: 'area', height: height, toolbar: {show: !1}},
        legend: {show: !1},
        dataLabels: {enabled: !1},
        fill: {type: 'solid', opacity: 0},
        stroke: {curve: 'smooth', show: !0, width: 2, colors: [markerStrokeColor]},
        xaxis: {axisBorder: {show: !1}, axisTicks: {show: !1}, labels: {show: !1}, crosshairs: {position: 'front', stroke: {color: markerStrokeColor, width: 1, dashArray: 3}}, tooltip: {enabled: !0, offsetY: 0, style: {fontSize: '12px'}}, categories: Object.keys(values)},
        yaxis: {labels: {show: !1}},
        states: {normal: {filter: {type: 'none', value: 0}}, hover: {filter: {type: 'none', value: 0}}, active: {allowMultipleDataPointsSelection: !1, filter: {type: 'none', value: 0}}},
        tooltip: {
            style: {fontSize: '12px'}, x: {
                formatter: function(e) {
                    const selectedDate = Object.keys(values)[e - 1];
                    const date = new Date(selectedDate);
                    const day = String(date.getDate()).padStart(2, '0');
                    const month = String(date.getMonth() + 1).padStart(2, '0');
                    return `${day}.${month}`;
                },
            }, y: {
                formatter: function(e) {
                    return numberFormat(e);
                },
            },
        },
        colors: [successColor],
        grid: {borderColor: borderDashedColor, strokeDashArray: 4, padding: {top: 0, right: 0, bottom: -10, left: -10}, yaxis: {lines: {show: !0}}},
        markers: {strokeColors: markerStrokeColor, strokeWidth: 2},
    };
}