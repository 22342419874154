import {Translator} from '@/app/interfaces';
import * as Yup from 'yup';
import {PhoneNumberUtil} from 'google-libphonenumber';

export const emailField = (_: Translator) => Yup.string()
    .email(_('Validation.INVALID_EMAIL'))
    .min(3, _('Validation.MIN_CHAR', {min: 3}))
    .max(50, _('Validation.MAX_CHAR', {max: 50}))
    .required(_('Validation.REQUIRED_FIELD'));

export const requiredString = (_: Translator, settings?: {min: number, max: number}) => {
    settings = settings || {min: 3, max: 50};
    return Yup.string()
        .min(settings.min, _('Validation.MIN_CHAR', {min: settings.min}))
        .max(settings.max, _('Validation.MAX_CHAR', {max: settings.max}))
        .required(_('Validation.REQUIRED_FIELD'));
};

export const optionalString = (_: Translator, settings?: {min: number, max: number}) => {
    settings = settings || {min: 1, max: 50};
    return Yup.string()
        .min(settings.min, _('Validation.MIN_CHAR', {min: settings.min}))
        .max(settings.max, _('Validation.MAX_CHAR', {max: settings.max}));
};

export const nullableString = (_: Translator, settings?: {min: number, max: number}) => {
    settings = settings || {min: 0, max: 50};
    return Yup.string()
        .nullable()
        .min(settings.min, _('Validation.MIN_CHAR', {min: settings.min}))
        .max(settings.max, _('Validation.MAX_CHAR', {max: settings.max}));
};

export const passwordField = (_: Translator) => {
    return Yup.string()
        .min(8, _('Validation.MIN_CHAR', {min: 8}))
        .required(_('Validation.REQUIRED_FIELD'));
};
export const passwordRepeatField = (_: Translator, passwordFieldName: string) => {
    return Yup.string()
        .min(8, _('Validation.MIN_CHAR', {min: 8}))
        .required(_('Validation.REQUIRED_FIELD'))
        .oneOf([Yup.ref(passwordFieldName)], _('Validation.PASSWORD_MISMATCH'));
};

export const phoneField = (_: Translator) => {
    return Yup.string()
        .test('isValidPhoneNumber', _('Validation.INVALID_PHONE'), (value) => {
            if (!value) return false;
            const phoneUtil = PhoneNumberUtil.getInstance();
            try {
                const phoneNumber = phoneUtil.parseAndKeepRawInput(value);
                return phoneUtil.isValidNumber(phoneNumber);
            } catch (error) {
                return false;
            }
        })
        .required(_('Validation.REQUIRED_FIELD'));
};

export const codeField = (_: Translator) => {
    return Yup.string().length(6, _('Validation.CODE_LENGTH')).required(_('Validation.REQUIRED_FIELD'));
};

export const userTypeField = (_: Translator) => {
    return Yup.string()
        .oneOf(['1', '2'], _('Validation.INVALID_USER_TYPE'))
        .required(_('Validation.REQUIRED_FIELD'));
};

export const urlField = (_: Translator, isRequired: boolean) => {
    if (!isRequired) return Yup.string().url(_('Validation.INVALID_URL'));
    return Yup.string()
        .url(_('Validation.INVALID_URL'))
        .required(_('Validation.REQUIRED_FIELD'));
};

export const numberField = (_: Translator, settings?: {min: number, max: number}) => {
    settings = settings || {min: 1, max: 99999999999};
    return Yup.number()
        .min(settings.min, _('Validation.MIN_NUMBER', {min: settings.min}))
        .max(settings.max, _('Validation.MAX_NUMBER', {max: settings.max}))
        .required(_('Validation.REQUIRED_FIELD'));
};