import React, {FC} from 'react';

interface Props {
    label: string;
    id: string;
}

const Label: FC<Props> = ({id, label}) => {
    return (
        <label className="fs-6 fw-semibold form-label mb-2" htmlFor={id}>{label}:</label>
    );
};

export default Label;