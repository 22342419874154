import React, {FC, useState} from 'react';
import Input from '@/app/modules/auth/components/tools/inputs/Input';
import {KTIcon} from '@/_metronic/helpers';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    formik: any;
    apiKey: any;
}

const ApiKeyDetailsForm: FC<Props> = ({formik, apiKey}) => {
    const _ = useTranslation();

    const [showApiKey, setShowApiKey] = useState(false);
    const toggleShowApiKey = () => {
        setShowApiKey(!showApiKey);
    };
    return (
        <>
            <Input name="key" formik={formik} type="textarea" className={'form-control-solid'} isRow label={_('ApiKeyDetailsForm.KEY')} disabled value={showApiKey ? apiKey : '*'.repeat(128)} afterComponent={<button type="button" onClick={toggleShowApiKey} className={'btn btn-icon btn-sm'}>
                <KTIcon iconName={showApiKey ? 'eye-slash' : 'eye'} className={'fs-2 me-1'} />
            </button>} />
            <Input className="form-control-lg form-control-solid" name={'isActive'} type={'checkbox'} formik={formik} label={_('ApiKeyDetailsForm.ACTIVE_STATUS')}
                   checked={formik.values['isActive']}
                   checkboxLabel={
                       formik.values['isActive'] ? (
                           <span className="label label-lg text-dark label-inline ">{_('General.ACTIVE')}</span>
                       ) : (
                           <span className="label label-lg text-dark label-inline ">{_('General.INACTIVE')}</span>
                       )}
                   isRow />
            <Input className="form-control-solid" name={'keyName'} type={'text'} formik={formik} label={_('ApiKeyDetailsForm.KEY_NAME')} isRow />
            {/*<Input className=" form-control-solid" name={'ipRange'} type={'text'} formik={formik} label={_('ApiKeyDetailsForm.IP_RANGE_RESTRICTION')} isRow*/}
            {/*       description={_('ApiKeyDetailsForm.IP_RANGE_DESCRIPTION')} />*/}
        </>
    );
};

export default ApiKeyDetailsForm;