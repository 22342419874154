import React, {type FC} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useParams} from 'react-router-dom';
import {AdminRouteConstants} from '@/app/constants/routes.constants';
import TransactionDetail from '@/app/pages/common/TransactionDetail/TransactionDetail';
import {getTransactionDetail} from '@/app/utils/requests/adminRequests';

interface Props {

}

const AdminTransactionDetail: FC<Props> = (props) => {
    const _ = useTranslation();

    const {id} = useParams();

    if (!id) return null;

    return (
        <>
            <ToolbarWrapper title={_('TransactionDetail.TITLE') + ` (#${id})`} additionalBreadcrumb={[
                {title: _('TransactionList.TITLE'), path: AdminRouteConstants.TRANSACTIONS, isActive: false},
            ]} />

            <TransactionDetail id={id} getTransactionFunc={getTransactionDetail} />
        </>
    );
};

export default AdminTransactionDetail;