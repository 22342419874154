import {FC, useCallback, useEffect, useState} from 'react';
import {calculateTimeLeft, secondsToCountdownFormat} from '@/app/modules/helpers';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    endTime?: Date;
    onEnd?: () => void;
}

const Timer: FC<Props> = ({endTime, onEnd}) => {
    const _ = useTranslation();

    const [timeLeftSeconds, setTimeLeftSeconds] = useState<number | null>(null);

    useEffect(() => {
        if (!endTime) return;
        const updateTimer = () => {
            const newTimeLeft = calculateTimeLeft(endTime);
            setTimeLeftSeconds(newTimeLeft);
            if (newTimeLeft === 0) {
                onEnd && onEnd();
            }
        };

        updateTimer();
        const timer = setInterval(updateTimer, 1000);

        return () => clearInterval(timer);
    }, [endTime, onEnd]);

    const showTimer = useCallback((timeLeftSeconds: null | number) => {
        if (timeLeftSeconds === null) return '--:--:--';
        if (timeLeftSeconds === 0) return _('Timer.TIME_UP');
        return secondsToCountdownFormat(timeLeftSeconds);
    }, [_]);

    if (!endTime) return (<></>);
    return (
        <>
            {showTimer(timeLeftSeconds)}
        </>
    );
};

export default Timer;