import {FC} from 'react';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    name?: string;
}

const KeyName: FC<Props> = (props) => {
    const _ = useTranslation();
    return (
        <>
            {props.name ? (
                <span className={'text-dark fw-bold d-block fs-6'}>{props.name}</span>
            ) : (
                <span className={'text-muted d-block fw-bold fs-6'}>{_('KeyName.UNNAMED')}</span>
            )}
        </>
    );
};

export default KeyName;