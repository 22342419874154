import {IAdminMerchantListFilters, IAdminTransactionFilters, IAdminUserListFilters, IAdminWithdrawalFilters, IBlockchainTransactionFilters, IEmployeeListFilters, IIpnHistoryFilters} from '@/app/interfaces/filters';

export const initialAdminTransactionFilters: IAdminTransactionFilters = {
    searchQuery: '',
    status: null,
    merchant: null,
};
export const initialAdminMerchantListFilters: IAdminMerchantListFilters = {
    searchQuery: '',
    status: null,
};

export const initialAdminUserListFilters: IAdminUserListFilters = {
    searchQuery: '',
    role: null,
    merchantID: null,
};

export const initialEmployeeListFilters: IEmployeeListFilters = {
    searchQuery: '',
};

export const initialMerchantTransactionFilters = {
    searchQuery: '',
    startDate: null,
    endDate: null,
    minAmount: null,
    maxAmount: null,
    type: null,
};

export const initialIpnHistoryFilters: IIpnHistoryFilters = {
    searchQuery: '',
    status: null,
};

export const initialBlockchainTransactionFilters: IBlockchainTransactionFilters = {
    searchQuery: '',
    status: null,
    coin: null,
};
export const initialAllWithdrawalFilters: IAdminWithdrawalFilters = {
    searchQuery: '',
    status: null,
    coin: null,
    merchant: null,
    isAdminWithdraw: null,
};