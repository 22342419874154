import {IDataTableHead} from '@/app/interfaces';

export const BlockchainTransactionsTableHead: IDataTableHead[] = [

    {
        text: 'BlockchainTransactionsTH.DATE',
        name: 'createdAt',
        isSortable: true,
        align: 'left',
        width: '70px',
    },
    {
        text: 'BlockchainTransactionsTH.TX_HASH',
        isSortable: false,
        align: 'left',
        width: '50px',
    },
    {
        text: 'BlockchainTransactionsTH.STATUS',
        name: 'status',
        align: 'center',
        isSortable: true,
        width: '100px',
    },
    {
        text: 'BlockchainTransactionsTH.AMOUNT',
        name: 'amount',
        isSortable: true,
        align: 'right',
        width: '100px',
    },
    {
        text: 'BlockchainTransactionsTH.COIN',
        name: 'coin.symbol',
        isSortable: true,
        align: 'center',
        width: '100px',
    },


];