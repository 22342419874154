import {FC} from 'react'
import {useLang} from './Metronici18n'
import {IntlProvider} from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/tr'

import trMessages from './messages/tr.json'
import enMessages from './messages/en.json'

import {WithChildren} from '../helpers'

const allMessages = {
    tr: trMessages,
    en: enMessages,

}

const I18nProvider: FC<WithChildren> = ({children}) => {
    const {selectedLang} = useLang()
    const messages = allMessages[selectedLang]

    return (
        // @ts-ignore
        <IntlProvider locale={selectedLang} messages={messages}>
            {children}
        </IntlProvider>
    )
}

export {I18nProvider}
