import {AxiosResponse} from 'axios';
import {Lang} from '@/_metronic/i18n/Metronici18n';
import {USER_TYPE} from '@/app/enums';

export enum CoinUnit {
    BTC = 'BTC',
    LTC = 'LTC',
    ETH = 'ETH',
    XRP = 'XRP',
    DOGE = 'DOGE',
    TRX = 'TRX',
    USDT = 'USDT',
    DASH = 'DASH',
    BNB = 'BNB',
    BUSD = 'BUSD',
}

export enum CoinCode {
    BTC = 'BTC',
    LTC = 'LTC',
    ETH = 'ETH',
    XRP = 'XRP',
    DOGE = 'DOGE',
    TRX = 'TRX',
    USDT_ERC20 = 'USDT-ERC20',
    USDT_TRC20 = 'USDT-TRC20',
    USDT_BEP20 = 'USDT-BEP20',
    DASH = 'DASH',
    BNB = 'BNB',
    BUSD = 'BUSD'
}


// export type TCoinCode = "BTC" | "LTC" | "ETH" | "XRP" | "DOGE" | "TRX" | "USDT-ERC20" | "USDT-TRC20" | "USDT-BEP20" | "BNB" | "BUSD";
export type TCoinCode = keyof typeof CoinCode;
export type TCoinUnit = keyof typeof CoinUnit;
export type TTransactionStatus = 'created' | 'pending' | 'completed' | 'partial' | 'failed' | 'cancelled';
export type TBlockchainTransactionStatus = 'pending' | 'confirmed' | 'failed' | 'unknown';
export type TWithdrawalStatus = 'beingVerified' | 'pending' | 'completed' | 'failed' | 'cancelled';
export type TWebhookType = 'withdraw' | 'deposit';
export type TPermissionKeys = 'getBasicInfo' | 'setBasicInfo' | 'createTransaction' | 'getTxInfo' | 'getCallbackAddress';
export type TActionsMobilePlacement = 'bottom' | 'bottom-end' | 'bottom-start' | 'top-start' | 'top-end'
export type TDashboardGraphStatType = 'transactionCount' | 'transactionAmount' | 'withdrawAmount';
export type TDashboardGraphStatRange = 'monthly' | 'weekly' | 'daily';
export type TUserType = USER_TYPE.SUPER_ADMIN | USER_TYPE.ADMIN | USER_TYPE.MERCHANT | USER_TYPE.EMPLOYEE;
export type TAdminUserType = 1 | 2;
export type TUserStatus = 1 | 2 | 3;
export type TMerchantStatus = 1 | 2 | 3;
export type RowRenderer<TData> = (data: TData, index: number, translator?: any, selectedLang?: Lang) => JSX.Element;
export type Translator = (id: string, values?: Record<string, string | number>) => string;

export const transactionStatusLabels: Record<TTransactionStatus, string> = {
    created: 'TransactionStatus.CREATED',
    pending: 'TransactionStatus.PENDING',
    completed: 'TransactionStatus.SUCCESS',
    partial: 'TransactionStatus.PARTIAL',
    failed: 'TransactionStatus.FAIL',
    cancelled: 'TransactionStatus.CANCELLED',
};

export const withdrawalStatusLabels: Record<TWithdrawalStatus, string> = {
    pending: 'TransactionStatus.PENDING',
    completed: 'TransactionStatus.SUCCESS',
    failed: 'TransactionStatus.FAIL',
    cancelled: 'TransactionStatus.CANCELLED',
    beingVerified: 'TransactionStatus.BEING_VERIFIED',
};
export const blockchainTransactionStatusLabels: Record<TBlockchainTransactionStatus, string> = {
    pending: 'TransactionStatus.PENDING',
    confirmed: 'TransactionStatus.CONFIRMED',
    failed: 'TransactionStatus.FAIL',
    unknown: 'TransactionStatus.UNKNOWN',
};


export const ipnHistoryStatusLabels: Record<TIpnHistoryStatusCode, string> = {
    200: '200 OK',
    201: '201 Created',
    400: '400 Bad Request',
    401: '401 Unauthorized',
    402: '402 Payment Required',
    403: '403 Forbidden',
    404: '404 Not Found',
    405: '405 Method Not Allowed',
    429: '429 Too Many Requests',
    500: '500 Server Error',
    501: '501 Not Implemented',
    502: '502 Bad Gateway',
    503: '503 Service Unavailable',
    504: '504 Gateway Timeout',
    510: '510 Not Extended',
    522: '522 Connection Timed Out',
    0: 'Error',
};

export const coinUnits = Object.values(CoinUnit);
export const coinCodes: TCoinCode[] = Object.keys(CoinCode) as TCoinCode[];


export interface IHistory {
    id: number;
    coinCode: TCoinCode;
    createdAt: Date;
    addressTxId: string;
    detailUrl: string;
    amount: number;
    status: TTransactionStatus;
}

export interface ICoin {
    name: string;
    code: TCoinCode;
    balance: number;
    usdValue: number;
    logo: string;
    unitCode: TCoinUnit;
    minWithdraw: number;
    withdrawFee: number;
}

export interface IApiKey {
    id: number;
    createdAt: Date;
    name: string;
    apiKey: string;
    status: boolean;
}

export interface IListCoin {
    id: number;
    name: string;
    symbol: string;
    network: string;
    chain: string;
    subSymbol: string | null;
    precision: string;
    minWithdraw: string;
    maxWithdraw: string;
    withdrawFixedFee: string;
    withdrawFeePercentage: string;
    feePercentage: string;
    fixedFee: string;
    regex: string;
    txDetailUrlFormat: string;
    status: boolean;
    isTestNetwork: boolean;
    warningText: string;
    createdAt: Date;
    updatedAt: Date;
}

export interface IUpdateCoinPayload {
    minWithdraw: number;
    maxWithdraw: number;
    withdrawFixedFee: number;
    feePercentage: number;
    fixedFee: number;
    status: boolean;
    warningText: string;
}

export interface IApiLog {
    id: number;
    createdAt: Date;
    ip: string;
    isSuccess: boolean;
    response: string;
    request: string;
}


export interface IActionItem {
    type: 'link' | 'separator';
    label?: string;
    icon?: string;
    route?: string;
    ktIconName?: string;
    onClick?: () => void;
}


export interface IPermissionItem {
    key: TPermissionKeys;
    title: string;
    description: string;
}

export type TIpnHistoryStatusCode = 0 | 200 | 201 | 400 | 401 | 402 | 403 | 404 | 500 | 503 | 510 | 522 | 429 | 501 | 502 | 504 | 405;

export interface IIpnHistory {
    id: number;
    transactionId?: string;
    withdrawId?: string;
    createdAt: Date;
    status: 'sent' | 'failed' | 'received' | 'processed';
    type: 'deposit' | 'withdraw';
    httpStatusCode: TIpnHistoryStatusCode;
    sentAt: Date;
    request: string;
    response: string;
}

export interface IOption {
    label: string,
    value: string,
}

export interface IFilter {
    [key: string]: string | object | null;
}

export interface IMerchant {
    id: number;
    merchantName: string;
    merchantEmail: string;
    status: TMerchantStatus;
    createdAt: Date;
}

export interface IMerchantListItem {
    id: number;
    merchantName: string;
}

export interface ICoinListItem {
    id: number;
    name: string;
}

export interface IUser {
    id: number;
    fullName: string;
    email: string;
    phoneNumber: string;
    userType: TUserType;
    status: TUserStatus;
    isEmailVerified: boolean;
    isPhoneVerified: boolean;
    isKYCVerified: boolean;
    merchant: IMerchant;
    createdAt: Date;
    lastLoginAt: Date;
}

export interface ITransaction {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    buyerEmail: string;
    productName: string;
    coinSymbol: string;
    coinSubSymbol: string;
    isTestNetwork: boolean;
    totalAmountAsCoin: string;
    productTotal: string;
    productPrice: string;
    productCount: number;
    productCurrency: string,
    status: TTransactionStatus;
    merchantName: string;
    merchantId: string;
    estimatedPaidAmount: string;
    paidAmountAsCoin: string;
    feeAsCoin: string;
    estimatedPaidAmountFiat: string;
    productTotalFiat: string;
    paymentAddress: string;
    blockchainTransactions: IBlockchainTransaction[];
}

export interface IBlockchainTransaction {
    id: number;
    txHash: string;
    status: TBlockchainTransactionStatus;
    amount: string;
    coinSubSymbol: string | null;
    coinSymbol: string;
    isTestNetwork: boolean;
    coinTxDetailUrlFormat: string;
    createdAt: Date;
}

export interface IAdminWithdrawal {
    id: number;
    coinSymbol: string;
    coinName: string;
    coinSubSymbol: string;
    isTestNetwork: boolean;
    amount: string;
    status: TWithdrawalStatus;
    createdAt: Date;
    txHash: string | null;
    txDetailUrlFormat: string;
    userId: number | null;
    userFullName: string | null;
    merchantId: string;
    merchantName: string;
    isAdminWithdraw: boolean;
}

export interface IDataTableHead {
    text: string;
    name?: string;
    isSortable?: boolean;
    align?: string;
    width?: string;
}

export interface ISortState {
    field: string;
    direction: 'asc' | 'desc';
}

export interface ILoginLog {
    id: number;
    createdAt: Date;
    ip: string;
    isSuccess: boolean;
    userAgent: string;
}

export interface IGetMerchantResult {
    id: string;
    merchantName: string;
    merchantEmail: string;
    merchantUrl: string;
    status: TMerchantStatus;
    ownerId: number;
    ownerName: string;
    ownerEmail: string;
    createdAt: Date;
}

export interface IBalance {
    coinId: number;
    coinName: string;
    coinSymbol: string;
    coinSubSymbol: string | null;
    isTestNetwork: boolean;
    balance: string;
    usdBalance: string;
    mainPublicAddress: string;
}

export interface IWithdrawalRecord {
    id: number;
    coinSymbol: string;
    coinName: string;
    coinSubSymbol: string;
    isTestNetwork: boolean;
    amount: string;
    status: TWithdrawalStatus;
    type: TWebhookType;
    createdAt: Date;
    txHash: string | null;
    txDetailUrlFormat: string;
    userId: number | null;
    userFullName: string | null;
    fiatAmount: string;
    usdAmount: string;
}

export interface ICheckWithdrawableResult {
    minWithdrawalAmount: string;
    balance: string;
    withdrawFixedFee: string;
    withdrawFeePercentage: string;
    coinSymbol: string;
    coinSubSymbol: string;
    isTestNetwork: boolean;
    coinName: string;
}

export interface IStartWithdrawResponse {
    validationId: string;
    validationType: number;
}

export interface IConfirmWithdrawResponse {
    message: string;
}


export interface IWithdrawalCoin {
    name: string;
    symbol: string;
    subSymbol: string;
    isTestNetwork: boolean;
    minWithdrawalAmount: string;
    withdrawFixedFee: string;
    withdrawFeePercentage: string;
}

export interface ICoinConfig {
    id: number;
    coinName: string;
    coinSymbol: string;
    coinSubSymbol: string | null;
    isTestNetwork: boolean;
    name: string;
    enabled: boolean;
    discountRate: string;
    feeDiscountRate: string;
}

export interface IApiKeyDetail {
    id: number;
    name: string;
    apiKey: string;
    status: boolean;
    permissions: IApiPermission[];
}

export interface IApiPermission {
    permissionId: number;
    name: string;
    enabled: boolean;
}

export interface IUpdateApiPayload {
    name: string;
    status: boolean;
    permissions: IApiPermission[];
}

export interface IGeneralStatsResponse {
    TOTAL_ORDER_COUNT: number;
    TOTAL_ORDER_AMOUNT: number;
    TOTAL_BALANCE: number;
    TOTAL_WITHDRAWAL_AMOUNT: number;
    CUSTOMER_COUNT: number;
    SUCCESSFUL_ORDER_RATE: number;
    EMPLOYEE_COUNT: number;
    IPN_COUNT: number;
    API_KEY_COUNT: number;
}

export interface IGraphStatsResponse {
    total: number;
    changeRate: number;
    values: Record<string, number>;
}

export interface IAdminConfigResponse {
    id: number;
    key: string;
    value: string;
}


export interface IChartCard {
    key: string;
    title: string;
    subtitle: string;
    isUsd?: boolean;
}

export interface IChartCardProps {
    merchantId?: string;
    cards: IChartCard[];
    fetchGraphStats: TFetchGraphStats;
}

export type TFetchGraphStats = (type: TDashboardGraphStatType, range: TDashboardGraphStatRange) => Promise<AxiosResponse<IGraphStatsResponse>>;

export interface IStatCard {
    key: string;
    title: string;
    svg: string;
    isUsd?: boolean;
    isPercentage?: boolean;
}

export type TFetchGeneralStats = () => Promise<AxiosResponse<IGeneralStatsResponse>>;


export interface IDateRange {
    startDate: Date;
    endDate: Date;
}
