import {useState} from 'react';
import {useFormik} from 'formik';
import {register, registerVerifyCode} from '../core/_requests';
import {Link} from 'react-router-dom';
import {useAuth} from '../core/Auth';
import Input from './tools/inputs/Input';
import Check from './tools/inputs/Check';
import Submit from './tools/buttons/Submit';
import Title from './tools/Title';
import FormikStatus from './tools/FormikStatus';
import SubmitPhone from './tools/modals/SubmitPhone';
import {AuthRegisterConfirmResponseModel} from '@/app/modules/auth';
import {registrationInitialValues} from '@/app/utils/initialValues';
import {registrationSchema} from '@/app/utils/yupSchema';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import 'react-international-phone/style.css';


export function Registration() {

    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);

    const [loading, setLoading] = useState(false);
    const {saveAuth, setCurrentUser} = useAuth();
    const [auth, setAuth] = useState<any>({});
    const [showModal, setShowModal] = useState<boolean>(false);
    const signIn = async (response: AuthRegisterConfirmResponseModel) => {
        setShowModal(false);
        saveAuth({
            api_token: response.accessToken,
        });
        setCurrentUser(response.user);
    };

    const verify = async (validationId: string, code: string) => {
        try {
            const {data: verify} = await registerVerifyCode(validationId, code);

            if (!verify.accessToken) {
                throw new Error(_('Registration.INVALID_VERIFICATION_CODE'));
            }

            await signIn(verify);
        } catch (error: any) {
            throw new Error(error.message);
        }
    };

    const formik = useFormik({
        initialValues: registrationInitialValues,
        validationSchema: registrationSchema(_),
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true);
            try {
                const {data: auth} = await register(
                    values.email,
                    values.fullName,
                    values.phoneNumber.toString(),
                    values.password,
                );

                if (auth.id) {
                    setAuth(auth);
                    // setUser(user)
                    setShowModal(true);
                }
                // saveAuth(auth)
                // setCurrentUser(user)
            } catch (error) {
                console.error(error);
                saveAuth(undefined);
                setStatus(getErrorMessage(error));
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });


    return (
        <>
            <SubmitPhone onEntered={verify} validationId={auth?.validationId} onSuccess={signIn} handleClose={() => setShowModal(false)} show={showModal} />
            <form
                className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
                noValidate
                id="kt_login_signup_form"
                onSubmit={formik.handleSubmit}
            >
                <Title title={_('Registration.TITLE')}>
                    {_('Registration.SUBTITLE')}
                </Title>

                <FormikStatus status={formik.status} />

                <Input label={_('Registration.FULL_NAME')} name={'fullName'} type={'text'} formik={formik} />
                <Input label={_('Registration.EMAIL')} name={'email'} type={'email'} formik={formik} />
                {/*<Input label={_('Registration.PHONE_NUMBER')} name={'phoneNumber'} type={'text'} numericKeyboard formik={formik} />*/}
                <Input label={_('Registration.PHONE_NUMBER')} name={'phoneNumber'} type={'phoneNumber'} numericKeyboard formik={formik} />
                <Input label={_('Registration.PASSWORD')} name={'password'} type={'password'} formik={formik} passwordMeter={true} />
                <Input label={_('Registration.PASSWORD_REPEAT')} name={'passwordRepeat'} type={'password'} formik={formik} />

                <Check formik={formik} name={'acceptTerms'}>
                    {/* TODO: Kullanım koşulları sözleşmesi eklenecek */}
                    <a href="https://google.com.tr" target="_blank" rel="noreferrer" className="ms-1 link-primary">{_('Registration.TERMS')}</a>
                    {' '} {_('Registration.TERMS_AGREEMENT')}
                </Check>


                {/* begin::Form group */}
                <div className="text-center">
                    <Submit id={'registerButton'}
                            disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
                            loading={loading} label={_('Registration.SUBMIT_BUTTON')}
                            className="btn-lg w-100 mb-5" />

                    <Link to="/auth/login">
                        <button
                            type="button"
                            id="kt_login_signup_form_cancel_button"
                            className="btn btn-lg btn-light-primary w-100 mb-5"
                        >
                            {_('Registration.CANCEL_BUTTON')}
                        </button>
                    </Link>
                </div>
                {/* end::Form group */}
            </form>
        </>
    );
}
