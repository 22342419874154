import React, {type FC} from 'react';
import {Modal} from 'react-bootstrap';
import {KTIcon, toAbsoluteUrl} from '@/_metronic/helpers';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    show: boolean;
    handleClose: () => void;
    onConfirm: () => Promise<void>;
    withdrawDetails: {
        address: string;
        amount: string;
        fee?: string;
        total: number;
    };
}

const ConfirmModal: FC<Props> = ({show, handleClose, onConfirm, withdrawDetails}) => {
    const _ = useTranslation();
    const [loading, setLoading] = React.useState<boolean>(false);

    const submit = async () => {
        setLoading(true);
        try {
            await onConfirm();
        } catch (error: any) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            id="kt_modal_withdraw_confirm"
            tabIndex={-1}
            aria-hidden="true"
            dialogClassName="modal-dialog modal-dialog-centered mw-500px"
            show={show}
            onHide={handleClose}
            backdrop={'static'}
        >
            <div className="modal-header">
                <h2>{_('Withdraw.CONFIRM_SENDING')}</h2>
                <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={handleClose}>
                    <KTIcon className="fs-1" iconName="cross" />
                </div>
            </div>
            <div className="modal-body scroll-y pt-10 pb-15 px-lg-17">
                <div className="text-center">
                    <img className={'mw-75px mb-5'} src={toAbsoluteUrl('/media/svg/custom/money-withdrawal.svg')}
                         alt="" />
                    <h6 className="mb-8 fw-bolder text-gray-600 text-hover-primary text-uppercase">{_('Withdraw.TRANSACTION_SUMMARY')}</h6>
                    <div className="mb-6">
                        <div className="fw-semibold text-gray-600 fs-7">{_('Withdraw.RECIPIENT_ADDRESS')}:</div>

                        <div className="fw-bold text-gray-800 fs-6">{withdrawDetails.address}</div>
                    </div>
                    <div className="mb-6">
                        <div className="fw-semibold text-gray-600 fs-7">{_('Withdraw.WITHDRAWAL_AMOUNT')}:</div>

                        <div className="fw-bold text-gray-800 fs-6">{withdrawDetails.amount}</div>
                    </div>
                    <div className="mb-6">
                        <div className="fw-semibold text-gray-600 fs-7">{_('Withdraw.TRANSACTION_FEE')}:</div>

                        <div className="fw-bold text-gray-800 fs-6">{withdrawDetails.fee}</div>
                    </div>
                    <div className="mb-6">
                        <div className="fw-semibold text-gray-600 fs-7">{_('Withdraw.TOTAL_FEE')}:</div>

                        <div className="fw-bold text-gray-800 fs-6">{withdrawDetails.total.toFixed(8)}</div>
                    </div>
                    <div className="d-flex justify-content-center gap-5">
                        <button disabled={loading} onClick={submit} className="btn btn-sm btn-light-primary  px-6">
                            {loading ? (
                                <span className="indicator-progress" style={{display: 'block'}}>
                                    {_('ConfirmModal.PROGRESS')}{' '}
                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                </span>
                            ) : (
                                <span className="indicator-label">{_('ConfirmModal.CONFIRM')}</span>
                            )}
                        </button>
                        <button disabled={loading} onClick={handleClose} className="btn btn-sm btn-light-danger btn-active-light-primary ">{_('ConfirmModal.CANCEL')}</button>
                    </div>


                </div>

            </div>
        </Modal>
    );
};

export default ConfirmModal;