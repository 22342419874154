import {AdminMerchantDetailSubRouteConstants} from '@/app/constants/routes.constants';

export const MerchantDetailSubMenu = [
    {
        title: 'MerchantMenu.STATISTICS',
        path: AdminMerchantDetailSubRouteConstants.STATISTICS,
    },
    {
        title: 'MerchantMenu.MEMBERS',
        path: AdminMerchantDetailSubRouteConstants.USERS,
    },
    {
        title: 'MerchantMenu.WALLET',
        path: AdminMerchantDetailSubRouteConstants.WALLET,
    },
    {
        title: 'MerchantMenu.REPORTS',
        path: AdminMerchantDetailSubRouteConstants.REPORTS,
    },
    {
        title: 'MerchantMenu.WITHDRAWAL_HISTORY',
        path: AdminMerchantDetailSubRouteConstants.WITHDRAWALS,
    },
    {
        title: 'MerchantMenu.TRANSACTION_HISTORY',
        path: AdminMerchantDetailSubRouteConstants.TRANSACTIONS,
    },
    {
        title: 'MerchantMenu.CREATE_ORDER',
        path: AdminMerchantDetailSubRouteConstants.CREATE_MANUEL_ORDER,
    },
    {
        title: 'MerchantMenu.MERCHANT_SETTINGS',
        path: AdminMerchantDetailSubRouteConstants.MERCHANT_SETTINGS,
    },
    {
        title: 'MerchantMenu.IPN_HISTORY',
        path: AdminMerchantDetailSubRouteConstants.IPN_HISTORY,
    },
    {
        title: 'MerchantMenu.COIN_SETTINGS',
        path: AdminMerchantDetailSubRouteConstants.COIN_ACCEPTANCE_SETTINGS,
    },
    {
        title: 'MerchantMenu.API_KEYS',
        path: AdminMerchantDetailSubRouteConstants.API_KEYS,
    },
];