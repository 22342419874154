import {IDataTableHead} from '@/app/interfaces';

export const CoinListTableHead: IDataTableHead[] = [
    {
        text: 'CoinListTH.COIN',
        isSortable: false,
        align: 'left',
        width: '100px',
    },
    {
        text: 'CoinListTH.STATUS',
        isSortable: false,
        align: 'center',
        width: '100px',
    },
    {
        text: 'CoinListTH.MIN_WITHDRAW',
        isSortable: false,
        align: 'center',
        width: '50px',
    },
    {
        text: 'CoinListTH.MAX_WITHDRAW',
        isSortable: false,
        align: 'center',
        width: '50px',
    },
    {
        text: 'CoinListTH.WITHDRAW_FEE',
        isSortable: false,
        align: 'center',
        width: '50px',
    },
    {
        text: 'CoinListTH.WITHDRAW_FEE_PERCENTAGE',
        isSortable: false,
        align: 'center',
        width: '50px',
    },
    {
        text: 'CoinListTH.FIXED_FEE',
        isSortable: false,
        align: 'center',
        width: '50px',
    },
    {
        text: 'CoinListTH.FEE_PERCENTAGE',
        isSortable: false,
        align: 'center',
        width: '50px',
    },
    {
        text: 'CoinListTH.IS_TEST_NETWORK',
        isSortable: false,
        align: 'center',
        width: '50px',
    },
    {
        text: 'CoinListTH.WARNING_TEXT',
        isSortable: false,
        align: 'center',
        width: '100px',
    },
    {
        text: 'CoinListTH.ACTIONS',
        width: '50px',
        align: 'end',
    },

];