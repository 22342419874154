import React, {FC} from 'react';
import Submit from '@/app/modules/auth/components/tools/buttons/Submit';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    showForm: boolean;
    formik: any;
    loading: boolean;
    setShowForm: (value: boolean) => void;
    children: React.ReactNode;
    buttonText: string;
    label: string;
    value: string;
    className?: string;
}

const AccountSettingsForm: FC<Props> = ({showForm, formik, loading, setShowForm, children, value, buttonText, label, className}) => {
    const _ = useTranslation();
    return (
        <div className={`d-flex flex-wrap align-items-center ${className}`}>
            <div className={showForm ? 'd-none' : ''}>
                <div className="fs-6 fw-bolder mb-1">{label}</div>
                <div className="fw-bold text-gray-600">{value}</div>
            </div>
            <div className={`flex-row-fluid ${!showForm ? 'd-none' : ''}`}>
                <form onSubmit={formik.handleSubmit} className="form" noValidate>
                    {children}

                    <div className="d-flex gap-2">
                        <Submit disabled={formik.isSubmitting || !formik.isValid} loading={loading} label={buttonText} />
                        <button type="button" onClick={() => setShowForm(false)} className="btn btn-color-gray-400 btn-active-light-primary ">{_('General.CANCEL')}</button>
                    </div>
                </form>
            </div>
            <div className={`ms-auto ${showForm ? 'd-none' : ''}`}>
                <button onClick={() => setShowForm(true)} className="btn btn-light btn-active-light-primary">{buttonText}</button>
            </div>
        </div>
    );
};

export default AccountSettingsForm;