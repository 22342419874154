import {useState} from 'react';
import {Link} from 'react-router-dom';
import {useFormik} from 'formik';
import {login, verifyCode} from '../core/_requests';
import {useAuth} from '../core/Auth';
import SubmitPhone from './tools/modals/SubmitPhone';
import Submit from './tools/buttons/Submit';
import Input from './tools/inputs/Input';
import Title from './tools/Title';
import FormikStatus from './tools/FormikStatus';
import ForgotPassword from './tools/modals/ForgotPassword';
import {AuthLoginConfirmResponseModel} from '@/app/modules/auth';
import {loginSchema} from '@/app/utils/yupSchema';
import {loginInitialValues} from '@/app/utils/initialValues';
import {useTranslation} from '@/app/modules/hooks/useTranslation';


export function Login() {
    const _ = useTranslation();
    const [loading, setLoading] = useState(false);
    const {saveAuth, setCurrentUser} = useAuth();
    const [auth, setAuth] = useState<any>({});
    const [show2faModal, setShow2faModal] = useState<boolean>(false);
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState<boolean>(false);

    const handleClose = () => setShow2faModal(false);

    const signIn = async (response: AuthLoginConfirmResponseModel) => {
        setShow2faModal(false);
        saveAuth({
            api_token: response.accessToken,
        });
        setCurrentUser(response.user);
    };

    const verify = async (validationId: string, code: string) => {
        try {
            const {data: verify} = await verifyCode(validationId, code);
            if (!verify.accessToken) {
                throw new Error(_('Login.INVALID_CODE'));
            }
            await signIn(verify);
        } catch (e: any) {
            throw new Error(e.message);
        }
    };

    const formik = useFormik({
        initialValues: loginInitialValues,
        validationSchema: loginSchema(_),
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true);
            try {
                const {data: auth} = await login(values.email, values.password);
                // const {data: user} = await getUserByToken(auth.api_token)

                if (auth.id) {
                    setAuth(auth);
                    // setUser(user)
                }

                setShow2faModal(true);
            } catch (error) {
                console.error(error);
                saveAuth(undefined);
                setStatus(_('Login.INVALID_CREDENTIALS'));
            } finally {
                setLoading(false);
                setSubmitting(false);
            }
        },
    });

    return (
        <>
            <ForgotPassword show={showForgotPasswordModal} handleClose={() => setShowForgotPasswordModal(false)} />
            <SubmitPhone onEntered={verify} validationId={auth?.validationId} handleClose={handleClose} show={show2faModal} onSuccess={signIn} />
            <form
                className="form w-100"
                onSubmit={formik.handleSubmit}
                noValidate
                id="kt_login_signin_form"
            >
                <Title title={_('Login.LOGIN_TITLE')}>
                    {_('Login.LOGIN_SUBTITLE')}
                </Title>
                <FormikStatus status={formik.status} />
                <Input label={_('Login.EMAIL_LABEL')} name={'email'} type={'email'} formik={formik} />
                <Input label={_('Login.PASSWORD_LABEL')} name={'password'} type={'password'} formik={formik} />
                <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                    <div />
                    <button type={'button'} onClick={() => setShowForgotPasswordModal(true)} className="link-primary btn btn-sm">
                        {_('Login.FORGOT_PASSWORD')}
                    </button>
                </div>
                <div className="d-grid mb-10">
                    <Submit id={'loginButton'} loading={loading} label={_('Login.SUBMIT')} disabled={formik.isSubmitting || !formik.isValid} />
                </div>
                <div className="text-gray-500 text-center fw-semibold fs-6">
                    {_('Login.NOT_MEMBER_YET')}{' '}
                    <Link to="/auth/registration" className="link-primary">
                        {_('Login.REGISTER')}
                    </Link>
                </div>
            </form>
        </>
    );
}
