export const createUpdateUserInitialValues = {
    fullName: '',
    email: '',
    phoneNumber: '',
    userType: '2',
};

export const createUpdateEmployeeInitialValues = {
    fullName: '',
    email: '',
    phoneNumber: '',
    password: '',
};

export const passwordUpdateInitialValues = {
    currentPassword: '',
    newPassword: '',
    passwordConfirmation: '',

};


export const registrationInitialValues = {
    fullName: '',
    email: '',
    phoneNumber: '',
    password: '',
    passwordRepeat: '',
    acceptTerms: false,
};

export const forgotPasswordInitialValues = {
    password: '',
    passwordRepeat: '',
};

export const loginInitialValues = {
    email: '',
    password: '',
};

export const apiKeyDetailsInitialValues = {
    keyName: '',
    isActive: true,
};

export const merchantSettingsInitialValues = {
    id: '',
    merchantName: '',
    merchantEmail: '',
    merchantUrl: '',
    ipnUrl: '',
    ipnSecret: '',
    withdrawCallbackUrl: '',
};
export const updateCoinInitialValues = {
    minWithdraw: '',
    maxWithdraw: '',
    withdrawFixedFee: '',
    withdrawFeePercentage: '',
    feePercentage: '',
    fixedFee: '',
    status: true,
    warningText: '',
};

export const createOrderInitialValues = {
    productName: '',
    productPrice: null,
    quantity: null,
};

export const withdrawalInitialValues = {
    amount: '',
    address: '',
};