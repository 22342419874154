import {createContext, ReactNode, useContext, useEffect, useState} from "react";

interface ContainerContextProps {
    isContainer: boolean;
    setIsContainer: (value: boolean) => void;
}

const defaultValue: ContainerContextProps = {
    isContainer: false,
    setIsContainer: () => {
    }
}


const ContainerContext = createContext<ContainerContextProps>(defaultValue);

export const useContainer = (): ContainerContextProps => {
    const context = useContext(ContainerContext);
    return context;
}

export const useMakeContainer = () => {
    const {setIsContainer} = useContainer();

    useEffect(() => {
        setIsContainer(true);
        return () => {
            setIsContainer(false);
        }
    }, [setIsContainer]);
}

interface ContainerProviderProps {
    children: ReactNode;
}

export const ContainerProvider = ({children}: ContainerProviderProps) => {
    const [isContainer, setIsContainer] = useState<boolean>(false);


    return (
        <ContainerContext.Provider value={{isContainer, setIsContainer}}>
            {children}
        </ContainerContext.Provider>
    )
}