export enum MerchantRouteConstants {
    DASHBOARD = '/dashboard',
    ACCOUNT_SETTINGS = '/account-settings',
    MY_WALLET = '/my-wallet',
    MY_WALLET_OVERVIEW = '/my-wallet/overview',
    DEPOSIT_HISTORY = '/my-wallet/deposit-history',
    WITHDRAWAL_HISTORY = '/my-wallet/withdraw-history',
    DEPOSIT = '/my-wallet/deposit/:coin',
    WITHDRAW = '/my-wallet/withdraw/:coin',
    TRANSACTION_HISTORY = '/my-wallet/transaction-history',
    TRANSACTION_DETAIL = '/my-wallet/transaction-history/:id',
    API_KEYS = '/api-keys',
    UPDATE_API_KEY = '/api-keys/update/:id',
    VIEW_API_KEY_LOG = '/api-keys/view-log/:id',
    CREATE_MANUEL_ORDER = '/merchant/create-manuel-order',
    MERCHANT_SETTINGS = '/merchant/settings',
    MERCHANT_COIN_ACCEPTANCE_SETTINGS = '/merchant/coin-acceptance-settings',
    MERCHANT_IPN_HISTORY = '/merchant/ipn-history',
    PAYMENT = '/payment',
    EMPLOYEES = '/employees',
    EMPLOYEE_DETAIL = '/employees/:id',
    REPORTS = '/reports',
}


export enum AdminRouteConstants {
    DASHBOARD = `/admin/dashboard`,
    MERCHANTS = `/admin/merchants`,
    MERCHANT_DETAIL = `/admin/merchants/:id`,
    USERS = `/admin/users`,
    USER_DETAIL = `/admin/users/:id`,
    TRANSACTIONS = `/admin/transactions`,
    TRANSACTION_DETAIL = `/admin/transactions/:id`,
    BLOCKCHAIN_TRANSACTIONS = `/admin/blockchain-transactions`,
    WITHDRAWALS = `/admin/withdrawals`,
    WITHDRAW = `/admin/withdraw/:coin`,
    WALLETS = `/admin/wallets`,
    SETTINGS = `/admin/settings`,
    COIN_SETTINGS = `/admin/coin-settings`,
    UPDATE_COIN_SETTING = `/admin/coin-settings/:id`,
    REPORTS = `/admin/reports`,
}

export enum AdminMerchantDetailSubRouteConstants {
    STATISTICS = `statistics`,
    USERS = `users`,
    WALLET = `wallet`,
    WITHDRAWALS = `withdrawal-history`,
    TRANSACTIONS = `transaction-history`,
    CREATE_MANUEL_ORDER = `create-manuel-order`,
    MERCHANT_SETTINGS = `merchant-settings`,
    COIN_ACCEPTANCE_SETTINGS = `coin-acceptance-settings`,
    IPN_HISTORY = `ipn-history`,
    API_KEYS = `api-keys`,
    UPDATE_API_KEY = `api-keys/update/:id`,
    VIEW_API_KEY_LOG = `api-keys/view-log/:id`,
    TRANSACTION_DETAIL = `transaction-history/:id`,
    REPORTS = `reports`,
}