import {TMerchantStatus, TUserStatus, TUserType} from '@/app/interfaces';
import {CURRENCY_SYMBOL, USER_TYPE} from '@/app/enums';

export const getUserType = (userType: TUserType) => {
    switch (userType) {
        case USER_TYPE.SUPER_ADMIN:
            return 'UserType.SUPER_ADMIN';
        case USER_TYPE.ADMIN:
            return 'UserType.ADMIN';
        case USER_TYPE.MERCHANT:
            return 'UserType.MERCHANT';
        case USER_TYPE.EMPLOYEE:
            return 'UserType.EMPLOYEE';
    }
};

export const getUserStatus = (userStatus: TUserStatus) => {
    switch (userStatus) {
        case 1:
            return 'UserStatus.INACTIVE';
        case 2:
            return 'UserStatus.ACTIVE';
        case 3:
            return 'UserStatus.BLOCKED';
    }
};

export const getUserStatusBadgeInfo = (userStatus: TUserStatus) => {
    switch (userStatus) {
        case 1:
            return {
                isLight: true,
                color: 'dark',
                text: getUserStatus(userStatus),
            };
        case 2:
            return {
                isLight: true,
                color: 'success',
                text: getUserStatus(userStatus),
            };
        case 3:
            return {
                isLight: false,
                color: 'danger',
                text: getUserStatus(userStatus),
            };
    }
};

export const getMerchantStatus = getUserStatus;

export const getMerchantStatusBadgeInfo = (userStatus: TMerchantStatus) => {
    switch (userStatus) {
        case 1:
            return {
                isLight: true,
                color: 'dark',
                text: getMerchantStatus(userStatus),
            };
        case 2:
            return {
                isLight: true,
                color: 'success',
                text: getMerchantStatus(userStatus),
            };
        case 3:
            return {
                isLight: false,
                color: 'danger',
                text: getMerchantStatus(userStatus),
            };
    }
};

export const getCurrencySymbol = (currency: string) => {
    switch (currency) {
        case 'USD':
            return CURRENCY_SYMBOL.USD;
        case 'EUR':
            return CURRENCY_SYMBOL.EUR;
        case 'GBP':
            return CURRENCY_SYMBOL.GBP;
        case 'TRY':
            return CURRENCY_SYMBOL.TRY;
    }
};

export const amountWithCurrency = (amount: string, currency: string) => {
    if (!amount) return '';
    const currencySymbol = getCurrencySymbol(currency);
    if (currency === 'TRY') {
        return `${amount} ${currencySymbol}`;
    } else {
        return `${currencySymbol}${amount}`;
    }
};