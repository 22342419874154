import {FC, Suspense} from 'react';
import {Route, Routes, Navigate} from 'react-router-dom';
import {MasterLayout} from '@/_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import {getCSSVariableValue} from '@/_metronic/assets/ts/_utils';
import {WithChildren} from '@/_metronic/helpers';
import {AdminRouteConstants, MerchantRouteConstants} from '../constants/routes.constants';
import {MerchantRoutes} from '@/app/routing/MerchantRoutes';
import {AdminRoutes} from '@/app/routing/AdminRoutes';
import {merchantExcludedPaths, superAdminExcludedPaths} from '@/app/utils/excludedPaths';

const MerchantPrivateRoutes = ({isMerchant = false}) => {
    let merchantRoutes = MerchantRoutes;
    if (!isMerchant) {
        merchantRoutes = MerchantRoutes.filter((route) => !merchantExcludedPaths.includes(route.path));
    }
    return (
        <Routes>
            <Route element={<MasterLayout />}>
                <Route path="auth/*" element={<Navigate to={MerchantRouteConstants.DASHBOARD} />} />
                <Route path={MerchantRouteConstants.MY_WALLET} element={<Navigate to={MerchantRouteConstants.MY_WALLET_OVERVIEW} />} />

                {/* Pages */}
                {merchantRoutes.map((route, index) => (
                    <Route key={index} path={route.path} element={<SuspensedView>{route.element}</SuspensedView>} />
                ))}

                {/* Page Not Found */}
                <Route path="*" element={<Navigate replace={true} to="/error/404" />} />
            </Route>
        </Routes>
    );
};

const AdminPrivateRoutes = ({isSuperAdmin = false}) => {
    let adminRoutes = AdminRoutes;
    if (!isSuperAdmin) {
        adminRoutes = AdminRoutes.filter((route) => !superAdminExcludedPaths.includes(route.path));
    }


    return (
        <Routes>
            <Route element={<MasterLayout />}>
                <Route path="auth/*" element={<Navigate to={AdminRouteConstants.DASHBOARD} />} />

                {/* Pages */}
                {adminRoutes.map((route, index) => (
                    <Route key={index} path={route.path} element={<SuspensedView>{route.element}</SuspensedView>} />
                ))}

                {/* Page Not Found */}
                <Route path="*" element={<Navigate replace={true} to="/error/404" />} />
            </Route>
        </Routes>
    );
};


const SuspensedView: FC<WithChildren> = ({children}) => {
    const baseColor = getCSSVariableValue('--bs-primary');
    TopBarProgress.config({
        barColors: {
            '0': baseColor,
        },
        barThickness: 1,
        shadowBlur: 5,
    });
    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export {MerchantPrivateRoutes, AdminPrivateRoutes};
