import React, {FC, ReactElement, useEffect, useMemo} from 'react';
import Filter from '@/app/modules/components/Filter';


interface Props {
    title: string;
    // filters: FilterProps[];
    children: ReactElement;
    onReset?: () => void;
    onFilter?: () => void;
    id: string;
}

const FilterAccordion: FC<Props> = props => {
    const accordionId = useMemo(() => `accordion-${props.id}`, [props.id]);
    const [openedStart] = React.useState<boolean>(localStorage.getItem(accordionId) === 'true' || false);
    const [opened, setOpened] = React.useState<boolean>(false);

    const accordionCollapseHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (opened) {
            setOpened(false);
            localStorage.setItem(accordionId, 'false');
        } else {
            setOpened(true);
            localStorage.setItem(accordionId, 'true');
        }
    };
    useEffect(() => {
        const opened = localStorage.getItem(accordionId);
        if (opened === 'true') {
            setOpened(true);
        } else {
            setOpened(false);
        }
    }, [accordionId]);


    return (
        <div className="accordion accordion-icon-toggle AccordionFilters" id="kt_accordion_1">
            <div className="accordion-item">
                <h2 className="accordion-header" id="kt_accordion_1_header_1">
                    <button
                        className={`accordion-button fs-4 fw-bold ${openedStart ? '' : 'collapsed'} `}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#kt_accordion_1_body_1"
                        aria-expanded="false"
                        aria-controls="kt_accordion_1_body_1"
                        onClick={accordionCollapseHandler}
                    >
                        {props.title}
                    </button>
                </h2>
                <div
                    id="kt_accordion_1_body_1"
                    className={`accordion-collapse collapse ${openedStart ? 'show' : ''} `}
                    aria-labelledby="kt_accordion_1_header_1"
                    data-bs-parent="#kt_accordion_1"
                >
                    <div className="accordion-body">
                        <Filter onReset={props.onReset} onFilter={props.onFilter}>
                            {props.children}
                        </Filter>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default FilterAccordion;