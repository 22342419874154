import clsx from 'clsx';
import {WithChildren} from '../../../helpers';
import {useLoading} from '@/app/providers/LoadingProvider';
import {useContainer} from '@/app/providers/ContainerProvider';

const Content = ({children}: WithChildren) => {

    const {isLoading} = useLoading();
    const {isContainer} = useContainer();

    return (
        <div
            id="kt_app_content"
            className={'app-content flex-column-fluid'}
        >
            <div
                id="kt_app_content_container"
                className={clsx('app-container', {
                    'app-content--loading': isLoading,
                    'fluid': !isContainer,
                    'container-xxl': isContainer,
                })}
            >
                <>
                    <div className={'pageLoader'}>
                        <div className="cssload-loader">
                            <div className="cssload-inner cssload-one"></div>
                            <div className="cssload-inner cssload-two"></div>
                            <div className="cssload-inner cssload-three"></div>
                        </div>
                    </div>

                    {children}
                </>
            </div>
        </div>
    );
};

export {Content};
