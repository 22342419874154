import {type FC} from 'react';
import StatsWithChartCard from '@/app/pages/common/Dashboard/StatsWithChartCards/StatsWithChartCard/StatsWithChartCard';
import {IChartCardProps, TDashboardGraphStatType} from '@/app/interfaces';

const StatsWithChartCards: FC<IChartCardProps> = ({fetchGraphStats, cards}) => {
    return (
        <>
            {cards.map((card) => (
                <div className="col-md-4 col-xl-4 ">
                    <StatsWithChartCard
                        key={`stats-with-chart-card-${card.key}`}
                        title={card.title}
                        subtitle={card.subtitle}
                        isUsd={card.isUsd}
                        type={card.key as TDashboardGraphStatType}
                        fetchGraphStats={fetchGraphStats}
                    />
                </div>
            ))}
        </>
    );
};

export default StatsWithChartCards;