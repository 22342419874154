import {type FC} from 'react';
import Withdraw from '@/app/pages/common/Withdraw/Withdraw';
import {useNavigate, useParams} from 'react-router-dom';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';


const MerchantWithdraw: FC = () => {
    const {coin: coinId} = useParams();
    const navigate = useNavigate();

    if (!coinId) {
        navigate(MerchantRouteConstants.MY_WALLET_OVERVIEW);
        return null;
    }
    return <Withdraw coinId={coinId} />;
};

export default MerchantWithdraw;