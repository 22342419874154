import {IApiLog} from "@/app/interfaces";
import {faker} from "@faker-js/faker/locale/tr";

export const getDummyApiLogs = (count: number): IApiLog[] => {
    faker.seed(100);
    return Array.from({length: count}).map((_, index) => {
        return {
            id: index + 1,
            createdAt: faker.date.past(),
            ip: faker.internet.ipv4(),
            isSuccess: faker.datatype.boolean(),
            response: JSON.stringify({
                response: faker.lorem.sentence({min: 2, max: 5}),
                test: faker.lorem.sentence({min: 2, max: 5}),
            }),
            request: JSON.stringify({
                request: faker.lorem.sentence({min: 2, max: 5}),
                test: faker.lorem.sentence({min: 2, max: 5}),
            }),
        }
    });
}