import React, {FC, ReactNode} from 'react';

interface Props {
    currentPage: number;
    totalPages: number;
    onChange: (page: number) => void;
}

const Pagination: FC<Props> = ({currentPage, totalPages, onChange}) => {

    const handleClick = (page: number, event?: React.MouseEvent<HTMLAnchorElement>) => {
        if (event)
            event.preventDefault();
        onChange(page);
    };
    const renderPages = () => {
        const pages: ReactNode[] = [];
        let startPage = Math.max(1, currentPage - 2);
        let endPage = Math.min(totalPages, currentPage + 2);

        if (currentPage <= 4) {
            startPage = 1;
            endPage = Math.min(5, totalPages);
        } else if (currentPage >= totalPages - 3) {
            startPage = Math.max(totalPages - 4, 1);
            endPage = totalPages;
        }

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <li key={i} className={`page-item ${currentPage === i ? 'active' : ''}`}>
                    <button className="page-link" onClick={(e) => handleClick(i)}>
                        {i}
                    </button>
                </li>
            );
        }

        if (startPage > 2) {
            pages.unshift(
                <li key="start-ellipsis" className="page-item disabled">
                    <button className="page-link">
                        ...
                    </button>
                </li>
            );
        }

        if (startPage > 1) {
            pages.unshift(
                <li key="page1" className={`page-item ${currentPage === 1 ? 'active' : ''}`}>
                    <button className="page-link" onClick={(e) => handleClick(1)}>
                        1
                    </button>
                </li>
            );
        }

        if (endPage < totalPages - 1) {
            pages.push(
                <li key="end-ellipsis" className="page-item disabled">
                    <button className="page-link">
                        ...
                    </button>
                </li>
            );
        }

        if (endPage < totalPages) {
            pages.push(
                <li key={totalPages} className={`page-item ${currentPage === totalPages ? 'active' : ''}`}>
                    <button className="page-link" onClick={(e) => handleClick(totalPages)}>
                        {totalPages}
                    </button>
                </li>
            );
        }

        return pages;
    };

    return (

        <ul className="pagination">
            <li className={`page-item previous ${currentPage === 1 ? 'disabled' : ''}`}>
                <button className="page-link" onClick={(e) => handleClick(currentPage - 1)}>
                    <i className="previous"></i>
                </button>
            </li>
            {renderPages()}
            <li className={`page-item next ${currentPage === totalPages ? 'disabled' : ''}`}>
                <button className="page-link" onClick={(e) => handleClick(currentPage + 1)}>
                    <i className="next"></i>
                </button>
            </li>
        </ul>
    );
};

export default Pagination;
