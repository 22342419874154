import {type FC, useEffect, useMemo} from 'react';
import {getCustomSvgUrl, numberFormat} from '@/app/modules/helpers';
import {useAreaLoading} from '@/app/modules/hooks/useAreaLoading';

interface Props {
    title: string;
    svg: string;
    value: string | number;
    isUsd?: boolean;
    isPercentage?: boolean;
    loading?: boolean;
}

const StatCard: FC<Props> = ({title, value, svg, isPercentage, isUsd, loading}) => {
    const {setLoading, LoadingComponent} = useAreaLoading();

    useEffect(() => {
        loading && setLoading(true);
        !loading && setLoading(false);
    }, [loading, setLoading]);

    const valueString = useMemo(() => {

        let valueString = '';
        if (typeof value === 'number') {
            valueString = numberFormat(value);
        } else {
            valueString = value;
        }
        if (isUsd) {
            valueString = `$${valueString}`;
        }
        if (isPercentage) {
            valueString = `${valueString}%`;
        }
        return valueString;
    }, [value, isUsd, isPercentage]);

    return (
        <LoadingComponent>
            <div className="card h-lg-100 BalanceCard RightIconVersion">
                <div className="card-body d-flex justify-content-between align-items-start flex-column">
                    <div className="m-0 coinIcon">
                        <img src={getCustomSvgUrl(svg)} alt="icon" className="w-25px " />
                    </div>
                    <div className="d-flex flex-column my-7 textArea">
                        <div className="m-0">
                            <span className="fw-bold fs-6 text-gray-700">{title}</span>
                        </div>
                        <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">{valueString}</span>
                    </div>
                </div>
            </div>
        </LoadingComponent>
    );
};

export default StatCard;