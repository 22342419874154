import React, {FC, useCallback} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {IBlockchainTransaction} from '@/app/interfaces';
import Card from '@/app/modules/components/card/Card';
import dayjs from 'dayjs';
import Balance from '@/app/pages/merchant/wallet/tools/Balance';
import useDataTable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {IBlockchainTransactionFilters} from '@/app/interfaces/filters';
import {initialBlockchainTransactionFilters} from '@/app/utils/initialFilters';
import {initialBlockchainTransactionsSort} from '@/app/utils/initialSorts';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getBlockchainTransactions} from '@/app/utils/requests/adminRequests';
import {getCoinSymbol} from '@/app/modules/helpers/getCoinSymbol';
import {BlockchainTransactionsTableHead} from '@/app/utils/tableHeads/admin/BlockchainTransactionsTableHead';
import BlockChainTransactionFilters from '@/app/pages/admin/blockchain-transaction/blockchainTransactionList/BlockchainTransactionFilters';
import BlockchainTransactionStatus from '@/app/pages/admin/blockchain-transaction/blockchainTransactionList/BlockchainTransactionStatus';
import TxHash from '@/app/modules/components/TxHash';


const BlockchainTransactionList: FC = () => {
    const _ = useTranslation();
    const getData: GetDataFunction<IBlockchainTransaction, IBlockchainTransactionFilters> = useCallback(async ({pageIndex, filters, pageSize, sort}) => {
        const params = {
            sort,
            pageIndex,
            pageSize,
            orFilters: filters.searchQuery
                ? {
                    'txHash': filters.searchQuery,
                }
                : undefined,
            exactFilters: {
                ...(filters.coin && {'coin.id': filters.coin}),
                ...(filters.status && {'status': filters.status}),
            },
        };
        const {data} = await getBlockchainTransactions(params);
        if (!data) return Promise.reject();
        return data;

    }, []);
    const {
        handleFilterSubmit,
        handleFilterReset,
        handleFilterChange,
        filters,
        DataTableComponent,
    } = useDataTable<IBlockchainTransaction, IBlockchainTransactionFilters>({
        initialSort: initialBlockchainTransactionsSort,
        initialFilters: initialBlockchainTransactionFilters,
        getData,
        id: 'blockchainTransactionList',
    });


    return (
        <>
            <ToolbarWrapper title={_('BlockchainTransactionList.TITLE')} />

            <Card>
                <BlockChainTransactionFilters handleFilterSubmit={handleFilterSubmit} handleFilterReset={handleFilterReset} handleFilterChange={handleFilterChange} filters={filters} />
                <DataTableComponent
                    head={BlockchainTransactionsTableHead}
                    className={'gy-5'}
                    renderRow={(transaction: IBlockchainTransaction) => {
                        return (
                            <tr key={`blockchainTransaction_${transaction.id}`}>

                                <td>
                                    <div className="badge badge-light fw-bold">{dayjs(transaction.createdAt).format('DD.MM.YYYY HH:mm:ss')}</div>
                                </td>
                                <td>
                                    <TxHash txHash={transaction.txHash} urlFormat={transaction.coinTxDetailUrlFormat} />
                                </td>
                                <td className="text-center"><BlockchainTransactionStatus status={transaction.status} /></td>
                                <td><Balance amount={transaction.amount} code={transaction.coinSymbol} isTestNetwork={transaction.isTestNetwork} /></td>
                                <td className="text-center">
                                    {getCoinSymbol({
                                        symbol: transaction.coinSymbol,
                                        subSymbol: transaction.coinSubSymbol,
                                        isTestNetwork: transaction.isTestNetwork,
                                    })}
                                </td>
                            </tr>
                        );
                    }}
                />
            </Card>
        </>
    );
};

export default BlockchainTransactionList;