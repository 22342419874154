import {type FC, useState} from 'react';
import Basket from '@/app/pages/payment/basket/Basket';
import {useParams} from 'react-router-dom';
import Pay from '@/app/pages/payment/pay/Pay';

interface Props {

}

const Payment: FC<Props> = (props) => {
    const [step, setStep] = useState<'basket' | 'pay'>('basket');

    const {id} = useParams();

    if (!id) {
        window.location.href = '/';
        return <h1>Redirecting...</h1>;
    }

    if (step === 'pay') {
        return <Pay id={id} />;
    }

    if (step === 'basket') {
        return <Basket id={id} redirectToPay={() => {
            setStep('pay');
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        }} />;
    }

    return null;
};

export default Payment;