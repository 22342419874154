import React, {FC} from 'react';
import Input from '@/app/modules/components/filterInputs/Input';
import Select from '@/app/modules/components/filterInputs/Select';
import Label from '@/app/modules/components/filterInputs/Label';
import FilterAccordion from '@/app/modules/components/FilterAccordion';
import {IMerchantTransactionFilters} from '@/app/interfaces/filters';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {transactionStatusLabels} from '@/app/interfaces';

interface Props {
    handleFilterSubmit: () => void;
    handleFilterReset: () => void;
    handleFilterChange: (name: string, value: string | object | null) => void;
    filters: IMerchantTransactionFilters;

}

const TransactionFilters: FC<Props> = ({handleFilterSubmit, handleFilterReset, handleFilterChange, filters}) => {
    const _ = useTranslation();
    const translatedStatusLabels = {};
    Object.keys(transactionStatusLabels).forEach((key) => {
        translatedStatusLabels[key] = _(transactionStatusLabels[key]);
    });
    return (
        <FilterAccordion id="transactionFilters" title={_('TransactionFilters.FILTERS')} onFilter={() => handleFilterSubmit()} onReset={() => handleFilterReset()}>
            <>
                <div className="row">
                    <div className="col-md-6 mb-md-10 mb-5">
                        <Input name="searchQuery" value={filters.searchQuery} label={_('Filter.SEARCH_QUERY')} onChange={handleFilterChange} submitOnEnterFunc={handleFilterSubmit} />
                    </div>
                    <div className="col-md-6 mb-10">
                        <Select options={translatedStatusLabels} name="type" label={_('TransactionFilters.TRANSACTION_STATUS')} onChange={handleFilterChange} value={filters.type} className={'form-control-solid'} isClearable />
                    </div>
                </div>
                <div className="row mb-md-5">
                    <div className="col-md-6 mb-md-0 mb-5">
                        <Label label={_('TransactionFilters.DATE_RANGE')} id={'startDate'} />
                        <div className="row">
                            <div className="col-md-6 mb-md-0 mb-2">
                                <Input name="startDate" type="datetime-local" value={filters.startDate || ''} placeholder={_('TransactionFilters.START_DATE')} onChange={handleFilterChange} />
                            </div>
                            <div className="col-md-6 mb-md-0 mb-5">
                                <Input name="endDate" type="datetime-local" value={filters.endDate || ''} placeholder={_('TransactionFilters.END_DATE')} onChange={handleFilterChange} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-md-0 mb-5">
                        <Label label={_('TransactionFilters.AMOUNT_RANGE')} id={'minAmount'} />
                        <div className="row">
                            <div className="col-md-6 mb-md-0 mb-2">
                                <Input name="minAmount" type="number" value={filters.minAmount || ''} placeholder={_('TransactionFilters.MIN_AMOUNT')} onChange={handleFilterChange} />
                            </div>
                            <div className="col-md-6 mb-md-0 mb-5">
                                <Input name="maxAmount" type="number" value={filters.maxAmount || ''} placeholder={_('TransactionFilters.MAX_AMOUNT')} onChange={handleFilterChange} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </FilterAccordion>
    );
};

export default TransactionFilters;