import {IDataTableHead} from '@/app/interfaces';

export const IpnHistoryTableHead: IDataTableHead[] = [
    {
        text: 'IpnHistoryTH.ORDER_ID',
        name: 'orderId',
        isSortable: false,
        align: 'left',
        width: '100px',
    },
    // {
    //     text: 'IpnHistoryTH.CREATED_AT',
    //     name: 'createdAt',
    //     isSortable: true,
    //     align: 'left',
    //     width: '100px',
    // },
    {
        text: 'IpnHistoryTH.TYPE',
        name: 'type',
        isSortable: false,
        align: 'center',
        width: '100px',
    },
    {
        text: 'IpnHistoryTH.STATUS_CODE',
        name: 'httpStatusCode',
        isSortable: true,
        align: 'center',
        width: '100px',
    },
    {
        text: 'IpnHistoryTH.LAST_SENT_AT',
        name: 'sentAt',
        isSortable: true,
        align: 'left',
        width: '100px',
    },
    {
        text: '',
        width: '100px',
    },

];