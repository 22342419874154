import {type FC, useMemo} from 'react';
import {TDashboardGraphStatRange, TDashboardGraphStatType} from '@/app/interfaces';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    range: TDashboardGraphStatRange;
    type: TDashboardGraphStatType;
    onChange: (range: TDashboardGraphStatRange) => void;
}

const RangePicker: FC<Props> = ({range, type, onChange}) => {
    const _ = useTranslation();

    const ranges = useMemo((): Record<TDashboardGraphStatRange, string> => {
        return {
            monthly: _('Dashboard.STATS.RANGE.MONTH'),
            weekly: _('Dashboard.STATS.RANGE.WEEK'),
            daily: _('Dashboard.STATS.RANGE.DAY'),
        };
    }, [_]);

    return (
        <div className="card-toolbar">
            <ul className="nav" id={`${type}_range`} role="tablist">
                {Object.keys(ranges).map((key) => (
                    <li key={`range_${key}`} className="nav-item" role="presentation">
                        <a className={`nav-link btn btn-sm btn-color-muted btn-active btn-active-primary fw-bold px-4 me-1 ${range === key ? 'active' : ''}`} data-bs-toggle="tab" id={`${type}_range_${key}_toggle`} href={`#${type}_range_${key}_tab`} aria-selected={range === key}
                           role="tab" onClick={() => onChange(key as TDashboardGraphStatRange)}>{ranges[key]}</a>
                    </li>
                ))}

            </ul>
        </div>
    );
};

export default RangePicker;