import React, {FC} from 'react';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import {getUserType} from '@/app/modules/helpers/getValuesFromId';
import {Link} from 'react-router-dom';
import {generateRoute} from '@/app/modules/helpers';
import {AdminRouteConstants} from '@/app/constants/routes.constants';
import {statusBadge} from '@/app/pages/admin/users/userTr';
import dayjs from 'dayjs';
import {IUser} from '@/app/interfaces';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    user: IUser | null;
    setShowUpdateUserModal: (value: boolean) => void;
    setShowDeleteUserModal: (value: boolean) => void;
    isAdmin?: boolean;
}

const UserInfoCard: FC<Props> = ({user, setShowDeleteUserModal, setShowUpdateUserModal, isAdmin}) => {
    const _ = useTranslation();
    return (
        <div className="card mb-5 mb-xl-8">
            <div className="card-body">
                <div className="d-flex flex-center flex-column py-5">
                    <div className="symbol symbol-100px symbol-circle mb-7">
                        <img src={toAbsoluteUrl('/media/avatars/blank.png')} alt="Avatar" />
                    </div>
                    <div className="fs-3 text-gray-800 fw-bold mb-3">
                        {user ? user.fullName : _('General.LOADING')}
                    </div>
                    <div className="mb-9">
                        <div className="badge badge-lg badge-light-primary d-inline">{user ? _(getUserType(user.userType)) : _('General.LOADING')}</div>
                    </div>
                </div>


                <div className="d-flex flex-stack fs-4 py-3">
                    <div className="fw-bold rotate collapsible active" data-bs-toggle="collapse" role="button" aria-expanded="true" aria-controls="kt_user_view_details">
                        {_('UserInfoCard.DETAILS')}
                    </div>
                    {isAdmin && (
                        <span data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-original-title="Edit customer details" data-kt-initialized="1">

                            <button onClick={() => setShowUpdateUserModal(true)} className="btn btn-sm btn-light-primary">
                                {_('UserInfoCard.EDIT')}
                            </button>
                        </span>
                    )}
                </div>
                <div className="separator"></div>
                <div id="kt_user_view_details" className="collapse show">
                    <div className="pb-5 fs-6">
                        {user?.merchant && isAdmin && (
                            <>
                                <div className="fw-bold mt-5">{_('UserType.MERCHANT')}</div>
                                <div className="text-gray-600"><Link to={generateRoute(AdminRouteConstants.MERCHANT_DETAIL, {id: user ? user.merchant.id.toString() : ''})}>{user?.merchant.merchantName}</Link></div>
                            </>
                        )}
                        <div className="fw-bold mt-5">{_('UserInfoCard.ID')}</div>
                        <div className="text-gray-600">{user?.id}</div>
                        <div className="fw-bold mt-5">{_('UserInfoCard.EMAIL')}</div>
                        <div className="text-gray-600"><a href={`mailto:${user?.email}`} className="text-gray-600 text-hover-primary">{user?.email}</a></div>
                        <div className="fw-bold mt-5">{_('UserInfoCard.STATUS')}</div>
                        <div className="text-gray-600 mt-1">{user ? statusBadge(user.status, _) : ''}</div>

                        <div className="fw-bold mt-5">{_('UserInfoCard.VERIFICATION')}</div>
                        <div className="text-gray-600 mt-1">
                            <div className={'d-flex gap-2'}>
                                <i className={`fas fa-at fs-1 ${user?.isEmailVerified ? 'text-success' : 'text-gray-200'}`}></i>
                                <i className={`fas fa-sms fs-1 ${user?.isPhoneVerified ? 'text-success' : 'text-gray-200'}`}></i>
                            </div>
                        </div>

                        <div className="fw-bold mt-5">{_('UserInfoCard.REGISTRATION_DATE')}</div>
                        <div className="text-gray-600">{dayjs(user?.createdAt).format('DD.MM.YYYY HH:mm')}</div>
                        <div className="fw-bold mt-5">{_('UserInfoCard.LAST_LOGIN_DATE')}</div>
                        <div className="text-gray-600">{dayjs(user?.lastLoginAt).format('DD.MM.YYYY HH:mm')}</div>

                    </div>
                </div>
                <button onClick={() => setShowDeleteUserModal(true)} className="btn btn-block mt-10 btn-sm btn-danger">
                    {_('UserInfoCard.DELETE_USER')}
                </button>
            </div>
        </div>
    );
};

export default UserInfoCard;