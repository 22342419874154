import React, {FC, useEffect, useState} from 'react';
import Card from '@/app/modules/components/card/Card';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {IApiKeyDetail} from '@/app/interfaces';
import PermissionCheckbox from '@/app/pages/merchant/apiKeys/Update/PermissionCheckbox';
import {toast} from 'react-toastify';
import Buttons from '@/app/modules/components/Buttons';
import {useFormik} from 'formik';
import {IApiKeyDetailsValues} from '@/app/interfaces/values';
import {apiKeyDetailsSchema} from '@/app/utils/yupSchema';
import Submit from '@/app/modules/auth/components/tools/buttons/Submit';
import {apiKeyDetailsInitialValues} from '@/app/utils/initialValues';
import ApiKeyDetailsForm from '@/app/pages/merchant/apiKeys/Update/ApiKeyDetailsForm';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';
import {getApiKey, updateApiKey} from '@/app/modules/auth/core/_requests';
import {getApiKeyFromAdmin, updateApiKeyFromAdmin} from '@/app/utils/requests/adminRequests';

interface Props {
    id?: string;
}

const Update: FC<Props> = ({id}) => {
    const {id: apiID} = useParams();
    const navigate = useNavigate();
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const [apiDetails, setApiDetails] = useState<IApiKeyDetail | null>(null);

    const apiKeyDetailsFormik = useFormik<IApiKeyDetailsValues>({
        initialValues: apiKeyDetailsInitialValues,
        validationSchema: apiKeyDetailsSchema(_),
        onSubmit: async (values, {setSubmitting}) => {
            setSubmitting(true);
            try {
                if (!apiDetails) throw new Error('API details not found');
                const payload = {
                    name: values.keyName,
                    status: values.isActive,
                    permissions: apiDetails.permissions,
                };
                if (id) {
                    await updateApiKeyFromAdmin(id, apiDetails?.id, payload);
                } else {
                    await updateApiKey(apiDetails?.id, payload);
                }
                toast.success(_('Update.API_KEY_UPDATED_SUCCESSFULLY'));
            } catch (e) {
                toast.error(getErrorMessage(e));
            } finally {
                setSubmitting(false);
            }

        },
    });

    useEffect(() => {
        if (!apiID) {
            navigate(MerchantRouteConstants.API_KEYS);
            return;
        }
        const fetchApiKey = id ? () => getApiKeyFromAdmin(id, parseInt(apiID)) : () => getApiKey(parseInt(apiID));

        fetchApiKey().then(({data}) => {
            setApiDetails(data);
            apiKeyDetailsFormik.setValues({
                keyName: data.name,
                isActive: data.status,
            });
            apiKeyDetailsFormik.validateForm().then();
        }).catch((e) => {
            toast.error(getErrorMessage(e));
            navigate(MerchantRouteConstants.API_KEYS);
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiID, getErrorMessage, navigate]);


    const handlePermissionChange = (permissionId: number, checked: boolean) => {
        setApiDetails((prev) => {
            if (!prev) return prev;
            return {
                ...prev,
                permissions: prev.permissions.map(permission => {
                    if (permission.permissionId === permissionId) {
                        return {
                            ...permission,
                            enabled: checked,
                        };
                    }
                    return permission;
                }),
            };
        });
    };

    const footer = (
        <div className="card-footer py-6 px-9">
            <Buttons>
                <Link to={MerchantRouteConstants.API_KEYS} className="btn btn-light btn-active-light-primary btn-sm">{_('General.CANCEL')}</Link>
                <Submit className={'btn-sm'} disabled={apiKeyDetailsFormik.isSubmitting || !apiKeyDetailsFormik.isValid} loading={apiKeyDetailsFormik.isSubmitting} label={_('Update.SAVE_CHANGES')} />
            </Buttons>
        </div>
    );

    return (
        <>
            <ToolbarWrapper title={_('Update.TITLE')} onlyTitle={!!id} isMerchantSubMenu={!!id} additionalBreadcrumb={[
                {
                    title: _('Update.MY_API_KEYS'),
                    path: MerchantRouteConstants.API_KEYS,
                    isActive: false,
                },
            ]} />
            <form className="form w-100 fv-plugins-bootstrap5 fv-plugins-framework" noValidate onSubmit={apiKeyDetailsFormik.handleSubmit}>
                <Card title={_('Update.UPDATE_API_KEY')} subTitle={_('Update.EDIT_API_KEY_INFO')} className={'apiKeys'} footer={footer}>
                    <ApiKeyDetailsForm formik={apiKeyDetailsFormik} apiKey={apiDetails?.apiKey} />
                </Card>

                <Card title={_('Update.API_KEY_PERMISSIONS')} subTitle={_('Update.SELECT_API_KEY_OPERATIONS')} className={'apiKeys'} footer={footer}>
                    {apiDetails?.permissions.map((permission, index) => (
                        <div key={index}>
                            <PermissionCheckbox
                                name={permission.name}
                                title={permission.name}
                                description={_(`API_PERMISSIONS.${permission.name}`)}
                                checked={permission.enabled}
                                onChange={(checked) => handlePermissionChange(permission.permissionId, checked)}
                            />
                            {index < apiDetails.permissions.length - 1 ? <div className="separator separator-dashed my-5"></div> : <div className={'my-5'} />}
                        </div>
                    ))}


                </Card>
            </form>
        </>
    );
};

export default Update;