import React, {FC, useCallback} from 'react';
import Card from '@/app/modules/components/card/Card';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {IListCoin} from '@/app/interfaces';
import useDatatable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {initialCoinListSort} from '@/app/utils/initialSorts';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getCoinList} from '@/app/utils/requests/adminRequests';
import {trCoin} from '@/app/pages/admin/coinSettings/trCoin';
import {CoinListTableHead} from '@/app/utils/tableHeads/admin/CoinListTableHead';

interface Props {
    id?: string;
}

const CoinList: FC<Props> = ({id}) => {
    const _ = useTranslation();

    const getData: GetDataFunction<IListCoin, {}> = useCallback(async ({pageIndex, filters, pageSize, sort}) => {
        const params = {
            sort,
            pageIndex,
            pageSize,
        };
        const {data} = await getCoinList(params);
        if (!data) return Promise.reject();
        return data;
    }, []);

    const {
        DataTableComponent,
    } = useDatatable<IListCoin, {}>({
        initialSort: initialCoinListSort,
        initialFilters: {},
        getData,
        id: 'coinList',
    });
    return (
        <>
            <ToolbarWrapper title={_('CoinList.TITLE')} />
            <Card title={`${_('CoinList.COIN_LIST')}`} subTitle={_('CoinList.MANAGE_COINS')} className={'coinList'}>
                <DataTableComponent head={CoinListTableHead} renderRow={(coin) => trCoin(coin, _)} />
            </Card>
        </>
    );
};

export default CoinList;