import React, {FC, useCallback, useState} from 'react';
import {toast} from 'react-toastify';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import Created from '@/app/pages/merchant/merchant/CreateOrder/Created';
import {createTransaction} from '@/app/modules/auth/core/_requests';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useFormik} from 'formik';
import {ICreateOrderValues} from '@/app/interfaces/values';
import {createOrderInitialValues} from '@/app/utils/initialValues';
import {createOrderSchema} from '@/app/utils/yupSchema';
import FormCard from '@/app/modules/components/form-card/FormCard';
import Input from '@/app/modules/auth/components/tools/inputs/Input';
import Buttons from '@/app/modules/components/Buttons';
import {Link} from 'react-router-dom';
import Submit from '@/app/modules/auth/components/tools/buttons/Submit';
import {createTransactionFromAdmin} from '@/app/utils/requests/adminRequests';
import {useErrorMessage} from '@/app/modules/hooks/useErrorMessage';

interface Props {
    id?: string;
}

const CreateOrder: FC<Props> = ({id}) => {
    const _ = useTranslation();
    const {getErrorMessage} = useErrorMessage(_);
    const [orderUrl, setOrderUrl] = useState('');

    const formik = useFormik<ICreateOrderValues>({
        initialValues: createOrderInitialValues,
        validationSchema: createOrderSchema(_),
        onSubmit: async (values, {setSubmitting}) => {
            if (!values.productName || !values.productPrice || !values.quantity) {
                toast.error(_('Order.FILL_ALL_FIELDS'));
                return;
            }
            try {
                const payload = {
                    name: values.productName,
                    price: values.productPrice,
                    count: values.quantity,
                };

                let status = 0;
                let transaction;
                if (id) {
                    const {data: transaction1, status: status1} = await createTransactionFromAdmin(id, payload);
                    transaction = transaction1;
                    status = status1;
                } else {
                    const {data: transaction2, status: status2} = await createTransaction(payload);
                    transaction = transaction2;
                    status = status2;
                }
                if (status === 201) {
                    setOrderUrl(`${window.location.origin}${MerchantRouteConstants.PAYMENT}/${transaction.id}`);
                    toast.success(_('Order.ORDER_CREATED_SUCCESSFULLY'));
                }
            } catch (error: any) {
                toast.error(getErrorMessage(error));
            } finally {
                setSubmitting(false);
            }
        },
    });

    const resetForm = useCallback(() => {
        formik.resetForm();
        setOrderUrl('');
    }, [formik]);

    const inputComponent = useCallback((props: {name: string, type: string, label: string, description: string, disabled?: boolean}) => {
        return <Input
            className="form-control-lg form-control-solid"
            name={props.name}
            type={props.type}
            formik={formik}
            labelSize={3}
            label={_(props.label)}
            placeholder=""
            isRow
            description={_(props.description)}
            disabled={props.disabled} />;
    }, [_, formik]);

    return (
        <>
            <ToolbarWrapper title={_('Order.MANUAL_ORDER_CREATE')} onlyTitle={!!id} isMerchantSubMenu={!!id} />
            {orderUrl ? (
                <Created orderUrl={orderUrl} resetForm={resetForm} />
            ) : (
                <form onSubmit={formik.handleSubmit} className="form d-flex flex-column gap-7 gap-lg-10" noValidate>
                    <FormCard title={_('Order.PRODUCT_DETAILS')}>
                        {inputComponent({name: 'productName', type: 'text', label: 'Order.PRODUCT_NAME', description: 'Order.ENTER_PRODUCT_NAME'})}
                        {inputComponent({name: 'productPrice', type: 'number', label: 'Order.PRODUCT_PRICE', description: 'Order.ENTER_PRODUCT_PRICE'})}
                        {inputComponent({name: 'quantity', type: 'number', label: 'Order.QUANTITY', description: 'Order.ENTER_QUANTITY'})}
                    </FormCard>
                    <Buttons>
                        <Link to={MerchantRouteConstants.DASHBOARD} className="btn btn-light">
                            {_('General.CANCEL')}
                        </Link>
                        <Submit disabled={formik.isSubmitting || !formik.isValid} loading={false} label={_('Order.CREATE_ORDER')} />
                    </Buttons>
                </form>
            )}
        </>
    );
};

export default CreateOrder;