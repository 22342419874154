import React, {FC} from 'react';
import {toAbsoluteUrl} from "@/_metronic/helpers";

interface Props {
    title: string;
    icon: string;
    children: React.ReactNode;
}

const BasketCard: FC<Props> = ({title, icon, children}) => {
    return (
        <div className="basketCard">
            <div className="title">
                <h2>{title}</h2>
                <img className="filigran" src={toAbsoluteUrl(`/custom/svg/${icon}`)} alt={title}/>
            </div>
            {children}
        </div>
    );
};

export default BasketCard;