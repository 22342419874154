import React, {FC} from 'react';
import {toAbsoluteUrl} from '@/_metronic/helpers';

interface Props {
    coinId: number;
    code: string;
    name: string;
    amount: string;
    svg?: string;
    selected?: boolean | null;
    onSelect: (coinId: number) => void;
}

const CoinSelection: FC<Props> = ({coinId, code, name, amount, svg, onSelect, selected}) => {
    return (
        <div className={`currency ${code.toLowerCase()} ${selected ? 'active' : ''}`} onClick={() => onSelect(coinId)}>
            <div className="coinLogo">
                <img src={toAbsoluteUrl(`/custom/coins/${svg ? svg : code.toLowerCase()}.svg`)} alt={name} />
            </div>
            <div className="coinInfo">
                {/*<div className="amount">{amount} <span>{code.toUpperCase()}</span></div>*/}
                <div className="coinName">{name}</div>
            </div>
        </div>
    );
};

export default CoinSelection;