import React, {type FC} from 'react';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {useParams} from 'react-router-dom';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import {MerchantRouteConstants} from '@/app/constants/routes.constants';
import TransactionDetail from '@/app/pages/common/TransactionDetail/TransactionDetail';
import {getMerchantTransactionDetail} from '@/app/modules/auth/core/_requests';
import {getMerchantTransactionDetailFromAdmin} from '@/app/utils/requests/adminRequests';

interface Props {
    id?: string;
}

const MerchantTransactionDetail: FC<Props> = ({id}) => {
    const _ = useTranslation();

    const {id: transactionId} = useParams();

    if (!transactionId) return null;

    return (
        <>
            <ToolbarWrapper title={_('TransactionDetail.TITLE') + ` (#${transactionId})`} additionalBreadcrumb={[
                {title: _('TransactionList.TITLE'), path: MerchantRouteConstants.TRANSACTION_HISTORY, isActive: false},
            ]} />

            <TransactionDetail id={transactionId} getTransactionFunc={id ? getMerchantTransactionDetailFromAdmin : getMerchantTransactionDetail} />
        </>
    );
};

export default MerchantTransactionDetail;