import React, {type FC, useCallback, useEffect, useState} from 'react';
import DateRangeFilter from '@/app/pages/common/Report/DateRangeFilter';
import {IDateRange} from '@/app/interfaces';
import {getAdminReport, getMerchantReport} from '@/app/utils/requests/adminRequests';
import {IReport} from '@/app/interfaces/report';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import TotalCard from '@/app/pages/common/Report/TotalCard';
import CoinTable from '@/app/pages/common/Report/CoinTable';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getReport} from '@/app/modules/auth/core/_requests';

interface Props {
    type: 'admin' | 'merchant' | 'merchantFromAdmin';
    merchantId?: string;
}


const Report: FC<Props> = ({type, merchantId}) => {
    const _ = useTranslation();
    const [dateRange, setDateRange] = useState<IDateRange>({startDate: new Date(), endDate: new Date()});
    const [report, setReport] = useState<IReport | null>(null);

    const getReportReq = useCallback(() => {
        setReport(null);

        switch (type) {
            case 'admin':
                getAdminReport(dateRange).then((res) => {
                    setReport(res.data);
                });
                break;
            case 'merchant':
                getReport(dateRange).then((res) => {
                    setReport(res.data);
                });
                break;
            case 'merchantFromAdmin':
                if (!merchantId) return;
                getMerchantReport(dateRange, merchantId).then((res) => {
                    setReport(res.data);
                });
                break;
        }
    }, [dateRange, merchantId, type]);


    useEffect(() => {
        getReportReq();
    }, [getReportReq]);

    return (
        <>
            <ToolbarWrapper title={_('REPORT.TITLE')}>
                <DateRangeFilter onChange={(value) => {
                    if (value) {
                        setDateRange({startDate: value[0], endDate: value[1]});
                    }
                }} />
            </ToolbarWrapper>

            {!report ? (
                <p>Loading...</p>
            ) : (
                <div className="row">
                    <div className="col-lg-4">
                        <TotalCard title={_('REPORT.TOTAL_DEPOSITS')} fiat={report.summary.totalDeposits.fiat} usd={report.summary.totalDeposits.usd} />
                    </div>
                    <div className="col-lg-4">
                        <TotalCard title={_('REPORT.TOTAL_WITHDRAWALS')} fiat={report.summary.totalWithdrawals.fiat} usd={report.summary.totalWithdrawals.usd} />
                    </div>

                    <div className="col-lg-4">
                        <TotalCard title={_('REPORT.TOTAL_FEES')} fiat={report.summary.totalFees.fiat} usd={report.summary.totalFees.usd} />
                    </div>

                    <div className="col-lg-6">
                        <TotalCard title={_('REPORT.TOTAL_GROSS_PROFIT')} fiat={report.summary.totalGrossProfit.fiat} usd={report.summary.totalGrossProfit.usd} isColored />
                    </div>
                    <div className="col-lg-6">
                        <TotalCard title={_('REPORT.TOTAL_NET_INCOME')} fiat={report.summary.totalNetIncome.fiat} usd={report.summary.totalNetIncome.usd} isColored />
                    </div>
                    {report.coins && Object.keys(report.coins).map((key) => (
                        <div className="col-lg-12" key={`report-${key}`}>
                            <CoinTable title={report.coins[key].coin.name} subtitle={report.coins[key].coin.symbol} summary={report.coins[key]} />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Report;