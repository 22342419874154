import React, {type FC, useCallback, useMemo} from 'react';
import {DateRange, DisabledDateFunction, ValueType} from 'rsuite/DateRangePicker';
import addDays from 'date-fns/addDays';
import subDays from 'date-fns/subDays';
import startOfMonth from 'date-fns/startOfMonth';
import addMonths from 'date-fns/addMonths';
import endOfMonth from 'date-fns/endOfMonth';
import {DateRangePicker} from 'rsuite';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    onChange: (value: DateRange | null) => void;
}

const shouldDisableDate: DisabledDateFunction = (date: Date, selectDate?: any, selectedDone?: boolean, target?: string) => {
    // Gelecekteki tarihleri devre dışı bırak
    return date.getTime() > new Date().getTime();
};

const resetTime = (date: Date) => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate());
};

const DateRangeFilter: FC<Props> = ({onChange}) => {
    const _ = useTranslation();

    const renderCustomValue = useCallback((value: ValueType, format: string) => {
        const [startDate, endDate] = value || [];
        const today = resetTime(new Date()); // Bugünün tarihi (saat bilgisi sıfırlanmış)

        if (startDate && endDate) {
            const start = resetTime(startDate);
            const end = resetTime(endDate);

            if (start.getTime() === today.getTime() && end.getTime() === today.getTime()) {
                return _('DATE_RANGE_FILTER.TODAY');
            } else if (start.getTime() === resetTime(addDays(today, -1)).getTime() && end.getTime() === resetTime(addDays(today, -1)).getTime()) {
                return _('DATE_RANGE_FILTER.YESTERDAY');
            } else if (start.getTime() === resetTime(subDays(today, 6)).getTime() && end.getTime() === today.getTime()) {
                return _('DATE_RANGE_FILTER.LAST_7_DAYS');
            } else if (start.getTime() === resetTime(subDays(today, 29)).getTime() && end.getTime() === today.getTime()) {
                return _('DATE_RANGE_FILTER.LAST_30_DAYS');
            } else if (start.getTime() === resetTime(startOfMonth(today)).getTime() && end.getTime() === today.getTime()) {
                return _('DATE_RANGE_FILTER.THIS_MONTH');
            } else if (start.getTime() === resetTime(startOfMonth(addMonths(today, -1))).getTime() && end.getTime() === resetTime(endOfMonth(addMonths(today, -1))).getTime()) {
                return _('DATE_RANGE_FILTER.LAST_MONTH');
            } else {
                return `${start.toLocaleDateString()} ~ ${end.toLocaleDateString()}`;
            }
        }

        return '';
    }, [_]);

    const predefinedRanges = useMemo(() => ([
        {
            label: _('DATE_RANGE_FILTER.TODAY'),
            value: [new Date(), new Date()] as DateRange,
            placement: 'left' as 'left' | 'bottom',
        },
        {
            label: _('DATE_RANGE_FILTER.YESTERDAY'),
            value: [addDays(new Date(), -1), addDays(new Date(), -1)] as DateRange,
            placement: 'left' as 'left' | 'bottom',
        },
        {
            label: _('DATE_RANGE_FILTER.LAST_7_DAYS'),
            value: [subDays(new Date(), 6), new Date()] as DateRange,
            placement: 'left' as 'left' | 'bottom',
        },
        {
            label: _('DATE_RANGE_FILTER.LAST_30_DAYS'),
            value: [subDays(new Date(), 29), new Date()] as DateRange,
            placement: 'left' as 'left' | 'bottom',
        },
        {
            label: _('DATE_RANGE_FILTER.THIS_MONTH'),
            value: [startOfMonth(new Date()), new Date()] as DateRange,
            placement: 'left' as 'left' | 'bottom',
        },
        {
            label: _('DATE_RANGE_FILTER.LAST_MONTH'),
            value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))] as DateRange,
            placement: 'left' as 'left' | 'bottom',
        },
    ]), [_]);

    return (
        <DateRangePicker
            ranges={predefinedRanges}
            placeholder="Placement left"
            style={{width: 300}}
            showHeader={false}
            shouldDisableDate={shouldDisableDate}
            renderValue={renderCustomValue}
            defaultValue={[new Date(), new Date()]}
            placement={'auto'}
            onChange={(value) => {
                onChange(value);
            }}
        />
    );
};

export default DateRangeFilter;