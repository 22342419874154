import {FC} from 'react';
import {toAbsoluteUrl} from '@/_metronic/helpers';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

interface Props {
    redirectUrl: string;
}

const Partial: FC<Props> = ({redirectUrl}) => {
    const _ = useTranslation();

    return (
        <section className="success">
            <img src={toAbsoluteUrl('/custom/svg/puzzle.svg')} alt="Partial" />
            <h1 className="text-dark fs-2qx mb-3">
                {_('Partial.PAYMENT_PARTIAL')}
            </h1>
            <div className="text-dark fs-base text-center mb-10">
                {_('Partial.PAYMENT_PARTIAL_DESCRIPTION')}
            </div>

            {/*TODO: Return to source site*/}
            <a href={redirectUrl} className="btn btn-primary me-4">
                {_('Success.RETURN_TO_SOURCE_SITE')}
            </a>
        </section>
    );
};

export default Partial;