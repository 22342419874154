import React, {FC} from 'react';
import clsx from "clsx";
import Select2 from "@/app/modules/components/filterInputs/Select";

interface Props {
    label?: string;
    placeholder?: string;
    name: string;
    formik: any;
    className?: string;
    isClearable?: boolean;
    isMulti?: boolean;
    isDisabled?: boolean;
    isSearchable?: boolean;
    options: Record<string, string>;
}

const Select: FC<Props> = (props) => {

    let inputClass = props.className ? props.className : 'bg-transparent'


    const renderSelect = () => (
        <>
            <Select2
                options={props.options}
                name={props.name}
                onChange={(name, value) => {
                    if (typeof value === "string") {
                        props.formik.setFieldValue(name, value)
                    }
                    props.formik.touched[props.name] = true
                }}
                value={props.formik.values[props.name]}
                isClearable={props.isClearable}
                isMulti={props.isMulti}
                isDisabled={props.isDisabled}
                isSearchable={props.isSearchable}
                className={clsx(
                    '',
                    {
                        'is-invalid': props.formik.touched[props.name] && props.formik.errors[props.name],
                    },
                    {
                        'is-valid': props.formik.touched[props.name] && !props.formik.errors[props.name],
                    },
                    inputClass
                )}
            />
            {props.formik.touched[props.name] && props.formik.errors[props.name] && (
                <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                        <span role='alert'>{props.formik.errors[props.name]}</span>
                    </div>
                </div>
            )}
        </>
    );

    const renderLabel = () => (
        props.label ? <label className='form-label fw-bolder text-dark fs-6'>{props.label}</label> : null
    );
    return (
        <div className='fv-row mb-8'>
            {renderLabel()}
            {renderSelect()}
        </div>
    );
};

export default Select;