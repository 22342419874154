import React, {type FC, useCallback} from 'react';
import {useLoading} from '@/app/providers/LoadingProvider';
import {ITransaction} from '@/app/interfaces';
import Balance from '@/app/pages/merchant/wallet/tools/Balance';
import UsdValue from '@/app/pages/merchant/wallet/tools/UsdValue';
import TransactionStatus from '@/app/pages/merchant/wallet/tools/TransactionStatus';
import TransactionDetailProducts from '@/app/pages/common/TransactionDetail/TransactionDetailProducts';
import TransactionDetailCard from '@/app/pages/common/TransactionDetail/TransactionDetailCard';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import FiatValue from '@/app/pages/merchant/wallet/tools/FiatValue';
import {amountWithCurrency} from '@/app/modules/helpers/getValuesFromId';
import TransactionBlockchainTransactionTable from '@/app/pages/common/TransactionDetail/TransactionBlockchainTransactionTable';

interface Props {
    id: string;
    getTransactionFunc: (params: {transactionId: string}) => Promise<any>;
}

const TransactionDetail: FC<Props> = ({id, getTransactionFunc}) => {
    const _ = useTranslation();
    const {setIsLoading} = useLoading();

    const [transaction, setTransaction] = React.useState<ITransaction | null>(null);

    const getTransactionReq = useCallback(async (id: string) => {
        setIsLoading(true);
        const req = await getTransactionFunc({transactionId: id});
        setTransaction({
            ...req.data,
        });
        setIsLoading(false);
    }, [setIsLoading]);

    React.useEffect(() => {
        getTransactionReq(id).then();
    }, [id, getTransactionReq]);

    return (
        <>
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10 mb-7 mb-lg-10">
                <TransactionDetailCard title={_('TransactionDetail.ORDER_DETAIL_TITLE')} items={[
                    {icon: 'disconnect', title: _('TransactionDetail.TRANSACTION_ID'), value: transaction?.id},
                    {icon: 'home', title: _('TransactionDetail.MERCHANT_NAME'), value: transaction?.merchantName},
                    {icon: 'user', title: _('TransactionDetail.EMAIL'), value: transaction?.buyerEmail},
                    {icon: 'to-right', title: _('TransactionDetail.STATUS'), value: transaction ? <TransactionStatus status={transaction?.status} /> : null},
                    {icon: 'notepad', title: _('TransactionDetail.CREATED_AT'), value: transaction?.createdAt.toString()},
                    {icon: 'notepad', title: _('TransactionDetail.UPDATED_AT'), value: transaction?.updatedAt.toString()},
                    {icon: 'abstract-23', title: _('TransactionDetail.PAYMENT_ADDRESS'), value: transaction?.paymentAddress},
                ]} />
                <TransactionDetailCard title={_('TransactionDetail.AMOUNT_DETAIL_TITLE')} items={[
                    {icon: 'bitcoin', title: _('TransactionDetail.TOTAL_AMOUNT_AS_FIAT'), value: transaction ? <FiatValue fiat={amountWithCurrency(transaction.productTotalFiat, transaction.productCurrency)} /> : null},
                    {icon: 'bitcoin', title: _('TransactionDetail.TOTAL_AMOUNT_AS_COIN'), value: transaction ? <Balance amount={transaction?.totalAmountAsCoin} code={transaction.coinSymbol} isTestNetwork={transaction.isTestNetwork} /> : null},
                    {icon: 'bitcoin', title: _('TransactionDetail.TOTAL_AMOUNT_AS_USD'), value: transaction ? <UsdValue amount={transaction.estimatedPaidAmount} /> : null},
                    {icon: 'double-check', title: _('TransactionDetail.PAID_FIAT'), value: transaction ? <FiatValue fiat={amountWithCurrency(transaction.estimatedPaidAmountFiat, transaction.productCurrency)} /> : null},
                    {icon: 'dollar', title: _('TransactionDetail.PAID_DOLLAR'), value: transaction ? <FiatValue fiat={amountWithCurrency(transaction.estimatedPaidAmount, 'USD')} /> : null},
                    {icon: 'bitcoin', title: _('TransactionDetail.PAID_AMOUNT_AS_COIN'), value: transaction ? <Balance amount={transaction?.paidAmountAsCoin} code={transaction.coinSymbol} isTestNetwork={transaction.isTestNetwork} /> : null},
                    {icon: 'bitcoin', title: _('TransactionDetail.FEE_AS_COIN'), value: transaction ? <Balance amount={transaction?.feeAsCoin} code={transaction.coinSymbol} isTestNetwork={transaction.isTestNetwork} /> : null},
                ]} />
            </div>

            {transaction && (
                <TransactionDetailProducts products={[
                    {name: transaction?.productName, quantity: transaction?.productCount, price: transaction?.productPrice},
                ]} transaction={transaction} />
            )}

            {transaction && (
                <TransactionBlockchainTransactionTable blockchainTransactions={transaction.blockchainTransactions} />
            )}


            {/*Blockchain transactions*/}
        </>
    );
};

export default TransactionDetail;