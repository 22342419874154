import UserDetail from '@/app/pages/common/userDetail/UserDetail';
import {FC} from 'react';

interface Props {

}

const EmployeeDetail: FC<Props> = (props) => {
    return <UserDetail />;
};

export default EmployeeDetail;