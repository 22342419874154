import React, {FC, useCallback, useEffect} from 'react';
import {ToolbarWrapper} from '@/_metronic/layout/components/toolbar';
import clsx from 'clsx';
import {KTIcon} from '@/_metronic/helpers';
import {useDevice} from '@/app/modules/providers/DeviceProvider';
import {IUser} from '@/app/interfaces';
import UserFilters from '@/app/pages/admin/users/UserFilters';
import Card from '@/app/modules/components/card/Card';
import {UsersTableHead} from '@/app/utils/tableHeads/admin/UsersTableHead';
import {userTr} from '@/app/pages/admin/users/userTr';
import CreateUpdateUserModal from '@/app/pages/admin/users/CreateUpdateUserModal';
import {toast} from 'react-toastify';
import useDatatable, {GetDataFunction} from '@/app/modules/hooks/useDataTable';
import {IAdminUserListFilters} from '@/app/interfaces/filters';
import {initialAdminUserListSort} from '@/app/utils/initialSorts';
import {initialAdminUserListFilters} from '@/app/utils/initialFilters';
import {ICreateUpdateUserValues} from '@/app/interfaces/values';
import {createUser, getUsers} from '@/app/utils/requests/adminRequests';
import {useTranslation} from '@/app/modules/hooks/useTranslation';
import {getAdditionalBreadcrumb} from '@/app/modules/helpers';
import {useLang} from '@/_metronic/i18n/Metronici18n';

interface Props {
    id?: string;
}

const Users: FC<Props> = ({id}) => {
    const _ = useTranslation();
    const [showCreateUserModal, setShowCreateUserModal] = React.useState<boolean>(false);
    const {selectedLang} = useLang();

    const getData: GetDataFunction<IUser, IAdminUserListFilters> = useCallback(async ({sort, pageIndex, pageSize, filters}) => {
        const params = {
            sort,
            pageIndex,
            pageSize,
            orFilters: filters.searchQuery
                ? {
                    'fullName': filters.searchQuery,
                    'email': filters.searchQuery,
                }
                : undefined,
            exactFilters: {
                ...(filters.merchantID && {'merchant.id': filters.merchantID}),
                ...(id && {'merchant.id': id}),
                ...(filters.role && {'userType': filters.role}),
            },
        };
        const {data} = await getUsers(params);
        if (!data) return Promise.reject();
        return data;
    }, [id]);
    const {
        handleFilterSubmit,
        handleFilterReset,
        handleFilterChange,
        filters,
        DataTableComponent,
        getDataCallback,
    } = useDatatable<IUser, IAdminUserListFilters>({
        initialSort: initialAdminUserListSort,
        initialFilters: initialAdminUserListFilters,
        getData,
        id: 'users',
    });

    useEffect(() => {
        if (id) {
            handleFilterChange('merchantID', id);
        } else {
            handleFilterChange('merchantID', null);
        }
    }, [handleFilterChange, id]);

    const handleCreateUser = async (user: ICreateUpdateUserValues) => {
        const createReq = await createUser({
            email: user.email,
            fullName: user.fullName,
            phoneNumber: user.phoneNumber,
            userType: user.userType,
        });
        if (!createReq.data) return Promise.reject();
        setShowCreateUserModal(false);
        toast.success(_('Users.USER_CREATED'));
        await getDataCallback();
    };

console.log('selectedLang', selectedLang);
    const {isMobile} = useDevice();
    return (
        <>
            <CreateUpdateUserModal
                show={showCreateUserModal}
                handleClose={() => setShowCreateUserModal(false)}
                handleSubmit={handleCreateUser}
            />
            <ToolbarWrapper title={_('Users.TITLE')} onlyTitle={!!id} isMerchantSubMenu={!!id} additionalBreadcrumb={id ? getAdditionalBreadcrumb() : undefined}>
                {!id && (
                    <div className={'d-flex align-items-center gap-3 gap-lg-3 toolsButtons'}>
                        <button onClick={() => setShowCreateUserModal(true)} className={clsx('btn btn-outline btn-outline-primary', {
                            'btn-sm': !isMobile,
                        })}>
                            <KTIcon iconName={'plus'} className={'fs-2 me-1'} />
                            {_('Users.ADD_USER')}
                        </button>
                    </div>
                )}
            </ToolbarWrapper>

            <Card>
                <UserFilters merchantID={id} handleFilterSubmit={handleFilterSubmit} handleFilterReset={handleFilterReset} handleFilterChange={handleFilterChange} filters={filters} />
                <DataTableComponent head={UsersTableHead} renderRow={(data, index) => userTr(data, index, _, selectedLang)} />
            </Card>
        </>
    );
};

export default Users;