/* eslint-disable react/jsx-no-target-blank */
import {KTIcon} from '../../../helpers';
import {useAuth} from '../../../../app/modules/auth';
import {useTranslation} from '@/app/modules/hooks/useTranslation';

const SidebarFooter = () => {
    const _ = useTranslation();
    const {logout} = useAuth();
    return (
        <div className="app-sidebar-footer flex-column-auto pt-2 pb-6 px-6" id="kt_app_sidebar_footer">
            <button
                className="btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100"
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                data-bs-dismiss-="click"
                title={_('General.LOGOUT')}
                onClick={logout}
            >
                <span className="btn-label">{_('General.LOGOUT')}</span>
                <KTIcon iconName="document" className="btn-icon fs-2 m-0" />
            </button>
        </div>
    );
};

export {SidebarFooter};
